import { Box, BoxProps } from '@chakra-ui/react';
import { useDrag } from '@use-gesture/react';
import * as math from 'mathjs';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  LineSegment,
  LineSegment2,
  Point,
} from '../../../domain/geometry/geometric-types';
import { getCanvasMousePosition } from '../../common/ForgeViewer';
import { projectInDOM, useCameraChangedEvent } from './util';
import { MetricLengthLabel } from './MetricLengthLabel';

type EdgeProps = BoxProps & {
  segment: LineSegment;
  viewer: Autodesk.Viewing.Viewer3D;
};
export const Edge = ({ segment, viewer, ...rest }: EdgeProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  // Just use an empty object so we can notify the component when the camera changes
  const [cameraPosition, setCameraPosition] = useState({});

  const positions2D = useMemo<LineSegment2>(
    () => [projectInDOM(segment[0], viewer), projectInDOM(segment[1], viewer)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cameraPosition, segment, viewer]
  );

  useCameraChangedEvent(
    viewer,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  const vertexElementRadius = 3.5; //To avoid onClick conflicts
  const length = math.distance(positions2D[0], positions2D[1]) as number;

  if (length < 1) {
    return null;
  }

  const [x1, y1] = positions2D[0];
  const [x2, y2] = positions2D[1];
  const offsetY = (ref.current?.getBoundingClientRect()?.height ?? 0) / length;
  const angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;

  return (
    <>
      <Box
        ref={ref}
        position="absolute"
        zIndex={1}
        width="4px"
        backgroundColor="green.500"
        // Placed in style for performance reasons
        style={{
          top: y1 - offsetY,
          left: x1,
          height: `${length}px`,
          transformOrigin: `1px 0`,
          transform: `rotate(${angle - 90}deg)`,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...rest}
      ></Box>
      {isHovered ? (
        <MetricLengthLabel segment={segment} viewer={viewer} />
      ) : null}
    </>
  );
};

type DraggableEdgeProps = EdgeProps & {
  onEdgeDrag?: (event: React.MouseEvent) => void;
};

export const DraggableEdge = ({ onEdgeDrag, ...rest }: DraggableEdgeProps) => {
  const bindDrag = useDrag<React.MouseEvent>(
    (state) => {
      if (onEdgeDrag) {
        onEdgeDrag(state.event);
      }
    },
    {
      filterTaps: true,
    }
  );
  return (
    <Edge
      cursor={'move'}
      _hover={{
        filter: 'brightness(1.3)',
      }}
      {...bindDrag()}
      {...rest}
    />
  );
};

export const getPositionOnEdge = (
  event: React.MouseEvent,
  segment: LineSegment,
  viewer: Autodesk.Viewing.Viewer3D
): Point => {
  const { canvasX, canvasY } = getCanvasMousePosition(
    event.clientX,
    event.clientY
  );
  const camera = viewer.navigation.getCamera();
  const containerBounds = viewer.navigation.getScreenViewport();
  const startThree = new THREE.Vector3(...segment[0]);
  const startProjected = startThree.project(camera);
  const endThree = new THREE.Vector3(...segment[1]);
  const endProjected = endThree.project(camera);
  const start2D = [
    Math.round(((startProjected.x + 1) / 2) * containerBounds.width),
    Math.round(((-startProjected.y + 1) / 2) * containerBounds.height),
  ];

  const end2D = [
    Math.round(((endProjected.x + 1) / 2) * containerBounds.width),
    Math.round(((-endProjected.y + 1) / 2) * containerBounds.height),
  ];

  const segmentLength = math.distance(start2D, end2D) as number;

  const distanceFromStart = math.distance(start2D, [
    canvasX,
    canvasY,
  ]) as number;

  const ratioAlongLine = distanceFromStart / segmentLength;

  const mousePositionProjected = math.add(
    startProjected.toArray(),
    math.multiply(
      ratioAlongLine,
      math.subtract(endProjected.toArray(), startProjected.toArray())
    )
  ) as Point;

  return new THREE.Vector3(...mousePositionProjected)
    .unproject(camera)
    .toArray() as Point;
};
