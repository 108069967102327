import { Auth } from '@aws-amplify/auth';
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { useMemo } from 'react';
import { useClient } from 'urql';
import awsExports from '../aws-exports';
import { GetExternalAwsCredentialsDocument } from '../gql/graphql';

const toAbsoluteUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  } else {
    const { origin } = window.location;
    return `${origin}${url}`;
  }
};

export const useS3Client = (projectId: string) => {
  const urqlClient = useClient();
  const s3Client = useMemo(() => {
    if (awsExports.local_s3_endpoint) {
      return new S3Client({
        region: 'local',
        endpoint: toAbsoluteUrl(awsExports.local_s3_endpoint),
        forcePathStyle: true,
        credentials: {
          accessKeyId: 'foo',
          secretAccessKey: 'bar',
        },
      });
    } else {
      return new S3Client({
        region: awsExports.aws_region,
        credentials: async () => {
          try {
            const session = await Auth.currentSession();
            const provider = fromCognitoIdentityPool({
              clientConfig: {
                region: awsExports.aws_region,
              },
              identityPoolId: awsExports.aws_cognito_identity_pool_id,
              logins: {
                [`cognito-idp.${awsExports.aws_region}.amazonaws.com/${awsExports.aws_user_pools_id}`]:
                  session.getIdToken().getJwtToken(),
              },
            });
            return provider();
          } catch (e) {
            const credentials = await urqlClient.query(
              GetExternalAwsCredentialsDocument,
              {
                projectId,
              },
              {
                requestPolicy: 'network-only',
              }
            );

            if (
              credentials.error ||
              !credentials.data?.externalAwsCredentials
            ) {
              throw new Error('Failed to get external AWS credentials');
            }

            return {
              accessKeyId: credentials.data.externalAwsCredentials.accessKeyId,
              secretAccessKey:
                credentials.data.externalAwsCredentials.secretAccessKey,
              sessionToken:
                credentials.data.externalAwsCredentials.sessionToken,
              expiration: new Date(
                credentials.data.externalAwsCredentials.expiration
              ),
            };
          }
        },
      });
    }
  }, [projectId, urqlClient]);

  return s3Client;
};
