import { maxBy, sum } from 'lodash';
import invariant from 'tiny-invariant';
import { makeHull } from '../../convex-hull';
import {
  MultiPolygonResult,
  Point2,
  ResultType,
  Triangle,
} from '../../geometric-types';
import { findPlanes, toPlanarPoints } from '../util/plane';
import { calculateArea, ringArea } from '../util/polygon';

// Finds the area of the largest approximately flat subsurface of a triangle tesselated surface
// Then rotates that plane to XY, finds a 2D convex hull, and returns the area of the hull.
export function calculateGrossSideAreaConvexHull(
  triangles: Triangle[],
  maxMatchDistancePlanes?: number
): MultiPolygonResult {
  const planes = findPlanes(triangles, maxMatchDistancePlanes);
  const largestPlane = maxBy(planes, (group) =>
    sum(group.triangles.map(calculateArea))
  );

  invariant(largestPlane, 'Should have found at least one plane');

  const points = largestPlane.triangles.flatMap((triangle) => triangle);

  const xyPoints = toPlanarPoints(points, largestPlane.plane);
  const pointsAsObjects = xyPoints.map((point, index) => ({
    index,
    x: point[0],
    y: point[1],
  }));
  const hullPoints = makeHull(pointsAsObjects);
  const hullPoints3d = hullPoints.map((p) => points[p.index]);
  const hullRing: Point2[] = [...hullPoints, hullPoints[0]].map((p) => [
    p.x,
    p.y,
  ]);

  const area = Math.abs(ringArea(hullRing));

  return {
    type: ResultType.MULTIPOLYGON,
    value: area,
    plane: largestPlane.plane,
    multiPolygon: {
      polygons: [
        {
          exterior: [...hullPoints3d, hullPoints3d[0]],
          interiors: [],
        },
      ],
    },
  };
}
