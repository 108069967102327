import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  DeleteCommentMutation,
  DeleteCommentMutationVariables,
  ListAllCommentsDocument,
  CreateCommentMutationMutation,
  CreateCommentMutationMutationVariables,
} from '../../gql/graphql';

export const createCommentResolver: UpdateResolver = (
  result: CreateCommentMutationMutation,
  args: CreateCommentMutationMutationVariables,
  cache
) => {
  cache.updateQuery(
    {
      query: ListAllCommentsDocument,
    },
    (data) => {
      if (result.createComment?.comment && data?.comments) {
        data.comments.push(result.createComment.comment);
      }
      return data || null;
    }
  );
  if (result?.createComment?.comment?.savedViews?.id) {
    // Invalidating for simplicity now, but feel free to update the cache instead
    cache.invalidate({
      __typename: 'Query',
      field: 'SavedViews', // Replace with the actual field name in your query
    });
  }
};

export const deleteCommentResolver: UpdateResolver = (
  result: DeleteCommentMutation,
  args: DeleteCommentMutationVariables,
  cache
) => {
  if (result?.deleteComment?.comment?.id) {
    // Invalidating for simplicity now, but feel free to update the cache instead
    cache.invalidate({
      __typename: 'Comment',
      id: result.deleteComment.comment?.id,
    });
  }
};
