import { Button } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useQuery } from 'urql';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import { useExportState } from '../common/ExportStateManagerContext';
import { GetOrderDeepDocument, OrderDeepFragment } from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';

type ProtanTableExporterProps = {
  orderId: string;
};

export const ProtanTableExporter = ({ orderId }: ProtanTableExporterProps) => {
  const { triggerExport, isExporting } = useExportState();
  const [{ data: orderData }] = useQuery({
    query: GetOrderDeepDocument,
    variables: {
      id: orderId,
    },
  });

  const orderToExport = orderData?.order as OrderDeepFragment;

  const handleExport = useCallback(() => {
    triggerExport([orderToExport], ['SKU', 'Quantity']);
  }, [orderToExport, triggerExport]);

  return (
    <Button
      isLoading={isExporting}
      onClick={handleExport}
      leftIcon={<SparkelIcon icon={faShare} color={'inherit'} />}
      colorScheme="blue"
    >
      Export to Protan Webshop
    </Button>
  );
};
