import * as math from 'mathjs';
import {
  LineSegment,
  LineSegment2,
  MultiPolyline,
  MultiPolyline2,
  Point2,
  Polyline,
  Polyline2,
} from '../../geometric-types';

export function calculateSegmentLength(
  segment: LineSegment | LineSegment2
): number {
  return math.norm(math.subtract(segment[0], segment[1])) as number;
}

// Note: Only works for Polyline2 currently
export const getPolylineMidpoint = (polyline: Polyline | Polyline2): Point2 => {
  // Calculate the total length of the polyline
  let totalLength = 0;
  for (let i = 1; i < polyline.length; i++) {
    const dx = polyline[i][0] - polyline[i - 1][0];
    const dy = polyline[i][1] - polyline[i - 1][1];
    totalLength += Math.sqrt(dx * dx + dy * dy);
  }

  // Find the midpoint
  let currentLength = 0;
  for (let i = 1; i < polyline.length; i++) {
    const dx = polyline[i][0] - polyline[i - 1][0];
    const dy = polyline[i][1] - polyline[i - 1][1];
    const segmentLength = Math.sqrt(dx * dx + dy * dy);

    if (currentLength + segmentLength > totalLength / 2) {
      // The midpoint is on this segment
      const remainingLength = totalLength / 2 - currentLength;
      const ratio = remainingLength / segmentLength;
      const midX = polyline[i - 1][0] + ratio * dx;
      const midY = polyline[i - 1][1] + ratio * dy;

      return [midX, midY];
    }

    currentLength += segmentLength;
  }

  // If we get here, something went wrong (e.g., empty polyline)
  // Return the last point as a fallback
  return [polyline[polyline.length - 1][0], polyline[polyline.length - 1][1]];
};

export function calculatePolylineLength(
  polyline: Polyline | Polyline2
): number {
  let totalLength = 0;
  for (let i = 0; i < polyline.length - 1; i++) {
    const segment = [polyline[i], polyline[i + 1]] as
      | LineSegment
      | LineSegment2;
    totalLength += calculateSegmentLength(segment);
  }
  return totalLength;
}

export function calculateMultiPolylineLength(
  polylines: MultiPolyline | MultiPolyline2
): number {
  let totalLength = 0;
  for (const polyline of polylines.lines) {
    totalLength += calculatePolylineLength(polyline);
  }
  return totalLength;
}
