import { maxBy, sum } from 'lodash';
import invariant from 'tiny-invariant';
import {
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';
import { degToRad } from '../util';
import {
  buildHalfEdgeGraph,
  findSegmentBorders,
  getTriangleForFace,
  segmentByAngle,
  triangulateBorder,
} from '../util/mesh';
import { calculateArea } from '../util/polygon';

export function calculateLargestSurfaceAreaWithoutOpenings(
  triangles: Triangle[]
): TriangulatedSurfaceResult | undefined {
  const graph = buildHalfEdgeGraph(triangles);
  const segments = segmentByAngle(graph, degToRad(25));
  const segmentsWithArea = segments.map((segment) => {
    const triangles = segment.faceIndices.map((i) =>
      getTriangleForFace(graph.faces[i])
    );
    const area = sum(triangles.map((triangle) => calculateArea(triangle)));
    return { area, triangles, segment };
  });
  const largestSegment = maxBy(segmentsWithArea, (segment) => segment.area);

  if (!largestSegment) {
    return undefined;
  }

  const borders = findSegmentBorders(graph, largestSegment.segment.faceIndices);
  if (borders.length === 0) {
    return undefined;
  } else if (borders.length === 1) {
    // base case, no borders
    return {
      type: ResultType.TRIANGULATED_SURFACE,
      value: largestSegment.area,
      triangles: largestSegment.triangles,
    };
  } else {
    const triangulatedBorders = borders.map((border) =>
      triangulateBorder(graph, border)
    );

    const triangulatedBordersWithFlatArea = triangulatedBorders.map(
      (triangulation) => {
        const area = sum(
          triangulation.map((triangle) => calculateArea(triangle))
        );
        return { area, triangulation };
      }
    );
    const largestBorder = maxBy(triangulatedBordersWithFlatArea, (border) =>
      Math.abs(border.area)
    );
    invariant(largestBorder, 'No largest border found');

    const triangulatedHoles = triangulatedBordersWithFlatArea.filter(
      (border) => border !== largestBorder
    );

    return {
      type: ResultType.TRIANGULATED_SURFACE,
      value:
        largestSegment.area +
        sum(triangulatedHoles.map((hole) => Math.abs(hole.area))),
      triangles: [
        ...largestSegment.triangles,
        ...triangulatedHoles.flatMap((border) => border.triangulation),
      ],
    };
  }
}
