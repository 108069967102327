import { chakra } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import {
  projectInDOM,
  useCameraChangedEvent,
} from '../../../components/orderContractor/shapes/util';
import {
  MultiPolylineResult,
  MultiPolyline2,
} from '../../../domain/geometry/geometric-types';

type PolylineResultVisualizationProps = {
  result: MultiPolylineResult;
  viewer: Autodesk.Viewing.Viewer3D;
};
export const PolylineResultVisualization = ({
  result,
  viewer,
}: PolylineResultVisualizationProps) => {
  // Just use an empty object so we can notify the component when the camera changes
  const [cameraPosition, setCameraPosition] = useState({});

  const result2d = useMemo<MultiPolyline2>(
    () => ({
      lines: result.multiPolyline.lines.map((polyline) =>
        polyline.map((point) => projectInDOM(point, viewer))
      ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cameraPosition, result, viewer]
  );

  useCameraChangedEvent(
    viewer,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  return (
    <chakra.svg position="absolute" top="0" left="0" width="100%" height="100%">
      {result2d.lines.map((polyline, index) => (
        <chakra.polyline
          key={index}
          points={polyline.map(([x, y]) => `${x},${y}`).join(' ')}
          strokeWidth="5"
          stroke="teal.400"
          fill={'none'}
        />
      ))}
    </chakra.svg>
  );
};
