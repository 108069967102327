import {
  MultiPolylineResult,
  Polyline,
  ResultType,
} from '../../geometric-types';
import { calculatePolylineLength } from '../util/line-segment';

export function calculateLineLength(points: Polyline): MultiPolylineResult {
  const value = calculatePolylineLength(points);

  return {
    type: ResultType.MULTIPOLYLINE,
    value,
    multiPolyline: { lines: [points] },
  };
}
