import {
  StyleConfig,
  StyleFunctionProps,
  extendTheme,
  withDefaultColorScheme,
  type ThemeConfig,
} from '@chakra-ui/react';
import { defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';

const config: ThemeConfig = {
  initialColorMode: 'light',
};

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

// Coped from the Chakra source at https://github.com/chakra-ui/chakra-ui/blob/2456a7090439ebd1a1d1cdee36daa472d12ce6f7/packages/components/theme/src/components/button.ts#LL75C1-L90C1
/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

const variantSolidNoTransparent = defineStyle((props) => {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = mode(`gray.100`, `gray.700`)(props);

    return {
      bg,
      color: mode(`gray.800`, `gray.200`)(props),
      _hover: {
        bg: mode(`gray.200`, `gray.600`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `gray.600`)(props) },
    };
  }

  const {
    bg = `${c}.500`,
    color = 'white',
    hoverBg = `${c}.600`,
    activeBg = `${c}.700`,
  } = accessibleColorMap[c] ?? {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        bg: background,
      },
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
});

const components: Record<string, StyleConfig> = {
  Heading: {
    baseStyle: (props) => ({
      color: mode('gray.800', 'gray.200')(props),
    }),
  },
  Link: {
    baseStyle: {
      _hover: {
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
  },
  Breadcrumb: {
    baseStyle: (props) => ({
      link: {
        _hover: {
          fontWeight: 'bold',
          textDecoration: 'none',
          color: mode('gray.600', 'gray.200')(props),
        },
      },
    }),
  },
  IconButton: {
    variants: {
      'solid-no-transparent': variantSolidNoTransparent,
    },
  },
  Button: {
    variants: {
      'solid-no-transparent': variantSolidNoTransparent,
      toggle: (props) => ({
        _active: {
          backgroundColor: mode('teal.50', '#283A40')(props),
        },
      }),
    },
    sizes: {
      md: {
        borderRadius: '3xl',
      },
      sm: {
        borderRadius: '3xl',
      },
      xs: {
        borderRadius: '3xl',
      },
    },
    defaultProps: {
      colorScheme: 'brand',
    },
  },
  Checkbox: {
    variants: {
      rounded: {
        control: {
          borderRadius: '50%',
        },
      },
      'rounded-noanimate': {
        control: {
          borderRadius: '50%',
          transition: 'none',
        },
        icon: {
          transition: 'none',
          animation: 'none',
        },
      },
    },
    defaultProps: {
      colorScheme: 'cyan',
    },
  },
  Modal: {
    baseStyle: (props) => ({
      dialog: {
        backgroundColor: mode('white', 'gray.800')(props),
        borderRadius: '2xl',
      },
      overlay: {
        background: mode(
          'rgba(113, 128, 150, 0.2)',
          'rgba(23, 25, 35, 0.5)'
        )(props),
        backdropFilter: 'blur(4px)',
      },
    }),
    sizes: {
      md: {
        dialog: {
          borderRadius: '2xl',
        },
      },
      xl: {
        dialog: {
          borderRadius: '2xl',
        },
      },
      '6xl': {
        dialog: {
          borderRadius: '2xl',
        },
      },
      full: {
        dialog: {
          borderRadius: '2xl',
        },
      },
    },
  },
  Popover: {
    baseStyle: (props) => ({
      content: {
        backgroundColor: mode('white', 'gray.800')(props),
      },
    }),
    variants: {
      responsive: {
        popper: {
          maxWidth: 'unset',
          width: 'unset',
          minWidth: 'unset',
        },
      },
    },
  },
  Drawer: {
    baseStyle: (props) => ({
      dialog: {
        backgroundColor: mode('white', 'gray.800')(props),
      },
    }),
    variants: {
      alwaysOpen: {
        dialog: {
          pointerEvents: 'auto',
        },
        dialogContainer: {
          pointerEvents: 'none',
        },
      },
    },
  },
  Table: {
    sizes: {
      md: {
        th: {
          paddingStart: 2,
          paddingEnd: 1,
        },
        td: {
          paddingStart: 2,
          paddingEnd: 1,
          py: 2,
        },
      },
    },
    baseStyle: (props) => ({
      tr: {
        td: {
          borderColor: mode('gray.100', 'gray.600')(props),
        },
        _last: {
          td: {
            borderBottomWidth: '0px',
          },
        },
      },
      th: {
        textTransform: 'none',
      },
    }),
  },
  Badge: {
    sizes: {
      md: {
        borderRadius: '3xl',
      },
    },
  },
  Accordion: {
    baseStyle: (props) => ({
      button: {
        _hover: {
          backgroundColor: mode('gray.50', 'gray.700')(props),
        },
      },
    }),
    variants: {
      'panel-accordion': {
        container: {
          borderTopWidth: '0px',
          borderBottomWidth: 'thin',
          _last: {
            borderBottomWidth: '0px',
          },
        },
        button: {
          py: '1',
          px: '2',
          fontSize: 'sm',
        },
      },
    },
  },
  Tabs: {
    variants: {
      'panel-tabs': (props) => ({
        tab: {
          fontWeight: 'normal',
          textColor: mode('gray.400', 'gray.500')(props),
          pl: 0,
          py: 0,
          _selected: {
            fontWeight: 'bold',
            textColor: mode('gray.800', 'gray.200')(props),
          },
          _hover: {
            textColor: mode('gray.800', 'gray.200')(props),
          },
        },
        tabpanel: {
          p: 0,
        },
      }),
      'soft-rounded': (props) => ({
        tab: {
          fontWeight: 'normal',
          textColor: 'gray.500',
          px: 4,
          py: 2,
          _selected: {
            pointerEvents: 'none',
            fontWeight: 'bold',
            textColor: mode(
              `${props.colorScheme}.700`,
              `${props.colorScheme}.200`
            )(props),
            backgroundColor: mode(
              `${props.colorScheme}.50`,
              '#4299E124'
            )(props),
          },
          _hover: {
            textColor: mode('gray.800', 'gray.200')(props),
          },
        },
      }),
    },
  },
  Select: {
    sizes: {
      sm: {
        field: {
          borderRadius: '2xl',
        },
      },
      md: {
        field: {
          borderRadius: '2xl',
        },
      },
    },
  },
  Input: {
    baseStyle: (props) => ({
      field: {
        _placeholder: {
          color: mode('gray.400', 'gray.500')(props),
        },
      },
    }),
    variants: {
      filled: (props) => ({
        field: {
          background: mode('gray.50', 'gray.700')(props),
          border: '1px solid',
          borderColor: mode('gray.200', 'gray.700')(props),
        },
      }),
    },
    sizes: {
      md: {
        field: {
          borderRadius: '3xl',
        },
      },
      sm: {
        field: {
          borderRadius: '3xl',
        },
      },
      xs: {
        field: {
          borderRadius: '3xl',
        },
      },
    },
    defaultProps: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      focusBorderColor: 'cyan.500',
    },
  },
  Textarea: {
    sizes: {
      md: {
        borderRadius: '3xl',
      },
    },
    defaultProps: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      focusBorderColor: 'cyan.500',
    },
  },
  Menu: {
    baseStyle: (props) => ({
      item: {
        fontSize: 'xs',
        bg: mode('white', 'gray.800')(props),
        _hover: {
          bg: mode('gray.50', 'gray.700')(props),
        },
      },
      list: {
        bg: mode('white', 'gray.800')(props),
        borderColor: mode('gray.50', 'gray.700')(props),
        borderRadius: '2xl',
        shadow: 'lg',
      },
    }),
  },
  Divider: {
    baseStyle: (props) => ({
      borderColor: mode('gray.200', 'gray.700')(props),
    }),
  },
  /*
  TODO: Find a way to style the toast without changing the alert
  Alert: {
    // This is to style the toast, which uses an alert under the hood
    baseStyle: {
      container: {
        top: '100px',
      },
    },
  },
  */
};

const theme = extendTheme(
  {
    config,
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          minHeight: '100vh',
          overflow: 'hidden',
          backgroundColor: mode('gray.50', 'gray.900')(props),
          color: mode('gray.800', 'gray.200')(props),
        },
        /* We're adding a class here as the "custom drag image" is created dynamically and appended to the body
         * so we can't use the `sx` prop as we normally would. Instead we assign it via the `element.className` property initialColorMode
         * the event handler.
         */
        '.sparkel-property-drag-image': {
          position: 'absolute',
          top: '-9999px',
          width: 'fit-content',
          fontSize: 'xs',
          color: mode('gray.500', 'gray.400')(props),
          borderRadius: 'lg',
          borderColor: mode('gray.200', 'gray.700')(props),
          borderWidth: '1px',
          paddingX: 2,
          paddingY: 1,
          zIndex: 9999,
          backgroundColor: mode('white', 'gray.800')(props),
        },
      }),
    },
    fonts: {
      body: 'Nunito, sans-serif',
      heading: 'Nunito, sans-serif',
    },
    components,
    colors: {
      brand: {
        50: '#EDFDFD',
        100: '#C4F1F9',
        200: '#9DECF9',
        300: '#76E4F7',
        400: '#0BC5EA',
        500: '#00B5D8',
        600: '#00A3C4',
        700: '#0987A0',
        800: '#086F83',
        900: '#065666',
      },
    },
  },
  withDefaultColorScheme({
    colorScheme: 'brand',
    components: ['Button'],
  })
);

export { theme };
