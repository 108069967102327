import { sortBy } from 'lodash';
import { MultiPolygon2Algorithm } from '../../algorithm-types';
import { MultiPolygon2Result, ResultType } from '../../geometric-types';
import { isPointWithinRing, ringArea } from '../util/polygon';

export const calculateSheetShapeGrossSideArea: MultiPolygon2Algorithm<
  MultiPolygon2Result
> = (multipolygon) => {
  let value = 0;
  let exteriorsWithArea = multipolygon.polygons.map((polygon) => ({
    exterior: polygon.exterior,
    area: Math.abs(ringArea(polygon.exterior)),
  }));
  // Exclude interior polygons from being included in the area
  exteriorsWithArea = sortBy(
    exteriorsWithArea,
    (polygon) => polygon.area
  ).reverse();

  const outerExteriors: typeof exteriorsWithArea = [];
  for (const exterior of exteriorsWithArea) {
    const isPolygonContainedInAnotherPolygon = outerExteriors.some(
      (relevantExterior) =>
        // Because a multipolygon doesn't contain intersections, it is sufficient to test a single point
        isPointWithinRing(relevantExterior.exterior, exterior.exterior[0])
    );
    if (!isPolygonContainedInAnotherPolygon) {
      outerExteriors.push(exterior);
      value += exterior.area;
    }
  }

  return {
    type: ResultType.MULTIPOLYGON2,
    value,
    multiPolygon: {
      polygons: outerExteriors.map((exteriorWithArea) => ({
        exterior: exteriorWithArea.exterior,
        interiors: [],
      })),
    },
  };
};
