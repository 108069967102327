import { clamp } from 'lodash';
import { ModuleThread, Pool, spawn } from 'threads';

import { MeshAlgorithmType } from '../geometry/algorithm-types';
import { GeometricResult, Triangle } from '../geometry/geometric-types';

// Worker pool logic placed here for clean separation of concerns and mockability

export type GeometricAlgorithmModule = {
  evaluateMeshAlgorithm: (
    triangles: Triangle[],
    algorithmType: MeshAlgorithmType
  ) => GeometricResult;
};
type WorkerPool = Pool<ModuleThread<GeometricAlgorithmModule>>;

const MAX_WORKERS = 12;
const POOL_SIZE = clamp(navigator.hardwareConcurrency - 1, 1, MAX_WORKERS);

let pool: WorkerPool | undefined;

export const createWorkerPool = () => {
  pool = Pool(
    () =>
      spawn<GeometricAlgorithmModule>(
        new Worker(new URL('./geometric-worker.ts', import.meta.url), {
          type: 'module',
        })
      ),
    POOL_SIZE
  );
};

export const getWorkerPool = () => {
  return pool;
};
