import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  ListUsersDocument,
  UpdateAccessibleProjectsForUserMutation,
  UpdateAccessibleProjectsForUserMutationVariables,
} from '../../gql/graphql';

export const createUserResolver: UpdateResolver = (
  result: CreateUserMutation,
  args: CreateUserMutationVariables,
  cache
) => {
  cache.updateQuery(
    {
      query: ListUsersDocument,
    },
    (data) => {
      if (result.createUser && data?.listUsers) {
        data.listUsers.push({
          id: result.createUser.id,
          email: result.createUser.email,
          displayName: result.createUser.displayName,
        });
      }
      return data;
    }
  );
};

export const deleteUserResolver: UpdateResolver = (
  result: DeleteUserMutation,
  args: DeleteUserMutationVariables,
  cache
) => {
  cache.updateQuery(
    {
      query: ListUsersDocument,
    },
    (data) => {
      if (result.deleteUser && data?.listUsers) {
        data.listUsers = data.listUsers.filter((user) => user.id !== args.id);
      }
      return data;
    }
  );
};

export const updateUserResolver: UpdateResolver = (
  result: UpdateAccessibleProjectsForUserMutation,
  args: UpdateAccessibleProjectsForUserMutationVariables,
  cache
) => {
  if (result.updateAccessibleProjectsForUser) {
    cache.invalidate('Query', 'listUsers');
  }
};
