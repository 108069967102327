import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'de_DE', 'no_NO', 'se_SE', 'fr_FR', 'pl_PL'],
    ns: [
      'common',
      'company',
      'header',
      'project',
      'paywall',
      'categories',
      'table',
      'protan',
    ],
    defaultNS: 'common',
    backend: {
      loadPath: '/locales/{{lng}}/default/{{ns}}.json',
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      caches: ['cookie'],
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
