import { ExtrudedPolygonAlgorithm } from '../../algorithm-types';
import {
  MultiPolyline,
  MultiPolylineResult,
  Point,
  Polyline,
  ResultType,
} from '../../geometric-types';

export const calculateExtrudedShapeThickness: ExtrudedPolygonAlgorithm<
  MultiPolylineResult
> = (polygon, plane, thickness) => {
  // Use the first point in the polygon and create a line that is thickness units away from the plane
  const firstPoint = polygon.polygons[0].exterior[0];
  const normal = plane.unitNormal;
  const secondPoint = firstPoint.map(
    (coord, i) => coord + normal[i] * thickness
  ) as Point;

  // Create a line between the two points
  const thicknessLine = {
    lines: [[firstPoint, secondPoint] as Polyline],
  } as MultiPolyline;

  return {
    type: ResultType.MULTIPOLYLINE,
    value: Math.abs(thickness),
    multiPolyline: thicknessLine,
  };
};
