import { maxBy } from 'lodash';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import {
  LineSegment,
  Point,
  MultiPolylineResult,
  ResultType,
  Triangle,
} from '../../geometric-types';
import { degToRad } from '../util';
import {
  find2DBoundingBoxes,
  findLargest2DBoundingBox,
} from '../util/bounding-box';
import { calculateSegmentLength } from '../util/line-segment';
import { calculatePlaneEquation, projectPointOnPlane } from '../util/plane';

// Max z-component of line direction for it to be considered horisontal
const MAX_Z_COMPONENT_HORISONTAL_LINE = math.sin(degToRad(3));

// Currently width is only well-defined for elements with horizontal length
export function calculateBoundingBoxWidth(
  triangles: Triangle[]
): MultiPolylineResult | undefined {
  const boundingBoxes = find2DBoundingBoxes(triangles);

  const largestBoundingBox = findLargest2DBoundingBox(
    boundingBoxes,
    MAX_Z_COMPONENT_HORISONTAL_LINE
  );

  if (!largestBoundingBox) {
    return undefined;
  }

  // First find the length segment
  const segmentCandidates = [
    [largestBoundingBox[0], largestBoundingBox[1]],
    [largestBoundingBox[0], largestBoundingBox[3]],
  ].filter(
    (segment) =>
      math.abs(segment[0][2] - segment[1][2]) < MAX_Z_COMPONENT_HORISONTAL_LINE
  ) as LineSegment[];
  const lengthSegment = maxBy(segmentCandidates, (segment) =>
    calculateSegmentLength(segment)
  );
  invariant(lengthSegment, 'Should have found a length segment');

  // Select the longest vector as projected down on the bounding box plane
  const lengthHeightPlane = calculatePlaneEquation([
    lengthSegment[0],
    lengthSegment[1],
    math.add(lengthSegment[0], [0, 0, 1]),
  ]);

  // Find the point that is farthest from the length-height plane
  const points = triangles.flatMap((t) => t);
  const farthestPoint = maxBy(points, (point) => {
    const closestPointOnPlane = projectPointOnPlane(point, lengthHeightPlane);
    return calculateSegmentLength([point, closestPointOnPlane]);
  });
  invariant(farthestPoint, 'Should have found a point farthest from the plane');

  // Find the vector from the closest point on the plane to the farthest point
  const closestPointOnPlane = projectPointOnPlane(
    farthestPoint,
    lengthHeightPlane
  );
  const widthSegment: LineSegment = [farthestPoint, closestPointOnPlane];
  const width = calculateSegmentLength([farthestPoint, closestPointOnPlane]);

  // Move widthSegment to the start of the length segment
  const widthVector: Point = math.subtract(widthSegment[1], widthSegment[0]);
  const movedWidthSegment = [
    lengthSegment[0],
    math.subtract(lengthSegment[0], widthVector),
  ];

  return {
    type: ResultType.MULTIPOLYLINE,
    value: width,
    multiPolyline: { lines: [movedWidthSegment] },
  };
}
