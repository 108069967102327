import { getCanvasMousePosition } from '../../../../components/common/ForgeViewer';
import { HitTestResult, MagicShapeResult } from './magic-shape-types';
import { getShapeByPlaneProjection } from './polygon-union-shape';

export function getShape(
  viewer: Autodesk.Viewing.Viewer3D,
  event: MouseEvent
): MagicShapeResult {
  const { canvasX, canvasY } = getCanvasMousePosition(
    event.clientX,
    event.clientY
  );

  const hitTest: HitTestResult | null = viewer.impl.hitTest(
    canvasX,
    canvasY,
    false
  );
  if (!hitTest) {
    return null;
  }

  try {
    return getShapeByPlaneProjection(viewer, hitTest);
  } catch (err) {
    console.error('Unable to get one click shape', err);
    return null;
  }
}
