import { MultiPolygonAlgorithm } from '../../algorithm-types';
import { MultiPolygonResult, ResultType } from '../../geometric-types';
import { toPlanarMultiPolygon } from '../util/plane';
import { toJstsMultiPolygon } from '../util/polygon';

export const calculateShapeArea: MultiPolygonAlgorithm<MultiPolygonResult> = (
  polygon,
  plane
) => {
  const planarPolyon = toPlanarMultiPolygon(polygon, plane);
  const jtsMultiPolygon = toJstsMultiPolygon(planarPolyon);
  const area = jtsMultiPolygon.getArea();

  return {
    type: ResultType.MULTIPOLYGON,
    value: area,
    multiPolygon: polygon,
    plane,
  };
};
