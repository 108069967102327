import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Divider,
  DividerProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { groupBy, sortBy } from 'lodash';
import React, {
  Context,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import SparkelIcon from '../../components/common/icon/SparkelIcon';

const MENU_ID = 'CONTEXT_MENU';

type TriggerEvent =
  | React.MouseEvent<HTMLDivElement>
  | React.TouchEvent<HTMLDivElement>
  | React.KeyboardEvent<HTMLDivElement>;

export type PredicateParams<Props = object, Data = object> = {
  triggerEvent: TriggerEvent;
  props?: Props;
  data?: Data;
};

export type ItemParams<Props = object, Data = object> = {
  triggerEvent: TriggerEvent;
  props?: Props;
  data?: Data;
};

type UseCustomContextMenuReturn = {
  bind: () => {
    onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseUp: (event: React.MouseEvent<HTMLDivElement>) => void;
    onMouseMove: (event: React.MouseEvent<HTMLDivElement>) => void;
    onTouchMove: (event: React.TouchEvent) => void;
    onTouchStart: (event: React.TouchEvent<HTMLDivElement>) => void;
    onTouchEnd: (event: React.TouchEvent<HTMLDivElement>) => void;
  };
  menuElement: React.ReactNode;
};

type MenuItemDefinition<MenuProps> = {
  id: string;
  label: string;
  group?: string;
  hidden?: (params: PredicateParams<MenuProps>) => boolean;
  icon?: React.ReactElement;
} & (
  | {
      onClick: (params: ItemParams<MenuProps>) => void;
    }
  | {
      subMenuItems: MenuItemDefinition<MenuProps>[];
    }
);

export type ContextMenuContextType<MenuProps> = {
  registerItem: (item: MenuItemDefinition<MenuProps>) => void;
  unregisterItem: (label: string) => void;
  items: MenuItemDefinition<MenuProps>[];
};

export function ContextMenuProvider<D>({
  children,
  context,
}: {
  children: React.ReactElement;
  context: Context<ContextMenuContextType<D>>;
}) {
  const [items, setItems] = useState<MenuItemDefinition<D>[]>([]);
  const registerItem: ContextMenuContextType<D>['registerItem'] = useCallback(
    (item) => {
      setItems((prevItems) => {
        // Replace existing item if it exists
        const existingItemIndex = prevItems.findIndex(
          (prevItem) => prevItem.id === item.id
        );

        if (existingItemIndex !== -1) {
          const newItems = [...prevItems];
          newItems[existingItemIndex] = item;
          return newItems;
        } else {
          return [...prevItems, item];
        }
      });
    },
    []
  );

  const unregisterItem: ContextMenuContextType<D>['unregisterItem'] =
    useCallback((id) => {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
    }, []);

  const contextValue = useMemo(
    () => ({ registerItem, unregisterItem, items }),
    [registerItem, unregisterItem, items]
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
}

type ContextMenuProps = BoxProps & {
  x: number;
  y: number;
  visible: boolean;
};

type ContextMenuState<MenuProps> = {
  x: number;
  y: number;
  visible: boolean;
  triggerEvent: TriggerEvent | null;
  props?: MenuProps;
};

function ContextMenu({
  x,
  y,
  visible,
  ...props
}: ContextMenuProps): React.ReactElement {
  // May not be 100% necessary, but it's good to be safe,
  const onContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box
      onContextMenu={onContextMenu}
      minWidth="3xs"
      borderRadius={'lg'}
      borderWidth="1px"
      borderStyle="solid"
      borderColor="var(--chakra-colors-chakra-border-color)"
      zIndex={3}
      position="absolute"
      backgroundColor="white"
      _dark={{
        backgroundColor: 'gray.700',
      }}
      top={y}
      left={x}
      shadow="lg"
      display={visible ? 'flex' : 'none'}
      flexDirection="column"
      alignItems="stretch"
      {...props}
    />
  );
}

type ContextMenuItemProps<MenuProps> = {
  hidden?: (params: PredicateParams<MenuProps>) => boolean;
  onClick: (params: ItemParams<MenuProps>) => void;
  menuState: ContextMenuState<MenuProps>;
} & Omit<ButtonProps, 'onClick' | 'hidden'>;

function ContextMenuItem<MenuProps>(
  props: ContextMenuItemProps<MenuProps>
): React.ReactElement {
  const { onClick, hidden, menuState, ...rest } = props;
  let hide = false;

  if (hidden && menuState.triggerEvent) {
    hide = hidden({
      triggerEvent: menuState.triggerEvent,
      props: menuState.props,
    });
  }

  const handleClick = () => {
    invariant(menuState.triggerEvent, 'No trigger event found');
    onClick({ triggerEvent: menuState.triggerEvent, props: menuState.props });
  };

  return (
    <Button
      display={hide ? 'none' : 'inline-flex'}
      variant="ghost"
      onClick={handleClick}
      justifyContent={'flex-start'}
      borderRadius={0}
      color="gray.700"
      size={'sm'}
      fontWeight="normal"
      _dark={{
        color: 'gray.300',
      }}
      {...rest}
    />
  );
}

type SeparatorProps = DividerProps;

function ContextMenuSeparator(props: SeparatorProps): React.ReactElement {
  return (
    <Divider
      color="gray.700"
      fontWeight="normal"
      _dark={{
        color: 'gray.300',
      }}
      {...props}
    />
  );
}

type SubMenuItemDefinition<MenuProps> = {
  label: string;
  hidden?: (params: PredicateParams<MenuProps>) => boolean;
  leftIcon?: React.ReactElement;
  menuState: ContextMenuState<MenuProps>;
} & Omit<BoxProps, 'hidden'>;

function ContextSubMenu<MenuProps>(
  props: SubMenuItemDefinition<MenuProps>
): React.ReactElement {
  const { hidden, label, leftIcon, menuState, ...rest } = props;
  let hide = false;

  if (hidden && menuState.triggerEvent) {
    hide = hidden({
      triggerEvent: menuState.triggerEvent,
      props: menuState.props,
    });
  }

  return (
    <Popover trigger="hover" placement="right-start" offset={[0, 0]} isLazy>
      <PopoverTrigger>
        <Button
          display={hide ? 'none' : 'inline-flex'}
          variant="ghost"
          size={'sm'}
          justifyContent={'space-between'}
          borderRadius={0}
          fontWeight="normal"
          color="gray.700"
          leftIcon={leftIcon}
          rightIcon={<SparkelIcon size="sm" icon={faChevronRight} />}
          _dark={{
            color: 'gray.300',
          }}
        >
          <Text as="span" textAlign={'start'} flexGrow={1}>
            {label}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent padding={0} width="3xs">
        <PopoverBody
          display="flex"
          flexDirection="column"
          fontWeight="normal"
          alignItems="stretch"
          padding={0}
        >
          {rest.children}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

// NOTE: A lower value could potentially make more sense, but that
// causes the menu to close before the user can click on it
const LONG_PRESS_DELAY = 600;

type ShowContextMenuParams<Props> = {
  event: TriggerEvent;
  props?: Props;
};

export type OnShowProps<Props> = {
  event: TriggerEvent;
  showContextMenu: (params: ShowContextMenuParams<Props>) => void;
};

export function useCustomContextMenu<MenuProps>(
  onTrigger?: (onShow: OnShowProps<MenuProps>) => void,
  menuItems: MenuItemDefinition<MenuProps>[] = [],
  groupOrder: string[] = []
): UseCustomContextMenuReturn {
  const [menuState, setMenuState] = useState<ContextMenuState<MenuProps>>({
    x: 0,
    y: 0,
    triggerEvent: null,
    visible: false,
  });

  useEffect(() => {
    const keyUpListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setMenuState((state) => ({ ...state, visible: false }));
      }
    };
    document.addEventListener('keyup', keyUpListener);
    return () => {
      document.removeEventListener('keyup', keyUpListener);
    };
  }, [setMenuState]);

  const showContextMenu = useCallback(
    (params: ShowContextMenuParams<MenuProps>) => {
      let x: number;
      let y: number;

      if (params.event.type === 'mouseup') {
        const event = params.event as React.MouseEvent;
        event.preventDefault();
        x = event.clientX;
        y = event.clientY;
      } else if (
        params.event.type === 'touchstart' ||
        params.event.type === 'touchend'
      ) {
        const event = params.event as React.TouchEvent;
        event.preventDefault();
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else {
        return;
      }

      setTimeout(() => {
        setMenuState({
          x,
          y,
          visible: true,
          triggerEvent: params.event,
          props: params.props,
        });
      }, 200);
    },
    []
  );

  const hideMenu = useCallback(() => {
    setMenuState((prev) => ({ ...prev, visible: false }));
  }, []);

  const isDraggingRef = useRef(false);

  const longPressTimer = useRef<ReturnType<typeof window.setTimeout> | null>(
    null
  );

  useEffect(() => {
    const hideMenuListener = () => {
      hideMenu();
    };

    document.addEventListener('click', hideMenuListener);
    return () => {
      document.removeEventListener('click', hideMenuListener);
    };
  }, [hideMenu]);

  const groupedItems = useMemo(
    () => groupBy(menuItems, (item) => item.group),
    [menuItems]
  );

  const sortedGroups = sortBy(Object.keys(groupedItems), (group) => {
    let order = groupOrder.indexOf(group);
    if (order === -1) {
      order = Infinity;
    }
    return order;
  });

  const menuElement = useMemo(
    () => (
      <ContextMenu
        id={MENU_ID}
        x={menuState.x}
        y={menuState.y}
        visible={menuState.visible}
      >
        {sortedGroups.map((group, index) => (
          <React.Fragment key={group}>
            {groupedItems[group].map((item) => {
              if ('subMenuItems' in item) {
                return (
                  <ContextSubMenu
                    key={item.id}
                    label={item.label}
                    hidden={item.hidden}
                    leftIcon={item.icon}
                    menuState={menuState}
                  >
                    {item.subMenuItems.map((subItem) => {
                      if ('subMenuItems' in subItem) {
                        console.warn(
                          'Multiple level submenus not supported yet'
                        );
                        return;
                      }
                      return (
                        <ContextMenuItem
                          key={subItem.label}
                          onClick={subItem.onClick}
                          hidden={subItem.hidden}
                          leftIcon={subItem.icon}
                          menuState={menuState}
                        >
                          {subItem.label}
                        </ContextMenuItem>
                      );
                    })}
                  </ContextSubMenu>
                );
              } else {
                return (
                  <ContextMenuItem
                    key={item.id}
                    onClick={item.onClick}
                    hidden={item.hidden}
                    leftIcon={item.icon}
                    menuState={menuState}
                  >
                    {item.label}
                  </ContextMenuItem>
                );
              }
            })}
            <ContextMenuSeparator hidden={index === sortedGroups.length - 1} />
          </React.Fragment>
        ))}
      </ContextMenu>
    ),
    [menuState, sortedGroups, groupedItems]
  );

  const handleShow = useCallback(
    async (event: TriggerEvent) => {
      if (onTrigger) {
        onTrigger({ event, showContextMenu });
      } else {
        showContextMenu({
          event,
        });
      }
    },
    [onTrigger, showContextMenu]
  );

  // Use mouse / touch events instead of contextmenu event
  // to avoid interfering with right click to drag interaction
  const bind: UseCustomContextMenuReturn['bind'] = useCallback(
    () => ({
      onContextMenu: (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      },
      onMouseUp(event) {
        if (event.button === 2 && !isDraggingRef.current) {
          handleShow(event);
        }
        isDraggingRef.current = false;
      },
      onMouseMove(event: React.MouseEvent<HTMLDivElement>) {
        if (event.buttons === 2) {
          isDraggingRef.current = true;
        }
      },
      onTouchMove: () => {
        if (longPressTimer.current !== null) {
          clearTimeout(longPressTimer.current);
          longPressTimer.current = null;
        }
      },
      onTouchStart: (event: React.TouchEvent<HTMLDivElement>) => {
        if (longPressTimer.current === null) {
          longPressTimer.current = setTimeout(() => {
            setTimeout(() => {
              handleShow(event);
            });
          }, LONG_PRESS_DELAY);
        }
      },
      onTouchEnd: () => {
        if (longPressTimer.current !== null) {
          clearTimeout(longPressTimer.current);
          longPressTimer.current = null;
        }
        hideMenu();
      },
    }),
    [hideMenu, handleShow]
  );

  return useMemo(
    () => ({
      bind,
      menuElement,
    }),
    [bind, menuElement]
  );
}
