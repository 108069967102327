export class ViewerPivotTool extends Autodesk.Viewing.ToolInterface {
  names: string[];
  viewer: Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D;

  constructor(
    viewer: Autodesk.Viewing.Viewer3D | Autodesk.Viewing.GuiViewer3D
  ) {
    super();
    this.names = ['viewer-pivot-point'];

    this.viewer = viewer;

    // Bypass TypeScript checks to delete the methods
    // According to https://aps.autodesk.com/blog/custom-tools-forge-viewer
    delete (this as any).activate;
    delete (this as any).getPriority;
    delete (this as any).handleButtonDown;
  }

  activate(): void {
    const navigation = this.viewer.navigation as any; // Using 'any' to bypass type checking
    if (navigation.setSelectionSetsPivot) {
      navigation.setSelectionSetsPivot(false);
    }
  }

  getPriority() {
    return 10;
  }

  handleButtonDown(event: MouseEvent, button: number): boolean {
    if (button === 0) {
      const hitTest = this.viewer.impl.hitTest(
        event.clientX,
        event.clientY - 68, // Offest due to header
        false
      );
      if (hitTest && hitTest.intersectPoint) {
        this.viewer.navigation.setPivotPoint(hitTest.intersectPoint);
      }
    }
    return false;
  }

  /*
  For debugging purposes
  addSphere(position: {x: number, y: number, z: number}) {
    const geom = new THREE.SphereGeometry(0.1, 8, 8);
    const material = new THREE.MeshBasicMaterial({ color: 0x48bb78 });
    const sphereMesh = new THREE.Mesh(geom, material);
    sphereMesh.position.set(position.x, position.y, position.z);

    const overlays = this.viewer.overlays as any;
    if (!overlays.hasScene('custom-scene')) {
        this.viewer.overlays.addScene('custom-scene');
    }

    this.viewer.overlays.addMesh(sphereMesh, 'custom-scene');
  }
  */
}
