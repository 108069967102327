import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateSheetShapeMutation,
  CreateSheetShapeMutationVariables,
  DeleteSheetShapeMutation,
  DeleteSheetShapeMutationVariables,
} from '../../gql/graphql';

export const createSheetShapeResolver: UpdateResolver = (
  result: CreateSheetShapeMutation,
  args: CreateSheetShapeMutationVariables,
  cache
) => {
  if (result.createSheetShape?.sheetShape) {
    const sheetShapesForProject = cache.resolve(
      {
        __typename: 'Project',
        id: result.createSheetShape.sheetShape.projectId,
      },
      'sheetShapes'
    ) as string[] | null;
    const sheetShapeToAdd = cache.keyOfEntity({
      __typename: 'SheetShape',
      id: result.createSheetShape.sheetShape.id,
    });
    if (sheetShapesForProject && sheetShapeToAdd) {
      sheetShapesForProject.push(sheetShapeToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createSheetShape.sheetShape.projectId,
        },
        'sheetShapes',
        sheetShapesForProject
      );
    }
  }
};

export const deleteSheetShapeResolver: UpdateResolver = (
  result: DeleteSheetShapeMutation,
  args: DeleteSheetShapeMutationVariables,
  cache
) => {
  if (result.deleteSheetShape?.sheetShape) {
    const sheetShapesForProject = cache.resolve(
      {
        __typename: 'Project',
        id: result.deleteSheetShape.sheetShape.projectId,
      },
      'sheetShapes'
    ) as string[] | null;
    const sheetShapeToRemove = cache.keyOfEntity({
      __typename: 'SheetShape',
      id: result.deleteSheetShape.sheetShape.id,
    });
    if (sheetShapesForProject && sheetShapeToRemove) {
      cache.link(
        {
          __typename: 'Project',
          id: result.deleteSheetShape.sheetShape.projectId,
        },
        'sheetShapes',
        sheetShapesForProject.filter((key) => key !== sheetShapeToRemove)
      );
    }
  }
};
