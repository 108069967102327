import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useParams } from 'react-router-dom';
import { ResolvedOrderEntry } from '../domain/resolve-order-entry';

import {
  ElementIdentifierDbidType,
  GetOrderLabelsDocument,
  GetOrderLabelsQuery,
  OrderDeepFragment,
  OrderEntryDeepFragment,
} from '../gql/graphql';

export type ResolvedOrder = Omit<
  OrderDeepFragment,
  'orderEntries' | 'labels'
> & {
  orderEntries: ResolvedOrderEntry[];
  labels: { key: string; value: string }[];
};

export type OrderEntryWithOrder = {
  orderEntry: OrderEntryDeepFragment;
  order: OrderDeepFragment;
};

export type ResolvedDbIdsReturn = {
  getDbIdsForOrder: (orderId: string) => ElementIdentifierDbidType[];
  findOrdersMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderDeepFragment[];
  findOrderEntriesMatchingDbIds: (
    searchDbIds: ElementIdentifierDbidType[]
  ) => OrderEntryWithOrder[];
  isLoading: boolean;
};
type UseOrderReturn = {
  labels: GetOrderLabelsQuery | null;
  location: string | undefined;
  fireRequirements: string | undefined;
  qkast: string | undefined;
  buildingHeight: string | undefined;
  loading: boolean;
  error: object | undefined;
};

export const useOrderLabelsProtan = (): UseOrderReturn => {
  const { orderId } = useParams<{
    orderId: string | undefined;
  }>() as { orderId: string };

  const [{ data: orderLabelsDocument, fetching, error: orderError }] = useQuery(
    {
      query: GetOrderLabelsDocument,
      variables: {
        id: orderId,
      },
    }
  );

  const labels: { key: string; value: string }[] = useMemo(
    () =>
      orderLabelsDocument?.order?.labels
        ? JSON.parse(orderLabelsDocument.order.labels)
        : [],
    [orderLabelsDocument?.order?.labels]
  );

  const location = labels.find((label) => label.key === 'Location')?.value;
  const fireRequirements = labels.find(
    (label) => label.key === 'Fire requirements'
  )?.value;
  const energyRequirements = labels.find(
    (label) => label.key === 'Energy requirements'
  )?.value;
  const qkast = labels.find((label) => label.key === 'Qₚ (N/m²)')?.value;
  const buildingHeight = labels.find(
    (label) => label.key === 'Building height (m)'
  )?.value;
  return {
    labels: orderLabelsDocument ?? null,
    location: location,
    fireRequirements: fireRequirements,
    qkast: qkast,
    buildingHeight: buildingHeight,
    loading: fetching,
    // This is to only make it true when we're "updating" an already resolved order, and not on the "first page load"
    error: orderError,
  };
};
