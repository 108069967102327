import { useCallback, useEffect, useMemo, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import { generateIdsForPoints, getArcPoints } from '../shapes/util';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import { useUserTenant } from '../../../services/auth-info';
import { SheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  ShapeDrawingMode,
  WedgesDrawingResult,
} from './SheetShapeDrawing';
import { DraggableVertex } from './SheetVertex';
import { IntermediateEdges } from './SheetPolygonShapeDrawing';
import { DiamondLabel } from './DiamondLabel';
import KileSelector from './KileSelector';
import { distanceBetweenPoints, kileSelector } from './kile-selector';

export enum KileTypeSKU {
  RW80110 = 'RW80110',
  RW80111 = 'RW80111',
  RW80112 = 'RW80112',
  RW257379 = 'RW257379',
  RW257426 = 'RW257426',
  RW257435 = 'RW257435',
  RW257437 = 'RW257437',
  RW257439 = 'RW257439',
  RW257440 = 'RW257440',
  RW257441 = 'RW257441',
  RW257442 = 'RW257442',
  INVALID_KILE = 'Invalid-kile',
}

export type Kile = {
  length: number;
  type: KileTypeSKU;
  name: string;
};

export type Diamond = {
  triangle1: boolean;
  triangle2: boolean;
  triangle3: boolean;
  triangle4: boolean;
};

// eslint-disable-next-line complexity
export const SheetWedgeShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Wedges];
  onResult: (result: WedgesDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [points, setPoints] = useState<Point2[]>(initialState?.points ?? []);
  const [diamonds, setDiamonds] = useState<Diamond[]>([
    {
      triangle1: true,
      triangle2: true,
      triangle3: true,
      triangle4: true,
    },
  ]);

  const [kileTypes, setKileTypes] = useState<KileTypeSKU[]>([]);
  const { isProtan } = useUserTenant();
  const [intermediatePoint, setIntermediatePoint] = useState<Point2 | null>(
    null
  );
  const {
    calibration: { calibration },
  } = useSheetViewer();
  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  const isShiftPressed = isHotkeyPressed('shift');
  const isAltKeyPressed = isHotkeyPressed('alt');

  const edges = useMemo(() => {
    const edges: LineSegment2[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      edges.push([points[i], points[i + 1]]);
    }
    return edges;
  }, [points]);

  const emitResult = useCallback(
    (points: Point2[], diamonds: Diamond[], kileType: KileTypeSKU[]) => {
      if (points.length > 1) {
        onResult({
          points,
          valid: true,
          diamond: diamonds,
          kileType: kileType,
        });
      } else {
        onResult({
          points,
          valid: false,
          diamond: diamonds,
          kileType: kileType,
        });
      }
    },
    [onResult]
  );
  const previousPointInDom = useMemo(() => {
    if (points.length === 0) {
      return null;
    }
    const pointInPageCoordinates = getPointInDomCoordinateSystem(
      points[points.length - 1]
    );
    return [pointInPageCoordinates[0], pointInPageCoordinates[1]];
  }, [getPointInDomCoordinateSystem, points]);

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      if (isShiftPressed) {
        return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
      }
      if (previousPointInDom) {
        const deltaX = event.clientX - previousPointInDom[0];
        const deltaY = event.clientY - previousPointInDom[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          return getPointInPdfCoordinateSystem([
            event.clientX,
            previousPointInDom[1],
          ]);
        } else {
          return getPointInPdfCoordinateSystem([
            previousPointInDom[0],
            event.clientY,
          ]);
        }
      }
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem, isShiftPressed, previousPointInDom]
  );

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0 || !unitScale) {
        return;
      }

      let newPoint = getPoint(event);

      let newPoints: Point2[] = [];

      if (isAltKeyPressed) {
        const endPoint = points.pop();
        const startPoint = points.pop();

        if (startPoint && endPoint) {
          const arcPoints = getArcPoints(startPoint, endPoint, newPoint);

          newPoints = [...points, ...arcPoints];
        }
      } else {
        newPoints = [...points, newPoint];
      }

      setPoints(newPoints);

      let newDiamonds = diamonds;
      let newKiler = kileTypes;

      if (newPoints.length > 1) {
        newDiamonds = [...diamonds, diamonds[diamonds.length - 1]];
        setDiamonds(newDiamonds);

        const length = distanceBetweenPoints(
          newPoints[newPoints.length - 2],
          newPoints[newPoints.length - 1],
          unitScale
        );

        const kile = kileSelector(newDiamonds[newDiamonds.length - 1], length);

        newKiler = [...kileTypes, kile as KileTypeSKU];
        setKileTypes(newKiler);
      }

      emitResult(newPoints, [...newDiamonds], [...newKiler]);
    },
    [
      diamonds,
      emitResult,
      getPoint,
      isAltKeyPressed,
      kileTypes,
      points,
      unitScale,
    ]
  );

  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    (event: MouseEvent) => {
      const newPoint = getPoint(event);

      setIntermediatePoint(newPoint);
    },
    [getPoint]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const handleVertexDrag = (event: MouseEvent, index: number) => {
    setIntermediatePoint(null);
    const newPoint = getPoint(event);
    const newPoints = [...points];
    newPoints[index] = newPoint;
    setPoints(newPoints);
    if (event.type === 'pointerup') {
      emitResult(newPoints, diamonds, kileTypes);
    }
  };

  const handleVertexClick = (event: React.MouseEvent, index: number) => {
    if (index !== points.length - 1) {
      const newPoints = [...points];
      newPoints.push(newPoints[index]);
      setPoints(newPoints);
      emitResult(newPoints, diamonds, kileTypes);
    }
  };

  const handleSetDiamondsFromSelector = (diamonds: Diamond[]) => {
    setDiamonds(diamonds);
    emitResult(points, diamonds, kileTypes);
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, index: number) => {
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const newPoints = [...points];
    newPoints.splice(index + 1, 0, newPoint);
    setPoints(newPoints);
    emitResult(newPoints, diamonds, kileTypes);
  };

  return (
    <>
      {edges.map((edge, index) => {
        // Don't render the last edge if alt is pressed
        if (index === edges.length - 1 && isAltKeyPressed) return;
        return (
          <SheetEdge
            // todo: index is not a good key. use a unique id
            key={index}
            segment={edge}
            cursor={'pointer'}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, index)}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
      {intermediatePoint && points.length > 0 && unitScale
        ? IntermediateEdges(
            intermediatePoint,
            generateIdsForPoints(points),
            isAltKeyPressed && points.length > 1,
            'green.500',
            getPointInDomCoordinateSystem
          )
        : null}
      {isProtan && points.length > 0 && intermediatePoint && unitScale && (
        <DiamondLabel
          diamond={diamonds[diamonds.length - 1]}
          segment={[
            [points[points.length - 1][0], points[points.length - 1][1]],
            intermediatePoint,
          ]}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      )}
      {points.map((point, index) => {
        return (
          // todo: index is not a good key. use a unique id
          <DraggableVertex
            key={index}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            onVertexDrag={(event) => handleVertexDrag(event, index)}
            onClick={(event) => handleVertexClick(event, index)}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
      {isProtan &&
        points.length > 1 &&
        points.map((point, index) => {
          if (index === 0) return null;
          return (
            <DiamondLabel
              diamond={diamonds[index - 1]}
              key={index}
              segment={[
                [points[index - 1][0], points[index - 1][1]],
                [points[index][0], points[index][1]],
              ]}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          );
        })}
      <KileSelector
        setDiamonds={handleSetDiamondsFromSelector}
        diamonds={[...diamonds]}
        kileType={kileTypes[kileTypes.length - 1]}
        index={diamonds.length - 1}
      />
    </>
  );
};
