import { useCallback, useEffect, useState } from 'react';
import { Point2 } from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import {
  InitialShapeDrawingState,
  PointDrawingResult,
  ShapeDrawingMode,
} from './SheetShapeDrawing';
import { DraggableVertex } from './SheetVertex';

export const SheetPointShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  //Update eller new i initial state her?
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Point];
  onResult: (result: PointDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [points, setPoints] = useState<Point2[]>(initialState?.point ?? []);

  const emitResult = useCallback(
    (points: Point2[]) => {
      if (points) {
        onResult({ points, valid: true });
      } else {
        onResult({ points: [], valid: false });
      }
    },
    [onResult]
  );

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem]
  );

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0) {
        return false;
      }

      const newPoint = getPoint(event);

      if (initialState?.updating) {
        setPoints([newPoint]);
        emitResult([newPoint]);
      } else {
        setPoints([...points, newPoint]);
        emitResult([...points, newPoint]);
      }
    },
    [emitResult, getPoint, points]
  );

  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handleVertexDrag = (event: MouseEvent, index: number) => {
    const newPoint = getPoint(event);
    if (!newPoint) {
      return;
    }
    let newPoints = [...points];
    if (initialState?.updating) {
      setPoints([newPoint]);
      newPoints = [newPoint];
    } else {
      newPoints[index] = newPoint;
      setPoints(newPoints);
    }

    if (event.type === 'pointerup') {
      emitResult(newPoints);
    }
  };

  //Handle doubleclick
  const handleDoubleClick = (index: number) => {
    if (!initialState?.updating) {
      const points2 = points;
      points2.splice(index, 1);
      setPoints([...points2]);
      emitResult([...points2]);
    }
  };

  return (
    points && (
      <>
        {points.map((point, index) => (
          <DraggableVertex
            key={index}
            theme={'default'}
            point={point}
            onDoubleClick={() => handleDoubleClick(index)}
            onVertexDrag={(event) => handleVertexDrag(event, index)}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        ))}
      </>
    )
  );
};
