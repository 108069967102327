import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import { SavedView } from '../../gql/graphql';

type ViewType = {
  cameraPosition?: SavedView['cameraPosition'];
  categories: SavedView['categories'];
  hiddenElements?: SavedView['hiddenElements'];
  hiddenModels?: SavedView['hiddenModels'];
  isolatedElements?: SavedView['isolatedElements'];
  layers: SavedView['layers'];
  statuses: SavedView['statuses'];
  section?: SavedView['section'];
};

interface ViewManagerContextType {
  getCurrentView: () => Promise<ViewType>;
  setCurrentView: (view: ViewType) => void;
  isGettingCurrentView: boolean;
  setSuccessfullyRetrievedCurrentView: (success: boolean) => void;
  currentItemIdSelection: string[];
  getCurrentItemIdSelection: () => Promise<string[]>;
  setCurrentItemIdSelection: (selection: string[]) => void;
  isGettingCurrentItemIdSelection: boolean;
  setSuccessfullyRetrievedCurrentItemIdSelection: (success: boolean) => void;
  currentExpandedItemIds: string[];
  getCurrentExpandedItemIds: () => Promise<string[]>;
  setCurrentExpandedItemIds: (expandedItemIds: string[]) => void;
  isGettingCurrentExpandedItemIds: boolean;
  setSuccessfullyRetrievedCurrentExpandedItemIds: (success: boolean) => void;
}

interface ExportStateManagerProps {
  children: React.ReactNode;
}

const ViewManagerContext = createContext<ViewManagerContextType | undefined>(
  undefined
);

export const ViewManagerProvider: React.FC<ExportStateManagerProps> = ({
  children,
}) => {
  // View state
  const [currentView, setCurrentView] = useState<ViewType | undefined>(
    undefined
  );
  const [resolvePendingPromiseView, setResolvePendingPromiseView] = useState<
    ((view: ViewType) => void) | null
  >(null);
  const [isGettingCurrentView, setIsGettingCurrentView] = useState(false);
  const [
    successfullyRetrievedCurrentView,
    setSuccessfullyRetrievedCurrentView,
  ] = useState(false);

  // Item selection state
  const [currentItemIdSelection, setCurrentItemIdSelection] = useState<
    string[]
  >([]);
  const [
    resolvePendingPromiseItemIdSelection,
    setResolvePendingPromiseItemIdSelection,
  ] = useState<((selection: string[]) => void) | null>(null);
  const [isGettingCurrentItemIdSelection, setIsGettingCurrentItemIdSelection] =
    useState(false);
  const [
    successfullyRetrievedCurrentItemIdSelection,
    setSuccessfullyRetrievedCurrentItemIdSelection,
  ] = useState(false);

  // Expanded item state
  const [currentExpandedItemIds, setCurrentExpandedItemIds] = useState<
    string[]
  >([]);
  const [
    resolvePendingPromiseExpandedItemIds,
    setResolvePendingPromiseExpandedItemIds,
  ] = useState<((expandedItemIds: string[]) => void) | null>(null);
  const [isGettingCurrentExpandedItemIds, setIsGettingCurrentExpandedItemIds] =
    useState(false);
  const [
    successfullyRetrievedCurrentExpandedItemIds,
    setSuccessfullyRetrievedCurrentExpandedItemIds,
  ] = useState(false);

  const getCurrentView = useCallback(() => {
    setIsGettingCurrentView(true);
    setSuccessfullyRetrievedCurrentView(false);
    return new Promise<ViewType>((resolve) => {
      setResolvePendingPromiseView(() => resolve);
    });
  }, []);

  const getCurrentItemIdSelection = useCallback(() => {
    setIsGettingCurrentItemIdSelection(true);
    setSuccessfullyRetrievedCurrentItemIdSelection(false);
    return new Promise<string[]>((resolve) => {
      setResolvePendingPromiseItemIdSelection(() => resolve);
    });
  }, []);

  const getCurrentExpandedItemIds = useCallback(() => {
    setIsGettingCurrentExpandedItemIds(true);
    setSuccessfullyRetrievedCurrentExpandedItemIds(false);
    return new Promise<string[]>((resolve) => {
      setResolvePendingPromiseExpandedItemIds(() => resolve);
    });
  }, []);

  // useEffects to resolve current view promises
  useEffect(() => {
    if (
      successfullyRetrievedCurrentView &&
      currentView &&
      resolvePendingPromiseView
    ) {
      resolvePendingPromiseView(currentView);
      setResolvePendingPromiseView(null);
      setIsGettingCurrentView(false);
    }
  }, [
    successfullyRetrievedCurrentView,
    currentView,
    resolvePendingPromiseView,
  ]);

  // useEffects to resolve current item selection promises
  useEffect(() => {
    if (
      successfullyRetrievedCurrentItemIdSelection &&
      currentItemIdSelection &&
      resolvePendingPromiseItemIdSelection
    ) {
      resolvePendingPromiseItemIdSelection(currentItemIdSelection);
      setResolvePendingPromiseItemIdSelection(null);
      setIsGettingCurrentItemIdSelection(false);
    }
  }, [
    successfullyRetrievedCurrentItemIdSelection,
    currentItemIdSelection,
    resolvePendingPromiseItemIdSelection,
  ]);

  // useEffects to resolve current expanded item ids promises
  useEffect(() => {
    if (
      successfullyRetrievedCurrentExpandedItemIds &&
      currentExpandedItemIds &&
      resolvePendingPromiseExpandedItemIds
    ) {
      resolvePendingPromiseExpandedItemIds(currentExpandedItemIds);
      setResolvePendingPromiseExpandedItemIds(null);
      setIsGettingCurrentExpandedItemIds(false);
    }
  }, [
    successfullyRetrievedCurrentExpandedItemIds,
    currentExpandedItemIds,
    resolvePendingPromiseExpandedItemIds,
  ]);

  // TODO: Refactor into separate groupings (view, item selection, expanded item ids)
  return (
    <ViewManagerContext.Provider
      value={{
        getCurrentView,
        setCurrentView,
        currentItemIdSelection,
        getCurrentItemIdSelection,
        setCurrentItemIdSelection,
        isGettingCurrentView,
        setSuccessfullyRetrievedCurrentView,
        isGettingCurrentItemIdSelection,
        setSuccessfullyRetrievedCurrentItemIdSelection,
        currentExpandedItemIds,
        getCurrentExpandedItemIds,
        setCurrentExpandedItemIds,
        isGettingCurrentExpandedItemIds,
        setSuccessfullyRetrievedCurrentExpandedItemIds,
      }}
    >
      {children}
    </ViewManagerContext.Provider>
  );
};

export const useViewManager = () => {
  const context = useContext(ViewManagerContext);

  invariant(
    context,
    'useViewManager must be used within an ViewManagerContext'
  );

  return context;
};
