import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { useUserTenant } from './services/auth-info';

const LoadCustomerTranslations = () => {
  const { i18n } = useTranslation();
  const { tenant } = useUserTenant();

  useEffect(() => {
    const loadTranslationsForTenant = async () => {
      const tenantId = tenant?.group;
      if (tenantId === 'Protan') {
        // Switch to the CDN for this specific tenant
        const cdnLoadPath = `https://cdn.simplelocalize.io/30f86b0611e449f687f44cfaaaa99f91/_latest/{{lng}}_${tenantId}/{{ns}}`;

        try {
          // Temporarily change the backend loadPath to the CDN for this tenant
          i18n
            .use(HttpApi)
            .init({
              backend: {
                loadPath: cdnLoadPath,
              },
            })
            .then(() => {
              // Reload the resources using the CDN path
              i18n.reloadResources(i18n.language, i18n.options.ns);
            })
            .catch((error) => {
              console.error('Error loading translations from CDN:', error);
            });
        } catch (error) {
          console.error('Failed to switch to CDN for translations:', error);
        }
      }
    };

    loadTranslationsForTenant();
  }, [tenant, i18n]);

  return null;
};

export default LoadCustomerTranslations;
