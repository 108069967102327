import * as math from 'mathjs';

import { Triangle } from '../../geometric-types';

// How much the unit normal can deviate before the plane is not considered horisontal
export const HORISONTAL_PLANE_EPSILON = 0.0001;

// https://stackoverflow.com/questions/1406029/how-to-calculate-the-volume-of-a-3d-mesh-object-the-surface-of-which-is-made-up
export function signedTetrahedronVolume(triangle: Triangle) {
  const [p1, p2, p3] = triangle;
  return math.dot(p1, math.cross(p2, p3)) / 6.0;
}
