import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';

import { DeleteProjectDocument, Project } from '../../gql/graphql';

type DeleteProjectTabProps = {
  project: Pick<Project, 'name' | 'id'>;
  closeModal: () => void;
};

type DeleteProjectFormData = {
  title: string;
};

export default function DeleteProjectTab({
  project,
  closeModal,
}: DeleteProjectTabProps): React.ReactElement {
  const toast = useToast();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<DeleteProjectFormData>({
    mode: 'onChange',
  });

  const [deleteProjectResult, deleteProject] = useMutation(
    DeleteProjectDocument
  );

  const onSubmit = handleSubmit(async () => {
    const result = await deleteProject({ input: { id: project.id } });
    if (result.error) {
      toast({
        title: 'An error occurred when deleting project',
        status: 'error',
      });
    } else {
      toast({
        title: 'Project successfully deleted',
        status: 'success',
      });
      closeModal();
      navigate('/projects/');
    }
  });

  return (
    <VStack spacing={2} alignItems={'start'}>
      <form onSubmit={onSubmit} id="delete-project-form">
        <Alert status="warning" borderRadius={'lg'}>
          <AlertIcon />
          {
            "Are you sure you want to delete this project? You can't undo this action."
          }
        </Alert>
        <FormControl mt={2}>
          <FormLabel>
            Type <strong>{project.name}</strong> to confirm deleting
          </FormLabel>
          <Input
            autoFocus
            {...register('title', {
              validate: (value) => value === project.name,
            })}
            type="text"
            placeholder={'Project name'}
          ></Input>
        </FormControl>
      </form>
      <Button
        disabled={!isValid}
        alignSelf={'end'}
        isLoading={deleteProjectResult.fetching}
        type="submit"
        form="delete-project-form"
        colorScheme="red"
        variant={'outline'}
      >
        Delete project
      </Button>
    </VStack>
  );
}
