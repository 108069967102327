import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Link,
  Text,
} from '@chakra-ui/react';
import { useUserTenant } from '../services/auth-info';

export default function MissingTenant() {
  const { signOut } = useUserTenant();
  return (
    <Alert
      status="error"
      backgroundColor="white"
      _dark={{
        backgroundColor: 'gray.700',
      }}
      rowGap={4}
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="100vh"
    >
      <AlertIcon />
      <AlertTitle>Missing group information</AlertTitle>
      <AlertDescription>
        <Text>You are not part of any Sparkel tenant.</Text>
        <Text>
          Contact{' '}
          <Link
            isExternal
            textColor="blue.600"
            _dark={{
              textColor: 'blue.300',
            }}
            href="mailto:peder@sparkel.io"
          >
            <i>peder@sparkel.io</i>
          </Link>{' '}
          to have your user added to the correct group.
        </Text>
      </AlertDescription>

      <Button colorScheme="red" onClick={signOut}>
        Log out
      </Button>
    </Alert>
  );
}
