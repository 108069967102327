import {
  FormLabel,
  HStack,
  VStack,
  Select,
  Button,
  Flex,
  useToast,
} from '@chakra-ui/react';

import React, { useCallback, useMemo, useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMutation } from 'urql';
import {
  AreaUnitSettingType,
  AreaUnitTypeEnum,
  LengthUnitSettingType,
  LengthUnitTypeEnum,
  Project,
  RoundingEnum,
  UnitSettingsType,
  UpdateProjectDocument,
  VolumeUnitSettingType,
  VolumeUnitTypeEnum,
} from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';

type UnitSettingsTabProps = {
  project: Pick<Project, 'id' | 'name' | 'settings'>;
};

const DEFAULT_UNITS_SETTINGS = {
  length: {
    units: LengthUnitTypeEnum.Meters,
    rounding: RoundingEnum['2Decimals'],
  } as LengthUnitSettingType,
  area: {
    units: AreaUnitTypeEnum.MetersSquared,
    rounding: RoundingEnum['2Decimals'],
  } as AreaUnitSettingType,
  volume: {
    units: VolumeUnitTypeEnum.MetersCubed,
    rounding: RoundingEnum['2Decimals'],
  } as VolumeUnitSettingType,
} as UnitSettingsType;

export default function UnitSettingsTab({
  project,
}: UnitSettingsTabProps): React.ReactElement {
  const toast = useToast();

  const initialSettings = useMemo(
    () => project?.settings?.unitSettings || DEFAULT_UNITS_SETTINGS,
    [project]
  );

  const [unitSettings, setUnitSettings] =
    useState<UnitSettingsType>(initialSettings);

  const [isLoading, setIsLoading] = useState(false);

  const changesMade = useMemo(
    () => JSON.stringify(initialSettings) !== JSON.stringify(unitSettings),
    [initialSettings, unitSettings]
  );

  const [, updateProject] = useMutation(UpdateProjectDocument);

  const saveChanges = useCallback(async () => {
    setIsLoading(true);

    const result = await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            unitSettings,
          },
        },
      },
    });

    if (result.error) {
      toast({
        title: 'An error occurred when setting unit settings',
        status: 'error',
      });
      throw Error();
    } else {
      toast({
        title: 'Project units successfully updated',
        status: 'success',
      });
    }

    setIsLoading(false);
  }, [project.id, project.settings, toast, unitSettings, updateProject]);

  return (
    <Flex height={'100%'} direction={'column'}>
      <VStack alignItems={'start'} gap={0} marginBottom={4}>
        <FormLabel>Length Units</FormLabel>
        <HStack paddingLeft={2}>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Units
            </FormLabel>
            <Select
              variant={'filled'}
              width={200}
              borderRadius={'md'}
              defaultValue={
                unitSettings.length?.units || LengthUnitTypeEnum.Meters
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  length: {
                    ...unitSettings.length,
                    units: e.target.value as LengthUnitTypeEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={LengthUnitTypeEnum.Millimeters}>
                Millimeters
              </option>
              <option value={LengthUnitTypeEnum.Centimeters}>
                Centimeters
              </option>
              <option value={LengthUnitTypeEnum.Decimeters}>Decimeters</option>
              <option value={LengthUnitTypeEnum.Meters}>Meters</option>
              <option value={LengthUnitTypeEnum.DecimalFeet}>
                Decimal Feet
              </option>
              <option value={LengthUnitTypeEnum.DecimalInches}>
                Decimal Inches
              </option>
            </Select>
          </VStack>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Precision
            </FormLabel>
            <Select
              variant={'filled'}
              width={150}
              borderRadius={'md'}
              defaultValue={
                unitSettings.length?.rounding || RoundingEnum['2Decimals']
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  length: {
                    ...unitSettings.length,
                    rounding: e.target.value as RoundingEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={RoundingEnum.Nearest_1000}>Nearest 1000</option>
              <option value={RoundingEnum.Nearest_100}>Nearest 100</option>
              <option value={RoundingEnum.Nearest_10}>Nearest 10</option>
              <option value={RoundingEnum['0Decimals']}>0 Decimals</option>
              <option value={RoundingEnum['1Decimals']}>1 Decimal</option>
              <option value={RoundingEnum['2Decimals']}>2 Decimals</option>
              <option value={RoundingEnum['3Decimals']}>3 Decimals</option>
            </Select>
          </VStack>
        </HStack>
      </VStack>
      <VStack alignItems={'start'} gap={0} marginBottom={4}>
        <FormLabel>Area Units</FormLabel>
        <HStack paddingLeft={2}>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Units
            </FormLabel>
            <Select
              variant={'filled'}
              width={200}
              borderRadius={'md'}
              defaultValue={
                unitSettings.area?.units || AreaUnitTypeEnum.MetersSquared
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  area: {
                    ...unitSettings.area,
                    units: e.target.value as AreaUnitTypeEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={AreaUnitTypeEnum.MillimetersSquared}>
                Square Millimeters
              </option>
              <option value={AreaUnitTypeEnum.CentimetersSquared}>
                Square Centimeters
              </option>
              <option value={AreaUnitTypeEnum.DecimetersSquared}>
                Square Decimeters
              </option>
              <option value={AreaUnitTypeEnum.MetersSquared}>
                Square Meters
              </option>
              <option value={AreaUnitTypeEnum.DecimalFeetSquared}>
                Square Decimal Feet
              </option>
              <option value={AreaUnitTypeEnum.DecimalInchesSquared}>
                Square Decimal Inches
              </option>
            </Select>
          </VStack>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Precision
            </FormLabel>
            <Select
              variant={'filled'}
              width={150}
              borderRadius={'md'}
              defaultValue={
                unitSettings.area?.rounding || RoundingEnum['2Decimals']
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  area: {
                    ...unitSettings.area,
                    rounding: e.target.value as RoundingEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={RoundingEnum.Nearest_1000}>Nearest 1000</option>
              <option value={RoundingEnum.Nearest_100}>Nearest 100</option>
              <option value={RoundingEnum.Nearest_10}>Nearest 10</option>
              <option value={RoundingEnum['0Decimals']}>0 Decimals</option>
              <option value={RoundingEnum['1Decimals']}>1 Decimal</option>
              <option value={RoundingEnum['2Decimals']}>2 Decimals</option>
              <option value={RoundingEnum['3Decimals']}>3 Decimals</option>
            </Select>
          </VStack>
        </HStack>
      </VStack>
      <VStack alignItems={'start'} gap={0}>
        <FormLabel>Volume Units</FormLabel>
        <HStack paddingLeft={2}>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Units
            </FormLabel>
            <Select
              variant={'filled'}
              width={200}
              borderRadius={'md'}
              defaultValue={
                unitSettings.volume?.units || VolumeUnitTypeEnum.MetersCubed
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  volume: {
                    ...unitSettings.volume,
                    units: e.target.value as VolumeUnitTypeEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={VolumeUnitTypeEnum.MillimetersCubed}>
                Cubic Millimeters
              </option>
              <option value={VolumeUnitTypeEnum.CentimetersCubed}>
                Cubic Centimeters
              </option>
              <option value={VolumeUnitTypeEnum.DecimetersCubed}>
                Cubic Decimeters
              </option>
              <option value={VolumeUnitTypeEnum.MetersCubed}>
                Cubic Meters
              </option>
              <option value={VolumeUnitTypeEnum.DecimalFeetCubed}>
                Cubic Decimal Feet
              </option>
              <option value={VolumeUnitTypeEnum.DecimalInchesCubed}>
                Cubic Decimal Inches
              </option>
            </Select>
          </VStack>
          <VStack alignItems={'start'} gap={0}>
            <FormLabel fontSize={'sm'} color={'gray.500'}>
              Precision
            </FormLabel>
            <Select
              variant={'filled'}
              width={150}
              borderRadius={'md'}
              defaultValue={
                unitSettings.volume?.rounding || RoundingEnum['2Decimals']
              }
              onChange={(e) =>
                setUnitSettings({
                  ...unitSettings,
                  volume: {
                    ...unitSettings.volume,
                    rounding: e.target.value as RoundingEnum,
                  },
                })
              }
              disabled={isLoading}
            >
              <option value={RoundingEnum.Nearest_1000}>Nearest 1000</option>
              <option value={RoundingEnum.Nearest_100}>Nearest 100</option>
              <option value={RoundingEnum.Nearest_10}>Nearest 10</option>
              <option value={RoundingEnum['0Decimals']}>0 Decimals</option>
              <option value={RoundingEnum['1Decimals']}>1 Decimal</option>
              <option value={RoundingEnum['2Decimals']}>2 Decimals</option>
              <option value={RoundingEnum['3Decimals']}>3 Decimals</option>
            </Select>
          </VStack>
        </HStack>
      </VStack>
      {changesMade && (
        <Button
          variant={'outline'}
          leftIcon={<SparkelIcon icon={faCheck} color={'inherit'} />}
          marginLeft={'auto'}
          marginTop={8}
          onClick={saveChanges}
          isLoading={isLoading}
        >
          Save changes
        </Button>
      )}
    </Flex>
  );
}
