export class SetCursorTool extends Autodesk.Viewing.ToolInterface {
  names: string[];
  cursor: string;

  constructor(cursor: string) {
    super();
    this.names = ['set-cursor'];
    this.cursor = cursor;

    // Bypass TypeScript checks to delete the methods
    // According to https://aps.autodesk.com/blog/custom-tools-forge-viewer
    delete (this as any).getPriority;
    delete (this as any).getCursor;
    delete (this as any).deactivate;
  }

  getPriority() {
    return 10;
  }

  getCursor(): string {
    return this.cursor;
  }

  deactivate(name: string): void {
    this.cursor = 'auto';
  }
}
