import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateProjectTagMutation,
  CreateProjectTagMutationVariables,
  DeleteProjectTagMutation,
  DeleteProjectTagMutationVariables,
  GetProjectTagsDocument,
  UpdateProjectTagMutation,
  UpdateProjectTagMutationVariables,
} from '../../gql/graphql';

export const createProjectTagResolver: UpdateResolver = (
  result: CreateProjectTagMutation,
  args: CreateProjectTagMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectTagsConnection')
    .forEach(() => {
      cache.updateQuery(
        {
          query: GetProjectTagsDocument,
        },
        (data) => {
          if (
            result.createProjectTag?.projectTag &&
            data?.projectTagsConnection
          ) {
            data.projectTagsConnection.nodes = [
              ...data.projectTagsConnection.nodes,
              result.createProjectTag.projectTag,
            ];
          }
          return data;
        }
      );
    });
};

export const updateProjectTagResolver: UpdateResolver = (
  result: UpdateProjectTagMutation,
  args: UpdateProjectTagMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectTagsConnection')
    .forEach(() => {
      cache.updateQuery(
        {
          query: GetProjectTagsDocument,
        },
        (data) => {
          if (
            result.updateProjectTag?.projectTag &&
            data?.projectTagsConnection
          ) {
            data.projectTagsConnection.nodes =
              data.projectTagsConnection.nodes.map((tag) =>
                tag.id === result.updateProjectTag?.projectTag?.id
                  ? result.updateProjectTag.projectTag
                  : tag
              );
          }
          return data;
        }
      );
    });
};

export const deleteProjectTagResolver: UpdateResolver = (
  result: DeleteProjectTagMutation,
  args: DeleteProjectTagMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectTagsConnection')
    .forEach(() => {
      cache.updateQuery(
        {
          query: GetProjectTagsDocument,
        },
        (data) => {
          if (
            result.deleteProjectTag?.projectTag?.id &&
            data?.projectTagsConnection
          ) {
            data.projectTagsConnection.nodes =
              data.projectTagsConnection.nodes.filter(
                (tag) => tag.id !== result.deleteProjectTag?.projectTag?.id
              );
          }
          return data;
        }
      );
    });
};
