import * as math from 'mathjs';
import invariant from 'tiny-invariant';

import { DirectedGraph } from 'graphology';
import { topologicalSort, willCreateCycle } from 'graphology-dag';

import { ResolvedQuantity } from './property-types';
import { Field, ResolvedField } from './resolve-order-entry';

const allVariablesRegex = /\bvar_\w+\b/g;
function getReferencedVariables(formula: string) {
  return formula.match(allVariablesRegex) || [];
}

function buildFormulaGraph(derivedFields: Field[]): DirectedGraph {
  const graph = new DirectedGraph();
  derivedFields.forEach((field) => {
    invariant(
      field.quantity.derivedFormula,
      'Missing formula for derived field'
    );
    const fieldVariable = columnIdToVariable(field.columnId);
    if (!graph.hasNode(fieldVariable)) {
      graph.addNode(fieldVariable);
    }
    getReferencedVariables(field.quantity.derivedFormula).forEach(
      (referencedVariable) => {
        if (!graph.hasNode(referencedVariable)) {
          graph.addNode(referencedVariable);
        }
        // If adding an edge creates a cycle, we just ignore the edge
        // For now, this will result in the formula scope missing the value for node, and the result will be NaN by mathjs
        if (
          !willCreateCycle(graph, fieldVariable, referencedVariable) &&
          !graph.hasEdge(fieldVariable, referencedVariable)
        ) {
          graph.addEdge(fieldVariable, referencedVariable);
        }
      }
    );
  });
  return graph;
}

export function resolveDerivedFields(
  resolvedNonDerivedFields: ResolvedField[],
  derivedFields: Field[]
): ResolvedField[] {
  const graph = buildFormulaGraph(derivedFields);
  const nodeOrder = topologicalSort(graph).reverse();

  const derivedFormulaScope = new Map<string, ResolvedQuantity['value']>(
    resolvedNonDerivedFields.map((field) => [
      columnIdToVariable(field.columnId),
      field.resolvedQuantity.value,
    ])
  );

  const derivedFieldsByVariableName = new Map<string, Field>(
    derivedFields.map((field) => [columnIdToVariable(field.columnId), field])
  );

  const result: ResolvedField[] = [];
  for (const node of nodeOrder) {
    const derivedField = derivedFieldsByVariableName.get(node);
    if (!derivedField) {
      // not a derived field
      continue;
    }
    invariant(
      derivedField.quantity.derivedFormula,
      'Missing formula for derived field'
    );
    const formulaResult = evaluateFormula(
      derivedField.quantity.derivedFormula,
      derivedFormulaScope
    );
    derivedFormulaScope.set(node, formulaResult.value);
    result.push({
      columnId: derivedField.columnId,
      resolvedQuantity: formulaResult,
      // For now, we just set this to false for formulas. Consider setting it to true if any inputs are missing a quantity.
      isSomeQuantityMissing: false,
    });
  }
  return result;
}

function columnIdToVariable(id: string) {
  return `var_${id.replaceAll('-', '_')}`;
}

function evaluateFormula(
  formula: string,
  formulaScope: Map<string, ResolvedQuantity['value']>
): ResolvedQuantity {
  let resolvedValue: ResolvedQuantity;
  try {
    const derivedValue = math.evaluate(formula, formulaScope);

    if (math.isMatrix(derivedValue)) {
      // TODO: handle case where result is a matrix with more than one row
      resolvedValue = {
        value: derivedValue.toArray() as number[],
        units: null,
      };
    } else if (
      typeof derivedValue === 'number' ||
      typeof derivedValue === 'string' ||
      Array.isArray(derivedValue)
    ) {
      resolvedValue = {
        value: derivedValue,
        units: null, // TODO: Do we want to carry units through?
      };
    } else {
      resolvedValue = {
        value: NaN,
        units: null,
      };
    }
  } catch (err) {
    // TODO: Do we want to add an error type?
    resolvedValue = {
      value: NaN,
      units: null,
    };
  }
  return resolvedValue;
}
