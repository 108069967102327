export type QuantityWithUnits = {
  value: number | string | (number | string)[];
  units: string | null;
};

export type ResolvedQuantity = QuantityWithUnits & {
  dataPoints?: Property[];
};

export enum PropertyType {
  Boolean,
  Text,
  Number,
  Unknown,
}

export type PropertyValue =
  | {
      type: PropertyType.Boolean;
      // Booleans are represented as 0 / 1, so keep doing this for backwards compatibility
      value: 0 | 1;
    }
  | {
      type: PropertyType.Text;
      value: string;
    }
  | {
      type: PropertyType.Number;
      value: number;
    }
  | {
      type: PropertyType.Unknown;
      value: string | number;
    };

export type Property = {
  modelUrn: string;
  dbId: number;
  attribute: {
    category: string;
    name: string;
  };
  units: string | null;
} & PropertyValue;
