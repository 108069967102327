import { sum } from 'lodash';
import * as math from 'mathjs';
import { MeshResult, ResultType, Triangle } from '../../geometric-types';
import { signedTetrahedronVolume } from '../util/volume';

export function calculateSummedTetrahedronVolume(
  triangles: Triangle[]
): MeshResult {
  const unscaledValue = math.abs(
    sum(triangles.map((triangle) => signedTetrahedronVolume(triangle)))
  );
  return {
    type: ResultType.MESH,
    value: unscaledValue,
    triangles,
  };
}
