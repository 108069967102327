import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  DeleteModelMutation,
  DeleteModelMutationVariables,
  DeleteModelVersionMutation,
  DeleteModelVersionMutationVariables,
  GetActiveModelsForProjectDocument,
  GetModelsForProjectDocument,
  ModelProcessingStatus,
  ModelProcessingSubscription,
  ModelProcessingSubscriptionVariables,
  Order,
  OrderEntry,
  Project,
  ProjectSettingsType,
} from '../../gql/graphql';

export const deleteModelVersionResolver: UpdateResolver<
  DeleteModelVersionMutation,
  DeleteModelVersionMutationVariables
> = (result, args, cache) => {
  if (result.deleteModelVersion?.model?.id) {
    // Invalidating for simplicity now, but feel free to update the cache instead
    cache.invalidate({
      __typename: 'Model',
      id: result.deleteModelVersion.model.id,
    });
  }
};

export const deleteModelResolver: UpdateResolver<
  DeleteModelMutation,
  DeleteModelMutationVariables
> = (result, args, cache) => {
  if (result.deleteModel?.model?.id) {
    // Invalidating for simplicity now, but feel free to update the cache instead
    cache.invalidate({
      __typename: 'Model',
      id: result.deleteModel.model.id,
    });
  }
};

const dbIdCacheKey: keyof OrderEntry = 'elementIdentifiersDbid';
const globalOffsetCacheKey: keyof ProjectSettingsType = 'viewerGlobalOffset';
const projectSettingsCacheKey: keyof Project = 'settings';
const ordersCacheKey: keyof Project = 'orders';
const orderEntriesCacheKey: keyof Order = 'orderEntries';

export const modelStatusUpdatedResolver: UpdateResolver<
  ModelProcessingSubscription,
  ModelProcessingSubscriptionVariables
> = (result, args, cache, info) => {
  if (result.modelStatusUpdated?.model) {
    const updatedModel = result.modelStatusUpdated?.model;
    if (updatedModel.status === ModelProcessingStatus.Complete) {
      const projectKey = { __typename: 'Project', id: args.projectId };
      const projectSettings = cache.resolve(
        projectKey,
        projectSettingsCacheKey
      ) as string | null;
      const globalOffset = projectSettings
        ? cache.resolve(projectSettings, globalOffsetCacheKey)
        : (null as string | null);
      if (!globalOffset) {
        console.log('Global offset missing, invalidating project settings');
        cache.invalidate(projectKey, projectSettingsCacheKey);
      }

      const ordersForProject = cache.resolve(projectKey, ordersCacheKey) as
        | string[]
        | null;
      ordersForProject?.forEach((order) => {
        const orderEntries = cache.resolve(order, orderEntriesCacheKey) as
          | string[]
          | null;
        orderEntries?.forEach((orderEntry) => {
          const elementIdentifiersDbid = cache.resolve(
            orderEntry,
            dbIdCacheKey
          );
          if (elementIdentifiersDbid) {
            cache.invalidate(orderEntry, dbIdCacheKey);
          }
        });
      });
    }

    cache.updateQuery(
      {
        query: GetModelsForProjectDocument,
        variables: { projectId: args.projectId },
      },
      (data) => {
        if (
          data?.models &&
          !data.models.find((model) => model.id === updatedModel.id)
        ) {
          data.models.push(updatedModel);
        }
        return data;
      }
    );

    cache.updateQuery(
      {
        query: GetActiveModelsForProjectDocument,
        variables: { projectId: args.projectId },
      },
      (data) => {
        if (data?.project?.activeModels) {
          const existingVersion = data.project.activeModels.find(
            (model) => model.name === updatedModel.name
          );
          if (!existingVersion) {
            data.project.activeModels.push(updatedModel);
          } else if (updatedModel.version > existingVersion.version) {
            data.project.activeModels.splice(
              data.project.activeModels.indexOf(existingVersion),
              1,
              updatedModel
            );
          }
        }
        return data;
      }
    );
  }
};
