import {
  LinearRing2,
  LineSegment2,
  Point2,
} from '../../../domain/geometry/geometric-types';
import { Diamond } from './SheetWedgeShapeDrawing';

// Function to calculate distance between two points
const distance = (p1: Point2, p2: Point2): number => {
  return Math.sqrt(Math.pow(p2[0] - p1[0], 2) + Math.pow(p2[1] - p1[1], 2));
};

export const findLongestAdjacentSegment = (vertices: LinearRing2) => {
  let maxLength = 0;
  let longestSegment: LineSegment2 = [
    [0, 0],
    [0, 0],
  ];
  for (let i = 0; i < vertices.length - 1; i++) {
    const length = distance(vertices[i], vertices[i + 1]);
    if (length > maxLength) {
      maxLength = length;
      longestSegment = [vertices[i], vertices[i + 1]];
    }
  }
  return { longestSegment, maxLength };
};

// Function to find the second-longest segment parallel to the longest segment
// eslint-disable-next-line complexity
export const findSecondLongestParallelSegment = (
  vertices: LinearRing2,
  longestSegment: LineSegment2
) => {
  const [x1, y1] = longestSegment[0];
  const [x2, y2] = longestSegment[1];
  const longestSlope = x2 !== x1 ? (y2 - y1) / (x2 - x1) : Infinity;

  let secondMaxLength = 0;
  let secondLongestSegment: LineSegment2 = [
    [0, 0],
    [0, 0],
  ];

  for (let i = 0; i < vertices.length - 1; i++) {
    const [vx1, vy1] = vertices[i];
    const [vx2, vy2] = vertices[i + 1];
    const length = distance([vx1, vy1], [vx2, vy2]);

    const slope = vx2 !== vx1 ? (vy2 - vy1) / (vx2 - vx1) : Infinity;

    if (
      (vx1 === x1 && vy1 === y1 && vx2 === x2 && vy2 === y2) ||
      (vx1 === x2 && vy1 === y2 && vx2 === x1 && vy1 === y1)
    ) {
      continue;
    }

    if (
      (Math.abs(slope - longestSlope) < 1 || slope == longestSlope) &&
      length > secondMaxLength
    ) {
      secondMaxLength = length;
      secondLongestSegment = [
        [vx1, vy1],
        [vx2, vy2],
      ];
    }
  }

  return { secondLongestSegment };
};

// Function to place points on lines
export const placePointsOnLines = (
  X: number,
  line1: LineSegment2,
  line2: LineSegment2,
  edgeDistanceFactor: number,
  unitScale: number
) => {
  const calculatePoints = (
    line: LineSegment2,
    numPoints: number,
    totalDistance: number
  ): Point2[] => {
    const [x1, y1] = line[0];
    const [x2, y2] = line[1];
    const lineLength = distance([x1, y1], [x2, y2]);

    // Calculate the direction vector of the line
    const directionX = x2 - x1;
    const directionY = y2 - y1;

    // Normalize the direction vector
    const directionLength = Math.sqrt(
      directionX * directionX + directionY * directionY
    );
    const unitDirectionX = directionX / directionLength;
    const unitDirectionY = directionY / directionLength;

    // Project the 2.4 factor onto the line
    const factor = 2.4 / unitScale;
    const projectedFactorX = factor * unitDirectionX;
    const projectedFactorY = factor * unitDirectionY;

    const edgeDistance = totalDistance * edgeDistanceFactor;
    const points: Point2[] = [];

    for (let i = 0; i < numPoints; i++) {
      let t =
        (edgeDistance +
          (i * (lineLength - 2 * edgeDistance)) / (numPoints - 1)) /
        lineLength;

      // Handle cases where projectedFactorX or projectedFactorY is zero
      let x, y;
      if (projectedFactorX === 0) {
        x = x1 + t * (x2 - x1);
      } else {
        x =
          x1 +
          Math.round((t * (x2 - x1)) / projectedFactorX) * projectedFactorX;
      }

      if (projectedFactorY === 0) {
        y = y1 + t * (y2 - y1);
      } else {
        y =
          y1 +
          Math.round((t * (y2 - y1)) / projectedFactorY) * projectedFactorY;
      }

      // Check if the calculated point (x, y) lies on the original line segment
      const withinSegment =
        Math.min(x1, x2) <= x &&
        x <= Math.max(x1, x2) &&
        Math.min(y1, y2) <= y &&
        y <= Math.max(y1, y2);

      if (withinSegment) {
        points.push([x, y]);
      }
    }

    return points;
  };

  const line1Length = distance(line1[0], line1[1]);
  const line2Length = distance(line2[0], line2[1]);
  const availableLength =
    line1Length -
    2 * edgeDistanceFactor +
    (line2Length - 2 * edgeDistanceFactor);

  const proportionLine1 =
    (line1Length - 2 * edgeDistanceFactor) / availableLength;
  const pointsLine1 = Math.round(X * proportionLine1);
  const pointsLine2 = X - pointsLine1;

  const totalDistance = (line1Length + line2Length) / (X - 1);

  const pointsOnLine1 = calculatePoints(line1, pointsLine1, totalDistance);
  const pointsOnLine2 = calculatePoints(line2, pointsLine2, totalDistance);

  return { pointsOnLine1, pointsOnLine2 };
};

// Plot normal directions, ensuring they point inwards
export const calculateNormals = (points: Point2[], isClockwise: boolean) => {
  const normals = [];
  for (let i = 0; i < points.length - 1; i++) {
    const [x1, y1] = points[i];
    const [x2, y2] = points[i + 1];
    const dx = x2 - x1;
    const dy = y2 - y1;
    const length = Math.sqrt(dx * dx + dy * dy);
    const nx = ((isClockwise ? dy : -dy) / length) * 10;
    const ny = ((isClockwise ? -dx : dx) / length) * 10;
    const midX = (x1 + x2) / 2;
    const midY = (y1 + y2) / 2;
    normals.push({ x: midX, y: midY, nx, ny });
  }
  return normals;
};

export const isNormalPointingInward = (
  normal: Point2,
  point: Point2,
  center: Point2
) => {
  const [nx, ny] = normal;
  const [px, py] = point;
  const [cx, cy] = center;
  const vectorToCenter = [cx - px, cy - py];
  const dotProduct = nx * vectorToCenter[0] + ny * vectorToCenter[1];
  return dotProduct > 0;
};
export const updateDiamondProperties = (
  normals: {
    x: number;
    y: number;
    nx: number;
    ny: number;
  }[],
  points: Point2[],
  clockwise: boolean,
  center: number[]
) => {
  return normals.map((normal, index) => {
    const normalVector = [normal.nx, normal.ny];
    const point = points[index];

    const xor = (a: boolean, b: boolean): boolean => {
      return (a || b) && !(a && b);
    };

    const pointsInward = xor(
      isNormalPointingInward(normalVector as Point2, point, center as Point2),
      clockwise
    );
    return {
      triangle1: pointsInward,
      triangle2: pointsInward,
      triangle3: !pointsInward,
      triangle4: !pointsInward,
    };
  });
};

export const calculatePolygonCenter = (vertices: LinearRing2) => {
  let xSum = 0;
  let ySum = 0;
  vertices.forEach(([x, y]) => {
    xSum += x;
    ySum += y;
  });
  return [xSum / vertices.length, ySum / vertices.length];
};

export const addWedgesOnEachSide = (diamondsProps: Diamond[]) => {
  //Pakke inn i en funksjon iallfall
  let firstDiamondProps = { ...diamondsProps[0] };
  if (firstDiamondProps.triangle4) {
    firstDiamondProps.triangle3 = false;
  } else {
    firstDiamondProps.triangle1 = false;
  }

  let lastDiamondProps = { ...diamondsProps[diamondsProps.length - 1] };
  if (lastDiamondProps.triangle4) {
    lastDiamondProps.triangle4 = false;
  } else {
    lastDiamondProps.triangle2 = false;
  }

  let endprops = [firstDiamondProps];
  endprops.push(...diamondsProps, lastDiamondProps);
  return endprops;
};

// Function to determine the winding order of the polygon
export const isClockwise = (vertices: LinearRing2) => {
  let sum = 0;
  for (let i = 0; i < vertices.length - 1; i++) {
    const [x1, y1] = vertices[i];
    const [x2, y2] = vertices[i + 1];
    sum += (x2 - x1) * (y2 + y1);
  }
  return sum < 0;
};

export function linearRing2ToLineSegments(ring: LinearRing2): LineSegment2[] {
  const segments: LineSegment2[] = [];
  for (let i = 0; i < ring.length - 1; i++) {
    segments.push([ring[i], ring[i + 1]]);
  }
  // Close the ring by connecting the last point to the first
  return segments;
}
