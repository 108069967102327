import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { ShapeDeepFragment } from '../../../gql/graphql';
import { toMultiPolygon } from '../../../domain/geometry/algorithms/util/type-mapping';
import {
  LineSegment,
  Plane,
  Point,
} from '../../../domain/geometry/geometric-types';
import {
  getSnapPoint,
  isPrimaryMouseButton,
  useSnapper,
  useViewerClickEvent,
} from './util';
import { Polygon } from './Polygon';
import { Edge } from './Edge';
import { Vertex } from './Vertex';

export const MoveOrCopy3DShapes = ({
  shapes,
  viewer,
  onResult,
}: {
  shapes: ShapeDeepFragment[];
  viewer: Autodesk.Viewing.GuiViewer3D;
  onResult: (translationVector: Point) => void;
}) => {
  const [startPoint, setStartPoint] = useState<Point | null>(null);
  const [intermediatePoint, setIntermediatePoint] = useState<Point | null>(
    null
  );
  const snapper = useSnapper(viewer);

  const getPoint = useCallback((): Point | null => {
    if (snapper.isSnapped()) {
      snapper.indicator.render();
      return getSnapPoint(snapper, viewer);
    }
    return null;
  }, [snapper, viewer]);

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (!isPrimaryMouseButton(event.button)) {
        return false;
      }

      const newPoint = getPoint();
      if (!newPoint) {
        return true;
      }

      if (!startPoint) {
        setStartPoint(newPoint);
      } else {
        const translationVector: Point = [
          newPoint[0] - startPoint[0],
          newPoint[1] - startPoint[1],
          newPoint[2] - startPoint[2],
        ];
        onResult(translationVector);
        setStartPoint(null); // Reset for the next operation
      }

      return true; // Stop the event from going to other tools in the stack
    },
    [getPoint, startPoint, onResult]
  );

  useViewerClickEvent(viewer, handleViewerClick);

  const handlePointerMove = useCallback(() => {
    snapper.indicator.clearOverlays();

    const newPoint = getPoint();
    if (!newPoint) {
      return;
    }
    setIntermediatePoint(newPoint);
  }, [getPoint, snapper.indicator]);

  useEffect(() => {
    viewer.container.addEventListener('pointermove', handlePointerMove);
    return () => {
      viewer.container.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewer]);

  const translatedShapes = useMemo(() => {
    if (!startPoint || !intermediatePoint) return [];

    const translation = {
      x: intermediatePoint[0] - startPoint[0],
      y: intermediatePoint[1] - startPoint[1],
      z: intermediatePoint[2] - startPoint[2],
    };

    return (
      <Box
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        pointerEvents={'none'}
      >
        {shapes.map((shape) => {
          if (
            ('polygon' in shape && shape.polygon) ||
            ('extrudedPolygon' in shape && shape.extrudedPolygon)
          ) {
            return IntermediatePolygonShape(shape, translation, viewer);
          } else if ('line' in shape && shape.line) {
            return IntermediateLineShape(shape, translation, viewer);
          } else if ('shapePoint' in shape && shape.shapePoint) {
            return IntermediatePointShape(shape, translation, viewer);
          }
        })}
      </Box>
    );
  }, [shapes, startPoint, intermediatePoint, viewer]);

  return <>{translatedShapes}</>;
};

const IntermediatePolygonShape = (
  shape: ShapeDeepFragment,
  translation: { x: number; y: number; z: number },
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const clonedShape: ShapeDeepFragment = JSON.parse(JSON.stringify(shape));
  clonedShape.polygon?.multipolygon.polygons.forEach((polygon) => {
    polygon.exterior.points.forEach((point) => {
      point.x += translation.x;
      point.y += translation.y;
      point.z += translation.z;
    });
    polygon.interiors.forEach((interior) => {
      interior.points.forEach((point) => {
        point.x += translation.x;
        point.y += translation.y;
        point.z += translation.z;
      });
    });
  });

  const planeCoefficients = clonedShape.polygon?.plane;
  if (planeCoefficients) {
    const unitNormal = [
      planeCoefficients.a,
      planeCoefficients.b,
      planeCoefficients.c,
    ];
    const planeCoefficient = planeCoefficients.d;

    const plane = {
      unitNormal,
      planeCoefficient,
    } as Plane;

    return clonedShape.polygon?.multipolygon ? (
      <Polygon
        key={shape.id}
        multipolygon={toMultiPolygon(clonedShape.polygon?.multipolygon)}
        plane={plane}
        isClosed={false}
        viewer={viewer}
      />
    ) : null;
  }
};

const IntermediateLineShape = (
  shape: ShapeDeepFragment,
  translation: { x: number; y: number; z: number },
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const clonedShape: ShapeDeepFragment = JSON.parse(JSON.stringify(shape));
  clonedShape.line?.points.map((point) => {
    point.x += translation.x;
    point.y += translation.y;
    point.z += translation.z;
  });

  const edges: LineSegment[] = [];

  if (clonedShape.line?.points) {
    for (let i = 0; i < clonedShape.line.points.length - 1; i++) {
      edges.push([
        [
          clonedShape.line.points[i].x,
          clonedShape.line.points[i].y,
          clonedShape.line.points[i].z,
        ],
        [
          clonedShape.line.points[i + 1].x,
          clonedShape.line.points[i + 1].y,
          clonedShape.line.points[i + 1].z,
        ],
      ]);
    }
  }

  return edges?.map((edge) => (
    <Edge
      key={shape.id}
      segment={edge}
      viewer={viewer}
      backgroundColor={'orange.300'}
    />
  ));
};

const IntermediatePointShape = (
  shape: ShapeDeepFragment,
  translation: { x: number; y: number; z: number },
  viewer: Autodesk.Viewing.GuiViewer3D
) => {
  const clonedShape: ShapeDeepFragment = JSON.parse(JSON.stringify(shape));

  if (clonedShape.shapePoint) {
    clonedShape.shapePoint.point.x += translation.x;
    clonedShape.shapePoint.point.y += translation.y;
    clonedShape.shapePoint.point.z += translation.z;

    return (
      <Vertex
        point={[
          clonedShape.shapePoint?.point.x,
          clonedShape.shapePoint?.point.y,
          clonedShape.shapePoint?.point.z,
        ]}
        viewer={viewer}
        theme={'default'}
        backgroundColor={'orange.300'}
        borderColor={'orange.300'}
      />
    );
  }
};
