import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateOrderEntryViewMutation,
  CreateOrderEntryViewMutationVariables,
  DeleteOrderEntryViewMutation,
  DeleteOrderEntryViewMutationVariables,
} from '../../gql/graphql';

export const createOrderEntryViewResolver: UpdateResolver = (
  result: CreateOrderEntryViewMutation,
  args: CreateOrderEntryViewMutationVariables,
  cache
) => {
  if (result.createOrderEntryView?.orderEntryView) {
    const orderEntryViewsForProject = cache.resolve(
      {
        __typename: 'Project',
        id: result.createOrderEntryView.orderEntryView.projectId,
      },
      'orderEntryViews'
    ) as string[] | null;

    const orderEntryViewToAdd = cache.keyOfEntity({
      __typename: 'OrderEntryView',
      id: result.createOrderEntryView.orderEntryView.id,
    });
    if (orderEntryViewsForProject && orderEntryViewToAdd) {
      orderEntryViewsForProject.push(orderEntryViewToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createOrderEntryView.orderEntryView.projectId,
        },
        'orderEntryViews',
        orderEntryViewsForProject
      );
    }
  }
};

export const deleteOrderEntryViewResolver: UpdateResolver = (
  result: DeleteOrderEntryViewMutation,
  args: DeleteOrderEntryViewMutationVariables,
  cache
) => {
  if (result.deleteOrderEntryView?.orderEntryView) {
    const orderEntryViewsForProject = cache.resolve(
      {
        __typename: 'Project',
        id: result.deleteOrderEntryView.orderEntryView.projectId,
      },
      'orderEntryViews'
    ) as string[] | null;

    const orderEntryViewToRemove = cache.keyOfEntity({
      __typename: 'OrderEntryView',
      id: result.deleteOrderEntryView.orderEntryView.id,
    });
    if (orderEntryViewsForProject && orderEntryViewToRemove) {
      cache.link(
        {
          __typename: 'Project',
          id: result.deleteOrderEntryView.orderEntryView.projectId,
        },
        'orderEntryViews',
        orderEntryViewsForProject.filter(
          (key) => key !== orderEntryViewToRemove
        )
      );
    }
  }
};
