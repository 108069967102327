import { authExchange as _authExchange } from '@urql/exchange-auth';
import { Auth } from '@aws-amplify/auth';
import { parseAccessTokenPayload } from '../utils/jwt-utils';

async function initializeAuthState() {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getIdToken().getJwtToken();

    return { token: accessToken, refreshSupported: true };
  } catch (e) {
    const localToken = localStorage.getItem('external_access_token');
    if (localToken) {
      return { token: localToken, refreshSupported: false };
    } else {
      return null;
    }
  }
}

export const authExchange = _authExchange(async (utils) => {
  const authState = await initializeAuthState();
  let token = authState?.token;
  return {
    addAuthToOperation: (operation) => {
      if (!token) {
        return operation;
      } else {
        return utils.appendHeaders(operation, {
          Authorization: `Bearer ${token}`,
        });
      }
    },
    didAuthError: (error) => {
      if (error.networkError) {
        return error.response?.status === 401;
      } else {
        return error.graphQLErrors.some(
          (error) => error.extensions?.code === 'UNAUTHENTICATED'
        );
      }
    },
    willAuthError: () => {
      // Auth fails if there is no token, or if the token is missing an exp, or if that exp is passed
      if (!token) {
        return true;
      } else {
        const { exp } = parseAccessTokenPayload(token);

        return !exp || Math.floor(Date.now() / 1000) >= exp;
      }
    },
    refreshAuth: async () => {
      if (authState?.refreshSupported) {
        const newAuthState = await Auth.currentSession();
        token = newAuthState.getIdToken().getJwtToken();
      } else {
        // Check if the user has an external access token, and redirect them to login with a "banner"
        // to indicate that their login expired
        if (localStorage.getItem('external_access_token')) {
          localStorage.removeItem('external_access_token');
          window.location.href = '/login?expired=true';
        } else {
          window.location.href = '/login';
        }
      }
    },
  };
});
