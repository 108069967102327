import * as math from 'mathjs';
import {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { isHotkeyPressed, useHotkeys } from 'react-hotkeys-hook';
import invariant from 'tiny-invariant';
import { cloneDeep } from 'lodash';

import { useToast } from '@chakra-ui/react';
import { useMutation } from 'urql';
import {
  LineSegment2,
  MultiPolygon2,
  Point2,
} from '../../../domain/geometry/geometric-types';
import {
  GetMagicSheetShapeDocument,
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
} from '../../../gql/graphql';
import {
  addEdge,
  copy,
  getInvalidEdges,
  intersects,
} from '../../../services/viewer/shape-drawing/intersections';
import { Viewport, useSheetViewer } from '../../common/SheetViewer';
import {
  Point2WithId,
  createIntermediateEdge,
  createMultipolygon,
  generateIdForPoint,
  generateIdForRing,
  generateIdsForEdges,
  generateIdsForPoints,
  getPositions,
  intermediateEdgeId,
} from '../shapes/util';
import { useUserTenant } from '../../../services/auth-info';
import { useProtanProjectSteps } from '../../common/ProtanProjectStepsProvider';
import { DraggableSheetEdge, SheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  PolygonShapeDrawingResult,
  ShapeDrawingMode,
} from './SheetShapeDrawing';

import { SheetPolygon } from './SheetPolygon';
import { DraggableVertex, SheetVertex } from './SheetVertex';
import {
  SheetPolygonDrawingAction,
  SheetPolygonDrawingActionType,
  SheetPolygonDrawingState,
  sheetPolygonDrawingReducer,
} from './sheet-polygon-drawing-reducer';
import AutoGeneratedRoof from './AutoGeneratedRoof';

function initializeState(
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon]
): SheetPolygonDrawingState {
  if (!initialState) {
    return {
      completedRings: [],
      incompleteRing: [],
      mousePointerPoint: null,
      intersectionsForIncompleteRing: new Map(),
      intersectionsForCompletedRings: new Map(),
    };
  }
  return {
    completedRings: initialState.multipolygon.polygons.flatMap((polygon) => [
      generateIdForRing(generateIdsForPoints(polygon.exterior)),
      ...polygon.interiors.map((interior) =>
        generateIdForRing(generateIdsForPoints(interior))
      ),
    ]),
    incompleteRing: [],
    mousePointerPoint: null,
    intersectionsForIncompleteRing: new Map(),
    intersectionsForCompletedRings: new Map(),
  };
}
export const SheetMagicShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
  viewerViewport,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Polygon];
  onResult: (result: PolygonShapeDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  viewerViewport: Viewport;
}) => {
  const [clickedPoints, setClickedPoints] = useState<
    (Point2WithId & { foreground: boolean })[]
  >([]);
  const [state, dispatch] = useReducer(
    sheetPolygonDrawingReducer,
    initialState,
    initializeState
  );

  const [, getMagicSheetShape] = useMutation(GetMagicSheetShapeDocument);
  const toast = useToast();
  const { activeSheetId, pageNumber } = useSheetViewer();

  const [isDragging, setIsDragging] = useState(false);

  const isShiftPressed = isHotkeyPressed('shift');

  const emitResult = useCallback(() => {
    const hasNoIntersections = [
      ...state.intersectionsForCompletedRings.values(),
    ].every((intersections) => intersections.length === 0);
    const hasNoNewPoints = state.incompleteRing.length === 0;
    const hasConfirmedRing = state.completedRings.length > 0;

    const isValidResult =
      hasNoIntersections && hasNoNewPoints && hasConfirmedRing;

    if (isValidResult && !isDragging) {
      const ringPositions = state.completedRings.map((ring) =>
        getPositions(ring.linearRing)
      );
      onResult({
        valid: true,
        multipolygon: createMultipolygon(ringPositions),
      });
    } else {
      onResult({
        valid: false,
      });
    }
  }, [
    isDragging,
    onResult,
    state.completedRings,
    state.incompleteRing.length,
    state.intersectionsForCompletedRings,
  ]);

  useEffect(() => {
    if (!isDragging) {
      emitResult();
    }
  }, [emitResult, isDragging]);

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      if (isShiftPressed && state.incompleteRing.length > 0) {
        const previousPointInDomCoordinates = getPointInDomCoordinateSystem(
          state.incompleteRing[state.incompleteRing.length - 1].point
        );
        const deltaX = event.clientX - previousPointInDomCoordinates[0];
        const deltaY = event.clientY - previousPointInDomCoordinates[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          return getPointInPdfCoordinateSystem([
            event.clientX,
            previousPointInDomCoordinates[1],
          ]);
        } else {
          return getPointInPdfCoordinateSystem([
            previousPointInDomCoordinates[0],
            event.clientY,
          ]);
        }
      }
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [
      getPointInDomCoordinateSystem,
      getPointInPdfCoordinateSystem,
      isShiftPressed,
      state.incompleteRing,
    ]
  );

  const { viewerRef, page } = useSheetViewer();

  const handleViewerClick = useCallback(
    // eslint-disable-next-line complexity
    async (event: MouseEvent) => {
      if (event.button !== 0) {
        return;
      }
      const canvas = viewerRef.current?.querySelector('canvas');
      if (!canvas) {
        return;
      }

      const pointInPdf = getPointInPdfCoordinateSystem([
        event.clientX,
        event.clientY,
      ]);

      const newPoints: typeof clickedPoints = [
        ...clickedPoints,
        {
          ...generateIdForPoint(pointInPdf),
          foreground: !(event.ctrlKey || event.metaKey),
        },
      ];
      setClickedPoints(newPoints);

      const viewerElement = viewerRef.current;
      if (!viewerViewport || !page || !viewerElement) {
        throw new Error('Viewer not ready');
      }

      const pageViewport = page.getViewport({
        scale: viewerViewport.scale,
        offsetX: viewerViewport.offset[0],
        offsetY: viewerViewport.offset[1],
      });

      let pointInPdfForMagicSheetShapes = cloneDeep(newPoints);

      //Handle PDFs with origo in center. Move origo to upper left corner
      pointInPdfForMagicSheetShapes = pointInPdfForMagicSheetShapes.map(
        (point) => {
          if (pageViewport.viewBox[0] < 0) {
            point.point[0] = point.point[0] - pageViewport.viewBox[0];
            point.point[1] = point.point[1] - pageViewport.viewBox[1];
          }
          return point;
        }
      );

      if (!activeSheetId) {
        console.error('No active sheet');
        return;
      }
      // Set cursor to spinner
      document.body.style.cursor = 'progress';

      const result = await getMagicSheetShape({
        input: {
          sheetId: activeSheetId,
          pageNumber,
          mousePoints: pointInPdfForMagicSheetShapes.map((p) => ({
            foreground: p.foreground,
            point: { x: p.point[0], y: p.point[1] },
          })),
        },
      });

      // Reset cursor
      document.body.style.cursor = 'auto';

      if (result.error) {
        console.error(result.error);
        toast({
          title: result.error.message,
          status: 'error',
        });
        return;
      }

      const ring = result.data?.getMagicSheetShape?.polygon?.points.map(
        (point) => {
          if (pageViewport.viewBox[0] < 0) {
            point.x = point.x + pageViewport.viewBox[0];
            point.y = point.y + pageViewport.viewBox[1];
          }
          return point;
        }
      );
      if (!ring) {
        throw new Error('Ring missing in response');
      }

      dispatch({
        type: SheetPolygonDrawingActionType.ADD_ONE_CLICK_SHAPE,
        ring: ring.map((p) => [p.x, p.y]),
      });
      event.stopPropagation();
    },
    [
      activeSheetId,
      clickedPoints,
      getMagicSheetShape,
      getPointInPdfCoordinateSystem,
      page,
      pageNumber,
      toast,
      viewerRef,
      viewerViewport,
    ]
  );
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const { projectSteps } = useProtanProjectSteps();
  const { isProtan } = useUserTenant();

  const roofOutlineInProgress =
    projectSteps?.find(
      (step) => step.step === ProtanProjectStepEnum.RoofOutline
    )?.status === ProtanProjectStepStatusEnum.InProgress;

  return (
    <>
      <CompletedRings
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        setIsDragging={setIsDragging}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        getPointInPdfCoordinateSystem={getPointInPdfCoordinateSystem}
      />

      {isProtan &&
        state.completedRings.length > 0 &&
        state.completedRings[0].linearRing &&
        roofOutlineInProgress && (
          <AutoGeneratedRoof
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            vertices={state.completedRings[0].linearRing.map(
              (point) => point.point
            )}
          ></AutoGeneratedRoof>
        )}
      <IncompleteRing
        state={state}
        dispatch={dispatch}
        getPoint={getPoint}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        getPointInPdfCoordinateSystem={getPointInPdfCoordinateSystem}
      />
      {clickedPoints.map(({ point, id: pointId, foreground }) => {
        return (
          <SheetVertex
            key={pointId}
            theme={foreground ? 'default' : 'inverted'}
            point={point}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            backgroundColor={'purple.100'}
            borderColor={'purple.500'}
          />
        );
      })}
    </>
  );
};

function IncompleteRing({
  state,
  dispatch,
  getPoint,
  isDragging,
  setIsDragging,
  getPointInDomCoordinateSystem,
  getPointInPdfCoordinateSystem,
}: {
  state: SheetPolygonDrawingState;
  dispatch: Dispatch<SheetPolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point2;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
}) {
  // To avoid triggering click events when double clicking
  const clickTimeoutRef = useRef<number>();
  const edgesForIncompleteRing = useMemo(
    () => generateIdsForEdges(state.incompleteRing),
    [state.incompleteRing]
  );

  const shouldAddMousePointerEdge =
    !!state.mousePointerPoint && state.incompleteRing.length > 0;

  const intersectionsForIncompleteRingIncludingMouseEdge = useMemo(() => {
    let result = state.intersectionsForIncompleteRing;
    if (state.mousePointerPoint && shouldAddMousePointerEdge) {
      // Find intersections with the edge between the last confirmed point and the mouse edge
      result = copy(result);
      const intermediateEdge = createIntermediateEdge(
        state.incompleteRing,
        state.mousePointerPoint
      );
      addEdge(result, edgesForIncompleteRing, intermediateEdge);
    }
    return result;
  }, [
    edgesForIncompleteRing,
    shouldAddMousePointerEdge,
    state.incompleteRing,
    state.intersectionsForIncompleteRing,
    state.mousePointerPoint,
  ]);

  const invalidEdgesForIncompleteRing = useMemo(() => {
    return getInvalidEdges(
      edgesForIncompleteRing,
      intersectionsForIncompleteRingIncludingMouseEdge
    );
  }, [
    edgesForIncompleteRing,
    intersectionsForIncompleteRingIncludingMouseEdge,
  ]);

  const doesIncompleteRingFormAValidPolygon = useMemo(() => {
    const numPoints = state.mousePointerPoint
      ? state.incompleteRing.length + 1
      : state.incompleteRing.length;

    if (numPoints < 3) {
      return false;
    }
    if (invalidEdgesForIncompleteRing.length > 0) {
      return false;
    }
    if (state.mousePointerPoint) {
      // check intersection from the mouse pointer point to the first point
      const edgeFromMousePoint: LineSegment2 = [
        state.mousePointerPoint,
        state.incompleteRing[0].point,
      ];
      return !edgesForIncompleteRing.some((edge) =>
        intersects(edgeFromMousePoint, edge.segment)
      );
    } else {
      // check intersection from the last point to the first point
      const edgeFromLastPoint: LineSegment2 = [
        state.incompleteRing[state.incompleteRing.length - 1].point,
        state.incompleteRing[0].point,
      ];
      return !edgesForIncompleteRing.some((edge) =>
        intersects(edgeFromLastPoint, edge.segment)
      );
    }
  }, [
    edgesForIncompleteRing,
    invalidEdgesForIncompleteRing.length,
    state.incompleteRing,
    state.mousePointerPoint,
  ]);
  const { viewerRef } = useSheetViewer();

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      if (state.incompleteRing.length === 0 || isDragging) {
        return;
      }
      const newPoint = getPoint(event);
      dispatch({
        type: SheetPolygonDrawingActionType.SET_MOUSE_POINTER_POINT,
        point: newPoint,
      });
    },
    [dispatch, getPoint, isDragging, state.incompleteRing.length]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const handleVertexDrag = (event: MouseEvent, pointId: string) => {
    setIsDragging(true);
    const position = getPoint(event);
    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT,
      position,
      pointId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDrag = (event: React.MouseEvent, edgeId: string) => {
    setIsDragging(true);
    const startVector = getPointInPdfCoordinateSystem([
      event.clientX - event.movementX,
      event.clientY - event.movementY,
    ]);
    const translationVector = math.subtract(
      getPointInPdfCoordinateSystem([event.clientX, event.clientY]),
      startVector
    );
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;

    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE,
      startPositionTranslation: translationVector,
      endPositionTranslation: translationVector,
      startPointId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };
  const handleFirstVertexClick = (event: React.MouseEvent) => {
    const handleFirstVertexClickThrottled = () => {
      if (
        state.incompleteRing.length < 3 ||
        event.button !== 0 ||
        event.detail > 1
      ) {
        return;
      }
      dispatch({ type: SheetPolygonDrawingActionType.CLOSE_RING });
    };
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear any existing timeout
    }
    // Wait before executing single click action
    const newTimeout = window.setTimeout(handleFirstVertexClickThrottled, 300);

    clickTimeoutRef.current = newTimeout;
  };

  const handleVertexDoubleClick = (
    event: React.MouseEvent,
    pointId: string
  ) => {
    if (clickTimeoutRef.current) {
      clearTimeout(clickTimeoutRef.current); // Clear the single click timeout
    }
    if (event.button !== 0) {
      return;
    }
    dispatch({
      type: SheetPolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT,
      pointId,
    });
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, edgeId: string) => {
    if (event.button !== 0) {
      return;
    }
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const edgeIndex = edgesForIncompleteRing.findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.incompleteRing[edgeIndex].id;
    dispatch({
      type: SheetPolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
    });
  };

  useHotkeys(
    ['enter'],
    () => dispatch({ type: SheetPolygonDrawingActionType.CLOSE_RING }),
    {
      preventDefault: true,
    }
  );

  const isIntermediateEdgeValid = useMemo(() => {
    const intersectionsForEdge =
      intersectionsForIncompleteRingIncludingMouseEdge.get(intermediateEdgeId);
    if (!intersectionsForEdge) {
      return true;
    }
    return intersectionsForEdge.length === 0;
  }, [intersectionsForIncompleteRingIncludingMouseEdge]);

  const incompletePolygon: MultiPolygon2 | null = useMemo(() => {
    if (!doesIncompleteRingFormAValidPolygon) {
      return null;
    }
    const exterior = getPositions(state.incompleteRing);
    if (shouldAddMousePointerEdge && state.mousePointerPoint) {
      exterior.push(state.mousePointerPoint);
    }
    exterior.push(state.incompleteRing[0].point);
    return {
      polygons: [
        {
          exterior,
          interiors: [],
        },
      ],
    };
  }, [
    doesIncompleteRingFormAValidPolygon,
    shouldAddMousePointerEdge,
    state.incompleteRing,
    state.mousePointerPoint,
  ]);
  return (
    <>
      {incompletePolygon && (
        <SheetPolygon
          multipolygon={incompletePolygon}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'orange.500'}
        />
      )}
      {edgesForIncompleteRing.map(({ id, segment }) => {
        const color = getEdgeColor(
          false,
          !invalidEdgesForIncompleteRing.includes(id)
        );
        return (
          <DraggableSheetEdge
            key={id}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, id)}
            segment={segment}
            backgroundColor={color}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            onEdgeDrag={(event) => handleEdgeDrag(event, id)}
          />
        );
      })}
      {shouldAddMousePointerEdge ? (
        <SheetEdge
          segment={[
            state.incompleteRing[state.incompleteRing.length - 1].point,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            state.mousePointerPoint!,
          ]}
          backgroundColor={getEdgeColor(false, isIntermediateEdgeValid)}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          pointerEvents={'none'} // Prevent edge on mouse pointer from capturing mouse events
        />
      ) : null}
      {state.incompleteRing.map(({ point, id: pointId }, index) => {
        return (
          <DraggableVertex
            key={pointId}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            onClick={
              index === 0 ? (event) => handleFirstVertexClick(event) : undefined
            }
            onDoubleClick={(event) => handleVertexDoubleClick(event, pointId)}
            onVertexDrag={(event) => handleVertexDrag(event, pointId)}
          />
        );
      })}
    </>
  );
}

function CompletedRings({
  state,
  dispatch,
  getPoint,
  setIsDragging,
  getPointInDomCoordinateSystem,
  getPointInPdfCoordinateSystem,
}: {
  state: SheetPolygonDrawingState;
  dispatch: Dispatch<SheetPolygonDrawingAction>;
  getPoint: (event: MouseEvent) => Point2;
  setIsDragging: (isDragging: boolean) => void;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
}) {
  const edgesForCompletedRings = useMemo(
    () =>
      Object.fromEntries(
        state.completedRings.map((ring) => [
          ring.id,
          generateIdsForEdges(ring.linearRing),
        ])
      ),
    [state.completedRings]
  );

  const invalidEdgesForCompletedRings = useMemo(() => {
    return getInvalidEdges(
      Object.values(edgesForCompletedRings).flat(),
      state.intersectionsForCompletedRings
    );
  }, [edgesForCompletedRings, state.intersectionsForCompletedRings]);

  const completedMultipolygon: MultiPolygon2 | null = useMemo(() => {
    const rings = state.completedRings.map((ring) => ring.linearRing);
    if (rings.length === 0) {
      return null;
    }
    // rings without intersections
    const validRings = rings
      .filter((ring) => {
        const edges = generateIdsForEdges(ring);
        return (
          getInvalidEdges(edges, state.intersectionsForCompletedRings)
            .length === 0
        );
      })
      .map((ring) => getPositions(ring));
    return createMultipolygon(validRings);
  }, [state.completedRings, state.intersectionsForCompletedRings]);

  const handleVertexDrag = (
    event: MouseEvent,
    pointId: string,
    ringId: string
  ) => {
    setIsDragging(true);
    const position = getPoint(event);
    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_POINT,
      position,
      pointId,
      ringId,
    });
    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDrag = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    setIsDragging(true);
    const startVector = getPointInPdfCoordinateSystem([
      event.clientX - event.movementX,
      event.clientY - event.movementY,
    ]);
    const translationVector = math.subtract(
      getPointInPdfCoordinateSystem([event.clientX, event.clientY]),
      startVector
    );
    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;
    invariant(startPointId, 'start point not found');

    dispatch({
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE,
      startPositionTranslation: translationVector,
      endPositionTranslation: translationVector,
      startPointId,
      ringId,
    });

    if (event.type === 'pointerup') {
      setIsDragging(false);
    }
  };

  const handleEdgeDoubleClick = (
    event: React.MouseEvent,
    edgeId: string,
    ringId: string
  ) => {
    if (event.button !== 0) {
      return;
    }
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const edgeIndex = edgesForCompletedRings[ringId].findIndex(
      (edge) => edge.id === edgeId
    );
    const startPointId = state.completedRings.find((ring) => ring.id === ringId)
      ?.linearRing[edgeIndex].id;
    invariant(startPointId, 'start point not found');

    dispatch({
      type: SheetPolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE,
      splitPosition: newPoint,
      startPointId,
      ringId,
    });
  };
  return (
    <>
      {completedMultipolygon && (
        <SheetPolygon
          multipolygon={completedMultipolygon}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'green.400'}
        />
      )}
      {Object.entries(edgesForCompletedRings).map(([ringId, edgesForRing]) =>
        edgesForRing.map(({ id: edgeId, segment }) => {
          const color = getEdgeColor(
            true,
            !invalidEdgesForCompletedRings.includes(edgeId)
          );
          return (
            <DraggableSheetEdge
              key={edgeId}
              onDoubleClick={(event) =>
                handleEdgeDoubleClick(event, edgeId, ringId)
              }
              onEdgeDrag={(event) => handleEdgeDrag(event, edgeId, ringId)}
              segment={segment}
              backgroundColor={color}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          );
        })
      )}

      {state.completedRings.map((ring) => {
        return ring.linearRing.map(({ point, id: pointId }, index) => {
          if (index === ring.linearRing.length - 1) {
            return null;
          }
          return (
            <DraggableVertex
              key={pointId}
              theme={index === 0 ? 'inverted' : 'default'}
              point={point}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
              onDoubleClick={() => {
                dispatch({
                  type: SheetPolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT,
                  ringId: ring.id,
                  pointId,
                });
              }}
              onVertexDrag={(event) =>
                handleVertexDrag(event, pointId, ring.id)
              }
            />
          );
        });
      })}
    </>
  );
}
const getEdgeColor = (isClosed?: boolean, isValid?: boolean) => {
  if (!isValid) {
    return 'red.400';
  } else if (isClosed) {
    return 'green.500';
  } else {
    return 'yellow.300';
  }
};
