import {
  Alert,
  AlertDescription,
  AlertIcon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { Select, SelectInstance } from 'chakra-react-select';
import React, { useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import { GetOrdersForProjectsDocument } from '../../gql/graphql';

type Option = {
  value: string;
  label: string;
  type: 'project' | 'order';
};

interface ProjectParams {
  projectId: string;
}

export function LauncherMenu(): React.ReactElement | null {
  const { projectId } = useParams<keyof ProjectParams>();
  const selectRef = useRef<SelectInstance<Option, true>>(null);
  const {
    isOpen: isModalOpen,
    onClose: closeModal,
    onOpen: openModal,
  } = useDisclosure();
  const navigate = useNavigate();

  const [selectedProjectOption, setSelectedProjectOption] =
    React.useState<Option | null>(null);

  useEffect(() => {
    if (selectedProjectOption !== null) {
      invariant(selectRef.current, 'selectRef is null');
      selectRef.current.setValue([selectedProjectOption], 'select-option');
    }
  }, [selectedProjectOption]);

  const handleModalClosed = () => {
    setSelectedProjectOption(null);
    closeModal();
  };

  const [{ data: listProjectsData, error: listProjectsError }] = useQuery({
    query: GetOrdersForProjectsDocument,
  });

  useHotkeys(['ctrl+k', 'meta+k'], openModal, { preventDefault: true });
  useHotkeys('esc', closeModal, { enabled: isModalOpen }, [isModalOpen]);

  useEffect(() => {
    if (projectId && listProjectsData?.projects) {
      const project = listProjectsData.projects.find(
        ({ id }) => id === projectId
      );
      // Have to set this after the modal is open, otherwise the select component
      // will not be mounted yet (and we'll get a null ref error)
      if (project && isModalOpen) {
        setSelectedProjectOption({
          label: project.name,
          value: `/project/${project.id}`,
          type: 'project',
        });
      }
    }
  }, [isModalOpen, listProjectsData?.projects, projectId]);

  const projectOptions: Option[] =
    listProjectsData?.projects?.map((project) => ({
      label: project.name,
      type: 'project',
      value: `/project/${project.id}`,
    })) ?? [];

  const orderOptions: Option[] =
    listProjectsData?.projects && selectedProjectOption !== null
      ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        listProjectsData?.projects
          .find(({ name }) => name === selectedProjectOption.label)
          ?.orders.map((order) => ({
            label: order.name,
            value: `/project/${order.projectId}/takeoff/${order.id}`,
            type: 'order',
          })) ?? []
      : [];

  const handleChange = (newValue: readonly Option[]) => {
    if (newValue.length === 0) {
      setSelectedProjectOption(null);
    } else if (newValue.length === 1) {
      if (newValue[0].type === 'project') {
        // NOTE: Should revisit this later, and consider a "fully controlled" approach here
        setSelectedProjectOption(newValue[0]);
      }
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = (event) => {
    if (
      event.key === 'Escape' &&
      selectRef.current?.inputRef &&
      selectRef.current.inputRef.value.length === 0
    ) {
      event.preventDefault();
      handleModalClosed();
    }

    invariant(selectRef.current, 'selectRef.current is null');
    const focusedOption = selectRef.current.state.focusedOption;

    if (focusedOption !== null) {
      if (event.key === 'Enter') {
        handleModalClosed();
        navigate(focusedOption.value);
      } else if (event.key === 'Tab') {
        if (focusedOption.type === 'project') {
          setSelectedProjectOption(focusedOption);
        } else {
          handleModalClosed();
          navigate(focusedOption.value);
        }
      }
    }
  };

  return (
    <Modal isOpen={isModalOpen} onClose={handleModalClosed}>
      <ModalOverlay />

      <ModalContent>
        <ModalBody px={1}>
          {listProjectsError ? (
            <Alert status="error">
              <AlertIcon />
              <AlertDescription>Failed fetching projects</AlertDescription>
            </Alert>
          ) : null}
          <Select<Option, true>
            isMulti
            ref={selectRef}
            autoFocus
            placeholder="Jump to project"
            noOptionsMessage={({ inputValue }) =>
              inputValue.length > 0 ? 'No project found' : 'No tables found'
            }
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            tabSelectsValue={false}
            options={
              selectedProjectOption !== null ? orderOptions : projectOptions
            }
            openMenuOnFocus
            closeMenuOnSelect={false}
            chakraStyles={{
              menu: (provided) => ({
                ...provided,
                position: 'relative',
                marginBottom: 0,
              }),
              menuList: (provided) => ({
                ...provided,
                borderWidth: 0,
              }),
            }}
            components={{ DropdownIndicator: () => null }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
