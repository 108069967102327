import JstsMultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import { ExtrudedPolygonAlgorithm } from '../../algorithm-types';
import {
  Plane,
  Point,
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';
import { toPlanarMultiPolygon } from '../util/plane';
import {
  calculateArea,
  generateSideTrianglesFromPolygon,
  toJstsMultiPolygon,
} from '../util/polygon';

export const calculateExtrudedShapeSideArea: ExtrudedPolygonAlgorithm<
  TriangulatedSurfaceResult
> = (multiPolygon, plane, thickness) => {
  const planarPolyon = toPlanarMultiPolygon(multiPolygon, plane);
  const jtsMultiPolygon = toJstsMultiPolygon(planarPolyon);

  const extrusionVector: Point = plane.unitNormal.map(
    (component) => component * thickness
  ) as Point;

  const triangles: Triangle[] = [];

  generateMesh(jtsMultiPolygon, plane, extrusionVector, triangles);

  let totalArea = 0;

  for (const triangle of triangles) {
    totalArea += calculateArea(triangle);
  }

  return {
    type: ResultType.TRIANGULATED_SURFACE,
    value: totalArea,
    triangles,
  };
};

function generateMesh(
  jtsMultiPolygon: JstsMultiPolygon,
  plane: Plane,
  extrusionVector: Point,
  triangles: Triangle[]
) {
  const numPolygons = jtsMultiPolygon.getNumGeometries();
  for (let i = 0; i < numPolygons; i++) {
    const jtsPolygon = jtsMultiPolygon.getGeometryN(i);

    // Create side triangles
    generateSideTrianglesFromPolygon(
      jtsPolygon,
      plane,
      extrusionVector,
      triangles
    );
  }
}
