import {
  Box,
  Grid,
  GridItem,
  StackProps,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useLocalStorage } from '../../hooks/local-storage';

type GridLayoutProps = {
  children: React.ReactNode;
};

export function Layout({ children }: GridLayoutProps): React.ReactElement {
  return (
    <Grid
      flex={1}
      maxHeight="100%"
      overflowY="hidden"
      templateColumns="min-content 1fr min-content min-content"
      templateRows="min-content 1fr min-content min-content"
      templateAreas={`"sidebar-left viewer-interactions sidebar-right"
      "sidebar-left . sidebar-right"
      "sidebar-left toolbar sidebar-right"
      "sidebar-left main sidebar-right"`}
      gap={2}
      sx={{
        '.adsk-viewing-viewer': {
          backgroundColor: 'inherit',
        },
        '> *': {
          zIndex: 2,
        },
        '@media (max-width: 1200px)': {
          gridTemplateRows:
            'min-content min-content 1fr min-content min-content',
          gridTemplateColumns: 'min-content 1fr',
          gridTemplateAreas: `"sidebar-left viewer-interactions"
          "sidebar-left ."
          "sidebar-right ."
          "sidebar-right toolbar"
          "sidebar-right main"
          `,
        },
      }}
    >
      {children}
    </Grid>
  );
}

type SidebarProps = {
  children: React.ReactNode;
  positioning?: 'left' | 'right';
  isDrawerOpen?: boolean;
} & StackProps;

export function Sidebar({
  children,
  positioning,
  isDrawerOpen,
  ...rest
}: SidebarProps): React.ReactElement {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const [leftSidebarWidth, setLeftSidebarWidth] = useLocalStorage<number>(
    'left-sidebar',
    280
  );
  const [rightSidebarWidth, setRightSidebarWidth] = useLocalStorage<number>(
    'right-sidebar',
    280
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const startX = event.clientX;
    const startWidth = sidebarRef.current?.clientWidth || 0;

    const inverted = positioning === 'left';
    const minWidth = 240;
    const maxWidth = 500;

    let newWidth = startWidth;

    const doResize = (mouseMoveEvent: MouseEvent) => {
      newWidth = Math.min(
        Math.max(
          startWidth +
            (inverted
              ? mouseMoveEvent.clientX - startX
              : -(mouseMoveEvent.clientX - startX)),
          minWidth
        ),
        maxWidth
      );
      if (sidebarRef.current) {
        sidebarRef.current.style.width = `${newWidth}px`;
      }
    };

    const stopResize = () => {
      positioning === 'left'
        ? setLeftSidebarWidth(newWidth)
        : setRightSidebarWidth(newWidth);
      window.removeEventListener('mousemove', doResize);
      window.removeEventListener('mouseup', stopResize);
    };

    window.addEventListener('mousemove', doResize);
    window.addEventListener('mouseup', stopResize);
  };

  const resizerColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <GridItem
      ref={sidebarRef}
      as={VStack}
      gridArea={positioning === 'left' ? 'sidebar-left' : 'sidebar-right'}
      alignSelf="start"
      // sx={positioning === 'left' ? { pl: 2 } : { pr: 2 }}
      maxHeight="100%"
      overflowY="auto"
      paddingTop={2}
      paddingBottom={2}
      spacing={2}
      paddingRight={positioning === 'right' ? 1 : 0}
      paddingLeft={positioning === 'left' ? 3 : 0}
      overflowX={'hidden'}
      alignItems="stretch"
      width={
        positioning === 'left'
          ? leftSidebarWidth
          : isDrawerOpen && rightSidebarWidth < 310
          ? 310
          : rightSidebarWidth
      }
      position={'relative'}
      sx={{
        '@media (max-width: 1200px)': {
          paddingLeft: 2,
          paddingRight: 0,
        },
      }}
      {...rest}
    >
      <Box
        height={'96%'}
        width={1.5}
        borderRadius={'full'}
        position={'absolute'}
        right={positioning === 'left' ? 0 : undefined}
        cursor={'ew-resize'}
        onMouseDown={handleMouseDown}
        _active={{
          backgroundColor: resizerColor,
          borderRadius: '2px',
        }}
        zIndex={2}
      />
      {children}
    </GridItem>
  );
}
