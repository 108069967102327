import { useEffect, useMemo, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useQuery } from 'urql';
import {
  GetActiveModelsForProjectDocument,
  ModelsDeepFragment,
} from '../gql/graphql';

export function useActiveModels(projectId: string) {
  const [{ data: bimModelsData, fetching, error }] = useQuery({
    query: GetActiveModelsForProjectDocument,
    variables: { projectId },
  });

  const activeModels = useMemo(() => {
    return bimModelsData?.project?.activeModels ?? null;
  }, [bimModelsData?.project?.activeModels]);

  const [currentValue, setCurrentValue] = useState<ModelsDeepFragment[] | null>(
    activeModels
  );

  useEffect(() => {
    // Because models reference is updated when polling,
    // use deep equality to prevent viewer hooks from going amok
    if (!isEqual(activeModels, currentValue)) {
      setCurrentValue(activeModels);
    }
  }, [activeModels, currentValue]);

  return useMemo(
    () => ({ activeModels: currentValue, fetching, error }),
    [currentValue, fetching, error]
  );
}
