import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateProjectMutation,
  CreateProjectMutationVariables,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables,
  GetProjectPageDocument,
} from '../../gql/graphql';

export const createProjectResolver: UpdateResolver = (
  result: CreateProjectMutation,
  args: CreateProjectMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectsConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          // Add the new project to the last page of results
          if (
            result.createProject?.project &&
            data?.projectsConnection &&
            !data.projectsConnection.pageInfo.hasNextPage
          ) {
            data.projectsConnection.nodes = [
              ...data.projectsConnection.nodes,
              result.createProject.project,
            ];
          }
          return data;
        }
      );
    });
};

export const duplicateProjectResolver: UpdateResolver = (
  result: DuplicateProjectMutation,
  args: DuplicateProjectMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectsConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          // Add the new project to the last page of results
          if (
            result.duplicateProject?.project &&
            data?.projectsConnection &&
            !data.projectsConnection.pageInfo.hasNextPage
          ) {
            data.projectsConnection.nodes = [
              ...data.projectsConnection.nodes,
              result.duplicateProject.project,
            ];
          }
          return data;
        }
      );
    });
};

export const deleteProjectResolver: UpdateResolver = (
  result: DeleteProjectMutation,
  args: DeleteProjectMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectsConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          if (data?.projectsConnection) {
            data.projectsConnection.nodes =
              data.projectsConnection.nodes.filter(
                (project) => project.id !== args.input.id
              );
          }
          return data;
        }
      );
    });
};
