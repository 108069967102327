import { intersection, keys, remove, union } from 'lodash';
import { ElementIdentifierDbidType } from '../gql/graphql';

export function mergeRecordOfLists(
  record1: Record<string, number[]>,
  record2: Record<string, number[]>
): Record<string, number[]> {
  const keys1 = keys(record1);
  const keys2 = keys(record2);
  const result: Record<string, number[]> = {};
  for (const key of union(keys1, keys2)) {
    result[key] = union(record1[key] ?? [], record2[key] ?? []);
  }

  return result;
}

export function intersectRecordOfLists(
  record1: Record<string, number[]>,
  record2: Record<string, number[]>
): Record<string, number[]> {
  const keys1 = keys(record1);
  const keys2 = keys(record2);
  const result: Record<string, number[]> = {};
  for (const key of union(keys1, keys2)) {
    result[key] = intersection(record1[key] ?? [], record2[key] ?? []);
  }

  return result;
}

export function removeFromSet(
  original: Record<string, number[]>,
  other: Record<string, number[]>
): Record<string, number[]> {
  // Mutates original to remove the elements of other
  for (const key of keys(other)) {
    if (original[key]) {
      remove(original[key], (id: number) => other[key].includes(id));
    }
  }

  return original;
}

export function removeElementsFromRecords(
  original: ElementIdentifierDbidType[],
  toRemove: Record<string, number[]>
): ElementIdentifierDbidType[] {
  return original.map(({ modelUrn, dbIds }) => {
    const newDbIds = dbIds.filter(
      (dbId) => !toRemove[modelUrn]?.includes(dbId)
    );
    return { modelUrn, dbIds: newDbIds };
  });
}

export function hasDbIds(record: Record<string, number[]>): boolean {
  return Object.values(record).some((dbIds) => dbIds.length > 0);
}

export function intersectionArrays<T>(arr1: T[], arr2: T[]): T[] {
  let result: T[] = [];
  arr1.forEach((element) => {
    if (arr2.includes(element)) {
      result.push(element);
    }
  });
  return result;
}
