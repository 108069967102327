import { sum } from 'lodash';
import {
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';

import { calculateArea } from '../util/polygon';

// Naive implementation, just sums the areas of all triangles
export function calculateTotalSurfaceArea(
  triangles: Triangle[]
): TriangulatedSurfaceResult {
  const area = sum(triangles.map(calculateArea));

  return {
    type: ResultType.TRIANGULATED_SURFACE,
    triangles,
    value: Math.abs(area),
  };
}
