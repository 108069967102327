type ExternalAccessTokenPayload = {
  exp: number;
  orderIds: string[];
  projectId: string;
  orderOwner: string;
  displayName: string;
  contractorName: string;
};

export const parseAccessTokenPayload = (token: string) => {
  const tokenParts = token.split('.');
  const tokenPayload = tokenParts[1].replace(/-/g, '+').replace(/_/g, '/');
  const decodedPayload = JSON.parse(atob(tokenPayload));
  return decodedPayload;
};

export const parseExternalAccessToken = (
  token: string
): ExternalAccessTokenPayload => {
  const decodedPayload = parseAccessTokenPayload(token);

  // Validate that the fields we expect are present
  if (
    decodedPayload.exp &&
    decodedPayload.orderIds &&
    decodedPayload.projectId &&
    decodedPayload.orderOwner &&
    decodedPayload.displayName &&
    decodedPayload.contractorName
  ) {
    return decodedPayload;
  } else {
    throw new Error('Invalid looking external access token');
  }
};
