/* eslint-disable complexity */
import {
  AbsoluteCenter,
  Fade,
  GridItem,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import {
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import {
  ModelProcessingStatus,
  Point3Type,
  ShapeDeepFragment,
  ShapeFolderDeepFragment,
  SheetPageCalibrationFragment,
  SheetShapeDeepFragment,
  SparkelPropertyInfoFragment,
} from '../../gql/graphql';
import { useViewerSelection } from '../../hooks/viewer-selection';
import { initializeViewer, loadModels } from '../../services/forge-viewer';
import { mergeRecordOfLists } from '../../services/viewer-services';

import { useLocalStorage } from '../../hooks/local-storage';
import { useForgeViewerContextMenu } from '../../services/viewer/ForgeContextMenu';
import {
  ForgeVisibilityManager,
  useForgeVisibilityManager,
} from '../../services/viewer/ForgeVisibilityManager';
import {
  ShapesManager,
  useShapesManager,
} from '../../services/viewer/ShapesManager';
import { ChakraColor, hexToRgb } from '../../utils/color-util';
import { useForgeAccessToken } from '../../hooks/forge-auth';
import { useActiveModels } from '../../hooks/active-models';
import NoDataPlaceholder from './NoDataPlaceholder';
import { ViewerPivotTool } from './forgeViewerInteractions/ViewerPivotTool';
import { useViewerMode } from './viewer-mode';

export type DbIdsPerModel = Record<string, number[]>;

export type ForgeViewerContextValue = {
  viewer: Autodesk.Viewing.GuiViewer3D | null;
  loadedModels: ModelsMap;
  isAllModelsLoaded: boolean;
  selectedDbIds: DbIdsPerModel;
  colorSection: (
    colorFunc: (urn: string, dbId: number) => string,
    dbIdsPerModel: DbIdsPerModel
  ) => void;
  clearColors: () => void;
  setGhostMode: (ghostingBoolean: boolean) => void;
  isGhostModeActive: boolean;
  showLabels: {
    isShowingLabels: boolean;
    setIsShowingLabels: (active: boolean) => void;
  };
  isOrthographicModeActive: boolean;
  setOrthographicMode: (ortographicBoolean: boolean) => void;
  shapesManager: ShapesManager;
  visibilityManager: ForgeVisibilityManager;
  toggleSelectDbId: (dbId: number, modelUrn: string) => void;
  setSelectedDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  selectDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
};

export const ForgeViewerContext = createContext<ForgeViewerContextValue | null>(
  null
);

const viewerContainerId = 'forge-viewer';

export const getCanvasMousePosition = (clientX: number, clientY: number) => {
  const container = document.getElementById(viewerContainerId);
  if (!container) {
    throw new Error('Container not found');
  }
  return {
    canvasX: clientX - container.offsetLeft,
    canvasY: clientY - container.offsetTop,
  };
};

type ForgeViewerProviderProps = {
  shouldHideViewer?: boolean;
  globalOffset: Point3Type | null;
  projectId: string;
  children: ReactElement;
};

type SelectionDefinition = {
  model: Autodesk.Viewing.Model;
  ids: number[];
  selectionType: Autodesk.Viewing.SelectionType;
};

// Map from model urn to autodesk models
export type ModelsMap = Record<string, Autodesk.Viewing.Model>;
// Map from shape urn to to sheet shapes
export type ShapesMap = Record<string, ShapeDeepFragment[]>;
// Map from sheet shape urn to sheet shapes
export type SheetShapesMap = Record<string, SheetShapeDeepFragment[]>;
// Map from shape urn to to shape folders
export type ShapeFoldersMap = Record<string, ShapeFolderDeepFragment[]>;
// Map from sheet id to calibrations
export type SheetCalibrationsMap = Record<
  string,
  SheetPageCalibrationFragment[]
>;
export type SparkelPropertiesMap = Record<
  string,
  SparkelPropertyInfoFragment[]
>;

export type ModelsNameMap = Record<string, string>;

export function ForgeViewerProvider({
  shouldHideViewer = false,
  globalOffset,
  children,
  projectId,
}: ForgeViewerProviderProps) {
  const { viewerMode } = useViewerMode();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const viewerContainerRef = useRef<HTMLDivElement | null>(null);
  const currentUrns = useRef<string[] | null>(null);
  const currentGlobalOffset = useRef<Point3Type | null>(null);
  const [aggregatedView, setAggregatedView] =
    useState<Autodesk.Viewing.AggregatedView | null>(null);

  const [loadedModels, setLoadedModels] = useState<ModelsMap>({});
  const [isModelsLoaded, setModelsLoaded] = useState<boolean>(false);
  const [viewerLoadError, setViewerLoadError] = useState<Error | null>(null);
  const shapesManager = useShapesManager(aggregatedView?.viewer ?? null);
  const dbIdSelection = useViewerSelection(aggregatedView?.viewer ?? null);
  const allModels = useMemo(
    () => ({
      ...loadedModels,
      ...shapesManager.loadedShapeModels,
    }),
    [loadedModels, shapesManager.loadedShapeModels]
  );
  // Only allow selected dbIds for loaded models.
  // If for example a model is deleted, we don't want to return it's selected dbIds
  const selectedDbIds: DbIdsPerModel = useMemo(() => {
    const allModelKeys = Object.keys(allModels);
    return dbIdSelection.filter((_, key) => allModelKeys.includes(key)).toJS();
  }, [allModels, dbIdSelection]);
  const [isGhostModeActive, setGhostMode] = useState(false);
  const [isShowingLabels, setIsShowingLabels] = useLocalStorage(
    'shape-labels',
    false
  );
  const [isOrthographicModeActive, setOrthographicMode] = useLocalStorage(
    'orthographic-mode',
    false
  );

  const visibilityManager = useForgeVisibilityManager(
    aggregatedView,
    loadedModels,
    shapesManager.loadedShapeModels
  );

  const { bind: bindContextMenu, menuElement } = useForgeViewerContextMenu(
    aggregatedView,
    isModelsLoaded,
    selectedDbIds,
    visibilityManager,
    shapesManager
  );

  const { activeModels } = useActiveModels(projectId);

  const getToken = useForgeAccessToken(projectId, activeModels ?? []);

  const urns = useMemo(() => {
    if (!activeModels) {
      return null;
    }
    return (
      activeModels
        .filter((model) => model.status === ModelProcessingStatus.Complete)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((model) => model.forgeUrn!)
    );
  }, [activeModels]);

  useEffect(() => {
    if (isModelsLoaded && aggregatedView !== null) {
      if (colorMode === 'dark') {
        aggregatedView.viewer.setTheme('dark-theme');
        aggregatedView.viewer.setBackgroundColor(23, 25, 35, 23, 25, 35);
      } else {
        aggregatedView.viewer.setTheme('light-theme');
        aggregatedView.viewer.setBackgroundColor(247, 250, 252, 247, 250, 252);
      }
    }
  }, [aggregatedView, colorMode, isModelsLoaded]);

  useEffect(() => {
    if (viewerLoadError) {
      console.error('Error loading viewer', viewerLoadError);
      toast({
        status: 'error',
        title: 'Failed loading your model',
        position: 'top',
      });
    }
  }, [viewerLoadError, toast]);

  useEffect(() => {
    const load = async (urns: string[], globalOffset: Point3Type) => {
      if (viewerContainerRef.current === null) {
        return;
      }

      setLoading(true);
      setModelsLoaded(false);

      let currentAggregatedView = await initializeViewer(
        viewerContainerRef.current,
        getToken,
        globalOffset
      );

      try {
        const newModels = await loadModels(currentAggregatedView, urns);
        await currentAggregatedView.viewer.waitForLoadDone({
          geometry: true,
          textures: false,
          propDb: true,
        });
        urns.forEach((urn) => {
          const model = newModels[urn];

          if (model && model.getData().loadOptions.fileExt === 'rvt') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            model.selector.setSelectionMode(
              window.Autodesk.Viewing.SelectionMode.MIXED
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            model.selector.setSelectionMode(
              window.Autodesk.Viewing.SelectionMode.FIRST_OBJECT
            );
          }
        });

        currentAggregatedView.viewer.setSelectionColor(
          new THREE.Color(ChakraColor.blue400),
          Autodesk.Viewing.SelectionType.MIXED
        );
        currentAggregatedView.viewer.setEnvMapBackground(false);
        currentAggregatedView.viewer.setGhosting(false);

        const boxSelectionExtension = currentAggregatedView.viewer.getExtension(
          'Autodesk.BoxSelection'
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ) as any;

        if (
          boxSelectionExtension &&
          boxSelectionExtension.boxSelectionTool &&
          boxSelectionExtension.boxSelectionTool
        ) {
          // This makes the box selection select elements behind each other
          (
            currentAggregatedView.viewer.getExtension(
              'Autodesk.BoxSelection'
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any
          ).boxSelectionTool.useGeometricIntersection = true;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const navigation = currentAggregatedView.viewer.navigation as any;
        if (navigation) {
          navigation.setPivotSetFlag(true);
          navigation.setWheelSetsPivot(true);
        }

        const viewerPivotTool = new ViewerPivotTool(
          currentAggregatedView.viewer
        );
        currentAggregatedView.viewer.toolController.registerTool(
          viewerPivotTool
        );
        currentAggregatedView.viewer.toolController.activateTool(
          viewerPivotTool.getName()
        );

        setLoadedModels(newModels);
      } catch (error) {
        setViewerLoadError(error as Error);
      }
      setModelsLoaded(true);
      setLoading(false);
      setAggregatedView(currentAggregatedView);
    };
    if (
      globalOffset &&
      urns &&
      urns.length > 0 &&
      (!isEqual(urns, currentUrns.current) ||
        !isEqual(globalOffset, currentGlobalOffset.current))
    ) {
      currentUrns.current = urns;
      currentGlobalOffset.current = globalOffset;
      load(urns, globalOffset);
    } else if (urns?.length === 0) {
      setModelsLoaded(true);
    }
  }, [aggregatedView, getToken, globalOffset, urns]);

  useEffect(() => {
    return () => {
      aggregatedView?.unloadAll();
    };
  }, [aggregatedView]);

  const toggleSelectDbId = useCallback(
    (dbId: number, modelUrn: string) => {
      const model = allModels[modelUrn];
      if (!model) {
        console.log('Could not find model');
        return;
      }
      aggregatedView?.viewer.toggleSelect(
        dbId,
        model,
        Autodesk.Viewing.SelectionType.MIXED
      );
    },
    [aggregatedView?.viewer, allModels]
  );

  const setSelectedDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      let selectionDefinitions: SelectionDefinition[] = [];
      Object.entries(dbIdsPerModel).forEach(([modelUrn, dbIds]) => {
        const model = allModels[modelUrn];
        if (!model) {
          console.log('Could not find model');
          return;
        }
        selectionDefinitions.push({
          model,
          ids: dbIds,
          selectionType: Autodesk.Viewing.SelectionType.MIXED,
        });
      });
      aggregatedView?.viewer.setAggregateSelection(selectionDefinitions);
    },
    [aggregatedView?.viewer, allModels]
  );

  const selectDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      const dbIdsToSelect = mergeRecordOfLists(selectedDbIds, dbIdsPerModel);
      setSelectedDbIds(dbIdsToSelect);
    },
    [selectedDbIds, setSelectedDbIds]
  );

  const colorSection = useCallback(
    async (
      colorFunc: (urn: string, dbId: number) => string, //Hex
      dbIdsPerModel: DbIdsPerModel
    ) => {
      const viewer = aggregatedView?.viewer;
      invariant(viewer, 'Cannot colorize without viewer');

      const gray = { r: 220, g: 220, b: 220 };

      for (const [modelUrn, dbIds] of Object.entries(dbIdsPerModel)) {
        const model = allModels[modelUrn];
        if (!model && modelUrn) {
          // Linked model isnt't always loaded for shapes, for example in project view
          continue;
        }
        invariant(model, 'Cannot colorize without loaded model');

        if (dbIds.length === 0) {
          viewer.clearThemingColors(model);
        } else {
          const instanceTree = model.getInstanceTree();
          instanceTree.enumNodeChildren(
            model.getInstanceTree().getRootId(),
            (dbId) => {
              // Color everything else gray to increase contrast
              viewer.setThemingColor(
                dbId,
                new THREE.Vector4(
                  gray.r / 255,
                  gray.g / 255,
                  gray.b / 255,
                  0.8
                ),
                model,
                true
              );
            }
          );

          for (const dbId of dbIds) {
            const [r, g, b] = hexToRgb(colorFunc(modelUrn, dbId));
            viewer.setThemingColor(
              dbId,
              new THREE.Vector4(r / 255, g / 255, b / 255, 1),
              model,
              true
            );
          }
        }
      }
    },
    [aggregatedView?.viewer, allModels]
  );

  const clearColors = useCallback(() => {
    if (!aggregatedView) {
      return;
    }
    for (const model of aggregatedView.viewer.getVisibleModels()) {
      aggregatedView.viewer.clearThemingColors(model);
    }
  }, [aggregatedView]);

  useEffect(() => {
    if (isModelsLoaded && aggregatedView !== null) {
      aggregatedView.viewer.setGhosting(isGhostModeActive);
    }
  }, [aggregatedView, isGhostModeActive, isModelsLoaded]);

  useEffect(() => {
    if (isModelsLoaded && aggregatedView !== null) {
      if (isOrthographicModeActive) {
        aggregatedView.viewer.autocam.toOrthographic();
      } else {
        aggregatedView.viewer.autocam.toPerspective();
      }
    }
  }, [aggregatedView, isModelsLoaded, isOrthographicModeActive]);

  const boxSelectionExtension = aggregatedView?.viewer.getExtension(
    'Autodesk.BoxSelection'
  );

  useEffect(() => {
    const isExtensionLoaded =
      !!boxSelectionExtension &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !!(boxSelectionExtension as any).boxSelectionTool;

    if (isExtensionLoaded) {
      // This makes the box selection select elements behind each other
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (boxSelectionExtension as any).boxSelectionTool.useGeometricIntersection =
        true;
    }
  }, [boxSelectionExtension]);

  const contextValue: ForgeViewerContextValue = useMemo(
    () => ({
      viewer: (aggregatedView?.viewer ??
        null) as Autodesk.Viewing.GuiViewer3D | null,
      loadedModels,
      isAllModelsLoaded: isModelsLoaded,
      colorSection,
      clearColors,
      selectedDbIds,
      setGhostMode,
      isGhostModeActive,
      showLabels: {
        isShowingLabels,
        setIsShowingLabels,
      },
      isOrthographicModeActive,
      setOrthographicMode,
      shapesManager,
      visibilityManager,
      toggleSelectDbId,
      setSelectedDbIds,
      selectDbIds,
    }),
    [
      aggregatedView?.viewer,
      loadedModels,
      isModelsLoaded,
      colorSection,
      clearColors,
      selectedDbIds,
      isGhostModeActive,
      isShowingLabels,
      setIsShowingLabels,
      isOrthographicModeActive,
      setOrthographicMode,
      shapesManager,
      visibilityManager,
      toggleSelectDbId,
      setSelectedDbIds,
      selectDbIds,
    ]
  );

  const loadingTextColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <>
      <Fade
        in={loading && !shouldHideViewer && viewerMode === 'models'}
        unmountOnExit
      >
        <AbsoluteCenter axis="both" textAlign={'center'}>
          <Spinner color="brand.400" />
          <Text size="sm" color={loadingTextColor}>
            Loading your models
          </Text>
        </AbsoluteCenter>
      </Fade>

      {menuElement}

      <ForgeViewerContext.Provider value={contextValue}>
        {children}
      </ForgeViewerContext.Provider>
      <GridItem
        id={viewerContainerId}
        ref={viewerContainerRef}
        position="relative"
        zIndex={!shouldHideViewer && viewerMode === 'models' ? 1 : 0}
        opacity={!shouldHideViewer && viewerMode === 'models' ? 1 : 0}
        pointerEvents={shouldHideViewer ? 'none' : undefined}
        area="sidebar-left-start / sidebar-left-start / sidebar-right-end / sidebar-right-end"
        sx={{
          '.adsk-viewing-viewer': {
            transition: 'opacity 0.8s ease-in-out',
            opacity: isModelsLoaded ? 1 : 0,
            bottom: undefined,
          },
          '@media (max-width: 1200px)': {
            gridArea:
              'sidebar-left-start / sidebar-left-start / main-end / main-end',
          },
        }}
        {...bindContextMenu()}
      />
      <NoDataPlaceholder
        hasModels={isModelsLoaded && Object.keys(loadedModels).length > 0}
        loaded={isModelsLoaded}
      />
    </>
  );
}

export const useViewer = (): ForgeViewerContextValue => {
  const viewerContextValue = useContext(ForgeViewerContext);
  if (viewerContextValue === null) {
    throw new Error('useViewer must be used within a ForgeViewerContext');
  } else {
    return viewerContextValue;
  }
};
