// Import hooks and GraphQL documents
import React, { createContext, useContext, useCallback } from 'react';
import { useQuery, useMutation } from 'urql';
import {
  GetProtanProjectStepsForProjectDocument,
  ProtanProjectStepEnum,
  ProtanProjectStepStatusEnum,
  UpsertProtanProjectStepDocument,
} from '../../gql/graphql';

type ProtanProjectStepsContextType = {
  projectSteps?: {
    step: ProtanProjectStepEnum;
    status: ProtanProjectStepStatusEnum;
  }[];
  allStepsCompleted: boolean;
  setStepStatus: (
    step: ProtanProjectStepEnum,
    status: ProtanProjectStepStatusEnum
  ) => void;
};

const ProtanProjectStepsContext =
  createContext<ProtanProjectStepsContextType | null>(null);

interface ProtanProjectStepsProviderProps {
  children: React.ReactNode;
  projectId: string;
  shouldIgnore: boolean;
}

export const ProtanProjectStepsProvider: React.FC<
  ProtanProjectStepsProviderProps
> = ({ children, projectId, shouldIgnore }) => {
  const [{ data: projectStepsData }] = useQuery({
    query: GetProtanProjectStepsForProjectDocument,
    variables: { projectId },
  });

  const projectSteps = Object.values(ProtanProjectStepEnum).map((step) => {
    const stepInData = projectStepsData?.project?.protanProjectSteps.find(
      (s) => s.step === step
    );

    return {
      step,
      status: stepInData?.status ?? ProtanProjectStepStatusEnum.NotStarted,
    };
  });

  const [, upsertProtanProjectStep] = useMutation(
    UpsertProtanProjectStepDocument
  );

  const setStepStatus = useCallback(
    (step: ProtanProjectStepEnum, status: ProtanProjectStepStatusEnum) => {
      if (shouldIgnore) {
        // Don't update the database if not Protan tenant
        return;
      }

      const input = {
        theProjectId: projectId,
        theStep: step,
        theStatus: status,
      };

      upsertProtanProjectStep({ input });
    },
    [projectId, shouldIgnore, upsertProtanProjectStep]
  );

  if (shouldIgnore) {
    return <>{children}</>;
  }

  const allStepsCompleted =
    projectStepsData?.project?.protanProjectSteps.length ===
      Object.keys(ProtanProjectStepEnum).length &&
    projectStepsData?.project?.protanProjectSteps.every(
      (step) => step.status === ProtanProjectStepStatusEnum.Completed
    );

  return (
    <ProtanProjectStepsContext.Provider
      value={{
        projectSteps,
        allStepsCompleted,
        setStepStatus,
      }}
    >
      {children}
    </ProtanProjectStepsContext.Provider>
  );
};

export const useProtanProjectSteps = () => {
  const context = useContext(ProtanProjectStepsContext);

  if (!context) {
    return {
      projectSteps: [],
      allStepsCompleted: false,
      setStepStatus: () => {
        console.log('Called step status without Protan tenant');
      },
    };
  }

  return context;
};
