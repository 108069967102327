import {
  Box,
  HStack,
  IconButton,
  Image,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import tutorialThumbnail from '../../../assets/img/tutorials/tutorial-thumbnail.png';
import fundamentalsThumbnail from '../../../assets/img/tutorials/fundamentals-thumbnail.png';
import linkingThumbnail from '../../../assets/img/tutorials/linking-thumbnail.png';
import quantitiesThumbnail from '../../../assets/img/tutorials/quantities-thumbnail.png';
import shapesThumbnail from '../../../assets/img/tutorials/shapes-thumbnail.png';
import {
  Panel,
  PanelActionButtons,
  PanelContent,
  PanelHeader,
  PanelTitle,
} from '../../common/Panel';
import SparkelIcon from '../../common/icon/SparkelIcon';

const TutorialItem = (props: { thumbnail: string; url: string }) => {
  return (
    <Box width={200} height={115} _hover={{ opacity: 0.5 }}>
      <Link href={props.url} isExternal>
        <Image src={props.thumbnail} borderRadius={'lg'} />
      </Link>
    </Box>
  );
};

export const TutorialsBanner = ({
  setShouldShowTutorials,
}: {
  setShouldShowTutorials: (value: string[]) => void;
}): React.ReactElement => {
  const { t } = useTranslation('company');
  return (
    <Panel width={'fit-content'}>
      <PanelHeader>
        <PanelTitle>{t('getting-started-header')}</PanelTitle>
        <PanelActionButtons>
          <Tooltip label="Close">
            <IconButton
              isRound
              aria-label="select grouping label"
              colorScheme="gray"
              size="xs"
              icon={<SparkelIcon icon={faTimes} />}
              onClick={() => setShouldShowTutorials(['false'])}
            />
          </Tooltip>
        </PanelActionButtons>
      </PanelHeader>
      <PanelContent>
        <HStack gap={2}>
          <TutorialItem
            thumbnail={tutorialThumbnail}
            url="https://www.youtube.com/watch?v=xx8vTKIozSA&list=PLD92677OPLExkYYXecx1r4Npa-jDnBt0T&index=1"
          />
          <TutorialItem
            thumbnail={fundamentalsThumbnail}
            url="https://www.youtube.com/watch?v=BPheyBj-WVI&list=PLD92677OPLExkYYXecx1r4Npa-jDnBt0T&index=2"
          />
          <TutorialItem
            thumbnail={linkingThumbnail}
            url="https://www.youtube.com/watch?v=DGzoZo8Smzo&list=PLD92677OPLExkYYXecx1r4Npa-jDnBt0T&index=3"
          />
          <TutorialItem
            thumbnail={quantitiesThumbnail}
            url="https://www.youtube.com/watch?v=x7Ei_p-BWWQ&list=PLD92677OPLExkYYXecx1r4Npa-jDnBt0T&index=4"
          />
          <TutorialItem
            thumbnail={shapesThumbnail}
            url="https://www.youtube.com/watch?v=aztJrrwieco&list=PLD92677OPLExkYYXecx1r4Npa-jDnBt0T&index=5"
          />
        </HStack>
      </PanelContent>
    </Panel>
  );
};
