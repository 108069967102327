import {
  faEyeSlash,
  faMousePointer,
  faScissors,
} from '@fortawesome/free-solid-svg-icons';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import invariant from 'tiny-invariant';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IsolationIcon } from '../../assets/icons/isolation.svg';
import {
  DbIdsPerModel,
  getCanvasMousePosition,
} from '../../components/common/ForgeViewer';
import SparkelIcon from '../../components/common/icon/SparkelIcon';
import {
  ContextMenuContextType,
  ContextMenuProvider,
  ItemParams,
  OnShowProps,
  PredicateParams,
  useCustomContextMenu as useCustomContextMenu,
} from './CustomContextMenu';
import { ForgeVisibilityManager } from './ForgeVisibilityManager';
import { ShapesManager } from './ShapesManager';

export type ForgeViewerMenuProps = {
  id?: string;
  selections: DbIdsPerModel;
  hasSelectedElements: boolean;
  target?: {
    type: 'ELEMENT' | 'SHAPE';
    modelUrn: string;
    dbId: number;
  };
};

export const ForgeContextMenuContext = createContext<
  ContextMenuContextType<ForgeViewerMenuProps>
>({
  registerItem: () => {
    throw new Error('No Context Menu Context provider found.');
  },
  unregisterItem: () => {
    throw new Error('No Context Menu Context provider found.');
  },
  items: [],
});

export const ForgeContextMenuProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <ContextMenuProvider context={ForgeContextMenuContext}>
      {children}
    </ContextMenuProvider>
  );
};

export function useForgeViewerContextMenu(
  aggregatedView: Autodesk.Viewing.AggregatedView | null,
  isModelsLoaded: boolean,
  selectedDbIds: DbIdsPerModel,
  visibilityManager: ForgeVisibilityManager,
  shapesManager: ShapesManager
) {
  const showAndSelect = useCallback(
    async ({ event, showContextMenu }: OnShowProps<ForgeViewerMenuProps>) => {
      if (!aggregatedView) {
        return;
      }

      // First, check if no elements are selected and there is on under the cursor
      let clientX: number;
      let clientY: number;

      if (event.type === 'mouseup') {
        event = event as React.MouseEvent<HTMLDivElement>;
        clientX = event.clientX;
        clientY = event.clientY;
      } else {
        invariant(event.type === 'touchstart', 'Unexpected event type');
        const touch = (event as React.TouchEvent<HTMLDivElement>).touches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;
      }

      const { canvasX, canvasY } = getCanvasMousePosition(clientX, clientY);
      const result = aggregatedView.viewer.hitTest(canvasX, canvasY, false);

      const currentSelection = aggregatedView.viewer
        .getAggregateSelection()
        .flatMap((selection) => selection.selection);

      if (result && result.dbId && currentSelection.length === 0) {
        const { dbId, model } = result;
        const isShape = Object.keys(shapesManager.loadedShapeModels).includes(
          model.getData().urn
        );
        aggregatedView.viewer.setAggregateSelection([
          {
            model,
            ids: [dbId],
            selectionType: Autodesk.Viewing.SelectionType.MIXED,
          },
        ]);
        showContextMenu({
          event,
          props: {
            selections: {
              [model.getData().urn]: [dbId],
            },
            hasSelectedElements: true,
            target: {
              type: isShape ? 'SHAPE' : 'ELEMENT',
              dbId,
              modelUrn: model.getData().urn,
            },
          },
        });
      } else if (currentSelection.length === 1) {
        const dbId = currentSelection[0];
        const urn = aggregatedView.viewer
          .getAggregateSelection()[0]
          .model.getData().urn;
        const isShape = Object.keys(shapesManager.loadedShapeModels).includes(
          urn
        );
        showContextMenu({
          event,
          props: {
            hasSelectedElements: true,
            selections: {
              [urn]: [dbId],
            },
            target: {
              type: isShape ? 'SHAPE' : 'ELEMENT',
              dbId,
              modelUrn: urn,
            },
          },
        });
      } else if (currentSelection.length > 1) {
        showContextMenu({
          event,
          props: {
            hasSelectedElements: true,
            selections: Object.fromEntries(
              aggregatedView.viewer
                .getAggregateSelection()
                .map((selection) => [
                  selection.model.getData().urn,
                  selection.selection,
                ])
            ),
          },
        });
      } else {
        showContextMenu({ event });
      }
    },
    [aggregatedView, shapesManager.loadedShapeModels]
  );

  // Hide the built-in menu
  useEffect(() => {
    if (aggregatedView) {
      aggregatedView.viewer.setContextMenu(null);
    }
  }, [aggregatedView]);

  const { registerItem, unregisterItem } = useForgeContextMenuContext();

  const selectAllVisibleHandler = useCallback(() => {
    invariant(aggregatedView, 'Aggregated view not found');
    const selection = aggregatedView.viewer.getAllModels().map((model) => {
      return {
        model,
        ids: Array.from(visibleFirstObjects(model)),
      };
    });
    aggregatedView.viewer.setAggregateSelection(selection);
  }, [aggregatedView]);

  const hideSelectedHandler = useCallback(() => {
    visibilityManager.hide(selectedDbIds);
  }, [selectedDbIds, visibilityManager]);

  const isolateSelectedHandler = useCallback(() => {
    visibilityManager.isolate(selectedDbIds);

    invariant(aggregatedView, 'Aggregated view not found');
    aggregatedView.viewer.setActiveNavigationTool('orbit');
  }, [visibilityManager, selectedDbIds, aggregatedView]);

  let sectionExtension: Autodesk.Viewing.Extension | null = null;

  if (aggregatedView && isModelsLoaded) {
    sectionExtension = aggregatedView.viewer.getExtension('Autodesk.Section');
  }

  /* eslint-disable @typescript-eslint/ban-ts-comment */
  const sectionBoxHandler = useCallback(() => {
    invariant(aggregatedView, 'Aggregated view not found');
    invariant(sectionExtension, 'Section extension not found');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const bbox = aggregatedView.viewer.impl.selector.getSelectionBounds();
    // @ts-ignore
    sectionExtension.setSectionBox(bbox);
  }, [aggregatedView, sectionExtension]);

  const sectionPlaneHandler = useCallback(
    ({ triggerEvent }: ItemParams) => {
      invariant(aggregatedView, 'Aggregated view not found');
      invariant(sectionExtension, 'Section extension not found');
      const aggregateSelection = aggregatedView.viewer.getAggregateSelection();
      const selected = aggregateSelection
        .map((selectionObject) => selectionObject.selection)
        .flat();
      const modelIds = aggregateSelection.map(
        (selectionObject) => selectionObject.model.id
      );
      const { canvasX, canvasY } = getCanvasMousePosition(
        // @ts-ignore
        triggerEvent.clientX,
        // @ts-ignore
        triggerEvent.clientY
      );
      const intersection = aggregatedView.viewer.impl.hitTest(
        canvasX,
        canvasY,
        false,
        // @ts-ignore
        selected,
        modelIds
      );
      // Ensure that the selected object is the on that recieved the context click.
      if (
        intersection?.face?.normal &&
        intersection.model &&
        selected.indexOf(intersection.dbId) !== -1
      ) {
        const mesh = aggregatedView.viewer.impl.getRenderProxy(
          intersection.model,
          intersection.fragId
        );
        const normalMatrix = new THREE.Matrix3().getNormalMatrix(
          mesh.matrixWorld
        );
        const normal = intersection.face.normal
          .clone()
          .applyMatrix3(normalMatrix)
          .normalize();

        // @ts-ignore
        sectionExtension.setSectionPlane(normal, intersection.point, false);
      }
    },
    [aggregatedView, sectionExtension]
  );

  const clearSectionHandler = useCallback(() => {
    invariant(sectionExtension, 'Section extension not found');
    sectionExtension.deactivate();
  }, [sectionExtension]);

  const hideSelectionRelatedItems = useCallback(
    ({ props }: PredicateParams<ForgeViewerMenuProps>) => {
      if (!props) {
        return true;
      } else {
        return !props.hasSelectedElements;
      }
    },
    []
  );

  const hideIfNoSection = useCallback(() => {
    if (!sectionExtension) {
      return true;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !sectionExtension.activeStatus;
    }
  }, [sectionExtension]);

  const hideSectionPlaneItem = useCallback(
    ({ triggerEvent }: PredicateParams<ForgeViewerMenuProps>) => {
      if (!sectionExtension || !aggregatedView) {
        return true;
      }

      const aggregateSelection = aggregatedView.viewer.getAggregateSelection();
      const selected = aggregateSelection
        .map((selectionObject) => selectionObject.selection)
        .flat();
      const modelIds = aggregateSelection.map(
        (selectionObject) => selectionObject.model.id
      );
      const { canvasX, canvasY } = getCanvasMousePosition(
        // @ts-ignore
        triggerEvent.clientX,

        // @ts-ignore
        triggerEvent.clientY
      );
      const intersection = aggregatedView.viewer.impl.hitTest(
        canvasX,
        canvasY,
        false,
        // @ts-ignore
        selected,
        modelIds
      );
      if (
        intersection?.face?.normal &&
        intersection.model &&
        selected.indexOf(intersection.dbId) !== -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    [aggregatedView, sectionExtension]
  );

  const { t } = useTranslation('project');

  useEffect(() => {
    registerItem({
      id: 'isolateSelected',
      label: t('viewer-menu.isolate-selected'),
      onClick: isolateSelectedHandler,
      hidden: hideSelectionRelatedItems,
      group: 'viewer visibility',
      icon: <SparkelIcon as={IsolationIcon} />,
    });
    return () => {
      unregisterItem('isolateSelected');
    };
  }, [
    hideSelectionRelatedItems,
    registerItem,
    isolateSelectedHandler,
    unregisterItem,
    t,
  ]);

  useEffect(() => {
    registerItem({
      id: 'hideSelected',
      label: t('viewer-menu.hide-selected'),
      onClick: hideSelectedHandler,
      hidden: hideSelectionRelatedItems,
      group: 'viewer visibility',
      icon: <SparkelIcon size="sm" fixedWidth icon={faEyeSlash} />,
    });
    return () => {
      unregisterItem('hideSelected');
    };
  }, [
    hideSelectionRelatedItems,
    registerItem,
    hideSelectedHandler,
    unregisterItem,
    t,
  ]);

  useEffect(() => {
    registerItem({
      id: 'selectAllVisible',
      label: t('viewer-menu.select-visible'),
      onClick: selectAllVisibleHandler,
      group: 'viewer selection',
      icon: <SparkelIcon size="sm" fixedWidth icon={faMousePointer} />,
    });
    return () => {
      unregisterItem('selectAllVisible');
    };
  }, [registerItem, selectAllVisibleHandler, t, unregisterItem]);

  useEffect(() => {
    registerItem({
      id: 'section',
      label: t('viewer-interactions.section'),
      hidden: hideSelectionRelatedItems,
      group: 'viewer section',
      icon: <SparkelIcon size="sm" icon={faScissors} fixedWidth />,
      subMenuItems: [
        {
          id: 'sectionBox',
          label: t('viewer-menu.section-box'),
          onClick: sectionBoxHandler,
        },
        {
          id: 'sectionPlane',
          label: 'Section Plane',
          onClick: sectionPlaneHandler,
          hidden: hideSectionPlaneItem,
        },
        {
          id: 'clearSection',
          label: 'Clear section',
          onClick: clearSectionHandler,
          hidden: hideIfNoSection,
        },
      ],
    });
    return () => {
      unregisterItem('section');
    };
  }, [
    clearSectionHandler,
    hideIfNoSection,
    hideSectionPlaneItem,
    hideSelectionRelatedItems,
    registerItem,
    sectionBoxHandler,
    sectionPlaneHandler,
    t,
    unregisterItem,
  ]);

  const { items } = useForgeContextMenuContext();
  return useCustomContextMenu(showAndSelect, items, [
    'viewer visibility',
    'viewer selection',
    'viewer section',
  ]);
}
export const useForgeContextMenuContext = () =>
  useContext(ForgeContextMenuContext);

// Starting from the root node, walk down the tree and find all visible nodes that are not models, layers or collections
// Similarly to how FIRST_OBJECT selection mode works
export function visibleFirstObjects(model: Autodesk.Viewing.Model) {
  const selected = new Set<number>();
  const instanceTree: Autodesk.Viewing.InstanceTree =
    model.getData().instanceTree;
  const rootId = instanceTree.getRootId();
  const queue = [rootId];

  while (queue.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const nodeId = queue.shift()!;

    if (!isNodeObjectType(nodeId, instanceTree)) {
      instanceTree.enumNodeChildren(nodeId, (childId) => {
        queue.push(childId);
      });
    } else if (isNodeVisible(nodeId, instanceTree)) {
      selected.add(nodeId);
    }
  }
  return selected;
}

function isNodeObjectType(
  nodeId: number,
  instanceTree: Autodesk.Viewing.InstanceTree
) {
  const nodeType = instanceTree.getNodeType(nodeId);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nodeType !== Autodesk.Viewing.Private.NODE_TYPE.NODE_TYPE_MODEL &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nodeType !== Autodesk.Viewing.Private.NODE_TYPE.NODE_TYPE_LAYER &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nodeType !== Autodesk.Viewing.Private.NODE_TYPE.NODE_TYPE_COLLECTION
  );
}

function isNodeVisible(
  nodeId: number,
  instanceTree: Autodesk.Viewing.InstanceTree
) {
  if (instanceTree.isNodeHidden(nodeId)) {
    // If the node is hidden, check if it has any visible children
    let hasVisibleChild = false;
    instanceTree.enumNodeChildren(
      nodeId,
      (childId) => {
        if (hasVisibleChild) {
          return;
        }
        if (!instanceTree.isNodeHidden(childId)) {
          hasVisibleChild = true;
        }
      },
      true
    );
    return hasVisibleChild;
  }
  return true;
}
