import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  GetProtanProjectStepsForProjectDocument,
  UpsertProtanProjectStepMutation,
} from '../../gql/graphql';

export const upsertProtanProjectStepResolver: UpdateResolver<
  UpsertProtanProjectStepMutation
> = (result, args, cache) => {
  const upsertedSteps = result.upsertProtanProjectStep?.protanProjectSteps;
  if (upsertedSteps) {
    upsertedSteps.forEach((step) => {
      cache.updateQuery(
        {
          query: GetProtanProjectStepsForProjectDocument,
          variables: { projectId: step.projectId },
        },
        (data) => {
          if (data?.project?.protanProjectSteps) {
            const existingStepIndex = data.project.protanProjectSteps.findIndex(
              (s) => s.step === step.step
            );
            if (existingStepIndex !== -1) {
              data.project.protanProjectSteps[existingStepIndex] = step;
            } else {
              data.project.protanProjectSteps.push(step);
            }
          }
          return data;
        }
      );
    });
  }
};
