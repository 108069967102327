import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import {
  CreateShapeFolderMutation,
  CreateShapeFolderMutationVariables,
  DeleteShapeFolderMutation,
  DeleteShapeFolderMutationVariables,
} from '../../gql/graphql';

export const createShapeFolderResolver: UpdateResolver = (
  result: CreateShapeFolderMutation,
  args: CreateShapeFolderMutationVariables,
  cache
) => {
  if (result.createShapeFolder?.shapeFolder) {
    const shapeFoldersForProject = cache.resolve(
      {
        __typename: 'Project',
        id: result.createShapeFolder.shapeFolder.projectId,
      },
      'shapeFolders'
    ) as string[] | null;
    const shapeFolderToAdd = cache.keyOfEntity({
      __typename: 'ShapeFolder',
      id: result.createShapeFolder.shapeFolder.id,
    });
    if (shapeFoldersForProject && shapeFolderToAdd) {
      shapeFoldersForProject.push(shapeFolderToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createShapeFolder.shapeFolder.projectId,
        },
        'shapeFolders',
        shapeFoldersForProject
      );
    }
  }
};

export const deleteShapeFolderResolver: UpdateResolver = (
  result: DeleteShapeFolderMutation,
  args: DeleteShapeFolderMutationVariables,
  cache
) => {
  if (result.deleteShapeFolder?.shapeFolder) {
    const folderId = result.deleteShapeFolder.shapeFolder.id;
    const projectEntity = {
      __typename: 'Project',
      id: result.deleteShapeFolder.shapeFolder.projectId,
    };
    const shapeFoldersForProject = cache.resolve(
      projectEntity,
      'shapeFolders'
    ) as string[] | null;
    const shapeFolderToRemove = cache.keyOfEntity({
      __typename: 'ShapeFolder',
      id: folderId,
    });
    if (shapeFoldersForProject && shapeFolderToRemove) {
      cache.link(
        projectEntity,
        'shapeFolders',
        shapeFoldersForProject.filter((key) => key !== shapeFolderToRemove)
      );
    }

    // Set shapeFolder to `null` for shapes in the deleted folder
    const shapesForProject = cache.resolve(projectEntity, 'shapes') as
      | string[]
      | null;
    if (shapesForProject) {
      const updateFolderFragment = gql`
        fragment _ on Shape {
          folder
        }
      `;
      shapesForProject.forEach((shapeKey) => {
        const folderIdForShape = cache.resolve(shapeKey, 'folder');
        if (folderIdForShape === folderId) {
          const shapeId = cache.resolve(shapeKey, 'id');
          cache.writeFragment(updateFolderFragment, {
            id: shapeId,
            folder: null,
          });
        }
      });
    }
  }
};
