import React, { useEffect, useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { LinearRing2, Point2 } from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import { useAutoGeneratedRoof } from '../../../hooks/autogenerated-roofs-provider';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import { DraggableSheetEdge } from './SheetEdge';
import { DraggableVertex } from './SheetVertex';
import { DiamondLabel } from './DiamondLabel';

interface PolygonVisualizerProps {
  vertices: LinearRing2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}

const AutogeneratedRoof: React.FC<PolygonVisualizerProps> = ({
  vertices,
  getPointInDomCoordinateSystem,
}) => {
  const {
    automaticDrainAndWedgesPlacement,
    setAutomaticDrainAndWedgesPlacement,
    setPolygonData,
    pointsOnLine1,
    pointsOnLine2,
    segments1,
    segments2,
    diamondsLine1,
    diamondsLine2,
  } = useAutoGeneratedRoof();

  const {
    calibration: { calibration },
  } = useSheetViewer();
  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : 0,
    [calibration]
  );

  useEffect(() => {
    if (vertices && unitScale && automaticDrainAndWedgesPlacement) {
      setPolygonData(vertices, unitScale);
    }
  }, [vertices, unitScale, setPolygonData, automaticDrainAndWedgesPlacement]);

  useHotkeys('tab', (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAutomaticDrainAndWedgesPlacement(!automaticDrainAndWedgesPlacement);
  });

  return (
    <>
      {automaticDrainAndWedgesPlacement && (
        <>
          {segments1.map((segment, index) => (
            <DiamondLabel
              key={index}
              segment={segment}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
              diamond={diamondsLine1[index]}
            ></DiamondLabel>
          ))}
          {segments2.map((segment, index) => (
            <DiamondLabel
              key={index}
              segment={segment}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
              diamond={diamondsLine2[index]}
            ></DiamondLabel>
          ))}

          {vertices.slice(0, -1).map((point, index) => (
            <DraggableSheetEdge
              key={index}
              segment={[
                [point[0], point[1]],
                [vertices[index + 1][0], vertices[index + 1][1]],
              ]}
              backgroundColor={'#FFFFFF'}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          ))}
          {pointsOnLine1.map((point, index) => (
            <DraggableVertex
              key={index}
              theme={'default'}
              point={point}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          ))}
          {pointsOnLine2.map((point, index) => (
            <DraggableVertex
              key={index}
              theme={'default'}
              point={point}
              getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
            />
          ))}
        </>
      )}
    </>
  );
};

export default AutogeneratedRoof;
