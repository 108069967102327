import Plausible from 'plausible-tracker';
import { createContext, useContext } from 'react';

type PlausibleClient = ReturnType<typeof Plausible>;

export function createPlausibleClient(): PlausibleClient {
  const host = window.location.hostname;
  const apiHost = `https://${host}/`;

  return Plausible({
    trackLocalhost: false,
    domain: host,
    apiHost: apiHost,
  });
}

// Create a Provider component and a hook to access the Plausible client

const PlausibleContext = createContext<PlausibleClient | null>(null);

export const PlausibleContextProvider = PlausibleContext.Provider;

export const usePlausibleClient = () => {
  const plausible = useContext(PlausibleContext);

  if (!plausible) {
    throw new Error(
      'usePlausible must be used within a PlausibleContextProvider'
    );
  }

  return plausible;
};
