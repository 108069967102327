import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from 'urql';
import { CreateTemplateFromProjectDocument, Project } from '../../gql/graphql';

type CreateTemplateFromProjectTabProps = {
  project: Pick<Project, 'id'>;
};

type TemplateFormValues = {
  templateName: string;
};

export default function CreateTemplateFromProjectTab({
  project,
}: CreateTemplateFromProjectTabProps): React.ReactElement {
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<TemplateFormValues>({ defaultValues: { templateName: '' } });

  const [, createTemplateFromProject] = useMutation(
    CreateTemplateFromProjectDocument
  );

  const onSubmit = handleSubmit(async ({ templateName }) => {
    const result = await createTemplateFromProject({
      projectId: project.id,
      templateName: templateName,
    });
    if (result.error) {
      toast({
        title: 'An error occurred when generating template',
        status: 'error',
      });
    } else {
      toast({
        title: 'Template successfully generated',
        status: 'success',
      });
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired>
        <FormLabel>Template name</FormLabel>
        <Input
          type="text"
          {...register('templateName')}
          placeholder={'Template name'}
        ></Input>
      </FormControl>
      <HStack>
        <Spacer />
        <Button
          variant={'outline'}
          type="submit"
          alignSelf={'end'}
          isLoading={isSubmitting}
          mt={2}
        >
          Create template
        </Button>
      </HStack>
    </form>
  );
}
