import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SheetShapeDeepFragment } from '../gql/graphql';
import { useUserTenant } from '../services/auth-info';
import { OperationResult } from '../domain/geometry/geometric-types';
import { calculateGeometry } from '../domain/geometric-operations';
import { useGeometricAlgorithms } from './geometric-algorithms';
import { usePropertyContext } from './property-resolving';
import { useDebouncedLoading } from './debounced-loading';
import { MultiPolygon2AlgorithmType } from 'src/domain/geometry/algorithm-types';

const sortOrder: Record<MultiPolygon2AlgorithmType, number> = {
  [MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING]: 2,
  [MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA]: 1,
  [MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA]: 0,
  [MultiPolygon2AlgorithmType.POLYGON2_GROSS_SIDE_AREA]: 0,
  [MultiPolygon2AlgorithmType.POLYGON2_PERIMETER]: 0,
  [MultiPolygon2AlgorithmType.POLYGON2_AREA]: 0,
};

export function useDefaultAlgorithmVisualizations(
  shapes: SheetShapeDeepFragment[]
): {
  operationResults: OperationResult[];
  isLoading: boolean;
} {
  const algorithms = useGeometricAlgorithms();
  const { isProtan } = useUserTenant();
  const { projectId } = useParams();
  const { propertyContext } = usePropertyContext(projectId as string);

  const [operationResults, setOperationResults] = useState<OperationResult[]>(
    []
  );
  const [isLoading, setIsLoading] = useDebouncedLoading();

  useEffect(() => {
    if (!shapes || !propertyContext) return;

    async function resolve() {
      const id = Math.random().toString();
      setIsLoading(true, id);

      const results: OperationResult[] = [];

      // Group shapes by urn
      const shapesByUrn = shapes?.reduce((acc, shape) => {
        if (shape.sheetShapePolygon) {
          if (!acc[shape.urn]) acc[shape.urn] = [];
          acc[shape.urn].push(shape);
        }
        return acc;
      }, {} as Record<string, SheetShapeDeepFragment[]>);

      for (const algorithmType of algorithms.sort(
        (a, b) =>
          (sortOrder[a as MultiPolygon2AlgorithmType] ?? 0) -
          (sortOrder[b as MultiPolygon2AlgorithmType] ?? 0)
      )) {
        if (
          isProtan &&
          (algorithmType ===
            MultiPolygon2AlgorithmType.POLYGON2_FIRE_SECTIONING ||
            algorithmType === MultiPolygon2AlgorithmType.POLYGON2_RAND_AREA ||
            algorithmType === MultiPolygon2AlgorithmType.POLYGON2_CORNER_AREA)
        ) {
          for (const [urn, urnShapes] of Object.entries(shapesByUrn ?? {})) {
            if (urnShapes.length > 0) {
              const operationResult = await calculateGeometry(
                algorithmType,
                [
                  {
                    modelUrn: urn,
                    dbIds: urnShapes.map((shape) => shape.dbId),
                  },
                ],
                propertyContext,
                undefined
              );

              if (operationResult.length > 0) {
                results.push(
                  ...operationResult.map(
                    (result) =>
                      [
                        result[0],
                        {
                          ...result[1],
                          modelUrn: urn,
                          algorithmType,
                        },
                      ] as OperationResult
                  )
                );
              }
            }
          }
        }
      }

      setOperationResults(results);
      setIsLoading(false, id);
    }

    resolve();
  }, [propertyContext, algorithms, isProtan, shapes, setIsLoading]);

  return { operationResults, isLoading };
}
