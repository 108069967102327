import { maxBy, sum } from 'lodash';
import {
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';

import {
  buildHalfEdgeGraph,
  getTriangleForFace,
  segmentByAngle,
} from '../util/mesh';
import { calculateArea } from '../util/polygon';
import { degToRad } from '../util';

export function calculateLargestSurfaceArea(
  triangles: Triangle[]
): TriangulatedSurfaceResult | undefined {
  const graph = buildHalfEdgeGraph(triangles);
  const segments = segmentByAngle(graph, degToRad(25));
  const largestSegment = maxBy(
    segments.map((segment) => {
      const triangles = segment.faceIndices.map((i) =>
        getTriangleForFace(graph.faces[i])
      );
      const area = sum(triangles.map((triangle) => calculateArea(triangle)));
      return { area, triangles };
    }),
    (segment) => segment.area
  );

  if (!largestSegment) {
    return undefined;
  }

  return {
    type: ResultType.TRIANGULATED_SURFACE,
    value: largestSegment.area,
    triangles: largestSegment.triangles,
  };
}
