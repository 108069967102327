import { Badge, Box } from '@chakra-ui/react';
import { first } from 'lodash';
import * as math from 'mathjs';
import { useCallback, useMemo, useState } from 'react';
import { calculateSegmentLength } from '../../../domain/geometry/algorithms/util/line-segment';
import {
  LineSegment,
  LineSegment2,
} from '../../../domain/geometry/geometric-types';
import { useViewer } from '../../common/ForgeViewer';
import { projectInDOM, useCameraChangedEvent } from './util';

type MetricLengthLabelProps = {
  segment: LineSegment;
  viewer: Autodesk.Viewing.Viewer3D;
};

export function MetricLengthLabel({ segment, viewer }: MetricLengthLabelProps) {
  // Just use an empty object so we can notify the component when the camera changes
  const [cameraPosition, setCameraPosition] = useState({});

  const { loadedModels } = useViewer();
  const unitScale = useMemo(
    () => first(Object.values(loadedModels))?.getUnitScale() ?? 1,
    [loadedModels]
  );

  const positions2D = useMemo<LineSegment2>(
    () => [projectInDOM(segment[0], viewer), projectInDOM(segment[1], viewer)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cameraPosition, segment, viewer]
  );

  useCameraChangedEvent(
    viewer,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  return (
    <Box
      position="absolute"
      zIndex={1}
      style={{
        top: positions2D[0][1] - 0.5 * (positions2D[0][1] - positions2D[1][1]),
        left: positions2D[0][0] - 0.5 * (positions2D[0][0] - positions2D[1][0]),
      }}
      pointerEvents="none"
    >
      <Badge
        textTransform={'none'}
        size={'md'}
        px={2}
        variant={'subtle'}
        colorScheme={'gray'}
      >
        {math.round(calculateSegmentLength(segment) * unitScale, 2)} m
      </Badge>
    </Box>
  );
}
