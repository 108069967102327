import { useColorModeValue, useToken } from '@chakra-ui/react';
import {
  FontAwesomeIcon,
  type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { forwardRef } from 'react';

export interface SparkelIconProps
  extends Omit<FontAwesomeIconProps, 'color' | 'icon'> {
  color?: string | [string, string];
  icon?: FontAwesomeIconProps['icon'];
  as?: React.ElementType;
}

const DEFAULT_COLORS: [string, string] = ['gray.600', 'white'];

const SparkelIcon = forwardRef<SVGSVGElement, SparkelIconProps>(
  (
    { color = DEFAULT_COLORS, as: Component, icon, ...rest },
    forwardedRef
  ): React.ReactElement => {
    const [lightModeColor, darkModeColor] = useToken(
      'colors',
      Array.isArray(color) ? [color[0], color[1]] : [color, color]
    );

    const iconColor = useColorModeValue(lightModeColor, darkModeColor);

    if (Component) {
      return (
        <Component
          {...rest}
          ref={forwardedRef}
          style={{
            color: iconColor,
          }}
        />
      );
    } else if (icon) {
      return (
        <FontAwesomeIcon
          {...rest}
          icon={icon}
          ref={forwardedRef}
          color={iconColor}
        />
      );
    } else {
      throw new Error('Either icon or as prop must be provided');
    }
  }
);

SparkelIcon.displayName = 'SparkelIcon';

export { type IconProp } from '@fortawesome/fontawesome-svg-core';
export default SparkelIcon;
