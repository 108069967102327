import { MultiPolygon2Algorithm } from '../../algorithm-types';
import { MultiPolygon2Result, ResultType } from '../../geometric-types';
import { toJstsMultiPolygon } from '../util/polygon';

export const calculateSheetShapeArea: MultiPolygon2Algorithm<
  MultiPolygon2Result
> = (multiPolygon) => {
  const jstsMultiPolygon = toJstsMultiPolygon(multiPolygon);
  const value = jstsMultiPolygon.getArea();
  return {
    type: ResultType.MULTIPOLYGON2,
    value,
    multiPolygon,
  };
};
