import { Badge, Box } from '@chakra-ui/react';
import * as math from 'mathjs';
import { useMemo } from 'react';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import { calculateSegmentLength } from '../../../domain/geometry/algorithms/util/line-segment';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import { useSheetViewer } from '../../common/SheetViewer';

type SheetMetricLengthLabelProps = {
  segment: LineSegment2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
};

export function SheetMetricLengthLabel({
  segment,
  getPointInDomCoordinateSystem,
}: SheetMetricLengthLabelProps) {
  const segmentInDom = useMemo<LineSegment2>(() => {
    const p1 = getPointInDomCoordinateSystem(segment[0]);
    const p2 = getPointInDomCoordinateSystem(segment[1]);
    return [p1, p2];
  }, [getPointInDomCoordinateSystem, segment]);

  const {
    calibration: { calibration },
  } = useSheetViewer();
  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  if (!unitScale) {
    return null;
  }

  return (
    <Box
      position="absolute"
      zIndex={2}
      style={{
        top:
          segmentInDom[0][1] - 0.5 * (segmentInDom[0][1] - segmentInDom[1][1]),
        left:
          segmentInDom[0][0] - 0.5 * (segmentInDom[0][0] - segmentInDom[1][0]),
      }}
      pointerEvents="none"
    >
      <Badge
        textTransform={'none'}
        size={'md'}
        px={2}
        variant={'subtle'}
        colorScheme={'gray'}
      >
        {math.round(calculateSegmentLength(segment) * unitScale, 2)} m
      </Badge>
    </Box>
  );
}
