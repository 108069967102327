import { useEffect } from 'react';
import {
  MeshResult,
  TriangulatedSurfaceResult,
} from '../../../domain/geometry/geometric-types';

export const MeshResultVisualization = ({
  result,
  viewer,
  overlayName,
}: {
  result: MeshResult | TriangulatedSurfaceResult;
  viewer: Autodesk.Viewing.Viewer3D;
  overlayName: string;
}) => {
  useEffect(() => {
    const geometry = new THREE.BufferGeometry();
    const vertices = new Float32Array(
      result.triangles.flatMap((triangle) => triangle.flatMap((p) => p))
    );

    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color().setHex(0x38b2ac),
      opacity: 0.7,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore method does exist
    geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    const mesh = new THREE.Mesh(geometry, material);

    viewer.overlays.addMesh(mesh, overlayName);

    return () => {
      viewer.overlays.removeMesh(mesh, overlayName);
      material.dispose();
      geometry.dispose();
    };
  }, [overlayName, result, viewer]);

  return null;
};
