import { useEffect, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { LineSegment2, Point2 } from '../domain/geometry/geometric-types';
import { SheetViewerContextType } from './common/SheetViewer';
import { SheetEdge } from './orderContractor/pdf-shapes/SheetEdge';
import { DraggableVertex } from './orderContractor/pdf-shapes/SheetVertex';

export const SheetCalibrationTool = ({
  calibrationContext,
  getPointInPdfCoordinateSystem,
  getPointInViewerCoordinateSystem,
  viewerRef,
}: {
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  calibrationContext: SheetViewerContextType['calibration'];
  viewerRef: React.RefObject<HTMLDivElement>;
}) => {
  const {
    editMode: { isActive, calibration, setCalibration },
  } = calibrationContext;

  const [intermediatePoint, setIntermediatePoint] = useState<Point2 | null>(
    null
  );

  const isShiftPressed = isHotkeyPressed('shift');

  useEffect(() => {
    const currentViewer = viewerRef.current;
    if (!currentViewer) {
      return;
    }
    const handlePageClick = (event: MouseEvent) => {
      if (!isActive) {
        return;
      }
      if (event.button === 0) {
        const point =
          intermediatePoint ||
          getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
        if (!calibration[0]) {
          setCalibration([point, null]);
        } else if (!calibration[1]) {
          setCalibration([calibration[0], point]);
        } else {
          setCalibration([point, null]);
        }
      }
    };
    currentViewer.addEventListener('click', handlePageClick);
    return () => {
      currentViewer.removeEventListener('click', handlePageClick);
    };
  }, [
    calibration,
    getPointInPdfCoordinateSystem,
    intermediatePoint,
    isActive,
    setCalibration,
    viewerRef,
  ]);

  // Keep an internal copy of the line segment to avoid re-rendering everything on drag
  // Only update the calibration when the user has finished dragging a vertex
  const [calibrationInternal, setCalibrationInternal] =
    useState<[Point2 | null, Point2 | null]>(calibration);

  useEffect(() => {
    setCalibrationInternal(calibration);
  }, [calibration]);

  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (!calibrationInternal[0]) {
        return;
      }
      if (calibrationInternal[1]) {
        setIntermediatePoint(null);
        return;
      }
      if (isShiftPressed) {
        const previousPointInDom = getPointInViewerCoordinateSystem(
          calibrationInternal[0]
        );
        previousPointInDom[1] += 68; // Offset for the header
        const deltaX = event.clientX - previousPointInDom[0];
        const deltaY = event.clientY - previousPointInDom[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          setIntermediatePoint(
            getPointInPdfCoordinateSystem([
              event.clientX,
              previousPointInDom[1],
            ])
          );
        } else {
          setIntermediatePoint(
            getPointInPdfCoordinateSystem([
              previousPointInDom[0],
              event.clientY,
            ])
          );
        }
        return;
      }
      setIntermediatePoint(
        getPointInPdfCoordinateSystem([event.clientX, event.clientY])
      );
    };

    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [
    getPointInPdfCoordinateSystem,
    calibrationInternal,
    viewerRef,
    isShiftPressed,
    getPointInViewerCoordinateSystem,
  ]);

  if (!isActive) {
    return null;
  }

  const p1 = calibrationInternal[0];
  const p2 = calibrationInternal[1];

  return (
    <>
      {p1 ? (
        <DraggableVertex
          point={p1}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          onVertexDrag={(event) => {
            const point = [event.clientX, event.clientY] as Point2;
            const pdfPoint = getPointInPdfCoordinateSystem(point);
            const newLineSegment = [pdfPoint, p2] as LineSegment2;
            setCalibrationInternal(newLineSegment);
            if (event.type === 'pointerup') {
              setCalibration(newLineSegment);
            }
          }}
        />
      ) : null}
      {p2 ? (
        <DraggableVertex
          point={p2}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          onVertexDrag={(event) => {
            const point = [event.clientX, event.clientY] as Point2;
            const pdfPoint = getPointInPdfCoordinateSystem(point);
            const newLineSegment = [p1, pdfPoint] as LineSegment2;
            setCalibrationInternal(newLineSegment);
            if (event.type === 'pointerup') {
              setCalibration(newLineSegment);
            }
          }}
        />
      ) : null}
      {p1 && p2 ? (
        <SheetEdge
          segment={calibrationInternal as LineSegment2}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          hideLengthLabel={true}
        />
      ) : null}
      {p1 && !p2 && intermediatePoint ? (
        <SheetEdge
          segment={[p1, intermediatePoint] as LineSegment2}
          backgroundColor="yellow.300"
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          hideLengthLabel={true}
        />
      ) : null}
    </>
  );
};
