import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateSavedViewMutation,
  CreateSavedViewMutationVariables,
  DeleteSavedViewMutation,
  DeleteSavedViewMutationVariables,
  GetSavedViewsForProjectDocument,
  GetSavedViewsForProjectQuery,
  UpdateSavedViewMutation,
  UpdateSavedViewMutationVariables,
} from '../../gql/graphql';

export const createSavedViewResolver: UpdateResolver<
  CreateSavedViewMutation,
  CreateSavedViewMutationVariables
> = (result, args, cache) => {
  if (args.input.savedView.projectId && result.createSavedView?.savedView) {
    const projectId = args.input.savedView.projectId;
    cache.updateQuery(
      {
        query: GetSavedViewsForProjectDocument,
        variables: { projectId, isCommentView: false },
      },
      (data) => {
        const createdView = result.createSavedView?.savedView;
        if (data?.savedViews && createdView) {
          data.savedViews.push(createdView);
          if (createdView.isDefault) {
            resolveHomeView(data, createdView.id);
          }
        }

        return data;
      }
    );
  }
};

export const updateSavedViewResolver: UpdateResolver<
  UpdateSavedViewMutation,
  UpdateSavedViewMutationVariables
> = (result, args, cache) => {
  const updatedView = result?.updateSavedView?.savedView;
  if (updatedView && args.input.patch.isDefault === true) {
    cache.updateQuery(
      {
        query: GetSavedViewsForProjectDocument,
        variables: { projectId: updatedView.projectId, isCommentView: false },
      },
      (data) => {
        if (data?.savedViews) {
          resolveHomeView(data, updatedView.id);
        }
        return data;
      }
    );
  }
};

export const deleteSavedViewResolver: UpdateResolver<
  DeleteSavedViewMutation,
  DeleteSavedViewMutationVariables
> = (result, args, cache) => {
  const deletedView = result.deleteSavedView?.savedView;
  if (deletedView) {
    cache.updateQuery(
      {
        query: GetSavedViewsForProjectDocument,
        variables: { projectId: deletedView.projectId, isCommentView: false },
      },
      (data) => {
        if (data?.savedViews) {
          data.savedViews = data.savedViews.filter(
            (view) => view.id !== deletedView.id
          );
        }
        return data;
      }
    );
  }
};

function resolveHomeView(
  data: GetSavedViewsForProjectQuery,
  newViewId: string
) {
  if (data?.savedViews) {
    const oldDefaultView = data.savedViews.find(
      (view) => view.isDefault && view.id !== newViewId
    );
    if (oldDefaultView) {
      oldDefaultView.isDefault = false;
    }
  }
}
