import { useMemo } from 'react';
import { useQuery } from 'urql';
import { groupBy } from 'lodash';
import {
  ModelsNameMap,
  ShapeFoldersMap,
  ShapesMap,
  SheetCalibrationsMap,
  SparkelPropertiesMap,
  useViewer,
} from '../components/common/ForgeViewer';
import {
  PropertyContext,
  SheetShapeWithSheetInfoMap,
} from '../domain/property-operations';
import {
  GetActiveModelsForProjectDocument,
  GetActiveSheetsForProjectDocument,
  GetShapeFoldersForProjectDeepDocument,
  GetShapesForProjectDeepDocument,
  GetSheetShapesForProjectDeepDocument,
  GetSparkelPropertiesForProjectDocument,
} from '../gql/graphql';
import { getShapeUrn } from '../services/viewer/ShapesManager';

// eslint-disable-next-line complexity
export function usePropertyContext(projectId: string) {
  const { isAllModelsLoaded, loadedModels } = useViewer();

  const [
    {
      data: shapesData,
      fetching: fetchingShapes,
      error: shapesError,
      stale: shapesStale,
    },
  ] = useQuery({
    query: GetShapesForProjectDeepDocument,
    variables: {
      projectId,
    },
  });

  const [
    {
      data: sparkelPropertiesData,
      fetching: fetchingSparkelProperties,
      error: sparkelPropertiesError,
      stale: sparkelPropertiesStale,
    },
  ] = useQuery({
    query: GetSparkelPropertiesForProjectDocument,
    variables: {
      projectId,
    },
  });

  const [
    {
      data: sheetShapesData,
      fetching: fetchingSheetShapes,
      error: sheetShapesError,
      stale: sheetShapesStale,
    },
  ] = useQuery({
    query: GetSheetShapesForProjectDeepDocument,
    variables: {
      projectId,
    },
  });

  const [
    {
      data: activeModels,
      fetching: fetchingaAtiveModels,
      error: activeModelsError,
      stale: activeModelsStale,
    },
  ] = useQuery({
    query: GetActiveModelsForProjectDocument,
    variables: {
      projectId,
    },
  });

  const [
    {
      data: shapeFoldersData,
      fetching: fetchingShapeFolders,
      error: shapeFoldersError,
      stale: shapeFoldersStale,
    },
  ] = useQuery({
    query: GetShapeFoldersForProjectDeepDocument,
    variables: {
      projectId,
    },
  });
  const [
    {
      data: sheetCalibrationsData,
      fetching: fetchingSheetCalibrations,
      error: sheetCalibrationsError,
      stale: sheetCalibrationsStale,
    },
  ] = useQuery({
    query: GetActiveSheetsForProjectDocument,
    variables: {
      projectId,
    },
  });

  const propertyContext = useMemo<PropertyContext>(() => {
    let loadedShapes: ShapesMap = {};
    let loadedSheetShapes: SheetShapeWithSheetInfoMap = {};
    let loadedShapeFolders: ShapeFoldersMap = {};
    let loadedSheetCalibrations: SheetCalibrationsMap = {};
    let loadedSparkelProperties: SparkelPropertiesMap = {};
    let loadedActiveModelsNameMap: ModelsNameMap = {};

    if (
      shapesData?.project &&
      shapeFoldersData?.project &&
      sheetShapesData?.project &&
      sheetCalibrationsData?.project?.activeSheets &&
      activeModels?.project?.activeModels
    ) {
      loadedShapes = groupBy(shapesData.project.shapes, (shape) => shape.urn);

      loadedShapeFolders = Object.fromEntries([
        [getShapeUrn(projectId), shapeFoldersData.project.shapeFolders],
      ]);

      loadedSheetCalibrations = Object.fromEntries(
        sheetCalibrationsData.project.activeSheets.map((sheet) => [
          sheet.id,
          sheet.sheetPageCalibrations,
        ])
      );

      loadedActiveModelsNameMap = Object.fromEntries(
        activeModels.project.activeModels?.map((activeModel) => [
          activeModel.id + '.ifc',
          activeModel.filename,
        ])
      );

      const sheets = sheetCalibrationsData.project.activeSheets;
      const sheetShapesWithSheet = sheetShapesData.project.sheetShapes.map(
        (sheetShape) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const sheet = sheets!.find(
            (sheet) => sheet.id === sheetShape.sheetId
          )!;
          return {
            ...sheetShape,
            sheet: {
              id: sheet.id,
              name: sheet.name,
              filename: sheet.filename,
            },
          };
        }
      );
      loadedSheetShapes = groupBy(
        sheetShapesWithSheet,
        (sheetShape) => sheetShape.urn
      );
    }

    if (sparkelPropertiesData?.project) {
      const project = sparkelPropertiesData.project;
      loadedSparkelProperties =
        project.sparkelProperties.reduce<SparkelPropertiesMap>(
          (propertiesMap, property) => {
            const urn = property.modelUrn;
            if (urn in propertiesMap) {
              propertiesMap[urn].push(property);
            } else {
              propertiesMap[urn] = [property];
            }
            return propertiesMap;
          },
          {}
        );
    }

    return {
      loadedModels,
      loadedShapes,
      loadedSheetShapes,
      loadedShapeFolders,
      loadedSheetCalibrations,
      loadedSparkelProperties,
      loadedActiveModelsNameMap,
    };
  }, [
    projectId,
    loadedModels,
    shapeFoldersData?.project,
    shapesData?.project,
    sheetShapesData?.project,
    sheetCalibrationsData?.project?.activeSheets,
    sparkelPropertiesData?.project,
    activeModels?.project,
  ]);

  return {
    propertyContext,
    isAllPropertiesLoaded:
      !fetchingShapes &&
      !fetchingSheetShapes &&
      !fetchingShapeFolders &&
      !fetchingSheetCalibrations &&
      !fetchingaAtiveModels &&
      !activeModelsStale &&
      !shapesStale &&
      !sheetShapesStale &&
      !shapeFoldersStale &&
      !sparkelPropertiesStale &&
      !sheetCalibrationsStale &&
      !fetchingSparkelProperties &&
      isAllModelsLoaded,
    error:
      shapesError ||
      activeModelsError ||
      sheetShapesError ||
      shapeFoldersError ||
      sheetCalibrationsError ||
      sparkelPropertiesError,
  };
}
