import { uniq } from 'lodash';
import * as math from 'mathjs';
import { BBox, RBush3D } from 'rbush-3d';
import {
  LineSegment,
  MultiPolygon,
  MultiPolygon2,
  Plane,
  Point,
  Point2,
  Polygon,
  Polygon2,
  Triangle,
  TrianglesInPlane,
} from '../../geometric-types';
import { unitVector } from '.';

// The maximum difference between the plane coefficients of two triangles
// to consider the triangles to be in the same plane
const MAX_PLANE_COEFFICIENT_DIFF = 0.0001;

// The maximum difference between the normals of two triangles
// to consider the triangles to be in the same plane
const MAX_PLANE_NORMAL_DIFF = 0.001;

// How much the unit normal can deviate before the plane is not considered horisontal
export const HORISONTAL_PLANE_EPSILON = 0.0001;

export function calculatePlaneEquation(triangle: Triangle): Plane {
  const [p1, p2, p3] = triangle;
  const p1p2 = math.subtract(p2, p1);
  const p1p3 = math.subtract(p3, p1);
  const triangleNormal = math.cross(p1p2, p1p3);
  const unitNormal = unitVector(triangleNormal as Point);
  // k in plane equation ax + by + cz + k = 0
  // where a, b, c is determined by normal
  const planeCoefficient =
    -p1[0] * unitNormal[0] - p1[1] * unitNormal[1] - p1[2] * unitNormal[2];
  return {
    unitNormal,
    planeCoefficient,
  };
}

export const checkIsHorisontalPlane = (plane: Plane) =>
  math.abs(plane.unitNormal[2]) > 1 - HORISONTAL_PLANE_EPSILON;

type ToPlanarArgs = {
  isHorisontalPlane: boolean;
  pointInPlane: Point;
  rotationMatrix: number[][];
};

type To3dArgs = {
  isHorisontalPlane: boolean;
  pointInPlane: Point;
  inverseRotationMatrix: number[][];
};

export const toPlanarMultiPolygon = (
  multiPolygon: MultiPolygon,
  plane: Plane
): MultiPolygon2 => {
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const rotationMatrix = rotationMatrixPlaneToXY(plane.unitNormal);

  return {
    polygons: multiPolygon.polygons.map((polygon) =>
      _toPlanarPolygon(polygon, {
        isHorisontalPlane,
        pointInPlane,
        rotationMatrix,
      })
    ),
  };
};

export const toPlanarPolygon = (polygon: Polygon, plane: Plane): Polygon2 => {
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const rotationMatrix = rotationMatrixPlaneToXY(plane.unitNormal);
  return _toPlanarPolygon(polygon, {
    isHorisontalPlane,
    pointInPlane,
    rotationMatrix,
  });
};

// Version of toPlanarPolygon where the pointInPlane and rotationMatrix is precalculated
function _toPlanarPolygon(polygon: Polygon, args: ToPlanarArgs): Polygon2 {
  const exterior = _toPlanarPoints(polygon.exterior, args);
  const interiors = polygon.interiors.map((ring) =>
    _toPlanarPoints(ring, args)
  );
  return {
    exterior,
    interiors,
  };
}

// Transforms points in the plane to 2d points, preserving distances between points
export function toPlanarPoints(points: Point[], plane: Plane): Point2[] {
  const { unitNormal } = plane;
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  return _toPlanarPoints(points, {
    isHorisontalPlane,
    pointInPlane,
    rotationMatrix: rotationMatrixPlaneToXY(unitNormal),
  });
}

// Version of toPlanarPoints where the pointInPlane and rotationMatrix is precalculated
function _toPlanarPoints(points: Point[], args: ToPlanarArgs): Point2[] {
  return points.map((point) => _toPlanarPoint(point, args));
}

export function toPlanarPoint(point: Point, plane: Plane): Point2 {
  const { unitNormal } = plane;
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const rotationMatrix = rotationMatrixPlaneToXY(unitNormal);

  return _toPlanarPoint(point, {
    isHorisontalPlane,
    pointInPlane,
    rotationMatrix,
  });
}

// Version of toPlanarPoint where the pointInPlane and rotationMatrix is precalculated
function _toPlanarPoint(point: Point, args: ToPlanarArgs): Point2 {
  const { isHorisontalPlane, pointInPlane, rotationMatrix } = args;

  let transformed: Point = math.subtract(point, pointInPlane);
  if (!isHorisontalPlane) {
    transformed = math.multiply(
      rotationMatrix,
      transformed
    ) as unknown as Point;
  }
  return [transformed[0], transformed[1]];
}

export const to3dMultiPolygon = (
  multiPolygon: MultiPolygon2,
  plane: Plane
): MultiPolygon => {
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const inverseRotationMatrix = math.transpose(
    rotationMatrixPlaneToXY(plane.unitNormal)
  );

  return {
    polygons: multiPolygon.polygons.map((polygon) =>
      _to3dPolygon(polygon, {
        isHorisontalPlane,
        pointInPlane,
        inverseRotationMatrix,
      })
    ),
  };
};

export const to3dPolygon = (polygon: Polygon2, plane: Plane): Polygon => {
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const inverseRotationMatrix = math.transpose(
    rotationMatrixPlaneToXY(plane.unitNormal)
  );
  return _to3dPolygon(polygon, {
    isHorisontalPlane,
    pointInPlane,
    inverseRotationMatrix,
  });
};

// Version of to3dPolygon where the pointInPlane and rotationMatrix is precalculated
function _to3dPolygon(polygon: Polygon2, args: To3dArgs): Polygon {
  const exterior = _to3dPoints(polygon.exterior, args);
  const interiors = polygon.interiors.map((ring) => _to3dPoints(ring, args));
  return {
    exterior,
    interiors,
  };
}

// Inverts the transformation done by toPlanarPoints, transforming 2d points in a plane back to 3d points
export function to3dPoints(points: Point2[], plane: Plane): Point[] {
  const { unitNormal } = plane;
  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const pointInPlane = getPointInPlane(plane);
  const inverseRotationMatrix = math.transpose(
    rotationMatrixPlaneToXY(unitNormal)
  );
  return _to3dPoints(points, {
    isHorisontalPlane,
    pointInPlane,
    inverseRotationMatrix,
  });
}

// Version of to3dPoints where the pointInPlane and rotationMatrix is precalculated
function _to3dPoints(points: Point2[], args: To3dArgs): Point[] {
  const { pointInPlane, isHorisontalPlane, inverseRotationMatrix } = args;

  return points.map((point) => {
    let point3d: Point = [point[0], point[1], 0];
    if (!isHorisontalPlane) {
      point3d = math.multiply(
        inverseRotationMatrix,
        point3d
      ) as unknown as Point;
    }
    return math.add(point3d, pointInPlane);
  });
}

export function rotationMatrixPlaneToXY(unitNormal: Point): number[][] {
  if (math.abs(unitNormal[2]) > 1 - HORISONTAL_PLANE_EPSILON) {
    // Plane already horisontal, no need to rotate
    return [
      [1, 0, 0],
      [0, 1, 0],
      [0, 0, 1],
    ];
  }

  let u = math.cross(unitNormal, [0, 0, 1]);
  u = math.divide(u, math.norm(u)) as math.MathArray;

  const u1 = u[0] as number;
  const u2 = u[1] as number;

  const cosTheta = unitNormal[2];
  const theta = Math.acos(cosTheta);
  const sinTheta = Math.sin(theta);

  return [
    [
      cosTheta + u1 ** 2 * (1 - cosTheta),
      u1 * u2 * (1 - cosTheta),
      u2 * sinTheta,
    ],
    [
      u1 * u2 * (1 - cosTheta),
      cosTheta + u2 ** 2 * (1 - cosTheta),
      -u1 * sinTheta,
    ],
    [-u2 * sinTheta, u1 * sinTheta, cosTheta],
  ];
}

// Groups triangles based on the similarity of their plane equation
// ax + by + cz + d = 0 where a**2 + b**2 + c**2 === 1
export function findPlanes(
  triangles: Triangle[],
  maxMatchDistancePlanes?: number
): TrianglesInPlane[] {
  let planes: Plane[] = [];
  for (const triangle of triangles) {
    planes.push(calculatePlaneEquation(triangle));
  }

  const maxPlaneNormalDiff = MAX_PLANE_NORMAL_DIFF;
  const maxPlaneCoefficientDiff =
    maxMatchDistancePlanes ?? MAX_PLANE_COEFFICIENT_DIFF;

  type TreeType = BBox & { plane: Plane; index: number };
  const items: TreeType[] = [];
  for (const [index, plane] of planes.entries()) {
    const normal = plane.unitNormal;
    items.push({
      minX: normal[0] - maxPlaneNormalDiff,
      maxX: normal[0] + maxPlaneNormalDiff,
      minY: normal[1] - maxPlaneNormalDiff,
      maxY: normal[1] + maxPlaneNormalDiff,
      minZ: normal[2] - maxPlaneNormalDiff,
      maxZ: normal[2] + maxPlaneNormalDiff,
      plane,
      index,
    });
  }

  const filterMatches = (item: TreeType, matches: TreeType[]) => {
    return matches.filter((candidate) => {
      const candidatePlane = candidate.plane;
      if (candidatePlane.planeCoefficient - item.plane.planeCoefficient === 0) {
        // Special case to avoid division by 0
        return true;
      }

      // The idea here is to scale the diff by the size of the coefficient
      // To have a diff dependent of the magnitude of the coefficient
      return (
        math.abs(
          (candidatePlane.planeCoefficient - item.plane.planeCoefficient) /
            (candidatePlane.planeCoefficient + item.plane.planeCoefficient)
        ) < maxPlaneCoefficientDiff
      );
    });
  };
  const groups = clusterRBush3D(items, filterMatches);

  return groups.map((group) => ({
    triangles: Array.from(group).map((index) => triangles[index]),
    plane: planes[group.values().next().value],
  }));
}

export const clusterRBush3D = <T extends BBox>(
  items: T[],
  filter: (item: T, matches: T[]) => T[]
): Set<number>[] => {
  // Would be even better with a 4D-tree for indexing the plane coefficient as well, but this will work for now
  const tree = new RBush3D(16);

  // First index every plane
  tree.load(items);

  const matches: Map<number, Set<number>> = new Map();
  const matchedIndexes = new Set<number>();
  for (const [index, item] of items.entries()) {
    // Skip items we have already found, these are part of a group already
    if (matchedIndexes.has(index)) {
      continue;
    }
    const candidates = tree.search(item) as T[];
    // Only find matches where the plane coefficient also matches.
    // Because the tree only allows for indexing in R3
    // If we had an R4-tree we could skip this and index the coefficient as well
    const matchesForItem = filter(item, candidates).map((match) => match.index);

    if (matchesForItem.length === 1) {
      // No other matches, found itself only
      matches.set(index, new Set(matchesForItem));
    } else {
      // Merge all matched indices to a single set
      const merged = new Set([
        ...matchesForItem,
        // Using object identity of the sets to find all distinct sets
        ...uniq(
          matchesForItem
            .filter((matchedIndex) => matches.has(matchedIndex))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((matchedIndex) => matches.get(matchedIndex)!)
        ).flatMap((matches) => Array.from(matches)),
      ]);
      matchesForItem.forEach((matchedIndex) =>
        matches.set(matchedIndex, merged)
      );
      matches.set(index, merged);
    }
    matchesForItem.forEach((matchedIndex) => matchedIndexes.add(matchedIndex));
  }

  // Using object identity of the sets to find all distinct sets
  return uniq(Array.from(matches.values()));
};

export function projectPointOnPlane(point: Point, plane: Plane): Point {
  // p - (n ⋅ p + d) * n
  const n = plane.unitNormal;
  const d = plane.planeCoefficient;
  const t = (math.dot(n, point) + d) as number;
  return math.subtract(point, math.multiply(t, n)) as unknown as Point;
}

export const getPointInPlane = (plane: Plane): Point => {
  const [a, b, c] = plane.unitNormal;
  // ax + by + cz + d = 0
  if (math.abs(a) > 0.01) {
    return [-plane.planeCoefficient / a, 0, 0];
  } else if (math.abs(b) > 0.01) {
    return [0, -plane.planeCoefficient / b, 0];
  }
  return [0, 0, -plane.planeCoefficient / c];
};

export const isPointOnPlane = (
  point: Point,
  plane: Plane,
  pointOnPlaneEpsilon = 1e-5
) => {
  let planeDist =
    point[0] * plane.unitNormal[0] +
    point[1] * plane.unitNormal[1] +
    point[2] * plane.unitNormal[2] +
    +plane.planeCoefficient;
  return Math.abs(planeDist) < pointOnPlaneEpsilon;
};

// ported from https://www.ilikebigbits.com/2017_09_25_plane_from_points_2.html
export function bestFitPlane(points: Point[]): Plane | null {
  const n = points.length;
  if (n < 3) {
    return null;
  }

  const centroid = points.reduce((a, b) => math.add(a, b)) as Point;
  centroid.forEach((val, idx, arr) => (arr[idx] = val / n));

  let xx = 0.0,
    xy = 0.0,
    xz = 0.0;
  let yy = 0.0,
    yz = 0.0,
    zz = 0.0;

  for (const point of points) {
    const r = math.subtract(point, centroid) as Point;
    xx += r[0] * r[0];
    xy += r[0] * r[1];
    xz += r[0] * r[2];
    yy += r[1] * r[1];
    yz += r[1] * r[2];
    zz += r[2] * r[2];
  }

  const divisor = n;
  xx /= divisor;
  xy /= divisor;
  xz /= divisor;
  yy /= divisor;
  yz /= divisor;
  zz /= divisor;

  let weightedDir: Point = [0, 0, 0];

  let detX = yy * zz - yz * yz;
  let axisDir = [detX, xz * yz - xy * zz, xy * yz - xz * yy];
  let weight = detX * detX;
  if ((math.dotDivide(weightedDir, axisDir) as number) < 0.0) weight = -weight;
  weightedDir = math.add(weightedDir, math.multiply(axisDir, weight)) as Point;

  let detY = xx * zz - xz * xz;
  axisDir = [xz * yz - xy * zz, detY, xy * xz - yz * xx];
  weight = detY * detY;
  if ((math.dotDivide(weightedDir, axisDir) as number) < 0.0) weight = -weight;
  weightedDir = math.add(weightedDir, math.multiply(axisDir, weight)) as Point;

  let detZ = xx * yy - xy * xy;
  axisDir = [xy * yz - xz * yy, xy * xz - yz * xx, detZ];
  weight = detZ * detZ;
  if ((math.dotDivide(weightedDir, axisDir) as number) < 0.0) weight = -weight;
  weightedDir = math.add(weightedDir, math.multiply(axisDir, weight)) as Point;

  const normal = unitVector(weightedDir);

  if (!isNaN(normal[0]) && !isNaN(normal[1]) && !isNaN(normal[2])) {
    return {
      unitNormal: normal,
      planeCoefficient: -math.dot(normal, centroid),
    };
  } else {
    return null;
  }
}

// Function for retrieving the intersection line segment between a plane and a triangle
export function getPlaneTriangleIntersection(
  plane: Plane,
  triangle: Triangle
): LineSegment | null {
  const [p1, p2, p3] = triangle;
  const d = plane.planeCoefficient;

  // Check if the triangle is on the plane
  if (
    isPointOnPlane(p1, plane) &&
    isPointOnPlane(p2, plane) &&
    isPointOnPlane(p3, plane)
  ) {
    return null;
  }

  // Check if the triangle is parallel to the plane
  const triangleNormal = math.cross(
    math.subtract(p2, p1),
    math.subtract(p3, p1)
  ) as Point;
  if (math.dot(triangleNormal, plane.unitNormal) === 0) {
    return null;
  }

  // Find the intersection line segment
  const intersectionPoints: Point[] = [];
  for (const edge of [
    [p1, p2],
    [p2, p3],
    [p3, p1],
  ]) {
    const [p, q] = edge;
    const pq = math.subtract(q, p) as Point;
    const t =
      -(math.dot(p, plane.unitNormal) + d) / math.dot(pq, plane.unitNormal);
    if (t >= 0 && t <= 1) {
      intersectionPoints.push(math.add(p, math.multiply(t, pq)) as Point);
    }
  }

  if (intersectionPoints.length === 2) {
    return intersectionPoints as [Point, Point];
  } else {
    return null;
  }
}
