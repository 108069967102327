import { useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Diamond, KileTypeSKU } from './SheetWedgeShapeDrawing';

const defaultDiamond: Diamond = {
  triangle1: true,
  triangle2: true,
  triangle3: true,
  triangle4: true,
};

const possibleDiamonds: Diamond[] = [
  {
    triangle1: true,
    triangle2: true,
    triangle3: false,
    triangle4: false,
  },
  {
    triangle1: false,
    triangle2: false,
    triangle3: true,
    triangle4: true,
  },
  {
    triangle1: true,
    triangle2: false,
    triangle3: true,
    triangle4: false,
  },
  {
    triangle1: false,
    triangle2: true,
    triangle3: false,
    triangle4: true,
  },
  {
    triangle1: true,
    triangle2: false,
    triangle3: false,
    triangle4: false,
  },
  {
    triangle1: false,
    triangle2: true,
    triangle3: false,
    triangle4: false,
  },
  {
    triangle1: false,
    triangle2: false,
    triangle3: true,
    triangle4: false,
  },
  {
    triangle1: false,
    triangle2: false,
    triangle3: false,
    triangle4: true,
  },
  {
    triangle1: true,
    triangle2: true,
    triangle3: true,
    triangle4: true,
  },
];

type KileSelectorProps = {
  setDiamonds: (diamonds: Diamond[]) => void;
  diamonds: Diamond[];
  kileType: KileTypeSKU;
  index: number;
};

export default function KileSelector({
  setDiamonds,
  diamonds,
  kileType,
  index,
}: KileSelectorProps) {
  useHotkeys('tab', (e) => {
    e.preventDefault();
    e.stopPropagation();
    // For each tab press, cycle through the possible diamonds, and set the current indexed diamond to the next one
    const currentDiamond = diamonds[index];
    setDiamonds([
      ...diamonds.slice(0, index),
      possibleDiamonds[
        (possibleDiamonds.indexOf(currentDiamond) + 1) % possibleDiamonds.length
      ],
      ...diamonds.slice(index + 1),
    ]);
  });

  const diamond = useMemo(() => {
    if (index === -1) {
      setDiamonds([defaultDiamond]);
      return defaultDiamond;
    }

    return diamonds[index];
  }, [diamonds, index, setDiamonds]);

  return (
    <>
      {/* <Select value={kileType}>
        {Object.keys(KileTypeSKU).map((kiletype, index) => (
          <option value={kiletype} key={index}>
            {kilerMap[kiletype][0].length +
              'm - ' +
              kilerMap[kiletype][0].resourceName}
          </option>
        ))}
      </Select>
      <HStack>
        <Button
          variant={'unstyled'}
          pointerEvents={'all'}
          opacity={diamond.triangle1 ? 0.7 : 0}
          onClick={() => {
            (diamonds[index] = {
              triangle1: !diamond.triangle1,
              triangle2: diamond.triangle2,
              triangle3: diamond.triangle3,
              triangle4: diamond.triangle4,
            }),
              setDiamonds(index === -1 ? [diamond] : [...diamonds]);
          }}
          // fillRule={'evenodd'}
        >
          ◢
        </Button>
        <Button
          variant={'unstyled'}
          pointerEvents={'all'}
          opacity={diamond.triangle2 ? 0.7 : 0}
          onClick={() => {
            (diamonds[index] = {
              triangle1: diamond.triangle1,
              triangle2: !diamond.triangle2,
              triangle3: diamond.triangle3,
              triangle4: diamond.triangle4,
            }),
              setDiamonds(index === -1 ? [diamond] : [...diamonds]);
          }}
          // fillRule={'evenodd'}
        >
          ◣
        </Button>
      </HStack>
      <HStack>
        <Button
          variant={'unstyled'}
          pointerEvents={'all'}
          opacity={diamond.triangle3 ? 0.7 : 0}
          onClick={() => {
            (diamonds[index] = {
              triangle1: diamond.triangle1,
              triangle2: diamond.triangle2,
              triangle3: !diamond.triangle3,
              triangle4: diamond.triangle4,
            }),
              setDiamonds(index === -1 ? [diamond] : [...diamonds]);
          }}
          // fillRule={'evenodd'}
        >
          ◥
        </Button>
        <Button
          variant={'unstyled'}
          pointerEvents={'all'}
          opacity={diamond.triangle4 ? 0.7 : 0}
          onClick={() => {
            (diamonds[index] = {
              triangle1: diamond.triangle1,
              triangle2: diamond.triangle2,
              triangle3: diamond.triangle3,
              triangle4: !diamond.triangle4,
            }),
              setDiamonds(index === -1 ? [diamond] : [...diamonds]);
          }}
          // fillRule={'evenodd'}
        >
          ◤
        </Button>
      </HStack> */}
    </>
  );
}
