import React from 'react';
import * as Sentry from '@sentry/react';
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Code,
  Button,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

type ModelErrorBoundaryProps = {
  children: React.ReactNode;
};

const generateEmailLink = (
  error: Error | undefined,
  componentStack: string | null
) => {
  const subject = 'An error occurred on sparkel.io';
  const body = `
  Hi!

  I came across an error while using Sparkel at ${window.location.href}.
  The error message I found was:

  ${error ? `<code>${error.message}</code>` : '<code>Unknown error</code>'}

  With the stack trace:
  <coed>${componentStack}</code>
  `;
  return `mailto:support@sparkel.io?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}&Content-Type=text/html`;
};

export default function SparkelErrorBoundary({
  children,
}: ModelErrorBoundaryProps): React.ReactElement {
  const navigate = useNavigate();
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Modal isOpen onClose={() => void resetError()} size="2xl">
          <ModalOverlay>
            <ModalContent>
              <ModalHeader>An unexpected error occurred</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box>
                  <p>
                    An error occurred <br />
                    {error ? <Code>{error.message}</Code> : null}
                    {process.env.NODE_ENV === 'development' ? (
                      <Code>{componentStack}</Code>
                    ) : null}
                    <br />
                    <br />
                    Try refreshing the page or contact us at <br />
                    <a
                      // Adding this here as we'll most likely change this if adding a component library that is "variant" aware
                      style={{ fontWeight: 'bold' }}
                      href={generateEmailLink(error, componentStack)}
                    >
                      support@sparkel.io
                    </a>
                  </p>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => {
                    resetError();
                    navigate('/');
                  }}
                >
                  Reload
                </Button>
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>
        </Modal>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
