import * as math from 'mathjs';
import { pi } from 'mathjs';
import { OperationContext, Point } from '../../geometric-types';

export const degToRad = (degrees: number) => (degrees * pi) / 180;

export function unitVector(vector: Point): Point {
  return math.divide(vector, math.norm(vector)) as Point;
}

export function getScaledValue(
  unscaledValue: number,
  context: OperationContext,
  dimensions: number
): {
  value: number;
  units: string;
} {
  return {
    value: unscaledValue * context.unitScale ** dimensions,
    units: dimensions === 1 ? 'm' : `m^${dimensions}`,
  };
}
