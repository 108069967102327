import { produce } from 'immer';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import { LinearRing2, Point2 } from '../../../domain/geometry/geometric-types';
import {
  EdgeIntersections,
  EdgeWithId,
  addEdge,
  copy,
  removeEdge,
  updateEdge,
} from '../../../services/viewer/shape-drawing/intersections';
import {
  LinearRing2WithId,
  Point2WithId,
  generateIdForEdge,
  generateIdForPoint,
  generateIdForRing,
  generateIdsForEdges,
  generateIdsForPoints,
  getArcPoints,
} from '../shapes/util';

export type SheetPolygonDrawingState = {
  completedRings: LinearRing2WithId[];
  incompleteRing: Point2WithId[];
  mousePointerPoint: Point2 | null;
  intersectionsForIncompleteRing: EdgeIntersections;
  intersectionsForCompletedRings: EdgeIntersections;
};

export enum SheetPolygonDrawingActionType {
  ADD_INCOMPLETE_RING_POINT,
  ADD_ONE_CLICK_SHAPE,
  REMOVE_INCOMPLETE_RING_POINT,
  REMOVE_COMPLETE_RING_POINT,
  MOVE_INCOMPLETE_RING_POINT,
  MOVE_INCOMPLETE_RING_EDGE,
  SPLIT_INCOMPLETE_RING_EDGE,
  MOVE_COMPLETE_RING_POINT,
  MOVE_COMPLETE_RING_EDGE,
  SPLIT_COMPLETE_RING_EDGE,
  SET_MOUSE_POINTER_POINT,
  CLOSE_RING,
}

export type SheetPolygonDrawingAction =
  | {
      type: SheetPolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT;
      position: Point2;
      isAltKeyPressed?: boolean;
    }
  | {
      type: SheetPolygonDrawingActionType.ADD_ONE_CLICK_SHAPE;
      ring: LinearRing2;
    }
  | {
      type: SheetPolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT;
      pointId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT;
      pointId: string;
      ringId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT;
      position: Point2;
      pointId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE;
      startPositionTranslation: Point2;
      endPositionTranslation: Point2;
      startPointId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE;
      splitPosition: Point2;
      startPointId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_POINT;
      position: Point2;
      pointId: string;
      ringId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE;
      startPositionTranslation: Point2;
      endPositionTranslation: Point2;
      startPointId: string;
      ringId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE;
      splitPosition: Point2;
      startPointId: string;
      ringId: string;
    }
  | {
      type: SheetPolygonDrawingActionType.SET_MOUSE_POINTER_POINT;
      point: Point2 | null;
    }
  | { type: SheetPolygonDrawingActionType.CLOSE_RING };

// eslint-disable-next-line complexity
export const sheetPolygonDrawingReducer = (
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction
): SheetPolygonDrawingState => {
  switch (action.type) {
    case SheetPolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT:
      return handleAddPoint(state, action);
    case SheetPolygonDrawingActionType.ADD_ONE_CLICK_SHAPE:
      return handleAddOneClickShape(state, action);
    case SheetPolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT:
      return handleRemoveIncompleteRingPoint(state, action);
    case SheetPolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT:
      return handleRemoveCompleteRingPoint(state, action);
    case SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT:
      return handleMoveIncompleteRingPoint(state, action);
    case SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE:
      return handleMoveIncompleteRingEdge(state, action);
    case SheetPolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE:
      return handleSplitIncompleteRingEdge(state, action);
    case SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_POINT:
      return handleMoveCompleteRingPoint(state, action);
    case SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE:
      return handleMoveCompleteRingEdge(state, action);
    case SheetPolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE:
      return handleSplitCompleteRingEdge(state, action);
    case SheetPolygonDrawingActionType.SET_MOUSE_POINTER_POINT:
      return handleSetMousePointerPoint(state, action);
    case SheetPolygonDrawingActionType.CLOSE_RING:
      return handleCloseRing(state, action);
  }
};

const handleAddPoint = produce(function (
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT;
  }
) {
  const newPointWithId = generateIdForPoint(action.position);

  if (action.isAltKeyPressed) {
    const endPoint = state.incompleteRing.pop();
    const startPoint = state.incompleteRing.pop();
    if (startPoint && endPoint) {
      const arcPoints = getArcPoints(
        startPoint.point,
        endPoint.point,
        newPointWithId.point
      );

      const arcPointsWithIds = arcPoints.map((point) =>
        generateIdForPoint(point)
      );

      state.incompleteRing.push(...arcPointsWithIds);
    }
    return;
  }

  if (state.incompleteRing.length >= 1) {
    const oldEdges = generateIdsForEdges(state.incompleteRing);
    const newEdge: EdgeWithId = generateIdForEdge(
      state.incompleteRing[state.incompleteRing.length - 1],
      newPointWithId
    );
    addEdge(state.intersectionsForIncompleteRing, oldEdges, newEdge);
  }
  state.incompleteRing.push(newPointWithId);
});

const handleAddOneClickShape = produce(function (
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.ADD_ONE_CLICK_SHAPE;
  }
) {
  state.completedRings = [generateIdForRing(generateIdsForPoints(action.ring))];
  state.incompleteRing = [];
  state.intersectionsForIncompleteRing = new Map();
  state.mousePointerPoint = null;
});

const handleRemoveIncompleteRingPoint = produce(
  (
    state: SheetPolygonDrawingState,
    action: SheetPolygonDrawingAction & {
      type: SheetPolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT;
    }
  ) => {
    const pointIndex = state.incompleteRing.findIndex(
      (point) => point.id === action.pointId
    );
    // Remove edge from previous point to current point (if any)
    if (pointIndex !== 0) {
      const edge = generateIdForEdge(
        state.incompleteRing[pointIndex - 1],
        state.incompleteRing[pointIndex]
      );
      removeEdge(state.intersectionsForIncompleteRing, edge.id);
    }
    // Remove edge from current point to next point (if any)
    if (pointIndex !== state.incompleteRing.length - 1) {
      const edge = generateIdForEdge(
        state.incompleteRing[pointIndex],
        state.incompleteRing[pointIndex + 1]
      );
      removeEdge(state.intersectionsForIncompleteRing, edge.id);
    }
    // Add edge from previous point to next point (if any)
    if (pointIndex !== 0 && pointIndex !== state.incompleteRing.length - 1) {
      const edge = generateIdForEdge(
        state.incompleteRing[pointIndex - 1],
        state.incompleteRing[pointIndex + 1]
      );
      addEdge(
        state.intersectionsForIncompleteRing,
        generateIdsForEdges(state.incompleteRing),
        edge
      );
    }
    state.incompleteRing.splice(pointIndex, 1);
  }
);

const handleRemoveCompleteRingPoint = produce(
  (
    state: SheetPolygonDrawingState,
    action: SheetPolygonDrawingAction & {
      type: SheetPolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT;
    }
  ) => {
    const ringIndex = state.completedRings.findIndex(
      (ring) => ring.id === action.ringId
    );
    const ring = state.completedRings[ringIndex].linearRing;
    if (ring.length === 4) {
      // If ring has only 3 different points, remove the ring
      state.completedRings.splice(ringIndex, 1);
      for (const edge of generateIdsForEdges(ring)) {
        removeEdge(state.intersectionsForCompletedRings, edge.id);
      }
      return;
    }

    const pointIndex = ring.findIndex((point) => point.id === action.pointId);

    const point = ring[pointIndex];
    const pointBefore =
      ring[pointIndex === 0 ? ring.length - 2 : pointIndex - 1];
    const pointAfter =
      pointIndex === ring.length - 1 ? ring[1] : ring[pointIndex + 1];
    // Remove edge from previous point
    removeEdge(
      state.intersectionsForCompletedRings,
      generateIdForEdge(pointBefore, point).id
    );
    // Remove edge from current point to next point
    removeEdge(
      state.intersectionsForCompletedRings,
      generateIdForEdge(point, pointAfter).id
    );

    // Add edge from previous point to next point
    const newEdge = generateIdForEdge(pointBefore, pointAfter);
    addEdge(
      state.intersectionsForCompletedRings,
      generateIdsForEdges(ring),
      newEdge
    );

    // Update points. If first / last point is removed, set new last/first point to satisfy first point === last point
    ring.splice(pointIndex, 1);
    if (pointIndex === 0) {
      ring[ring.length - 1] = ring[0];
      ring[ring.length - 1] = ring[0];
    } else if (pointIndex === ring.length) {
      ring[0] = ring[ring.length - 1];
      ring[0] = ring[ring.length - 1];
    }
  }
);

function handleMoveIncompleteRingPoint(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT;
  }
): SheetPolygonDrawingState {
  const index = state.incompleteRing.findIndex(
    (point) => point.id === action.pointId
  );
  invariant(index >= 0, 'point not found');
  const newPoints = [...state.incompleteRing];
  const newPoint = {
    id: action.pointId,
    point: action.position,
  };
  newPoints[index] = newPoint;
  if (newPoints.length < 2) {
    return {
      ...state,
      mousePointerPoint: null,
      incompleteRing: newPoints,
    };
  }
  const newEdges = generateIdsForEdges(newPoints);
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  if (index !== 0) {
    const updatedIncomingEdge = generateIdForEdge(
      newPoints[index - 1],
      newPoints[index]
    );
    updateEdge(newIntersections, newEdges, updatedIncomingEdge);
  }
  if (index !== newPoints.length - 1) {
    const updatedOutgoingEdge = generateIdForEdge(
      newPoints[index],
      newPoints[index + 1]
    );
    updateEdge(newIntersections, newEdges, updatedOutgoingEdge);
  }
  return {
    ...state,
    incompleteRing: newPoints,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleMoveIncompleteRingEdge(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE;
  }
): SheetPolygonDrawingState {
  const newPoints = [...state.incompleteRing];
  const index = newPoints.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(index >= 0, 'point not found');
  const newStartPoint = {
    id: action.startPointId,
    point: math.add(newPoints[index].point, action.startPositionTranslation),
  };
  const newEndPoint = {
    id: newPoints[index + 1].id,
    point: math.add(newPoints[index + 1].point, action.endPositionTranslation),
  };
  newPoints[index] = newStartPoint;
  newPoints[index + 1] = newEndPoint;
  const newEdges = generateIdsForEdges(newPoints);
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  // Update previous edge
  if (index !== 0) {
    updateEdge(
      newIntersections,
      newEdges,
      generateIdForEdge(newPoints[index - 1], newPoints[index])
    );
  }
  // Update current edge
  updateEdge(
    newIntersections,
    newEdges,
    generateIdForEdge(newPoints[index], newPoints[index + 1])
  );

  // Update next edge
  if (index !== newPoints.length - 2) {
    updateEdge(
      newIntersections,
      newEdges,
      generateIdForEdge(newPoints[index + 1], newPoints[index + 2])
    );
  }
  return {
    ...state,
    incompleteRing: newPoints,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSplitIncompleteRingEdge(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE;
  }
): SheetPolygonDrawingState {
  const newPoints = [...state.incompleteRing];
  const index = newPoints.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(index >= 0, 'point not found');
  const newPointWithId = generateIdForPoint(action.splitPosition);
  newPoints.splice(index + 1, 0, newPointWithId);
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  const newEdges = generateIdsForEdges(newPoints);
  const edgeToNewPoint = generateIdForEdge(
    newPoints[index],
    newPoints[index + 1]
  );
  const edgeFromNewPoint = generateIdForEdge(
    newPoints[index + 1],
    newPoints[index + 2]
  );
  const oldEdge = generateIdForEdge(
    state.incompleteRing[index],
    state.incompleteRing[index + 1]
  );
  removeEdge(newIntersections, oldEdge.id);
  addEdge(newIntersections, newEdges, edgeToNewPoint);
  addEdge(newIntersections, newEdges, edgeFromNewPoint);
  return {
    ...state,
    incompleteRing: newPoints,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleMoveCompleteRingPoint(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_POINT;
  }
): SheetPolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const pointIndex = ring.findIndex((point) => point.id === action.pointId);
  invariant(pointIndex >= 0, 'point not found');
  const newRing = [...ring];
  const newPoint = {
    id: action.pointId,
    point: action.position,
  };
  newRing[pointIndex] = newPoint;
  if (pointIndex === 0) {
    // Update last point to be the same as the first point
    newRing[newRing.length - 1] = newPoint;
  } else if (pointIndex === newRing.length - 1) {
    // Update first point to be the same as the last point
    newRing[0] = newPoint;
  }
  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };

  const newEdges = newRings
    .map((ring) => generateIdsForEdges(ring.linearRing))
    .flat();
  const newIntersections = copy(state.intersectionsForCompletedRings);
  // -2 because the last point is a duplicate of the first point,
  const pointBefore =
    newRing[pointIndex === 0 ? newRing.length - 2 : pointIndex - 1];
  const pointAfter =
    pointIndex === newRing.length - 1 ? newRing[1] : newRing[pointIndex + 1];
  const updatedIncomingEdge = generateIdForEdge(pointBefore, newPoint);
  updateEdge(newIntersections, newEdges, updatedIncomingEdge);
  const updatedOutgoingEdge = generateIdForEdge(newPoint, pointAfter);
  updateEdge(newIntersections, newEdges, updatedOutgoingEdge);
  return {
    ...state,
    intersectionsForCompletedRings: newIntersections,
    completedRings: newRings,
    mousePointerPoint: null,
  };
}

function handleMoveCompleteRingEdge(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE;
  }
): SheetPolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const newRing = [...ring];
  const startPointIndex = newRing.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(startPointIndex >= 0, 'point not found');
  const newStartPoint = {
    id: action.startPointId,
    point: math.add(
      newRing[startPointIndex].point,
      action.startPositionTranslation
    ),
  };
  const newEndPoint = {
    id: newRing[startPointIndex + 1].id,
    point: math.add(
      newRing[startPointIndex + 1].point,
      action.endPositionTranslation
    ),
  };
  newRing[startPointIndex] = newStartPoint;
  newRing[startPointIndex + 1] = newEndPoint;
  if (startPointIndex === 0) {
    // Update last point to be the same as the first point
    newRing[newRing.length - 1] = newStartPoint;
  } else if (startPointIndex + 1 === newRing.length - 1) {
    // Update first point to be the same as the last point
    newRing[0] = newEndPoint;
  }

  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };

  const newEdges = newRings
    .map((ring) => generateIdsForEdges(ring.linearRing))
    .flat();
  const newIntersections = copy(state.intersectionsForCompletedRings);

  const endPointOfPreviousEdge =
    newRing[startPointIndex === 0 ? newRing.length - 2 : startPointIndex - 1];

  const startPointOfNextEdge =
    newRing[startPointIndex + 2 === newRing.length ? 1 : startPointIndex + 2];
  // Update previous edge
  updateEdge(
    newIntersections,
    newEdges,
    generateIdForEdge(endPointOfPreviousEdge, newStartPoint)
  );
  // Update current edge
  updateEdge(
    newIntersections,
    newEdges,
    generateIdForEdge(newStartPoint, newEndPoint)
  );

  // Update next edge
  updateEdge(
    newIntersections,
    newEdges,
    generateIdForEdge(newEndPoint, startPointOfNextEdge)
  );

  return {
    ...state,
    completedRings: newRings,
    intersectionsForCompletedRings: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSplitCompleteRingEdge(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE;
  }
): SheetPolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const newRing = [...ring];
  const startPointIndex = newRing.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(startPointIndex >= 0, 'point not found');
  const newPointWithId = generateIdForPoint(action.splitPosition);
  newRing.splice(startPointIndex + 1, 0, newPointWithId);

  const newEdges = state.completedRings
    .map((ring) =>
      ring.id === action.ringId
        ? generateIdsForEdges(newRing)
        : generateIdsForEdges(ring.linearRing)
    )
    .flat();

  const newIntersections = copy(state.intersectionsForCompletedRings);
  const edgeToNewPoint = generateIdForEdge(
    newRing[startPointIndex],
    newRing[startPointIndex + 1]
  );
  const edgeFromNewPoint = generateIdForEdge(
    newRing[startPointIndex + 1],
    newRing[startPointIndex + 2]
  );
  const oldEdge = generateIdForEdge(
    newRing[startPointIndex],
    newRing[startPointIndex + 2]
  );
  removeEdge(newIntersections, oldEdge.id);
  addEdge(newIntersections, newEdges, edgeToNewPoint);
  addEdge(newIntersections, newEdges, edgeFromNewPoint);
  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };
  return {
    ...state,
    completedRings: newRings,
    intersectionsForCompletedRings: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSetMousePointerPoint(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.SET_MOUSE_POINTER_POINT;
  }
): SheetPolygonDrawingState {
  if (state.incompleteRing.length === 0) {
    return state;
  }
  return {
    ...state,
    mousePointerPoint: action.point,
  };
}

function handleCloseRing(
  state: SheetPolygonDrawingState,
  action: SheetPolygonDrawingAction & {
    type: SheetPolygonDrawingActionType.CLOSE_RING;
  }
): SheetPolygonDrawingState {
  if (state.incompleteRing.length < 3) {
    return state;
  }
  const intersectionsForCompletedRings = copy(
    state.intersectionsForCompletedRings
  );
  const completedRing = generateIdForRing([
    ...state.incompleteRing,
    state.incompleteRing[0],
  ]);
  const newEdges = generateIdsForEdges(completedRing.linearRing);

  // Update intersections for completed rings
  const completedEdges = [
    ...state.completedRings
      .map((ring) => generateIdsForEdges(ring.linearRing))
      .flat(),
    ...newEdges,
  ];

  for (const newEdge of newEdges) {
    addEdge(intersectionsForCompletedRings, completedEdges, newEdge);
  }

  return {
    ...state,
    completedRings: [...state.completedRings, completedRing],
    incompleteRing: [],
    intersectionsForIncompleteRing: new Map(),
    intersectionsForCompletedRings,
    mousePointerPoint: null,
  };
}
