import { sortBy, uniqueId } from 'lodash';
import * as math from 'mathjs';
import { useEffect, useMemo, useRef } from 'react';
import {
  checkIsHorisontalPlane,
  getPointInPlane,
  toPlanarPoint,
  toPlanarPoints,
  toPlanarPolygon,
} from '../../../domain/geometry/algorithms/util/plane';
import {
  isCCW,
  isPointWithinRing,
  ringArea,
} from '../../../domain/geometry/algorithms/util/polygon';
import { toVertex } from '../../../domain/geometry/extract-forge-geometry';
import {
  LineSegment,
  LineSegment2,
  LinearRing2,
  MultiPolygon,
  MultiPolygon2,
  Plane,
  Point,
  Point2,
  Polygon,
  Polygon2,
} from '../../../domain/geometry/geometric-types';
import { EdgeWithId } from '../../../services/viewer/shape-drawing/intersections';
import { getCanvasMousePosition } from '../../common/ForgeViewer';

const maxSnapPixelDistance = 15;

export const projectInDOM = (
  point: Point,
  viewer: Autodesk.Viewing.Viewer3D
): Point2 => {
  const pointThree = new THREE.Vector3(...point);
  const projected = Autodesk.Viewing.MeasureCommon.project(pointThree, viewer);
  return [projected.x, projected.y];
};

export const isPrimaryMouseButton = (button: number) => button === 0;

export const useCameraChangedEvent = (
  viewer: Autodesk.Viewing.Viewer3D | undefined,
  // Note: Should use useCallback
  onChange: () => void
) => {
  useEffect(() => {
    if (!viewer) {
      return;
    }
    viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onChange);
    return () => {
      viewer.removeEventListener(
        Autodesk.Viewing.CAMERA_CHANGE_EVENT,
        onChange
      );
    };
  }, [onChange, viewer]);
};

// The only way I figured out how to prevent events from bubbling to the viewer
export const useViewerClickEvent = (
  viewer: Autodesk.Viewing.Viewer3D,
  // Return true if the event should be prevented from bubbling
  callback: (event: MouseEvent, button: number) => boolean
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const tool = useMemo(
    () =>
      new (class extends Autodesk.Viewing.ToolInterface {
        names = [uniqueId('viewer-click-tool-')];

        constructor() {
          super();
          // Hack: delete functions defined on the *instance* of a ToolInterface (we want the tool controller to call our class methods instead)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          delete this.handleSingleClick;
        }

        override handleSingleClick(event: MouseEvent, button: number): boolean {
          return callbackRef.current(event, button);
        }
      })(),
    []
  );

  useEffect(() => {
    viewer.toolController.registerTool(tool);
    viewer.toolController.activateTool(tool.getName());
    return () => {
      viewer.toolController.deactivateTool(tool.getName());
      viewer.toolController.deregisterTool(tool);
    };
  }, [tool, viewer]);
};

export const useSnapper = (viewer: Autodesk.Viewing.Viewer3D) => {
  const snapper = useMemo(
    () =>
      new Autodesk.Viewing.Extensions.Snapping.Snapper(viewer, {
        renderSnappedGeometry: false,
        renderSnappedTopology: false,
        forceSnapEdges: false,
        forceSnapVertices: false,
        toolName: 'Snapper',
      }),
    [viewer]
  );

  useEffect(() => {
    viewer.toolController.registerTool(snapper);
    viewer.toolController.activateTool('Snapper');
    return () => {
      viewer.toolController.deactivateTool('Snapper');
      viewer.toolController.deregisterTool(snapper);
    };
  }, [snapper, viewer]);

  return snapper;
};

export const getSnapPoint = (
  snapper: Autodesk.Viewing.Extensions.Snapping.Snapper,
  viewer: Autodesk.Viewing.Viewer3D
): Point => {
  const snapResult = snapper.getSnapResult();
  const { SnapType } = Autodesk.Viewing.MeasureCommon;
  const pointThree = (() => {
    switch (snapResult.geomType) {
      case SnapType.SNAP_VERTEX:
      case SnapType.SNAP_MIDPOINT:
      case SnapType.SNAP_INTERSECTION:
      case SnapType.SNAP_CIRCLE_CENTER:
      case SnapType.RASTER_PIXEL:
      case SnapType.SNAP_EDGE:
      case SnapType.SNAP_CIRCULARARC:
      case SnapType.SNAP_CURVEDEDGE: {
        const vertexPixelPos = Autodesk.Viewing.MeasureCommon.project(
          snapResult.geomVertex,
          viewer
        );
        const intersectionPixelPos = Autodesk.Viewing.MeasureCommon.project(
          snapResult.intersectPoint,
          viewer
        );
        const isWithinPixelDistance = math.smaller(
          math.distance(
            [vertexPixelPos.x, vertexPixelPos.y],
            [intersectionPixelPos.x, intersectionPixelPos.y]
          ),
          maxSnapPixelDistance
        ) as boolean;
        if (isWithinPixelDistance) {
          return snapResult.geomVertex;
        }
        return snapResult.intersectPoint;
      }
      default:
        return snapResult.intersectPoint;
    }
  })();
  return [pointThree.x, pointThree.y, pointThree.z];
};

export const projectMouseOnPlane = (
  clientX: number,
  clientY: number,
  plane: Plane,
  viewer: Autodesk.Viewing.Viewer3D
): Point => {
  const { canvasX, canvasY } = getCanvasMousePosition(clientX, clientY);
  const ray = viewer.impl.viewportToRay(
    viewer.impl.clientToViewport(canvasX, canvasY)
  );
  // https://www.wikiwand.com/en/Line%E2%80%93plane_intersection
  const p0 = getPointInPlane(plane);
  // const p0 = toVertex(points[0]);
  const l0 = toVertex(ray.origin);
  const l = toVertex(ray.direction);
  const n = plane.unitNormal;
  const d = math.divide(math.dot(math.subtract(p0, l0), n), math.dot(l, n));
  return math.add(l0, math.multiply(l, d));
};

export function getPolygonGeometry(
  polygon: Polygon,
  plane: Plane
): THREE.BufferGeometry {
  const pointInPlane = getPointInPlane(plane);

  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const planarPolygon: Polygon2 = toPlanarPolygon(polygon, plane);

  let exterior = planarPolygon.exterior;
  // apparently THREE.js needs the exterior to be counter-clockwise
  if (!isCCW(exterior)) {
    exterior = [...exterior].reverse();
  }
  const shape = new THREE.Shape();
  shape.moveTo(exterior[0][0], exterior[0][1]);
  for (let i = 1; i < exterior.length - 1; i++) {
    shape.lineTo(exterior[i][0], exterior[i][1]);
  }
  for (const interior of planarPolygon.interiors) {
    const path = new THREE.Path();
    path.moveTo(interior[0][0], interior[0][1]);
    for (let i = 1; i < interior.length - 1; i++) {
      path.lineTo(interior[i][0], interior[i][1]);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shape.holes.push(path);
  }
  const thickness = 0.01;
  let polygonGeometry = new THREE.BufferGeometry().fromGeometry(
    new THREE.ExtrudeGeometry(shape, {
      steps: 1,
      amount: thickness,
      bevelEnabled: false,
    })
  );

  const thicknessCentering = new THREE.Matrix4().makeTranslation(
    0,
    0,
    -thickness / 2
  );
  polygonGeometry.applyMatrix(thicknessCentering);

  if (isHorisontalPlane) {
    const mat = new THREE.Matrix4().makeTranslation(
      pointInPlane[0],
      pointInPlane[1],
      pointInPlane[2]
    );
    polygonGeometry.applyMatrix(mat);
  } else {
    const rotation = new THREE.Quaternion().setFromUnitVectors(
      new THREE.Vector3(0, 0, 1),
      new THREE.Vector3(...plane.unitNormal)
    );
    const scale = new THREE.Vector3(1, 1, 1);
    const mat = new THREE.Matrix4().compose(
      new THREE.Vector3(...pointInPlane),
      rotation,
      scale
    );
    polygonGeometry.applyMatrix(mat);
  }
  return polygonGeometry;
}

export function getExtrudedPolygonGeometry(
  polygon: Polygon,
  plane: Plane,
  thickness: number
): THREE.BufferGeometry {
  const pointInPlane = getPointInPlane(plane);

  const isHorisontalPlane = checkIsHorisontalPlane(plane);
  const planarPolygon: Polygon2 = toPlanarPolygon(polygon, plane);

  let exterior = planarPolygon.exterior;
  // apparently THREE.js needs the exterior to be counter-clockwise
  if (!isCCW(exterior)) {
    exterior = [...exterior].reverse();
  }
  const shape = new THREE.Shape();
  shape.moveTo(exterior[0][0], exterior[0][1]);
  for (let i = 1; i < exterior.length - 1; i++) {
    shape.lineTo(exterior[i][0], exterior[i][1]);
  }
  for (const interior of planarPolygon.interiors) {
    let interiorRing = interior;
    if (isCCW(interiorRing)) {
      interiorRing = [...interiorRing].reverse();
    }
    const path = new THREE.Path();
    path.moveTo(interiorRing[0][0], interiorRing[0][1]);
    for (let i = 1; i < interiorRing.length - 1; i++) {
      path.lineTo(interiorRing[i][0], interiorRing[i][1]);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    shape.holes.push(path);
  }
  let polygonGeometry = new THREE.BufferGeometry().fromGeometry(
    new THREE.ExtrudeGeometry(shape, {
      steps: 1,
      amount: thickness,
      bevelEnabled: false,
    })
  );

  if (isHorisontalPlane) {
    // Apply scaling if normal is pointing down
    if (plane.unitNormal[2] < 0) {
      // Flip the geometry along Z-axis
      const flipMat = new THREE.Matrix4().makeScale(1, 1, -1);
      // Apply scaling before translation
      polygonGeometry.applyMatrix(flipMat);
    }

    // Apply translation to position the geometry
    const mat = new THREE.Matrix4().makeTranslation(
      pointInPlane[0],
      pointInPlane[1],
      pointInPlane[2]
    );
    polygonGeometry.applyMatrix(mat);
  } else {
    const rotation = new THREE.Quaternion().setFromUnitVectors(
      new THREE.Vector3(0, 0, 1),
      new THREE.Vector3(...plane.unitNormal)
    );
    const scale = new THREE.Vector3(1, 1, 1);
    const mat = new THREE.Matrix4().compose(
      new THREE.Vector3(...pointInPlane),
      rotation,
      scale
    );
    polygonGeometry.applyMatrix(mat);
  }
  return polygonGeometry;
}

type MultiPolygonPointType<MultiPolygonType> =
  MultiPolygonType extends MultiPolygon2 ? Point2 : Point;

export function transformMultipolygonCoordinates<
  P extends MultiPolygon2 | MultiPolygon,
  T
>(multipolygon: P, mappingFn: (point: MultiPolygonPointType<P>) => T) {
  return {
    polygons: multipolygon.polygons.map((polygon) =>
      transformPolygonCoordinates(polygon, (p) =>
        mappingFn(p as MultiPolygonPointType<P>)
      )
    ),
  };
}

type PolygonPointType<MultiPolygonType> = MultiPolygonType extends MultiPolygon2
  ? Point2
  : Point;
export function transformPolygonCoordinates<P extends Polygon2 | Polygon, T>(
  polygon: P,
  mappingFn: (point: PolygonPointType<P>) => T
) {
  return {
    exterior: polygon.exterior.map((p) => mappingFn(p as PolygonPointType<P>)),
    interiors: polygon.interiors.map((interior) =>
      interior.map((p) => mappingFn(p as PolygonPointType<P>))
    ),
  };
}

export function getPolygon2PathData(polygon: Polygon2): string {
  const exterior =
    'M ' +
    polygon.exterior.map((point) => `${point[0]},${point[1]}`).join(' L ');

  const interiors = polygon.interiors
    .map(
      (interior) =>
        'M ' + interior.map((point) => `${point[0]},${point[1]}`).join(' L ')
    )
    .join(' ');

  return exterior + ' Z ' + interiors + ' Z';
}

export type PointWithId<PointType extends Point2 | Point> = {
  id: string;
  point: PointType;
};
export type Point2WithId = PointWithId<Point2>;
export type Point3WithId = PointWithId<Point>;

export type LinearRingWithId<PointType extends Point2 | Point> = {
  id: string;
  linearRing: PointWithId<PointType>[];
};
export type LinearRing2WithId = LinearRingWithId<Point2>;
export type LinearRing3WithId = LinearRingWithId<Point>;

export function getPositions<PointType extends Point2 | Point>(
  pointsWithId: PointWithId<PointType>[]
): PointType[] {
  return pointsWithId.map((pointWithId) => pointWithId.point);
}

export type SegmentTypeForPoint<PointType extends Point2 | Point> =
  PointType extends Point2 ? LineSegment2 : LineSegment;

export function generateIdForEdge<PointType extends Point2 | Point>(
  point1: PointWithId<PointType>,
  point2: PointWithId<PointType>
): EdgeWithId<string, SegmentTypeForPoint<PointType>> {
  return {
    id: `${point1.id}-${point2.id}`,
    segment: [point1.point, point2.point] as SegmentTypeForPoint<PointType>,
  };
}

export function generateIdsForEdges<PointType extends Point2 | Point>(
  points: PointWithId<PointType>[]
): EdgeWithId<string, SegmentTypeForPoint<PointType>>[] {
  type SegmentType = SegmentTypeForPoint<PointType>;
  const edges: EdgeWithId<string, SegmentType>[] = [];
  for (let i = 0; i < points.length - 1; i++) {
    edges.push(generateIdForEdge(points[i], points[i + 1]));
  }
  return edges;
}

export function generateIdForPoint<PointType extends Point2 | Point>(
  point: PointType
): PointWithId<PointType> {
  return {
    id: uniqueId('point_'),
    point,
  };
}

export function generateIdForRing<PointType extends Point2 | Point>(
  ring: PointWithId<PointType>[]
): LinearRingWithId<PointType> {
  return {
    id: uniqueId('ring_'),
    linearRing: ring,
  };
}

export function generateIdsForPoints<PointType extends Point2 | Point>(
  points: PointType[]
): PointWithId<PointType>[] {
  return points.map((point) => generateIdForPoint(point));
}
export const intermediateEdgeId = 'edge-to-intermediate';
export const intermediatePointId = 'intermediate';

export function createIntermediateEdge<PointType extends Point2 | Point>(
  points: PointWithId<PointType>[],
  intermediatePoint: PointType
): EdgeWithId<string, SegmentTypeForPoint<PointType>> {
  return {
    id: intermediateEdgeId,
    segment: [
      points[points.length - 1].point,
      intermediatePoint,
    ] as SegmentTypeForPoint<PointType>,
  };
}

export const toPoint2WithId = (
  point: Point3WithId,
  plane: Plane
): Point2WithId => {
  return toPoints2WithId([point], plane)[0];
};

export const toPoints2WithId = (
  points: Point3WithId[],
  plane: Plane
): Point2WithId[] => {
  const positions = toPlanarPoints(getPositions(points), plane);
  return points.map((p, index) => ({
    id: p.id,
    point: positions[index],
  }));
};

// Assumes no intersections between rings, creates a valid multipolygon - e.g. determines which rings are holes and which are polygons
export function createMultipolygon(linearRings: LinearRing2[]): MultiPolygon2 {
  const ringsByAreaDesc = sortedByAreaDesc(linearRings);

  let polygons: Polygon2[] = [];

  // A smaller ring should never contain a larger ring, begin with the largest ring and build up from there
  for (const ring of ringsByAreaDesc) {
    let isContainedInPolygon = false;
    // Find the smallest polygon containing the ring
    for (let i = polygons.length - 1; i >= 0; i--) {
      const polygon = polygons[i];
      // Because no rings intersect, it is sufficient to test a single point
      if (isPointWithinRing(polygon.exterior, ring[0])) {
        isContainedInPolygon = true;
        let isContainedInHole = false;
        for (const hole of polygon.interiors) {
          if (isPointWithinRing(hole, ring[0])) {
            // A hole within a hole, which means this will be a new polygon
            isContainedInHole = true;
            polygons.push({
              exterior: ring,
              interiors: [],
            });
            break;
          }
        }
        if (!isContainedInHole) {
          polygon.interiors.push(ring);
        }
        break;
      }
    }
    if (!isContainedInPolygon) {
      polygons.push({
        exterior: ring,
        interiors: [],
      });
    }
  }
  return {
    polygons,
  };
}

function sortedByAreaDesc(rings: LinearRing2[]): LinearRing2[] {
  const ringsWithArea = rings.map((ring) => ({
    ring,
    area: Math.abs(ringArea(ring)),
  }));
  const ringsByAreaDesc = sortBy(ringsWithArea, (ring) => ring.area);
  ringsByAreaDesc.reverse();
  return ringsByAreaDesc.map((ring) => ring.ring);
}

export function ringsToSegments(rings: LinearRing2[]): LineSegment2[] {
  return rings.flatMap((ring) => {
    const segments: LineSegment2[] = [];
    for (let i = 0; i < ring.length - 1; i++) {
      segments.push([ring[i], ring[i + 1]]);
    }
    return segments;
  });
}

export function getSegmentIntersection(
  segment1: LineSegment2,
  segment2: LineSegment2,
  tolerance = 0.01
): Point2 | LineSegment2 | null {
  const [[x1, y1], [x2, y2]] = segment1;
  const [[x3, y3], [x4, y4]] = segment2;

  // Calculate denominators
  const den = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);
  const num_t = (x1 - x3) * (y3 - y4) - (y1 - y3) * (x3 - x4);
  const num_u = (x1 - x2) * (y1 - y3) - (y1 - y2) * (x1 - x3);

  if (Math.abs(den) < tolerance) {
    // Lines are parallel or collinear
    if (Math.abs(num_t) < tolerance && Math.abs(num_u)) {
      // Check for overlap (for collinear segments)
      return checkForOverlap(segment1, segment2);
    }
    // No intersection
    return null;
  }

  // Calculate the intersection t and u to check if the point lies within both segments
  const t = num_t / den;
  const u = -num_u / den;

  if (
    t >= -tolerance &&
    t <= 1 + tolerance &&
    u >= -tolerance &&
    u <= 1 + tolerance
  ) {
    // Calculate intersection point
    return [x1 + t * (x2 - x1), y1 + t * (y2 - y1)] as Point2;
  }

  // No intersection
  return null;
}

function checkForOverlap(
  segment1: LineSegment2,
  segment2: LineSegment2
): LineSegment2 | null {
  const points = [...segment1, ...segment2];
  // Sort points along the line (assuming collinearity)
  points.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

  // Check if the sorted points have the middle two points from different segments
  if (segment1.includes(points[1]) != segment1.includes(points[2])) {
    return [points[1], points[2]]; // Middle points are the overlap
  }
  return null;
}

export function getSegmentPlaneIntersection(
  lineSegment: LineSegment,
  plane: Plane
): Point2 | null {
  const p1 = lineSegment[0];
  const p2 = lineSegment[1];

  const direction = math.subtract(p2, p1) as Point;
  const denominator = math.dot(plane.unitNormal, direction) as number;
  if (denominator === 0) return null; // Parallel or lies entirely on the plane

  let t =
    -(math.dot(p1, plane.unitNormal) + plane.planeCoefficient) / denominator;
  if (t < 0 || t > 1) return null; // Intersection not within the segment

  const intersectionPoint = math.add(p1, math.multiply(direction, t)) as Point;

  return toPlanarPoint(intersectionPoint, plane);
}

export function doesRingEncloseAnother(
  ring1: LinearRing2,
  ring2: LinearRing2
): boolean {
  // Loop through each point in ring2, and check if it's inside ring1
  for (const point of ring2) {
    if (!isPointWithinRing(ring1, point)) {
      return false;
    }
  }

  return true;
}

export function doRingsShareEdges(
  ring1: LinearRing2,
  ring2: LinearRing2
): boolean {
  for (let i = 0; i < ring1.length - 1; i++) {
    const segment1 = [ring1[i], ring1[i + 1]] as LineSegment2;
    for (let j = 0; j < ring2.length - 1; j++) {
      const segment2 = [ring2[j], ring2[j + 1]] as LineSegment2;
      if (areSegmentsEqual(segment1, segment2, 0.01)) {
        return true; // Found a shared edge
      }
    }
  }
  return false; // No shared edges found
}

function normalizeRing(ring: LinearRing2): LinearRing2 {
  // Remove the last point if it's a duplicate of the first to handle closure
  if (ring.length > 1 && pointsAreEqual(ring[0], ring[ring.length - 1], 0)) {
    ring = ring.slice(0, -1);
  }

  // Find the index of the minimum point
  const minIndex = ring.reduce((minIdx, currentPoint, idx, arr) => {
    const minPoint = arr[minIdx];
    if (
      currentPoint[0] < minPoint[0] ||
      (currentPoint[0] === minPoint[0] && currentPoint[1] < minPoint[1])
    ) {
      return idx;
    }
    return minIdx;
  }, 0);

  // Rotate the ring so it starts with the minimum point
  const rotatedRing = [...ring.slice(minIndex), ...ring.slice(0, minIndex)];

  // Ensure the ring is closed by repeating the start point at the end
  rotatedRing.push(rotatedRing[0]);

  return rotatedRing;
}

export function areRingsEquivalent(ring1: LinearRing2, ring2: LinearRing2) {
  // First, check if the rings have the same number of points
  if (ring1.length !== ring2.length) {
    return false;
  }

  const normalizedRing1 = normalizeRing(ring1);
  const normalizedRing2 = normalizeRing(ring2);

  // Assuming ring1 and ring2 are already normalized to start from the smallest point and are clockwise.
  const normalizedRing2Reversed = [...normalizedRing2].reverse();

  // Compare ring1 to ring2 in normal and reversed order
  return (
    normalizedRing1.every((point, index) =>
      pointsAreEqual(point, normalizedRing2[index], 0.01)
    ) ||
    normalizedRing1.every((point, index) =>
      pointsAreEqual(point, normalizedRing2Reversed[index], 0.01)
    )
  );
}

export function isPoint2(test: any): test is Point2 {
  return (
    Array.isArray(test) && test.length === 2 && typeof test[0] === 'number'
  );
}

export function areSegmentsEqual(
  segment1: LineSegment2,
  segment2: LineSegment2,
  tolerance: number
): boolean {
  const [p1, p2] = segment1;
  const [q1, q2] = segment2;
  // Check if the segments are equal or reversed by comparing their endpoints with tolerance
  return (
    (pointsAreEqual(p1, q1, tolerance) && pointsAreEqual(p2, q2, tolerance)) ||
    (pointsAreEqual(p1, q2, tolerance) && pointsAreEqual(p2, q1, tolerance))
  );
}

function pointsAreEqual(p1: Point2, p2: Point2, tolerance: number): boolean {
  const [x1, y1] = p1;
  const [x2, y2] = p2;
  return Math.abs(x1 - x2) <= tolerance && Math.abs(y1 - y2) <= tolerance;
}

function isPointOnSegment(
  point: Point2,
  segment: LineSegment2,
  tolerance = 0.01
): boolean {
  // Calculate the relative position of the point on the segment
  const t = calculateRelativePosition(point, segment);
  const segmentLength = math.norm(
    math.subtract(segment[1], segment[0])
  ) as number;
  const relativeTolerance = tolerance / segmentLength;

  // Check if the relative position is within the segment's bounds, considering tolerance
  if (t >= -relativeTolerance && t <= 1 + relativeTolerance) {
    // Calculate the actual point on the segment using the relative position
    const segmentPoint: Point2 = [
      segment[0][0] + t * (segment[1][0] - segment[0][0]),
      segment[0][1] + t * (segment[1][1] - segment[0][1]),
    ];

    // Calculate the distance between the actual point on the segment and the given point
    const distanceToSegment = math.norm(
      math.subtract(point, segmentPoint)
    ) as number;

    // If the distance is within tolerance, the point is considered on the segment
    return distanceToSegment <= tolerance;
  }

  return false;
}

export function getDistanceToSegment(
  point: Point2,
  segment: LineSegment2
): number {
  // Calculate the relative position of the point on the segment
  const t = calculateRelativePosition(point, segment);

  // Ensure t is within the segment's bounds
  const clampedT = Math.max(0, Math.min(1, t));

  // Calculate the closest point on the segment using the clamped relative position
  const closestPointOnSegment: Point2 = [
    segment[0][0] + clampedT * (segment[1][0] - segment[0][0]),
    segment[0][1] + clampedT * (segment[1][1] - segment[0][1]),
  ];

  // Calculate and return the distance between the actual point and the closest point on the segment
  return math.norm(math.subtract(point, closestPointOnSegment)) as number;
}

export function isPointOnRing(
  point: Point2,
  ring: LinearRing2,
  tolerance = 0.01
): boolean {
  // Check if the point is on any of the ring's edges
  for (let i = 0; i < ring.length - 1; i++) {
    const segment = [ring[i], ring[i + 1]] as LineSegment2;
    if (isPointOnSegment(point, segment, tolerance)) {
      return true;
    }
  }

  return false;
}

export function calculateRelativePosition(
  point: Point2,
  segment: LineSegment2
): number {
  const [p0, p1] = segment; // Start and end points of the segment
  const [px, py] = point; // The point in question

  // Vector from p0 to p1 (segment vector)
  const dx = p1[0] - p0[0];
  const dy = p1[1] - p0[1];

  // Avoid division by zero by checking if the segment is a point
  if (dx === 0 && dy === 0) return Infinity; // The segment is a point

  // Vector from p0 to the point
  const dxp = px - p0[0];
  const dyp = py - p0[1];

  // Calculate the dot product of the segment vector and the vector from p0 to the point
  const dot = dxp * dx + dyp * dy;

  // Calculate the squared length of the segment vector
  const lenSq = dx * dx + dy * dy;

  // Calculate the relative position of the projection of the point on the segment
  const t = dot / lenSq;

  return t;
}

export function projectPointOnSegment(
  point: Point2,
  segment: LineSegment2
): Point2 {
  const t = calculateRelativePosition(point, segment);
  const clampedT = Math.max(0, Math.min(1, t));
  return [
    segment[0][0] + clampedT * (segment[1][0] - segment[0][0]),
    segment[0][1] + clampedT * (segment[1][1] - segment[0][1]),
  ];
}

export function isNearlyParallelAndCloseToPlane(
  lineSegment: LineSegment,
  plane: Plane,
  tolerance = 0.1
): boolean {
  const p1 = lineSegment[0];
  const p2 = lineSegment[1];

  const distance1 = math.abs(
    math.dot(p1, plane.unitNormal) + plane.planeCoefficient
  ) as number;
  const distance2 = math.abs(
    math.dot(p2, plane.unitNormal) + plane.planeCoefficient
  ) as number;

  // Check if both endpoints are within the tolerance distance from the plane
  if (distance1 <= tolerance && distance2 <= tolerance) {
    // Calculate the edge direction
    const edgeDirection = math.subtract(p2, p1);
    // Normalize the edge direction
    const normalizedEdgeDirection = math.divide(
      edgeDirection,
      math.norm(edgeDirection)
    ) as Point;

    // Calculate the dot product between the normalized edge direction and the plane normal
    const dotProduct = math.dot(normalizedEdgeDirection, plane.unitNormal);

    // A dot product close to 0 indicates the edge is nearly parallel to the plane
    return Math.abs(dotProduct) < tolerance;
  }

  return false;
}

export function getArcPoints(
  startPoint: Point2,
  endPoint: Point2,
  pointOnArc: Point2
): Point2[] {
  const NUM_SEGMENTS = 10;
  const circle = calculateCircle(startPoint, endPoint, pointOnArc);

  let startAngle = Math.atan2(
    startPoint[1] - circle.center[1],
    startPoint[0] - circle.center[0]
  );
  let endAngle = Math.atan2(
    endPoint[1] - circle.center[1],
    endPoint[0] - circle.center[0]
  );
  const arcAngle = Math.atan2(
    pointOnArc[1] - circle.center[1],
    pointOnArc[0] - circle.center[0]
  );

  // Normalize angles to [0, 2π)
  startAngle = (startAngle + 2 * Math.PI) % (2 * Math.PI);
  endAngle = (endAngle + 2 * Math.PI) % (2 * Math.PI);
  let normalizedArcAngle = (arcAngle + 2 * Math.PI) % (2 * Math.PI);

  // Ensure endAngle is always greater than startAngle by adjusting the range
  if (endAngle < startAngle) {
    endAngle += 2 * Math.PI;
  }
  // Check if arcAngle is between startAngle and endAngle in the adjusted range
  if (normalizedArcAngle < startAngle) {
    normalizedArcAngle += 2 * Math.PI;
  }

  // Determine if the pointOnArc indicates a shorter arc or requires a longer wrap-around
  let isShorterArc = normalizedArcAngle <= endAngle;

  // If the chosen arc is not the shorter one (i.e., it wraps around past 2π), adjust angles for computation
  if (!isShorterArc) {
    // This means the direct arc from startPoint to endPoint (through pointOnArc) exceeds half the circle, and we should take the longer route
    if (startAngle < normalizedArcAngle) {
      startAngle += 2 * Math.PI;
    } else {
      endAngle += 2 * Math.PI;
    }
  }

  const points: Point2[] = [];
  let angleRange = endAngle - startAngle;
  const angleStep = angleRange / (NUM_SEGMENTS - 1);

  for (let i = 0; i < NUM_SEGMENTS; i++) {
    const angle = (startAngle + angleStep * i) % (2 * Math.PI);
    points.push([
      circle.center[0] + circle.radius * Math.cos(angle),
      circle.center[1] + circle.radius * Math.sin(angle),
    ]);
  }

  return points;
}

function calculateCircle(
  endPoint1: Point2,
  endPoint2: Point2,
  pointOnCircle: Point2
): { center: Point2; radius: number } {
  // Calculate the midpoints of the segments [endPoint1, pointOnCircle] and [endPoint2, pointOnCircle]
  const midPoint1: Point2 = [
    (endPoint1[0] + pointOnCircle[0]) / 2,
    (endPoint1[1] + pointOnCircle[1]) / 2,
  ];
  const midPoint2: Point2 = [
    (endPoint2[0] + pointOnCircle[0]) / 2,
    (endPoint2[1] + pointOnCircle[1]) / 2,
  ];

  // Calculate the slopes of the lines connecting endPoint1 to pointOnCircle, and endPoint2 to pointOnCircle
  const slope1 =
    (pointOnCircle[1] - endPoint1[1]) / (pointOnCircle[0] - endPoint1[0]);
  const slope2 =
    (pointOnCircle[1] - endPoint2[1]) / (pointOnCircle[0] - endPoint2[0]);

  // Calculate the slopes of the perpendicular bisectors
  const perpSlope1 = -1 / slope1;
  const perpSlope2 = -1 / slope2;

  // The center of the circle is the intersection of the two perpendicular bisectors
  // Solve the equations y = mx + b to find their intersection point
  const b1 = midPoint1[1] - perpSlope1 * midPoint1[0];
  const b2 = midPoint2[1] - perpSlope2 * midPoint2[0];

  // Solve for x: x = (b2 - b1) / (perpSlope1 - perpSlope2)
  const centerX = (b2 - b1) / (perpSlope1 - perpSlope2);
  // Solve for y using one of the line equations
  const centerY = perpSlope1 * centerX + b1;

  const center: Point2 = [centerX, centerY];
  // Calculate the radius as the distance from the center to any of the points
  const radius = Math.sqrt(
    Math.pow(center[0] - endPoint1[0], 2) +
      Math.pow(center[1] - endPoint1[1], 2)
  );

  return { center, radius };
}
