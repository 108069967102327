import { sum } from 'lodash';
import {
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';
import { calculatePlaneEquation } from '../util/plane';
import { calculateArea } from '../util/polygon';

// [-1, 1] limit at which planes are considered to be pointing in the same direction
// Represents cosine direction diff, aligned vectors are 1, oppositely aligned are -1
const MAX_COS_ANGLE_LIMIT = 0.01;

// Finds the area of triangles with unit normals with Z component greater than cosAngleLimit
export function calculateTopSurfaceArea(
  triangles: Triangle[],
  cosAngleLimit?: number
): TriangulatedSurfaceResult {
  const maxCosAngleLimit = cosAngleLimit ?? MAX_COS_ANGLE_LIMIT;
  const topSurfaceTriangles = triangles.filter(
    (triangle) =>
      calculatePlaneEquation(triangle).unitNormal[2] > maxCosAngleLimit
  );

  const unscaledValue = sum(topSurfaceTriangles.map(calculateArea));
  return {
    type: ResultType.TRIANGULATED_SURFACE,
    triangles: topSurfaceTriangles,
    value: unscaledValue,
  };
}
