import * as React from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const PlaceholderText: React.FC<
  React.PropsWithChildren<Omit<TextProps, 'color'>>
> = (props) => {
  return (
    <Text
      as="span"
      color="gray.500"
      fontWeight="normal"
      _dark={{
        color: 'gray.400',
      }}
      {...props}
    />
  );
};

export default PlaceholderText;
