import { Box } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useViewer } from '../../../components/common/ForgeViewer';
import {
  Viewport,
  getPointInViewerCoordinateSystem,
  useSheetViewer,
} from '../../../components/common/SheetViewer';
import { useViewerMode } from '../../../components/common/viewer-mode';
import {
  OperationResult,
  Point2,
  ResultType,
} from '../../../domain/geometry/geometric-types';
import { useSheetShapes } from '../../../hooks/sheet-shapes';
import { toMultiPolygon2 } from '../../../domain/geometry/algorithms/util/type-mapping';
import { MeshResultVisualization } from './MeshResultVisualization';
import { Polygon2ResultVisualization } from './Polygon2ResultVisualization';
import { PolygonResultVisualization } from './PolygonResultVisualization';
import { Polyline2ResultVisualization } from './Polyline2ResultVisualization';
import { PolylineResultVisualization } from './PolylineResultVisualization';
import { MultiPolygon2AlgorithmType } from 'src/domain/geometry/algorithm-types';

export function GeometricResultVisualization({
  operationResults,
}: {
  operationResults: OperationResult[];
}): ReactElement {
  const { viewerMode } = useViewerMode();
  switch (viewerMode) {
    case 'models':
      return (
        <ForgeGeometricResultVisualization
          operationResults={operationResults}
        />
      );
    case 'sheets':
      return (
        <SheetsGeometricResultVisualization
          operationResults={operationResults}
        />
      );
  }
}

function ForgeGeometricResultVisualization({
  operationResults,
}: {
  operationResults: OperationResult[];
}) {
  const overlayName = useMemo(() => {
    return uniqueId('geometric-result-visualization');
  }, []);

  const { viewer } = useViewer();

  useEffect(() => {
    if (!viewer) {
      return;
    }
    viewer.overlays.addScene(overlayName);
    return () => {
      viewer.overlays.removeScene(overlayName);
    };
  }, [overlayName, viewer]);

  if (!viewer) {
    return null;
  }

  return createPortal(
    <Box
      position="absolute"
      overflow="clip"
      w="100%"
      h="100%"
      zIndex={1}
      pointerEvents={'none'}
    >
      {operationResults.map((result) => (
        <VisualizedForgeResult
          key={`${result[1].algorithmType}-${result[1].modelUrn}-${result[1].dbId}`}
          overlayName={overlayName}
          operationResult={result}
          viewer={viewer}
        />
      ))}
    </Box>,
    viewer.clientContainer
  );
}

function VisualizedForgeResult({
  operationResult: [result, context],
  viewer,
  overlayName,
}: {
  viewer: Autodesk.Viewing.GuiViewer3D;
  operationResult: OperationResult;
  overlayName: string;
}) {
  if (!result) {
    return null;
  }
  switch (result.type) {
    case ResultType.MULTIPOLYLINE:
      return <PolylineResultVisualization result={result} viewer={viewer} />;
    case ResultType.MULTIPOLYGON:
      return (
        <PolygonResultVisualization
          result={result}
          overlayName={overlayName}
          viewer={viewer}
        />
      );
    case ResultType.MESH:
    case ResultType.TRIANGULATED_SURFACE:
      return (
        <MeshResultVisualization
          result={result}
          overlayName={overlayName}
          viewer={viewer}
        />
      );
    default:
      return null;
  }
}

function SheetsGeometricResultVisualization({
  operationResults,
}: {
  operationResults: OperationResult[];
}) {
  const {
    viewerRef,
    page,
    addViewportChangeHandler,
    removeViewportChangeHandler,
    shapesManager: { renderedSheetShapes },
    activeSheetId,
    pageNumber,
  } = useSheetViewer();

  const viewerContainer = viewerRef.current;

  const [viewerViewport, setViewerViewport] = useState<Viewport | null>(null);

  useEffect(() => {
    const onViewportChange = (vp: Viewport) => {
      setViewerViewport(vp);
    };
    addViewportChangeHandler(onViewportChange);
    return () => {
      removeViewportChangeHandler(onViewportChange);
    };
  }, [addViewportChangeHandler, removeViewportChangeHandler]);

  const _getPointInDomCoordinateSystem = useCallback(
    (pointInDom: Point2) => {
      if (!viewerViewport || !page || !viewerRef.current) {
        throw new Error('Viewer is not ready');
      }
      const viewerPoint = getPointInViewerCoordinateSystem(
        pointInDom,
        viewerViewport.offset,
        viewerViewport.scale,
        page
      );
      return [viewerPoint[0], viewerPoint[1]] as Point2;
    },
    [viewerViewport, page, viewerRef]
  );

  if (!viewerContainer || !viewerViewport || !page) {
    return null;
  }

  return createPortal(
    <Box overflow="clip" w="100%" h="100%" zIndex={1} pointerEvents={'none'}>
      {operationResults
        .filter(
          // Hide results that belong to a different page
          ([, context]) => {
            const shape = renderedSheetShapes[context.modelUrn]?.find(
              (shape) => shape.dbId === context.dbId
            );
            if (!shape) {
              return false;
            }
            return (
              shape.sheetId === activeSheetId &&
              shape.sheetPageNumber === pageNumber
            );
          }
        )
        .map((result) => (
          <VisualizedSheetsResult
            key={`${result[1].algorithmType}-${result[1].modelUrn}-${result[1].dbId}`}
            operationResult={result}
            getPointInDomCoordinateSystem={_getPointInDomCoordinateSystem}
          />
        ))}
    </Box>,
    viewerContainer
  );
}

function VisualizedSheetsResult({
  operationResult: [result, context],
  getPointInDomCoordinateSystem,
}: {
  operationResult: OperationResult;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) {
  const {
    shapes: { data: shapesData },
  } = useSheetShapes();

  if (!result) {
    return null;
  }

  const thisShape = shapesData?.find(
    (shape) => shape.dbId === context.dbId && shape.urn === context.modelUrn
  );

  const enclosingPolygon =
    context.algorithmType === 'Sheet shape inner area parameterized' &&
    thisShape?.sheetShapePolygon?.multipolygon
      ? toMultiPolygon2(thisShape?.sheetShapePolygon?.multipolygon)
      : undefined;

  switch (result.type) {
    case ResultType.MULTIPOLYLINE2:
      return (
        <Polyline2ResultVisualization
          result={result}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        />
      );
    case ResultType.MULTIPOLYGON2:
      return (
        <Polygon2ResultVisualization
          result={result}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          enclosingPolygon={enclosingPolygon}
          algorithmType={context.algorithmType as MultiPolygon2AlgorithmType}
        />
      );
    default:
      return null;
  }
}
