import { maxBy, sum } from 'lodash';
import invariant from 'tiny-invariant';

import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
import {
  MultiPolygon,
  ResultType,
  Triangle,
  TriangulatedSurfaceResult,
} from '../../geometric-types';
import { findPlanes, toPlanarMultiPolygon } from '../util/plane';
import { calculateArea, toJstsMultiPolygon } from '../util/polygon';

export function calculateLargestFlatSurfaceArea(
  triangles: Triangle[]
): TriangulatedSurfaceResult {
  const planes = findPlanes(triangles);
  const largestPlane = maxBy(planes, (group) =>
    sum(group.triangles.map(calculateArea))
  );
  invariant(largestPlane, 'Should have found at least one plane');
  // Because the triangles found might overlap, we union them together
  const trianglesAsMultiPolygon: MultiPolygon = {
    polygons: largestPlane.triangles.map((triangle) => ({
      exterior: [...triangle, triangle[0]],
      interiors: [],
    })),
  };
  const multipolygon2 = toPlanarMultiPolygon(
    trianglesAsMultiPolygon,
    largestPlane.plane
  );

  const jstsMultiPolygon = toJstsMultiPolygon(multipolygon2);
  const union = BufferOp.bufferOp(jstsMultiPolygon, 0, 0);
  const value = union.getArea();

  return {
    type: ResultType.TRIANGULATED_SURFACE,
    value,
    triangles: largestPlane.triangles,
  };
}
