import { Box, Text, useColorMode } from '@chakra-ui/react';
import { useMemo } from 'react';
import { Point, Point2 } from '../../../domain/geometry/geometric-types';
import { ShapeDeepFragment } from '../../../gql/graphql';
import { projectInDOM } from './util';

type ShapeLabelProps = {
  shape: ShapeDeepFragment;
  position: Point;
  viewer: Autodesk.Viewing.Viewer3D;
  cameraPosition: { position?: Point2 };
};

const ShapeLabel = ({
  shape,
  position,
  viewer,
  cameraPosition,
}: ShapeLabelProps) => {
  const { colorMode } = useColorMode();

  const position2D = useMemo<Point2>(
    () => projectInDOM(position, viewer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cameraPosition, position, viewer]
  );

  return (
    <Box
      position={'absolute'}
      top={position2D[1] + 68} // Offset due to header
      left={position2D[0]}
      transform="translate(-50%, -50%)"
      pointerEvents={'none'}
      color={colorMode === 'light' ? 'blackAlpha.700' : 'whiteAlpha.900'}
      backgroundColor={
        colorMode === 'light' ? 'whiteAlpha.500' : 'blackAlpha.200'
      }
      paddingX={2}
      borderRadius={'xl'}
      textAlign={'center'}
    >
      <Text fontSize={'sm'} fontWeight={'semibold'}>
        {shape.name}
      </Text>
    </Box>
  );
};

export default ShapeLabel;
