import MultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import * as math from 'mathjs';
import { ExtrudedPolygonAlgorithm } from '../../algorithm-types';
import {
  MeshResult,
  Plane,
  Point,
  ResultType,
  Triangle,
} from '../../geometric-types';
import { to3dPoints, toPlanarMultiPolygon } from '../util/plane';
import {
  generateSideTrianglesFromPolygon,
  toJstsMultiPolygon,
  triangulatePolygon,
} from '../util/polygon';

export const calculateExtrudedShapeVolume: ExtrudedPolygonAlgorithm<
  MeshResult
> = (polygon, plane, thickness) => {
  const planarPolyon = toPlanarMultiPolygon(polygon, plane);
  const jtsMultiPolygon = toJstsMultiPolygon(planarPolyon);
  const area = jtsMultiPolygon.getArea();
  const volume = area * Math.abs(thickness);

  const extrusionVector: Point = plane.unitNormal.map(
    (component) => component * thickness
  ) as Point;

  const triangles: Triangle[] = [];

  generateMesh(jtsMultiPolygon, plane, extrusionVector, triangles);

  return {
    type: ResultType.MESH,
    value: volume,
    triangles,
  };
};

function generateMesh(
  jtsMultiPolygon: MultiPolygon,
  plane: Plane,
  extrusionVector: Point,
  triangles: Triangle[]
) {
  const numPolygons = jtsMultiPolygon.getNumGeometries();
  for (let i = 0; i < numPolygons; i++) {
    const jtsPolygon = jtsMultiPolygon.getGeometryN(i);

    // Triangulate the base polygon and get vertices
    const triangulationResult = triangulatePolygon(jtsPolygon);

    // Map planar coordinates back to 3D and create base and top triangles
    createBaseAndTopTriangles(
      triangulationResult,
      plane,
      extrusionVector,
      triangles
    );

    // Create side triangles
    generateSideTrianglesFromPolygon(
      jtsPolygon,
      plane,
      extrusionVector,
      triangles
    );
  }
}

function createBaseAndTopTriangles(
  triangulationResult: { trianglesIndices: number[]; vertices: number[] },
  plane: Plane,
  extrusionVector: Point,
  triangles: Triangle[]
) {
  const { trianglesIndices, vertices } = triangulationResult;
  const numTriangles = trianglesIndices.length / 3;

  for (let i = 0; i < numTriangles; i++) {
    const idx0 = trianglesIndices[i * 3];
    const idx1 = trianglesIndices[i * 3 + 1];
    const idx2 = trianglesIndices[i * 3 + 2];

    // Base triangle in 3D
    const p0 = to3dPointFromIndex(idx0, vertices, plane);
    const p1 = to3dPointFromIndex(idx1, vertices, plane);
    const p2 = to3dPointFromIndex(idx2, vertices, plane);
    triangles.push([p0, p1, p2]);

    // Top triangle in 3D (reverse order)
    const tp0 = math.add(p0, extrusionVector);
    const tp1 = math.add(p1, extrusionVector);
    const tp2 = math.add(p2, extrusionVector);
    triangles.push([tp2, tp1, tp0]);
  }
}

function to3dPointFromIndex(
  index: number,
  vertices: number[],
  plane: Plane
): Point {
  const x = vertices[index * 2];
  const y = vertices[index * 2 + 1];
  const point2D: [number, number] = [x, y];
  const point3D = to3dPoints([point2D], plane)[0];
  return point3D;
}
