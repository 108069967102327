import { intersection, isEmpty, union, without } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DbIdsPerModel, ModelsMap } from '../../components/common/ForgeViewer';

// Rules:
// Hiding: Hide the specified elements and its children
// Isolation: Show only the specified elements and its children.
// Hiding is applied after isolation, so if an element is both isolated and hidden, it will be hidden
export type ForgeVisibilityManager = {
  // Set to empty object for no isolation.
  isolatedElements: DbIdsPerModel;
  hiddenElements: DbIdsPerModel;
  // Filtering is the same as isolating, but is controlled by the toolbar filters separately,
  // so isolation can be reset separately without resetting the filters
  filteredElements: DbIdsPerModel;
  // Hides all elements in the specified models
  hiddenModelUrns: string[];
  // Hides the specified shapes - separate state so we can reset hiding state without resetting the hidden shapes
  hiddenShapes: DbIdsPerModel;
  // Shows all models
  showAllModels: () => void;
  // Hides all models
  hideAllModels: () => void;
  // Adds the specified model to the list of hidden models
  hideModel: (urn: string) => void;
  // Removes the specified model from the list of hidden models
  showModel: (urn: string) => void;
  // Sets the list of hidden models
  setHiddenModelUrns: (urns: string[]) => void;
  // Adds the specified shape to the list of hidden shapes
  hideShape: (urn: string, dbId: number) => void;
  // Removes the specified shape from the list of hidden shapes
  showShape: (urn: string, dbId: number) => void;
  // Sets the filtered elements
  setFilteredElements: (elements: DbIdsPerModel) => void;
  // Adds the specified elements to the list of hidden elements
  hide: (elements: DbIdsPerModel) => void;
  // Removes the specified elements from the list of hidden elements
  show: (elements: DbIdsPerModel) => void;
  // Sets the hidden elements
  setHiddenElements: (elements: DbIdsPerModel) => void;
  // Resets hidden models and shapes, and sets the isolated elements
  isolate: (elements: DbIdsPerModel) => void;
  // Sets the isolated elements
  setIsolatedElements: (elements: DbIdsPerModel) => void;
  // Adds the specified elements to the isolated elements
  addToIsolation: (elements: DbIdsPerModel) => void;
  // Resets all state
  reset: () => void;
};

/**
 * Hook for managing visibility of elements in the Forge viewer.
 * If you want visibility for all elements, including sheet elements, consider [visibility.ts](./visibility.ts)
 */
export const useForgeVisibilityManager = (
  aggregatedView: Autodesk.Viewing.AggregatedView | null,
  loadedModels: ModelsMap,
  loadedShapeModels: ModelsMap
): ForgeVisibilityManager => {
  const [hiddenModelUrns, setHiddenModelUrns] = useState<string[]>([]);
  const [hiddenShapes, setHiddenShapes] = useState<DbIdsPerModel>({});
  const [filteredElements, setFilteredElements] = useState<DbIdsPerModel>({});
  const [isolatedElements, setIsolatedElements] = useState<DbIdsPerModel>({});
  const [hiddenElements, setHiddenElements] = useState<DbIdsPerModel>({});

  const isolateElements = (
    urn: string,
    model: Autodesk.Viewing.Model,
    filteredElements: DbIdsPerModel,
    isolatedElements: DbIdsPerModel,
    aggregatedView: Autodesk.Viewing.AggregatedView
  ) => {
    // No isolation, show all
    if (isEmpty(filteredElements) && isEmpty(isolatedElements)) {
      aggregatedView.viewer.show(model.getRootId(), model);
      return;
    }
    let elementsToIsolate: number[] = [];
    if (!isEmpty(filteredElements) && !isEmpty(isolatedElements)) {
      elementsToIsolate = intersection(
        filteredElements[urn],
        isolatedElements[urn]
      );
    } else if (!isEmpty(filteredElements)) {
      elementsToIsolate = filteredElements[urn] ?? [];
    } else if (!isEmpty(isolatedElements)) {
      elementsToIsolate = isolatedElements[urn] ?? [];
    }

    if (elementsToIsolate.length === 0) {
      aggregatedView.viewer.hide(model.getRootId(), model);
    } else {
      aggregatedView.viewer.isolate(elementsToIsolate, model);
    }
  };

  // Effect for syncing visibility of elements with Forge viewer
  useEffect(() => {
    if (!aggregatedView) {
      return;
    }
    for (const [urn, model] of Object.entries(loadedModels)) {
      if (hiddenModelUrns.includes(urn)) {
        aggregatedView.viewer.hide(model.getRootId(), model);
        continue;
      }

      isolateElements(
        urn,
        model,
        filteredElements,
        isolatedElements,
        aggregatedView
      );
      const elementsToHide: number[] = hiddenElements[urn] ?? [];
      if (elementsToHide.length > 0) {
        aggregatedView.viewer.hide(elementsToHide, model);
      }
    }
  }, [
    aggregatedView,
    filteredElements,
    hiddenElements,
    hiddenModelUrns,
    isolatedElements,
    loadedModels,
  ]);

  // Effect for syncing visibility of shapes with Forge viewer
  useEffect(() => {
    if (!aggregatedView) {
      return;
    }
    for (const [urn, model] of Object.entries(loadedShapeModels)) {
      isolateElements(
        urn,
        model,
        filteredElements,
        isolatedElements,
        aggregatedView
      );
      const elementsToHide: number[] = union(
        hiddenElements[urn],
        hiddenShapes[urn]
      );
      if (elementsToHide.length > 0) {
        aggregatedView.viewer.hide(elementsToHide, model);
      }
    }
  }, [
    aggregatedView,
    filteredElements,
    hiddenElements,
    hiddenShapes,
    isolatedElements,
    loadedShapeModels,
  ]);

  const showAllModels = useCallback(() => {
    setHiddenModelUrns([]);
  }, []);

  const hideAllModels = useCallback(() => {
    setHiddenModelUrns(Object.keys(loadedModels));
  }, [loadedModels]);

  const showModel = useCallback((modelUrn: string) => {
    setHiddenModelUrns((modelUrns) => without(modelUrns, modelUrn));
  }, []);

  const hideModel = useCallback((modelUrn: string) => {
    setHiddenModelUrns((modelUrns) => union(modelUrns, [modelUrn]));
  }, []);

  const showShape = useCallback((shapeUrn: string, dbId: number) => {
    setHiddenShapes((hiddenShapes) => {
      if (shapeUrn in hiddenShapes) {
        return {
          ...hiddenShapes,
          [shapeUrn]: without(hiddenShapes[shapeUrn], dbId),
        };
      }
      return hiddenShapes;
    });
  }, []);

  const hideShape = useCallback((shapeUrn: string, dbId: number) => {
    setHiddenShapes((hiddenShapes) => {
      if (shapeUrn in hiddenShapes) {
        return {
          ...hiddenShapes,
          [shapeUrn]: union(hiddenShapes[shapeUrn], [dbId]),
        };
      } else {
        return { ...hiddenShapes, [shapeUrn]: [dbId] };
      }
    });
  }, []);

  const hide = useCallback((elements: DbIdsPerModel) => {
    setHiddenElements((hiddenElements) => {
      const result = { ...hiddenElements };
      for (const [urn, dbIds] of Object.entries(elements)) {
        if (urn in hiddenElements) {
          result[urn] = union(hiddenElements[urn], dbIds);
        } else {
          result[urn] = dbIds;
        }
      }
      return result;
    });
  }, []);

  const show = useCallback(
    (elements: DbIdsPerModel) => {
      setHiddenElements((hiddenElements) => {
        const result = { ...hiddenElements };
        for (const [urn, dbIds] of Object.entries(elements)) {
          if (urn in hiddenElements) {
            result[urn] = without(hiddenElements[urn], ...dbIds);
          }
        }
        return result;
      });
    },
    [setHiddenElements]
  );

  const isolate = useCallback(
    (elements: DbIdsPerModel) => {
      setIsolatedElements(elements);
      if (!isEmpty(elements)) {
        setHiddenElements({});
        for (const [urn, dbIds] of Object.entries(elements)) {
          if (dbIds.length > 0) {
            if (urn in loadedModels) {
              showModel(urn);
            } else if (urn in loadedShapeModels) {
              dbIds.forEach((dbId) => showShape(urn, dbId));
            }
          }
        }
      }
    },
    [loadedModels, loadedShapeModels, showModel, showShape]
  );

  const addToIsolation = useCallback(
    (elements: DbIdsPerModel) => {
      setIsolatedElements((isolatedElements) => {
        const result = { ...isolatedElements };
        for (const [urn, dbIds] of Object.entries(elements)) {
          if (urn in isolatedElements) {
            result[urn] = union(isolatedElements[urn], dbIds);
          } else {
            result[urn] = dbIds;
          }
        }
        return result;
      });
    },
    [setIsolatedElements]
  );

  const reset = useCallback(() => {
    setFilteredElements({});
    setIsolatedElements({});
    setHiddenModelUrns([]);
    setHiddenShapes({});
    setHiddenElements({});
  }, []);

  return useMemo(
    () => ({
      hiddenModelUrns,
      hiddenShapes,
      filteredElements,
      hiddenElements,
      isolatedElements,
      showAllModels,
      hideAllModels,
      hideModel,
      showModel,
      hideShape,
      showShape,
      setFilteredElements,
      hide,
      show,
      setHiddenElements,
      setHiddenModelUrns,
      isolate,
      addToIsolation,
      setIsolatedElements,
      reset,
    }),
    [
      filteredElements,
      hiddenElements,
      hiddenModelUrns,
      hiddenShapes,
      isolatedElements,
      addToIsolation,
      showAllModels,
      hideAllModels,
      hide,
      show,
      hideModel,
      hideShape,
      isolate,
      reset,
      showModel,
      showShape,
    ]
  );
};
