import { Flex, IconButton, ListItem, Text, Tooltip } from '@chakra-ui/react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { LabelType } from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';

type ProjectItemProps = {
  allowedLabel: LabelType;
  onDeleteLabel: () => Promise<void>;
};

export default function AllowedLabelItem({
  allowedLabel,
  onDeleteLabel,
}: ProjectItemProps): React.ReactElement {
  return (
    <ListItem paddingTop={1} role="group">
      <Flex justifyContent={'space-between'}>
        <Text>{allowedLabel.name}</Text>
        <Tooltip label="Delete label">
          <IconButton
            opacity={0}
            _groupHover={{
              opacity: 1,
              transition: 'opacity 0.5s ease-in',
            }}
            aria-label="delete label"
            colorScheme="gray"
            size={'xs'}
            onClick={onDeleteLabel}
            icon={<SparkelIcon icon={faTrash} color={'red.400'} />}
          ></IconButton>
        </Tooltip>
      </Flex>
    </ListItem>
  );
}
