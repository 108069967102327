import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateCalibrationMutation,
  CreateCalibrationMutationVariables,
  CreateSheetMutation,
  CreateSheetMutationVariables,
  DeleteSheetVersionMutation,
  DeleteSheetVersionMutationVariables,
} from '../../gql/graphql';

export const createSheetResolver: UpdateResolver = (
  result: CreateSheetMutation,
  args: CreateSheetMutationVariables,
  cache
) => {
  if (result.createSheet?.sheet) {
    const key = cache.keyOfEntity({
      __typename: 'Project',
      id: result.createSheet.sheet.projectId,
    });
    cache
      .inspectFields(key)
      .filter((field) => field.fieldName === 'activeSheets')
      .forEach((field) => {
        cache.invalidate(key, field.fieldKey);
      });
  }
};

export const deleteSheetVersionResolver: UpdateResolver<
  DeleteSheetVersionMutation,
  DeleteSheetVersionMutationVariables
> = (result, args, cache) => {
  if (result.deleteSheetVersion?.sheet?.id) {
    // Invalidating for simplicity now, but feel free to update the cache instead
    cache.invalidate({
      __typename: 'Sheet',
      id: result.deleteSheetVersion.sheet.id,
    });
  }
};

export const createSheetPageCalibrationResolver: UpdateResolver = (
  result: CreateCalibrationMutation,
  args: CreateCalibrationMutationVariables,
  cache
) => {
  if (
    result.createSheetPageCalibration?.sheetPageCalibration &&
    args.input.sheetPageCalibration.sheetId
  ) {
    const calibrationsForSheet = cache.resolve(
      {
        __typename: 'Sheet',
        id: args.input.sheetPageCalibration.sheetId,
      },
      'sheetPageCalibrations'
    ) as string[] | null;

    const calibrationToAdd = cache.keyOfEntity({
      __typename: 'SheetPageCalibration',
      id: `${args.input.sheetPageCalibration.sheetId}-${args.input.sheetPageCalibration.pageNumber}`,
    });
    if (calibrationsForSheet && calibrationToAdd) {
      calibrationsForSheet.push(calibrationToAdd);
      cache.link(
        {
          __typename: 'Sheet',
          id: args.input.sheetPageCalibration.sheetId,
        },
        'sheetPageCalibrations',
        calibrationsForSheet
      );
    }
  }
};
