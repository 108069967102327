import { maxBy } from 'lodash';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import {
  LineSegment,
  MultiPolylineResult,
  ResultType,
  Triangle,
} from '../../geometric-types';
import { degToRad } from '../util';
import {
  find2DBoundingBoxes,
  findLargest2DBoundingBox,
} from '../util/bounding-box';
import { calculateSegmentLength } from '../util/line-segment';

// Max z-component of line direction for it to be considered horisontal
const MAX_Z_COMPONENT_HORISONTAL_LINE = math.sin(degToRad(3));

export function calculateBoundingBoxLength(
  triangles: Triangle[],
  onlyHorisontalLines?: boolean
): MultiPolylineResult | undefined {
  const maxLineZValue = onlyHorisontalLines
    ? MAX_Z_COMPONENT_HORISONTAL_LINE
    : Infinity;

  const boundingBoxes = find2DBoundingBoxes(triangles);

  const largestBoundingBox = findLargest2DBoundingBox(
    boundingBoxes,
    maxLineZValue
  );

  if (!largestBoundingBox) {
    return undefined;
  }

  let segmentCandidates = [
    [largestBoundingBox[0], largestBoundingBox[1]],
    [largestBoundingBox[0], largestBoundingBox[3]],
  ].filter(
    (segment) => math.abs(segment[0][2] - segment[1][2]) < maxLineZValue
  ) as LineSegment[];
  let longestSegment = maxBy(segmentCandidates, (segment) =>
    calculateSegmentLength(segment)
  );
  invariant(longestSegment, 'Should have found a segment');

  return {
    type: ResultType.MULTIPOLYLINE,
    multiPolyline: { lines: [longestSegment] },
    value: calculateSegmentLength(longestSegment),
  };
}
