import { useMemo } from 'react';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import { useSheetViewer } from '../../common/SheetViewer';
import { calculateSegmentLength } from '../../../domain/geometry/algorithms/util/line-segment';
import ProtanDiamond from './ProtanDiamond';
import { Diamond } from './SheetWedgeShapeDrawing';
import { kileSelector, onlyOneSide } from './kile-selector';
import {
  GetSparkelPropertiesForProjectQuery,
  SheetScaleType,
  SheetShapeDeepFragment,
} from 'src/gql/graphql';

type DiamondLabelProps = {
  segment: LineSegment2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  diamond: Diamond;
};

enum DiamondPlacement {
  ONESIDE = 'ONESIDE',
  CENTER = 'CENTER',
}

export function createDiamondAroundSegment(
  segment: LineSegment2,
  diamondPlacement: DiamondPlacement
): Point2[][] {
  const [start, end] = segment;

  // Calculate the midpoint of the line segment
  const midPoint: Point2 = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

  // Calculate the distance between start and end points (length of the segment)
  const length = Math.sqrt((end[0] - start[0]) ** 2 + (end[1] - start[1]) ** 2);

  // Calculate the angle of the segment
  const angle = Math.atan2(end[1] - start[1], end[0] - start[0]);

  const widthLengthFactorSingle = 3600 / 14400;
  const widthLengthFactorDouble = 3600 / (14400 * 2);

  if (diamondPlacement === DiamondPlacement.ONESIDE) {
    const trianglePoints: Point2[] = [
      [end[0], end[1]],
      [start[0], start[1]],
      [
        end[0] +
          length * widthLengthFactorSingle * Math.cos(angle + Math.PI / 2),
        end[1] +
          length * widthLengthFactorSingle * Math.sin(angle + Math.PI / 2),
      ],
    ];
    const trianglePoints2: Point2[] = [
      [start[0], start[1]],
      [end[0], end[1]],
      [
        start[0] +
          length * widthLengthFactorSingle * Math.cos(angle + Math.PI / 2),
        start[1] +
          length * widthLengthFactorSingle * Math.sin(angle + Math.PI / 2),
      ],
    ];

    const trianglePoints3: Point2[] = [
      [end[0], end[1]],
      [start[0], start[1]],
      [
        end[0] +
          length *
            widthLengthFactorSingle *
            Math.cos(angle + (Math.PI * 2 * 3) / 4),
        end[1] +
          length *
            widthLengthFactorSingle *
            Math.sin(angle + (Math.PI * 2 * 3) / 4),
      ],
    ];
    const trianglePoints4: Point2[] = [
      [start[0], start[1]],
      [end[0], end[1]],
      [
        start[0] +
          length *
            widthLengthFactorSingle *
            Math.cos(angle + (Math.PI * 2 * 3) / 4),
        start[1] +
          length *
            widthLengthFactorSingle *
            Math.sin(angle + (Math.PI * 2 * 3) / 4),
      ],
    ];

    return [trianglePoints, trianglePoints2, trianglePoints3, trianglePoints4];
  }
  // Calculate the coordinates of the diamond points
  const trianglePoints: Point2[] = [
    [midPoint[0], midPoint[1]],
    [start[0], start[1]],
    [
      midPoint[0] +
        length * widthLengthFactorDouble * Math.cos(angle + Math.PI / 2),
      midPoint[1] +
        length * widthLengthFactorDouble * Math.sin(angle + Math.PI / 2),
    ],
  ];
  const trianglePoints2: Point2[] = [
    [midPoint[0], midPoint[1]],
    [end[0], end[1]],
    [
      midPoint[0] +
        length * widthLengthFactorDouble * Math.cos(angle + Math.PI / 2),
      midPoint[1] +
        length * widthLengthFactorDouble * Math.sin(angle + Math.PI / 2),
    ],
  ];

  const trianglePoints3: Point2[] = [
    [midPoint[0], midPoint[1]],
    [start[0], start[1]],
    [
      midPoint[0] +
        length *
          widthLengthFactorDouble *
          Math.cos(angle + (Math.PI * 2 * 3) / 4),
      midPoint[1] +
        length *
          widthLengthFactorDouble *
          Math.sin(angle + (Math.PI * 2 * 3) / 4),
    ],
  ];
  const trianglePoints4: Point2[] = [
    [midPoint[0], midPoint[1]],
    [end[0], end[1]],
    [
      midPoint[0] +
        length *
          widthLengthFactorDouble *
          Math.cos(angle + (Math.PI * 2 * 3) / 4),
      midPoint[1] +
        length *
          widthLengthFactorDouble *
          Math.sin(angle + (Math.PI * 2 * 3) / 4),
    ],
  ];

  return [trianglePoints, trianglePoints2, trianglePoints3, trianglePoints4];
}
export function DiamondLabel({
  segment,
  getPointInDomCoordinateSystem,
  diamond,
}: DiamondLabelProps) {
  const {
    calibration: { calibration },
  } = useSheetViewer();
  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  if (!unitScale) {
    return null;
  }

  const onlyOneSideVar = onlyOneSide(diamond);
  const trianglePoints = createDiamondAroundSegment(
    segment,
    onlyOneSideVar ? DiamondPlacement.ONESIDE : DiamondPlacement.CENTER
  );

  if (!diamond) return <></>;
  return (
    <ProtanDiamond
      diamond={diamond}
      trianglePoints={trianglePoints}
      getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
    />
  );
}

export const getDiamond = (
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  dbId: number,
  urn: string
) => {
  return {
    triangle1:
      propertyData?.project?.sparkelProperties.find(
        (prop) =>
          prop.propertySet === 'Triangle1' &&
          prop.dbId === dbId &&
          prop.modelUrn === urn
      )?.propertyValue === '1'
        ? true
        : false,
    triangle2:
      propertyData?.project?.sparkelProperties.find(
        (prop) =>
          prop.propertySet === 'Triangle2' &&
          prop.dbId === dbId &&
          prop.modelUrn === urn
      )?.propertyValue === '1'
        ? true
        : false,
    triangle3:
      propertyData?.project?.sparkelProperties.find(
        (prop) =>
          prop.propertySet === 'Triangle3' &&
          prop.dbId === dbId &&
          prop.modelUrn === urn
      )?.propertyValue === '1'
        ? true
        : false,
    triangle4:
      propertyData?.project?.sparkelProperties.find(
        (prop) =>
          prop.propertySet === 'Triangle4' &&
          prop.dbId === dbId &&
          prop.modelUrn === urn
      )?.propertyValue === '1'
        ? true
        : false,
  } as Diamond;
};

export const getWedgeFromLineShape = (
  shape: SheetShapeDeepFragment,
  points: Point2[],
  propertyData: GetSparkelPropertiesForProjectQuery,
  calibration: SheetScaleType
) => {
  const diamond = getDiamond(propertyData, shape.dbId, shape.urn);

  const unitScale = getUnitScaleFromCalibration(calibration);

  const scaledPoints = points.map(
    (p) => [p[0] * unitScale, p[1] * unitScale] as Point2
  );

  const firstSegment = [scaledPoints[0], scaledPoints[1]] as LineSegment2;
  const firstSegmentLength = calculateSegmentLength(firstSegment);

  const wedge = kileSelector(diamond, firstSegmentLength);

  return wedge;
};
