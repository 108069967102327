import {
  Box,
  BoxProps,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type PanelProps = {
  resizeable?: boolean;
  panelContentRef?: React.RefObject<HTMLDivElement>;
  setPanelHeight?: (height: number | undefined) => void;
};

export function Panel({
  resizeable,
  panelContentRef,
  setPanelHeight,
  ...props
}: PanelProps & BoxProps): React.ReactElement {
  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const startY = event.clientY;
    const startHeight = panelContentRef?.current?.clientHeight || 0;

    const minHeight = 0;
    const maxHeight = 800;

    let newHeight = startHeight;

    const doResize = (mouseMoveEvent: MouseEvent) => {
      newHeight = Math.min(
        Math.max(startHeight + mouseMoveEvent.clientY - startY, minHeight),
        maxHeight
      );
      if (panelContentRef?.current) {
        panelContentRef.current.style.height = `${newHeight}px`;
      }
    };

    const stopResize = () => {
      if (setPanelHeight) setPanelHeight(newHeight);
      window.removeEventListener('mousemove', doResize);
      window.removeEventListener('mouseup', stopResize);
    };

    window.addEventListener('mousemove', doResize);
    window.addEventListener('mouseup', stopResize);
  };

  const backgroundColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  return (
    <Box
      backgroundColor={backgroundColor}
      paddingTop={2}
      paddingX={2}
      borderRadius="2xl"
      borderColor={borderColor}
      borderWidth="1px"
      position={'relative'}
      {...props}
    >
      {resizeable && (
        <Box
          position="absolute"
          bottom={-2}
          height={2}
          width={'96%'}
          cursor={'row-resize'}
          zIndex={2}
          userSelect={'none'}
          onMouseDown={handleMouseDown}
          onDoubleClick={() => {
            panelContentRef?.current &&
              (panelContentRef.current.style.height = 'fit-content');
            if (setPanelHeight) setPanelHeight(undefined);
          }}
          borderRadius={'full'}
          _active={{
            backgroundColor: borderColor,
          }}
        />
      )}
      {props.children}
    </Box>
  );
}

type PanelHeaderProps = BoxProps & { hasNoMarginBottom?: boolean };

export function PanelHeader({
  hasNoMarginBottom: hasMarginBottom,
  ...rest
}: PanelHeaderProps): React.ReactElement {
  return (
    <>
      <Flex
        paddingLeft={2}
        justifyContent="space-between"
        alignItems="flex-end"
        minHeight="24px"
        {...rest}
      />
      <Divider orientation="horizontal" mt={2} mb={hasMarginBottom ? 0 : 2} />
    </>
  );
}

type PanelTitleProps = {
  children: React.ReactNode;
};

export function PanelTitle({ children }: PanelTitleProps): React.ReactElement {
  return (
    <Heading size="sm" as="h3">
      {children}
    </Heading>
  );
}

type PanelActionButtonsProps = {
  children: React.ReactNode;
};

export function PanelActionButtons({
  children,
}: PanelActionButtonsProps): React.ReactElement {
  return <ButtonGroup>{children}</ButtonGroup>;
}

type PanelContentProps = {
  children: React.ReactNode;
};

const PanelContent = forwardRef<HTMLDivElement, PanelContentProps & BoxProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        fontSize="xs"
        paddingLeft={2}
        paddingBottom={2}
        overflow="auto"
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

PanelContent.displayName = 'PanelContent';

export { PanelContent };
