import { GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  AbsoluteCenter,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  ChakraProps,
  GridItem,
  GridItemProps,
  Spinner,
  Text,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDrag, useWheel } from '@use-gesture/react';
import { debounce } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Document, Page } from 'react-pdf';
import { PageCallback } from 'react-pdf/dist/cjs/shared/types';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import awsExports from '../../aws-exports';
import { Point2 } from '../../domain/geometry/geometric-types';
import { findCurrentCalibration } from '../../domain/sheet-calibration';
import {
  GetSparkelPropertiesForProjectDocument,
  GetSparkelPropertiesForProjectQuery,
  SheetPageCalibrationFragment,
} from '../../gql/graphql';
import { useLocalStorage } from '../../hooks/local-storage';
import { useS3Client } from '../../hooks/s3';
import { Sheet, useSheets } from '../../hooks/sheets';
import {
  SheetShapesManager,
  useSheetShapesManager,
} from '../../services/viewer/SheetShapesManager';
import { useSheetViewerContextMenu } from '../../services/viewer/SheetsContextMenu';
import {
  SheetsVisibilityManager,
  useSheetsVisibility,
} from '../../services/viewer/SheetsVisibilityManager';
import { SheetCalibrationTool } from '../SheetCalibrationTool';
import { SheetMeasurementTool } from '../SheetMeasurementTool';
import { SheetShapes, shapeUrnDataAttribute } from '../SheetShapes';
import { DbIdsPerModel } from './ForgeViewer';
import { useViewerMode } from './viewer-mode';

type SheetViewerProviderProps = {
  shouldHideViewer: boolean;
  projectId: string;
  children: React.ReactNode;
};

export type Viewport = {
  scale: number;
  offset: Point2;
};

export const SheetViewerContext = createContext<SheetViewerContextType | null>(
  null
);

export const useSheetViewer = () => {
  const context = useContext(SheetViewerContext);
  if (!context) {
    throw new Error('useSheetViewer must be used within a SheetViewerProvider');
  }
  return context;
};

export enum GhostMode {
  NONE = 0,
  TRANSPARENT = 1,
  HIDDEN = 2,
}

export type SheetViewerContextType = {
  viewerRef: React.RefObject<HTMLDivElement>;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  numberOfPages: number | null;
  activeSheetId: string | null;
  changeActiveSheet: (sheetId: string) => void;
  page: PageCallback | null;
  // Use event handlers to pass viewport changes, instead of keeping it in the context,
  // which would cause a a lot of unnecessary re-renders
  addViewportChangeHandler: (handler: (vp: Viewport) => void) => void;
  removeViewportChangeHandler: (handler: (vp: Viewport) => void) => void;
  calibration: SheetCalibration;
  colorization: SheetColorization;
  shapesManager: SheetShapesManager;
  selectedDbIds: DbIdsPerModel;
  toggleSelectDbId: (dbId: number, modelUrn: string) => void;
  setSelectedDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  selectDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  visibilityManager: SheetsVisibilityManager;
  ghosting: {
    ghostMode: GhostMode;
    setGhostMode: (mode: GhostMode) => void;
  };
  showLabels: {
    isShowingLabels: boolean;
    setIsShowingLabels: (active: boolean) => void;
  };
  isDrawing: boolean;
  setIsDrawing: (active: boolean) => void;
  isMeasuring: boolean;
  setIsMeasuring: (isMeasuring: boolean) => void;
  propertyData: GetSparkelPropertiesForProjectQuery | undefined;
};

/**
 * Convert a point in PDF coordinate system to offset coordinates
 * relative to the top left corner of the viewer
 **/
export const getPointInViewerCoordinateSystem = (
  pdfCoordinate: Point2,
  offset: Point2,
  scale: number,
  page: PageCallback
): Point2 => {
  const pageViewport = page.getViewport({
    scale,
    offsetX: offset[0],
    offsetY: offset[1],
  });

  const vp = pageViewport.convertToViewportPoint(
    pdfCoordinate[0],
    pdfCoordinate[1]
  ) as Point2;
  return vp;
};

/**
 * Convert a point in page coordinates (relative to the top left of the page)
 * to a point in PDF coordinate system
 * Note: this is not the inverse of getPointInViewerCoordinateSystem
 **/
export const getPointInPdfCoordinateSystem = (
  pageCoordinate: Point2,
  offset: Point2,
  scale: number,
  page: PageCallback,
  viewerElement: HTMLDivElement
): Point2 => {
  const pageViewport = page.getViewport({
    scale,
    offsetX: offset[0],
    offsetY: offset[1],
  });

  const viewerTopLeft = viewerElement.getBoundingClientRect();

  return pageViewport.convertToPdfPoint(
    pageCoordinate[0] - viewerTopLeft.left,
    pageCoordinate[1] - viewerTopLeft.top
  ) as Point2;
};

export default function SheetViewerProvider({
  shouldHideViewer,
  projectId,
  children,
}: SheetViewerProviderProps): React.ReactElement | null {
  const viewerRef = useRef<HTMLDivElement>(null);
  const [activeSheetId, setActiveSheetId] = useLocalStorage<string | null>(
    `${projectId}-active-sheet-id`,
    null
  );
  const [numberOfPages, setNumberOfPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [page, setPage] = useState<PageCallback | null>(null);
  const { sheets } = useSheets(projectId);
  const calibration = useSheetCalibration(sheets, activeSheetId, pageNumber);
  const colorization = useSheetColorization();
  const shapesManager = useSheetShapesManager();
  const [selectedDbIds, setSelectedDbIds] = useState<DbIdsPerModel>({});
  const [viewportChangeHandlers, setViewportChangeHandlers] = useState<
    ((vp: Viewport) => void)[]
  >([]);
  const [isMeasuring, setIsMeasuring] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [ghosting, setGhosting] = useLocalStorage<number>(
    'sheet-ghost-mode',
    0
  );
  const [isShowingLabels, setIsShowingLabels] = useLocalStorage(
    'show-sheet-shape-labels',
    true
  );
  const addViewportChangeHandler = useCallback(
    (handler: (vp: Viewport) => void) => {
      setViewportChangeHandlers((viewportChangeHandlers) => [
        ...viewportChangeHandlers,
        handler,
      ]);
    },
    [setViewportChangeHandlers]
  );
  const removeViewportChangeHandler = useCallback(
    (handler: (vp: Viewport) => void) => {
      setViewportChangeHandlers((viewportChangeHandlers) =>
        viewportChangeHandlers.filter((h) => h !== handler)
      );
    },
    [setViewportChangeHandlers]
  );

  const toggleSelectDbId = useCallback(
    (dbId: number, modelUrn: string) => {
      setSelectedDbIds((selectedDbIds) => {
        const selectedDbIdsForModel = selectedDbIds[modelUrn] || [];
        const newSelectedDbIdsForModel = selectedDbIdsForModel.includes(dbId)
          ? selectedDbIdsForModel.filter((id) => id !== dbId)
          : [...selectedDbIdsForModel, dbId];
        return {
          ...selectedDbIds,
          [modelUrn]: newSelectedDbIdsForModel,
        };
      });
    },
    [setSelectedDbIds]
  );

  const selectDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      setSelectedDbIds((selectedDbIds) => {
        return {
          ...selectedDbIds,
          ...dbIdsPerModel,
        };
      });
    },
    [setSelectedDbIds]
  );

  const selectedDbIdsWithoutEmptyEntries = useMemo(() => {
    return Object.fromEntries(
      Object.entries(selectedDbIds).filter(([_, dbIds]) => dbIds.length > 0)
    );
  }, [selectedDbIds]);

  const visibility = useSheetsVisibility();

  const [sheetS3Url, setSheetS3Url] = useState<string | null>(null);
  const s3Client = useS3Client(projectId);

  useEffect(() => {
    const resolve = async () => {
      const activeSheet = sheets?.find((sheet) => sheet.id === activeSheetId);
      if (!activeSheetId || !sheets || !activeSheet) {
        setSheetS3Url(null);
        return;
      }

      invariant(s3Client, 'S3 client must be initialized');
      invariant(
        awsExports.attachments_bucket_name,
        'Attachments bucket name must be defined'
      );

      const getObjectCommand = new GetObjectCommand({
        Bucket: awsExports.attachments_bucket_name,
        Key: activeSheet.s3Key,
      });

      try {
        const signedUrl = await getSignedUrl(s3Client, getObjectCommand, {
          expiresIn: 3600,
        });
        setSheetS3Url(signedUrl);
      } catch (err) {
        // TODO: Use a toast or alert to display the error
        console.error(err);
      }
    };

    resolve();
  }, [activeSheetId, s3Client, sheets]);

  const changeActiveSheet = useCallback(
    (sheetId: string) => {
      setActiveSheetId(sheetId);
      setPageNumber(1);
      setPage(null);
    },
    [setActiveSheetId]
  );

  useHotkeys(
    'esc',
    () => {
      setSelectedDbIds({});
      setIsMeasuring(false);
      setIsDrawing(false);
    },
    [setSelectedDbIds]
  );

  const [
    {
      data: sparkelPropertiesData,
      fetching: fetchingSparkelProperties,
      error: sparkelPropertiesError,
      stale: sparkelPropertiesStale,
    },
  ] = useQuery({
    query: GetSparkelPropertiesForProjectDocument,
    variables: {
      projectId,
    },
  });

  const contextValue = useMemo<SheetViewerContextType>(() => {
    const setPageNumberWithBoundaryCheck = (pageNumber: number) => {
      if (pageNumber < 1) {
        return;
      }
      if (numberOfPages !== null && pageNumber > numberOfPages) {
        return;
      }
      setPageNumber(pageNumber);
    };

    return {
      viewerRef,
      activeSheetId,
      changeActiveSheet,
      pageNumber,
      setPageNumber: setPageNumberWithBoundaryCheck,
      numberOfPages,
      addViewportChangeHandler,
      removeViewportChangeHandler,
      calibration,
      colorization,
      page,
      shapesManager,
      selectedDbIds: selectedDbIdsWithoutEmptyEntries,
      toggleSelectDbId,
      setSelectedDbIds,
      selectDbIds,
      visibilityManager: visibility,
      ghosting: {
        ghostMode: ghosting,
        setGhostMode: setGhosting,
      },
      showLabels: {
        isShowingLabels,
        setIsShowingLabels,
      },
      isMeasuring,
      setIsMeasuring,
      isDrawing,
      setIsDrawing,
      propertyData: sparkelPropertiesData,
    };
  }, [
    activeSheetId,
    changeActiveSheet,
    pageNumber,
    numberOfPages,
    addViewportChangeHandler,
    removeViewportChangeHandler,
    calibration,
    colorization,
    page,
    shapesManager,
    selectedDbIdsWithoutEmptyEntries,
    toggleSelectDbId,
    selectDbIds,
    visibility,
    ghosting,
    setGhosting,
    isShowingLabels,
    setIsShowingLabels,
    isMeasuring,
    isDrawing,
    sparkelPropertiesData,
  ]);
  return (
    <SheetViewerContext.Provider value={contextValue}>
      {children}
      <SheetViewer
        setNumberOfPages={setNumberOfPages}
        setPage={setPage}
        shouldHideViewer={shouldHideViewer}
        viewportChangeHandlers={viewportChangeHandlers}
        sheetS3Url={sheetS3Url}
      />
    </SheetViewerContext.Provider>
  );
}

// eslint-disable-next-line complexity
function SheetViewer({
  setNumberOfPages,
  setPage,
  shouldHideViewer,
  viewportChangeHandlers,
  sheetS3Url,
}: {
  setNumberOfPages: (numPages: number) => void;
  setPage: (page: PageCallback | null) => void;
  shouldHideViewer: boolean;
  viewportChangeHandlers: ((vp: Viewport) => void)[];
  sheetS3Url: string | null;
}) {
  const {
    setSelectedDbIds,
    pageNumber,
    viewerRef,
    page,
    calibration,
    ghosting: { ghostMode },
    showLabels: { isShowingLabels },
    visibilityManager,
    selectedDbIds,
    shapesManager: { renderedSheetShapes },
    isMeasuring,
  } = useSheetViewer();
  const { viewerMode } = useViewerMode();

  const [scale, setScale] = useState(1);
  const [[x, y], setOffset] = useState<Point2>([0, 0]);

  const bindDrag = useDrag(
    ({ buttons, shiftKey, delta: [dx, dy] }) => {
      if (buttons === 2 || shiftKey) {
        setOffset((offset) => [offset[0] + dx, offset[1] + dy]);
      }
    },
    {
      pointer: {
        buttons: [1, 2],
      },
      filterTaps: true,
    }
  );
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { menuElement, bind: bindContextMenu } = useSheetViewerContextMenu(
    viewerRef,
    visibilityManager,
    selectedDbIds,
    setSelectedDbIds,
    renderedSheetShapes
  );

  const handleDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumberOfPages(numPages);
  };

  const handlePageLoadSuccess = (page: PageCallback | undefined | false) => {
    if (!page) {
      setPage(null);
    } else {
      setPage(page);
    }
  };

  const handlePageClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // get 'data-shape-urn' attribute from the clicked element
    const shapeUrn = event.target
      ? (event.target as HTMLElement).getAttribute(shapeUrnDataAttribute)
      : null;
    // if the clicked element is a shape, do not deselect the selected shapes
    if (shapeUrn) {
      return;
    }
    if (event.button === 0) {
      setSelectedDbIds({});
    }
  };

  const _getPointInViewerCoordinateSystem = useCallback(
    (pointInPdf: Point2) => {
      if (!page || !viewerRef.current) {
        throw new Error('Page not loaded');
      }
      return getPointInViewerCoordinateSystem(pointInPdf, [x, y], scale, page);
    },
    [page, scale, viewerRef, x, y]
  );

  const _getPointInPdfCoordinateSystem = useCallback(
    (pointInDom: Point2) => {
      if (!page || !viewerRef.current) {
        throw new Error('Page not loaded');
      }
      return getPointInPdfCoordinateSystem(
        pointInDom,
        [x, y],
        scale,
        page,
        viewerRef.current
      );
    },
    [page, scale, viewerRef, x, y]
  );

  const bindWheel = useWheel(({ event, delta: [, scrollChange] }) => {
    if (!canvasRef.current) {
      return;
    }
    const pageTopLeft = canvasRef.current.getBoundingClientRect();

    const deltaX = event.clientX - pageTopLeft.left;
    const deltaY = event.clientY - pageTopLeft.top;
    const deltaScale = -scrollChange * 0.001;

    setScale(scale * (1 + deltaScale));
    // Adjust offset so that the point under the cursor stays in the same place
    setOffset([x - deltaX * deltaScale, y - deltaY * deltaScale]);
  });

  useEffect(() => {
    const viewport: Viewport = {
      scale,
      offset: [x, y],
    };
    viewportChangeHandlers.forEach((handler) => handler(viewport));
  }, [viewportChangeHandlers, scale, x, y]);

  const toggledBindContextMenu = bindContextMenu();
  const isSheetViewerVisible = !shouldHideViewer && viewerMode === 'sheets';

  const isSheetViewerVisibleStyle: GridItemProps = isSheetViewerVisible
    ? {
        opacity: 1,
        pointerEvents: 'auto',
        zIndex: 1,
      }
    : {
        display: 'none',
      };

  let shapeOpacity = 1;
  if (ghostMode === GhostMode.TRANSPARENT) {
    shapeOpacity = 0.3;
  } else if (ghostMode === GhostMode.HIDDEN) {
    shapeOpacity = 0;
  }

  return (
    <>
      {menuElement}
      <GridItem
        ref={viewerRef}
        cursor={
          calibration.editMode.isActive || isMeasuring ? 'crosshair' : undefined
        }
        area="sidebar-left-start / sidebar-left-start / sidebar-right-end / sidebar-right-end"
        position="relative"
        {...isSheetViewerVisibleStyle}
        {...bindWheel()}
        {...bindDrag()}
        {...toggledBindContextMenu}
        onClick={handlePageClick}
        overflow={'hidden'}
      >
        <Document
          file={sheetS3Url}
          onLoadSuccess={handleDocumentLoadSuccess}
          loading={
            <AbsoluteCenter axis="both" textAlign={'center'}>
              <Spinner color="brand.400" />
              <Text size="sm" color="gray.500" _dark={{ color: 'gray.300' }}>
                Loading your sheets
              </Text>
            </AbsoluteCenter>
          }
          error={
            <AbsoluteCenter axis="both" textAlign={'center'}>
              <Alert status="error">
                <AlertIcon />
                <AlertTitle>Failed to load sheet.</AlertTitle>
                <AlertDescription>
                  Verify that you uploaded a PDF file.
                </AlertDescription>
              </Alert>
            </AbsoluteCenter>
          }
        >
          <DebouncedPage
            canvasRef={canvasRef}
            pageNumber={pageNumber}
            offsetX={x}
            offsetY={y}
            scale={scale}
            onLoadSuccess={handlePageLoadSuccess}
            opacity={shapeOpacity}
          />
        </Document>
        {page && viewerRef.current ? (
          <>
            {calibration.editMode.isActive ? (
              <SheetCalibrationTool
                getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
                getPointInViewerCoordinateSystem={
                  _getPointInViewerCoordinateSystem
                }
                calibrationContext={calibration}
                viewerRef={viewerRef}
              />
            ) : null}
            {isMeasuring && calibration.calibration ? (
              <SheetMeasurementTool
                getPointInPdfCoordinateSystem={_getPointInPdfCoordinateSystem}
                getPointInViewerCoordinateSystem={
                  _getPointInViewerCoordinateSystem
                }
                calibration={calibration.calibration}
                viewerRef={viewerRef}
              />
            ) : null}
            <SheetShapes
              getPointInViewerCoordinateSystem={
                _getPointInViewerCoordinateSystem
              }
              showLabels={isShowingLabels}
            />
          </>
        ) : null}
      </GridItem>
    </>
  );
}

const ChakraPage = chakra(Page, {
  shouldForwardProp: (prop) =>
    !['transform', 'transformOrigin', 'display', '_dark'].includes(prop),
});

// When setting the scale too high, the pdf.js crashes with memory issues
// This is a workaround to avoid that
// Look into tiling when this is implemented in pdf.js
// https://github.com/mozilla/pdf.js/issues/6419
const maxPdfJsScale = 5;
// https://github.com/wojtekmaj/react-pdf/issues/875
// Prevent flashing when changing scale by rendering two pages, one with the old scale and one with the new scale
// Only show the one with the new scale when it has finished rendering
const DebouncedPage = ({
  canvasRef,
  pageNumber,
  offsetX,
  offsetY,
  scale,
  onLoadSuccess,
  ...props
}: {
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  pageNumber: number;
  offsetX: number;
  offsetY: number;
  scale: number;
  onLoadSuccess: (page: PageCallback | undefined | false) => void;
} & ChakraProps) => {
  // gray.50 and its 180deg hue-rotated inverse
  const canvasBackgroundColor = useColorModeValue('#f7fafc', '#e3e5ef');

  // Debounce the scale to avoid rerendering the page too often
  const [debouncedScale, setDebouncedScale] = useState(scale);
  const debouncedSetDebouncedScale = useRef(
    debounce(setDebouncedScale, 1000, { trailing: true, leading: false })
  ).current;

  useEffect(() => {
    debouncedSetDebouncedScale(scale);
    return debouncedSetDebouncedScale.cancel; // Cancel the debounce on unmount
  }, [scale, debouncedSetDebouncedScale]);

  const [renderedScale, setRenderedScale] = useState<number>(scale);

  const targetScale = Math.min(debouncedScale, maxPdfJsScale);

  const isFinishedRendering = renderedScale === targetScale;

  // If the target scale goes above the max scale, we scale the remaining amount with css
  const remainderScaleWithCssTransform = scale / targetScale;
  const canvasRefLoading = useRef<HTMLCanvasElement>(null);
  const canvasRefComplete = useRef<HTMLCanvasElement>(null);
  if (isFinishedRendering) {
    canvasRef.current = canvasRefComplete.current;
  } else {
    canvasRef.current = canvasRefLoading.current;
  }

  return (
    <>
      {/* Page that is displayed while the default page is rendering */}
      <ChakraPage
        display={isFinishedRendering ? 'none' : undefined}
        canvasRef={canvasRefLoading}
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        canvasBackground={canvasBackgroundColor}
        // Set for correct convertToPdfPoint / convertToViewportPoint
        transformOrigin={'0 0'}
        // To reduce blur when zooming in, we could consider setting scale also here, but I'm getting some flashing of the PDF when doing this
        scale={renderedScale}
        transform={`translate(${offsetX}px, ${offsetY}px) scale(${
          scale / renderedScale
        })`}
        _dark={{
          filter: 'invert(1) hue-rotate(180deg)',
        }}
        {...props}
      />
      <ChakraPage
        display={!isFinishedRendering ? 'none' : undefined}
        canvasRef={canvasRefComplete}
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        canvasBackground={canvasBackgroundColor}
        scale={targetScale}
        transform={`translate(${offsetX}px, ${offsetY}px) scale(${remainderScaleWithCssTransform})`}
        transformOrigin={'0 0'}
        onLoadSuccess={onLoadSuccess}
        onRenderSuccess={() => {
          setRenderedScale(targetScale);
        }}
        _dark={{
          filter: 'invert(1) hue-rotate(180deg)',
        }}
        {...props}
      />
    </>
  );
};

type SheetCalibration = {
  calibration: SheetPageCalibrationFragment | null;
  editMode: {
    isActive: boolean;
    setActive: (active: boolean) => void;
    // A line segment in PDF coordinate system
    calibration: [Point2 | null, Point2 | null];
    setCalibration: (calibration: [Point2 | null, Point2 | null]) => void;
  };
};

function useSheetCalibration(
  sheets: Sheet[] | null,
  activeSheetId: string | null,
  pageNumber: number
): SheetCalibration {
  const currentCalibration = useMemo(
    () => findCurrentCalibration(sheets, activeSheetId, pageNumber),
    [activeSheetId, pageNumber, sheets]
  );
  const [isActive, setActive] = useState(false);
  const [calibrationResult, setCalibrationResult] = useState<
    [Point2 | null, Point2 | null]
  >([null, null]);

  return useMemo(
    () => ({
      calibration: currentCalibration,
      editMode: {
        isActive,
        setActive,
        calibration: calibrationResult,
        setCalibration: setCalibrationResult,
      },
    }),
    [calibrationResult, currentCalibration, isActive]
  );
}

type SheetColorization = {
  colorSection: (
    colorFunc: (urn: string, dbId: number) => string,
    dbIdsPerModel: DbIdsPerModel
  ) => void;
  clearColors: () => void;
  colorization: {
    colorFunc: (urn: string, dbId: number) => string;
    dbIdsPerModel: DbIdsPerModel;
  } | null;
};

function useSheetColorization(): SheetColorization {
  const [colorization, setColorization] = useState<{
    colorFunc: (urn: string, dbId: number) => string;
    dbIdsPerModel: DbIdsPerModel;
  } | null>(null);

  const colorSection = useCallback(
    (
      colorFunc: (urn: string, dbId: number) => string,
      dbIdsPerModel: DbIdsPerModel
    ) => {
      setColorization({
        colorFunc,
        dbIdsPerModel,
      });
    },
    []
  );
  const clearColors = useCallback(() => {
    setColorization(null);
  }, []);

  return useMemo(
    () => ({
      colorSection,
      clearColors,
      colorization,
    }),
    [clearColors, colorSection, colorization]
  );
}
