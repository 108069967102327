import {
  groupBy,
  intersection,
  isEmpty,
  isEqual,
  isNil,
  omit,
  partition,
  round,
  sortBy,
} from 'lodash';
import invariant from 'tiny-invariant';
import { Client } from 'urql';
import {
  ColumnType,
  ElementIdentifierDbidType,
  ForgeAttributePredicateType,
  ForgeAttributeQueryType,
  ForgeAttributeType,
  OrderColumnDeepFragment,
  OrderDeepFragment,
  OrderEntryDeepFragment,
  OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate,
  PredicateType,
  Quantity,
  QuantityEnum,
  ShapeDeepFragment,
  ShapeFolderDeepFragment,
  SheetScaleType,
  SheetShapeDeepFragment,
  UnitSettingsType,
  UpdateOrderEntryDocument,
} from '../gql/graphql';
import { convertToDbIdsPerModel, difference, merge } from '../utils/dbid-utils';

import { getColorValueFromGradient } from '../utils/color-util';
import { formatQuantity } from '../utils/quantity-utils';
import {
  convertQuantityToSIUnits,
  convertSIQuantity,
  formatUnit,
  roundingToPrecision,
  unitToUnitType,
} from '../utils/unit-utils';
import {
  PropertyContext,
  ShapesProperties,
  resolveElementIdentifiersDBID,
  resolveProperties,
  resolveQuantity,
  shapesPropertySet,
} from './property-operations';
import { ResolvedQuantity } from './property-types';
import { resolveDerivedFields } from './resolve-formula';

export type ResolvedCustomField = Omit<
  OrderEntryDeepFragment['customFields'][0],
  'resolvedValue'
> & {
  resolvedValue: ResolvedQuantity;
};

export type ResolvedOrderEntry = Omit<
  OrderEntryDeepFragment,
  | 'isMissingResolvedQuantity'
  | 'customFields'
  | 'resolvedElementIdentifiersDbid'
  | 'resolvedQuantity'
> & {
  isMissingResolvedQuantity: boolean;
  subRows?: ResolvedOrderEntry[];
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[];
  resolvedQuantity: ResolvedQuantity | null;
  customFields: ResolvedCustomField[];
};

export type ResolvedQuantities = {
  resolvedCustomFields: ResolvedCustomField[];
  resolvedQuantity: ResolvedQuantity | null;
  isMissingResolvedQuantity: boolean;
};

const sha256 = async (message: string) => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};

const digestShapes = (
  shapesForOrder: ShapeDeepFragment[],
  sheetShapesForOrder: SheetShapeDeepFragment[],
  shapeFolders: ShapeFolderDeepFragment[],
  sheetCalibrations: SheetScaleType[]
): Promise<string> => {
  const shapes = sortBy(
    shapesForOrder.map((shape) => omit(shape, ['createdAt', 'updatedAt'])),
    (shape) => shape.id
  );
  const sheetShapes = sortBy(
    sheetShapesForOrder.map((shape) => omit(shape, ['createdAt', 'updatedAt'])),
    (shape) => shape.id
  );
  const folders = sortBy(
    shapeFolders.map((folder) => omit(folder, ['createdAt', 'updatedAt'])),
    (folder) => folder.id
  );
  return sha256(
    JSON.stringify(shapes) +
      // Avoid changing hash when sheetShapes is empty - so that we don't need to reresolve everything
      (sheetShapes.length > 0 ? JSON.stringify(sheetShapes) : '') +
      (sheetShapes.length > 0 ? JSON.stringify(sheetCalibrations) : '') +
      JSON.stringify(folders)
  );
};

type OrderEntriesCache = {
  inputs: Map<
    string,
    { entry: OrderEntryDeepFragment; context: PropertyContext }
  >;
  resolvedOrders: Map<string, ResolvedOrderEntry>;
  projectId: string;
  polygonCount: number | null | undefined;
};

const orderEntriesCache: OrderEntriesCache = {
  inputs: new Map(),
  resolvedOrders: new Map<string, ResolvedOrderEntry>(),
  projectId: '',
  polygonCount: null,
};

// eslint-disable-next-line complexity
export const resolveOrderEntry = async (
  projectId: string,
  urqlClient: Client,
  context: PropertyContext,
  orderColumns: OrderColumnDeepFragment[],
  entry: OrderEntryDeepFragment,
  persist = true,
  unitSettings?: UnitSettingsType,
  polygonCount?: number | undefined | null
): Promise<ResolvedOrderEntry> => {
  let polyCountChanged = polygonCount !== orderEntriesCache.polygonCount;
  // Reset cache if we navigate to a different project
  if (
    projectId !== orderEntriesCache.projectId ||
    polygonCount !== orderEntriesCache.polygonCount
  ) {
    orderEntriesCache.inputs = new Map();
    orderEntriesCache.resolvedOrders = new Map();
    orderEntriesCache.projectId = projectId;
    orderEntriesCache.polygonCount = polygonCount;
  }
  // Attempt to retrieve the cache
  const cachedEntryInput = orderEntriesCache.inputs.get(entry.id);
  const resolvedOrderEntry = orderEntriesCache.resolvedOrders.get(entry.id);
  if (resolvedOrderEntry && isEqual({ entry, context }, cachedEntryInput)) {
    return resolvedOrderEntry;
  }

  const quantityColumn = orderColumns.find(
    (column) => column.columnType === ColumnType.Quantity
  );
  invariant(quantityColumn, 'Quantity column not found');

  let resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] = [];
  let resolvedQuantity: ResolvedQuantity | null = null;
  let resolvedCustomFields: ResolvedCustomField[] = [];
  let missingQuantity = false;

  const columnIdsForOrder = orderColumns.map((col) => col.id);
  const loadedModels = Object.keys(context.loadedModels);

  const { shapesHashForEntry, isShapesHashEqual } = await calculateShapesHash(
    entry,
    context
  );

  const canUsePersistedResolvedData =
    !polyCountChanged &&
    isShapesHashEqual &&
    isEmpty(entry.groupByAttributes) &&
    isEqual(new Set(entry.resolvedForModels ?? []), new Set(loadedModels)) &&
    !isEmpty(entry.resolvedForColumns) &&
    entry.resolvedForColumns?.every((colId) =>
      columnIdsForOrder.includes(colId)
    ) &&
    loadedModels.length > 0 &&
    //Do not use cache when Sparkel Attributes are used for linking or values
    !entry.elementIdentifiersQuery?.predicates.some((predicate) =>
      predicate.attribute.category.includes('Sparkel Attributes')
    ) &&
    !entry.customFields.some((customField) =>
      customField.value.calculation?.attribute?.category.includes(
        'Sparkel Attributes'
      )
    );

  if (canUsePersistedResolvedData) {
    if (entry.resolvedElementIdentifiersDbid) {
      resolvedElementIdentifiersDbid = entry.resolvedElementIdentifiersDbid;
    }
    if (entry.resolvedQuantity) {
      resolvedQuantity = {
        value: entry.resolvedQuantity,
        units: null,
      };
    }
    resolvedCustomFields = entry.customFields
      .filter((field) => !isNil(field.resolvedValue))
      .map((field) => ({
        ...field,
        resolvedValue: {
          value: field.resolvedValue as string,
          units: null,
        },
      }));

    if (entry.isMissingResolvedQuantity) {
      missingQuantity = entry.isMissingResolvedQuantity;
    }
  } else {
    const dynamicValues = await resolveOrderEntryDynamicValues(
      entry,
      orderColumns,
      context,
      polygonCount || 5000
    );
    if (dynamicValues.resolvedElementIdentifiersDbid) {
      resolvedElementIdentifiersDbid =
        dynamicValues.resolvedElementIdentifiersDbid;
    }
    if (dynamicValues.resolvedQuantity) {
      resolvedQuantity = dynamicValues.resolvedQuantity;
    }
    if (dynamicValues.resolvedCustomFields) {
      resolvedCustomFields = dynamicValues.resolvedCustomFields;
    }
    if (dynamicValues.missingQuantity) {
      missingQuantity = dynamicValues.missingQuantity;
    }
    if (persist && isEmpty(entry.groupByAttributes)) {
      const { error } = await urqlClient.mutation(
        UpdateOrderEntryDocument,
        {
          input: {
            id: entry.id,
            patch: {
              resolvedForColumns: columnIdsForOrder,
              resolvedForModels: loadedModels,
              resolvedShapesHash: shapesHashForEntry,
              resolvedQuantity:
                // only persist resolvedQuantity if it's a number
                // because we only support numbers here
                typeof resolvedQuantity?.value === 'number'
                  ? resolvedQuantity.value
                  : null,
              isMissingResolvedQuantity: missingQuantity,
              resolvedElementIdentifiersDbid: resolvedElementIdentifiersDbid,
              customFields: {
                updateById: resolvedCustomFields.map((field) => ({
                  id: field.id,
                  patch: {
                    // Persist formatted quantity here, as it's only used for display for now
                    // and the field only supports strings
                    resolvedValue: formatQuantity(field.resolvedValue),
                    isSomeQuantityMissing: field.isSomeQuantityMissing,
                  },
                })),
              },
            },
          },
        },
        { requestPolicy: 'network-only' }
      );
      if (error) {
        console.error(error);
      }
    }
  }

  const resolvedEntry: ResolvedOrderEntry = {
    ...entry,
    resolvedQuantity: resolvedQuantity,
    resolvedElementIdentifiersDbid,
    customFields: resolvedCustomFields,
    isMissingResolvedQuantity: missingQuantity,
  };

  const subRows = await resolveSubRows(
    context,
    orderColumns,
    entry,
    resolvedElementIdentifiersDbid,
    entry.groupByAttributes?.[0] ?? null,
    entry.groupByAttributes?.slice(1) ?? null,
    unitSettings
  );

  resolvedEntry.subRows = subRows;

  // Set the cache to be used for subsequent requests
  orderEntriesCache.inputs.set(entry.id, { entry, context });
  orderEntriesCache.resolvedOrders.set(entry.id, resolvedEntry);

  return resolvedEntry;
};

// This function is heavily inspired by `resolveOrderEntry` above, but with the difference that it
// does not use the urql client to persist the resolved data. This is because we want to batch
// the persisting of the resolved data for all order entries in an order, but not trigger the cache updates
// At some point we may want to deprecate `resolveOrderEntry` and use this function instead for all cases
// eslint-disable-next-line complexity
export const resolveOrderEntryBatch = async (
  saveOrder: (
    entriesToUpdate: OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate[]
  ) => Promise<OrderDeepFragment>,
  projectId: string,
  context: PropertyContext,
  orderColumns: OrderColumnDeepFragment[],
  entries: OrderEntryDeepFragment[],
  persist = true,
  unitSettings?: UnitSettingsType,
  polygonCount?: number | null | undefined
): Promise<ResolvedOrderEntry[]> => {
  const quantityColumn = orderColumns.find(
    (column) => column.columnType === ColumnType.Quantity
  );
  invariant(quantityColumn, 'Quantity column not found');

  const columnIdsForOrder = orderColumns.map((col) => col.id);
  const loadedModels = Object.keys(context.loadedModels);

  const orderEntriesToUpdate: OrderEntryOnOrderEntryForOrderEntriesOrderIdFkeyUsingOrderEntriesPkeyUpdate[] =
    [];
  const resolvedOrderEntries: ResolvedOrderEntry[] = [];
  let polyCountChanged = polygonCount !== orderEntriesCache.polygonCount;

  // Reset cache if we navigate to a different project
  if (
    projectId !== orderEntriesCache.projectId ||
    polygonCount !== orderEntriesCache.polygonCount
  ) {
    orderEntriesCache.inputs = new Map();
    orderEntriesCache.resolvedOrders = new Map();
    orderEntriesCache.projectId = projectId;
    orderEntriesCache.polygonCount = null;
  }

  for (const entry of entries) {
    // retrieve order Entries Cache
    const cachedEntryInput = orderEntriesCache.inputs.get(entry.id);
    const resolvedOrderEntry = orderEntriesCache.resolvedOrders.get(entry.id);
    if (resolvedOrderEntry && isEqual({ entry, context }, cachedEntryInput)) {
      resolvedOrderEntries.push(resolvedOrderEntry);
      continue;
    }

    let resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] = [];
    let resolvedQuantity: ResolvedQuantity | null = null;
    let resolvedCustomFields: ResolvedCustomField[] = [];
    let missingQuantity = false;

    const { shapesHashForEntry, isShapesHashEqual } = await calculateShapesHash(
      entry,
      context
    );
    const canUsePersistedResolvedData =
      !polyCountChanged &&
      isShapesHashEqual &&
      isEmpty(entry.groupByAttributes) &&
      isEqual(new Set(entry.resolvedForModels ?? []), new Set(loadedModels)) &&
      !isEmpty(entry.resolvedForColumns) &&
      entry.resolvedForColumns?.every((colId) =>
        columnIdsForOrder.includes(colId)
      ) &&
      loadedModels.length > 0 &&
      //Do not use cache when Sparkel Attributes are used for linking or values
      !entry.elementIdentifiersQuery?.predicates.some((predicate) =>
        predicate.attribute.category.includes('Sparkel Attributes')
      ) &&
      !entry.customFields.some((customField) =>
        customField.value.calculation?.attribute?.category.includes(
          'Sparkel Attributes'
        )
      );

    if (canUsePersistedResolvedData) {
      if (entry.resolvedElementIdentifiersDbid) {
        resolvedElementIdentifiersDbid = entry.resolvedElementIdentifiersDbid;
      }
      if (entry.resolvedQuantity) {
        resolvedQuantity = {
          value: entry.resolvedQuantity,
          units: null,
        };
      }
      resolvedCustomFields = entry.customFields
        .filter((field) => !isNil(field.resolvedValue))
        .map((field) => ({
          ...field,
          // For now, map persisted resolved values to string types, as we're currently only using it for display
          resolvedValue: {
            value: field.resolvedValue as string,
            units: null,
          },
        }));

      if (entry.isMissingResolvedQuantity) {
        missingQuantity = entry.isMissingResolvedQuantity;
      }
    } else {
      const dynamicValues = await resolveOrderEntryDynamicValues(
        entry,
        orderColumns,
        context,
        polygonCount || 5000
      );
      if (dynamicValues.resolvedElementIdentifiersDbid) {
        resolvedElementIdentifiersDbid =
          dynamicValues.resolvedElementIdentifiersDbid;
      }
      if (dynamicValues.resolvedQuantity) {
        resolvedQuantity = dynamicValues.resolvedQuantity;
      }
      if (dynamicValues.resolvedCustomFields) {
        resolvedCustomFields = dynamicValues.resolvedCustomFields;
      }
      if (typeof dynamicValues.missingQuantity === 'boolean') {
        missingQuantity = dynamicValues.missingQuantity;
      }
      if (persist && isEmpty(entry.groupByAttributes)) {
        orderEntriesToUpdate.push({
          id: entry.id,
          patch: {
            resolvedForColumns: columnIdsForOrder,
            resolvedForModels: loadedModels,
            resolvedShapesHash: shapesHashForEntry,
            resolvedQuantity:
              // only persist resolvedQuantity if it's a number
              // because we only support numbers here
              typeof resolvedQuantity?.value === 'number'
                ? resolvedQuantity.value
                : null,
            isMissingResolvedQuantity: missingQuantity,
            resolvedElementIdentifiersDbid: resolvedElementIdentifiersDbid,
            customFields: {
              updateByOrderColumnIdAndOrderEntryId: resolvedCustomFields.map(
                (field) => ({
                  orderColumnId: field.orderColumnId,
                  orderEntryId: entry.id,
                  patch: {
                    resolvedValue: formatQuantity(field.resolvedValue),
                    isSomeQuantityMissing: field.isSomeQuantityMissing,
                  },
                })
              ),
            },
          },
        });
      }
    }

    const resolvedEntry: ResolvedOrderEntry = {
      ...entry,
      resolvedQuantity,
      resolvedElementIdentifiersDbid,
      customFields: resolvedCustomFields,
      isMissingResolvedQuantity: missingQuantity,
    };

    const subRows = await resolveSubRows(
      context,
      orderColumns,
      entry,
      resolvedElementIdentifiersDbid,
      entry.groupByAttributes?.[0] ?? null,
      entry.groupByAttributes?.slice(1) ?? null,
      unitSettings
    );

    resolvedEntry.subRows = subRows;

    // Set the cache to be used for subsequent requests
    orderEntriesCache.inputs.set(entry.id, { entry, context });
    orderEntriesCache.resolvedOrders.set(entry.id, resolvedEntry);
    resolvedOrderEntries.push(resolvedEntry);
  }

  if (orderEntriesToUpdate.length > 0) {
    await saveOrder(orderEntriesToUpdate);
  }

  orderEntriesCache.polygonCount = polygonCount;

  return resolvedOrderEntries;
};

export type OrderEntryDynamicValues = {
  resolvedQuantity: ResolvedQuantity | null;
  missingQuantity: boolean;
  resolvedCustomFields: ResolvedCustomField[];
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] | null;
};

export const resolveOrderEntryDynamicValues = async (
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'elementIdentifiersQuery' | 'elementIdentifiersDbid' | 'customFields'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  context: PropertyContext,
  polygonCount = 5000
): Promise<OrderEntryDynamicValues> => {
  let resolvedElementIdentifiersDbid = await resolveDbIds(
    context,
    orderEntry.elementIdentifiersQuery,
    orderEntry.elementIdentifiersDbid
  );

  const { resolvedCustomFields, resolvedQuantity, isMissingResolvedQuantity } =
    await resolveQuantities(
      orderEntry,
      orderColumns,
      resolvedElementIdentifiersDbid,
      context,
      polygonCount
    );
  return {
    resolvedQuantity,
    resolvedCustomFields,
    resolvedElementIdentifiersDbid,
    missingQuantity: isMissingResolvedQuantity,
  };
};

export async function resolveDbIds(
  context: PropertyContext,
  elementIdentifiersQuery: ForgeAttributeQueryType | null | undefined,
  elementIdentifiersDbid: ElementIdentifierDbidType[] | null | undefined
) {
  if (elementIdentifiersQuery) {
    return await resolveElementIdentifiersDBID(
      context,
      elementIdentifiersQuery
    );
  } else if (elementIdentifiersDbid) {
    return excludeMissingDbIds(context, elementIdentifiersDbid);
  } else {
    return [];
  }
}

// Exclude links to models and shapes that are not present in the property context,
// Which can occur if for example a model is removed from the project, or if a shape is deleted
function excludeMissingDbIds(
  context: PropertyContext,
  elementIdentifiersDbid: ElementIdentifierDbidType[]
) {
  const loadedUrns = [
    ...Object.keys(context.loadedModels),
    ...Object.keys(context.loadedShapes),
    ...Object.keys(context.loadedSheetShapes),
  ];

  const loadedShapes = {
    ...context.loadedShapes,
    ...context.loadedSheetShapes,
  };

  return elementIdentifiersDbid
    .filter((dbId) => loadedUrns.includes(dbId.modelUrn))
    .map((dbId) => {
      if (dbId.modelUrn in loadedShapes) {
        const loadedShapeDbIds = loadedShapes[dbId.modelUrn].map(
          (shape) => shape.dbId
        );
        return {
          ...dbId,
          // Exclude shape dbIds that are not loaded, to handle deleted shapes
          dbIds: intersection(dbId.dbIds, loadedShapeDbIds),
        };
      }
      return dbId;
    });
}

export type Field = {
  columnId: string;
  quantity: Omit<Quantity, '__typename'>;
};

export type ResolvedField = {
  columnId: string;
  resolvedQuantity: ResolvedQuantity;
  isSomeQuantityMissing: boolean;
};

async function resolveNonDerivedFields(
  nonDerivedFields: Field[],
  context: PropertyContext,
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[],
  polygonCount = 5000
): Promise<ResolvedField[]> {
  const result: ResolvedField[] = [];
  for (const field of nonDerivedFields) {
    const resolvedQuantity = await resolveQuantity(
      context,
      resolvedElementIdentifiersDbid,
      field.quantity,
      undefined,
      polygonCount
    );
    const isMissing = isSomeQuantityMissing(
      resolvedQuantity,
      resolvedElementIdentifiersDbid
    );
    result.push({
      columnId: field.columnId,
      resolvedQuantity,
      isSomeQuantityMissing: isMissing,
    });
  }
  return result;
}

function getFields(
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'quantity' | 'unitPrice'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[]
): Field[] {
  const fields = orderEntry.customFields.map((cf) => ({
    columnId: cf.orderColumnId,
    quantity: cf.value,
  }));

  const quantityColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.Quantity
  );

  if (quantityColumn && orderEntry.quantity) {
    fields.push({
      columnId: quantityColumn.id,
      quantity: orderEntry.quantity,
    });
  }

  const priceColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.UnitPrice
  );

  if (priceColumn) {
    fields.push({
      columnId: priceColumn.id,
      quantity: {
        type: QuantityEnum.Static,
        value: orderEntry.unitPrice,
      },
    });
  }

  const elementsColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.LinkedCount
  );

  if (elementsColumn) {
    fields.push({
      columnId: elementsColumn.id,
      quantity: {
        type: QuantityEnum.Static,
        value: resolvedElementIdentifiersDbid.length,
      },
    });
  }
  return fields;
}

function getResolvedQuantityFromFields(
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedFields: ResolvedField[]
): ResolvedField | null {
  const quantityColumn = orderColumns.find(
    (col) => col.columnType === ColumnType.Quantity
  );

  const resolvedQuantityField = resolvedFields.find(
    (field) => field.columnId === quantityColumn?.id
  );
  if (
    !resolvedQuantityField ||
    typeof resolvedQuantityField.resolvedQuantity.value !== 'number'
  ) {
    return null;
  }

  return resolvedQuantityField;
}

async function resolveQuantities(
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'quantity' | 'unitPrice'
  >,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[],
  context: PropertyContext,
  polygonCount = 5000
): Promise<ResolvedQuantities> {
  const fields = getFields(
    orderEntry,
    orderColumns,
    resolvedElementIdentifiersDbid
  );
  const [derivedFields, nonDerivedFields] = partition(
    fields,
    (cf) => cf.quantity.type === QuantityEnum.Derived
  );

  const resolvedNonDerivedFields = await resolveNonDerivedFields(
    nonDerivedFields,
    context,
    resolvedElementIdentifiersDbid,
    polygonCount
  );

  const resolvedDerivedFields = resolveDerivedFields(
    resolvedNonDerivedFields,
    derivedFields
  );
  const resolvedFields = [
    ...resolvedNonDerivedFields,
    ...resolvedDerivedFields,
  ];

  const resolvedQuantity = getResolvedQuantityFromFields(
    orderColumns,
    resolvedFields
  );

  const resolvedCustomFields = getResolvedCustomFieldsFromFields(
    orderColumns,
    resolvedFields,
    orderEntry
  );

  return {
    resolvedCustomFields,
    resolvedQuantity: resolvedQuantity?.resolvedQuantity ?? null,
    isMissingResolvedQuantity: resolvedQuantity?.isSomeQuantityMissing ?? false,
  };
}

function getResolvedCustomFieldsFromFields(
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  resolvedFields: ResolvedField[],
  orderEntry: Pick<
    OrderEntryDeepFragment,
    'customFields' | 'unitPrice' | 'quantity'
  >
) {
  const customColumnIds = orderColumns
    .filter((oc) => oc.columnType === ColumnType.Custom)
    .map((oc) => oc.id);
  return resolvedFields
    .filter((field) => customColumnIds.includes(field.columnId))
    .map((field): ResolvedCustomField => {
      const customField = orderEntry.customFields.find(
        (cf) => cf.orderColumnId === field.columnId
      );
      invariant(customField, 'Custom field not found');
      return {
        ...customField,
        resolvedValue: field.resolvedQuantity,
        isSomeQuantityMissing: field.isSomeQuantityMissing,
      };
    });
}

function isSomeQuantityMissing(
  resolvedQuantity: ResolvedQuantity | null,
  resolvedElementIdentifiersDbid: ElementIdentifierDbidType[] | undefined
) {
  if (!resolvedQuantity?.dataPoints || !resolvedElementIdentifiersDbid) {
    return false;
  }
  const inputDbids = resolvedElementIdentifiersDbid
    .flatMap((dbIds) => dbIds.dbIds.map((dbId) => `${dbIds.modelUrn}:${dbId}`))
    .sort();
  const outputDbids = resolvedQuantity.dataPoints
    .map((point) => `${point.modelUrn}:${point.dbId}`)
    .sort();
  return !isEqual(inputDbids, outputDbids);
}

export const resolveSubRows = async (
  context: PropertyContext,
  orderColumns: Pick<OrderColumnDeepFragment, 'id' | 'columnType'>[],
  entry: OrderEntryDeepFragment,
  dbIdsForParent: ElementIdentifierDbidType[],
  groupByAttribute: ForgeAttributeType | null,
  groupingChain: ForgeAttributeType[] | null,
  unitSettings?: UnitSettingsType
): Promise<ResolvedOrderEntry[] | undefined> => {
  if (!context || groupByAttribute === null || !dbIdsForParent) {
    return undefined;
  }

  const subRows = await findSubrows(
    context,
    dbIdsForParent,
    groupByAttribute,
    entry.elementIdentifiersQuery,
    unitSettings
  );

  const { sortedSubRows, minValue, maxValue } =
    sortSubrowsAndExtractMaxima(subRows);

  let currentIndex = 0;

  const resolveSubRowEntries: ResolvedOrderEntry[] = [];
  for (const {
    subrowName,
    dbIdsForSubrow,
    elementIdentifiersQuery,
  } of sortedSubRows) {
    const {
      resolvedCustomFields,
      resolvedQuantity,
      isMissingResolvedQuantity,
    } = await resolveQuantities(entry, orderColumns, dbIdsForSubrow, context);

    const color = getSubrowColor(
      entry,
      sortedSubRows,
      currentIndex,
      minValue,
      maxValue
    );

    const resolvedEntry: ResolvedOrderEntry = {
      ...entry,
      color,
      name: subrowName,
      isMissingResolvedQuantity,
      elementIdentifiersQuery,
      groupByAttributes: null,
      resolvedQuantity: resolvedQuantity,
      resolvedElementIdentifiersDbid: dbIdsForSubrow,
      customFields: resolvedCustomFields,
    };

    if (groupingChain !== null && groupingChain.length > 0) {
      resolvedEntry.subRows = await resolveSubRows(
        context,
        orderColumns,
        { ...entry, color },
        dbIdsForSubrow,
        groupingChain[0],
        groupingChain.slice(1),
        unitSettings
      );
    }
    resolveSubRowEntries.push(resolvedEntry);

    currentIndex++;
  }

  return resolveSubRowEntries;
};

function sortSubrowsAndExtractMaxima(
  subRows: {
    subrowName: string;
    dbIdsForSubrow: ElementIdentifierDbidType[];
    elementIdentifiersQuery: ForgeAttributeQueryType | null;
  }[]
) {
  // Determine if subrowName values are numeric
  const areSubrowNamesNumeric = subRows.every(
    (row) => !isNaN(Number(row.subrowName))
  );
  let maxValue = undefined;
  let minValue = undefined;

  let sortedSubRows = subRows;

  if (areSubrowNamesNumeric) {
    // Find the max and min values
    for (const row of sortedSubRows) {
      const value = Number(row.subrowName);
      if (!maxValue || value > maxValue) {
        maxValue = value;
      }
      if (!minValue || value < minValue) {
        minValue = value;
      }
    }

    // Sort the subrows by the numeric value
    sortedSubRows = subRows.sort(
      (a, b) => Number(a.subrowName) - Number(b.subrowName)
    );
  } else {
    // Sort the subrows by the subrowName
    sortedSubRows = subRows.sort((a, b) =>
      a.subrowName.localeCompare(b.subrowName)
    );
  }

  return {
    sortedSubRows,
    minValue,
    maxValue,
  };
}

function getSubrowColor(
  entry: OrderEntryDeepFragment,
  allSubRows: {
    subrowName: string;
    dbIdsForSubrow: ElementIdentifierDbidType[];
    elementIdentifiersQuery: ForgeAttributeQueryType | null;
  }[],
  index: number,
  minValue: number | undefined,
  maxValue: number | undefined
) {
  let color = entry.color;

  if (color.includes('gradient')) {
    let factor;
    if (allSubRows.length === 1) {
      factor = 0.5;
    } else {
      factor = index / (allSubRows.length - 1);
    }

    if (
      !isNaN(Number(entry.name)) &&
      maxValue &&
      minValue &&
      maxValue !== minValue
    ) {
      // Calculate the factor based on the min and max values
      factor = (Number(entry.name) - minValue) / (maxValue - minValue);
    }
    color = getColorValueFromGradient(color, factor);
  }

  return color;
}

async function findSubrows(
  context: PropertyContext,
  dbIds: ElementIdentifierDbidType[],
  groupByAttribute: ForgeAttributeType,
  dbIdQuery: ForgeAttributeQueryType | null | undefined,
  unitSettings?: UnitSettingsType
) {
  const properties = (
    await resolveProperties(context, groupByAttribute, dbIds)
  ).map((property) => {
    if (typeof property.value === 'number') {
      // Round for grouping by floating point numbers
      if (property.units && unitSettings) {
        const propertyAsSIQuantity = convertQuantityToSIUnits(property);
        const convertedValue = convertSIQuantity(
          {
            value: propertyAsSIQuantity.value,
            units: propertyAsSIQuantity.units,
          },
          unitSettings
        );

        if (!convertedValue || !convertedValue.value || !convertedValue.units) {
          return property;
        }

        const unitType = unitToUnitType(convertedValue.units);
        const rounding = unitType
          ? unitSettings[unitType]?.rounding
          : undefined;
        const precision = rounding ? roundingToPrecision(rounding) : 2;

        const propertyValue = round(convertedValue.value as number, precision);
        const propertyUnits = formatUnit(convertedValue.units);

        property.value = `${propertyValue} ${propertyUnits}`;
      } else {
        property.value = round(
          convertQuantityToSIUnits(property).value as number,
          2
        );
      }
    }
    return property;
  });

  const propertiesForSubrows = groupBy(
    properties,
    (property) => property.value
  );

  const dbIdsForSubrows = Object.entries(propertiesForSubrows).map(
    ([subrowName, propertiesForSubrow]) => {
      const propertiesForModel = groupBy(
        propertiesForSubrow,
        (property) => property.modelUrn
      );
      const dbIdsForSubrow: ElementIdentifierDbidType[] = Object.entries(
        propertiesForModel
      ).map(([modelUrn, properties]) => ({
        modelUrn,
        dbIds: properties.map((properties) => properties.dbId),
      }));
      let newDbIdQuery: ForgeAttributeQueryType | null = null;
      if (dbIdQuery) {
        const predicate: ForgeAttributePredicateType = {
          attribute: {
            category: groupByAttribute.category,
            name: groupByAttribute.name,
          },
          predicate: PredicateType.Equals,
          attributeValues: [subrowName],
        };
        newDbIdQuery = {
          predicates: [...dbIdQuery.predicates, predicate],
        };
      }
      return {
        subrowName,
        dbIdsForSubrow,
        elementIdentifiersQuery: newDbIdQuery,
      };
    }
  );

  const dbIdsMissingGroupByAttribute = difference(
    dbIds,
    dbIdsForSubrows
      .map((dbIds) => dbIds.dbIdsForSubrow)
      .reduce((prev, next) => merge(prev, next), [])
  );

  if (!isEmpty(dbIdsMissingGroupByAttribute)) {
    let subrowDbIdQuery: ForgeAttributeQueryType | null = null;
    if (dbIdQuery) {
      const predicate: ForgeAttributePredicateType = {
        attribute: {
          category: groupByAttribute.category,
          name: groupByAttribute.name,
        },
        predicate: PredicateType.NotExists,
        attributeValues: [],
      };
      subrowDbIdQuery = {
        predicates: [...dbIdQuery.predicates, predicate],
      };
    }
    dbIdsForSubrows.push({
      subrowName: `Missing property ${groupByAttribute.name}`,
      dbIdsForSubrow: dbIdsMissingGroupByAttribute,
      elementIdentifiersQuery: subrowDbIdQuery,
    });
  }
  return dbIdsForSubrows;
}

async function calculateShapesHash(
  entry: OrderEntryDeepFragment,
  context: PropertyContext
) {
  const loadedSheetShapes = Object.values(context.loadedSheetShapes).flatMap(
    (shapes) => shapes
  );
  const loadedShapes = Object.values(context.loadedShapes).flatMap(
    (shapes) => shapes
  );
  const loadedFolders = Object.values(context.loadedShapeFolders).flatMap(
    (shapes) => shapes
  );

  // By only hashing the shapes that are linked to the entry, we can avoid
  // re-resolving when irrelevant shapes are added/deleted/mutated
  let relevantShapes: ShapeDeepFragment[] = [];
  let relevantSheetShapes: SheetShapeDeepFragment[] = [];
  let relevantFolders: ShapeFolderDeepFragment[] = [];
  let relevantCalibrations: SheetScaleType[] = [];
  if (
    entry.elementIdentifiersQuery &&
    !isEmpty(entry.elementIdentifiersQuery.predicates)
  ) {
    const isSomeCategoryShape = entry.elementIdentifiersQuery?.predicates?.some(
      (predicate) => predicate.attribute.category === shapesPropertySet
    );
    if (isSomeCategoryShape) {
      // For dynamic linking to Shapes we cannot know which shapes are relevant
      relevantShapes = loadedShapes;
      relevantSheetShapes = loadedSheetShapes;
      if (
        entry.elementIdentifiersQuery.predicates.some(
          (p) => p.attribute.name === ShapesProperties.FOLDER
        )
      ) {
        relevantFolders = loadedFolders;
      }
    } else {
      relevantShapes = [];
      relevantSheetShapes = [];
    }
  } else if (entry.elementIdentifiersDbid) {
    const linkedElements = convertToDbIdsPerModel(entry.elementIdentifiersDbid);

    relevantShapes = loadedShapes.filter((shape) => {
      linkedElements[shape.urn]?.includes(shape.dbId) ?? false;
    });
    relevantSheetShapes = loadedSheetShapes.filter(
      (sheetShape) =>
        linkedElements[sheetShape.urn]?.includes(sheetShape.dbId) ?? false
    );
  }

  // Include calibrations for all relevant sheet shapes
  relevantCalibrations = relevantSheetShapes
    .map(
      (shape) =>
        context.loadedSheetCalibrations[shape.sheetId]?.find(
          (calibration) => calibration.pageNumber === shape.sheetPageNumber
        )?.calibration
    )
    .filter(
      (calibration): calibration is SheetScaleType => calibration !== undefined
    );

  const hash = await digestShapes(
    relevantShapes,
    relevantSheetShapes,
    relevantFolders,
    relevantCalibrations
  );
  return {
    shapesHashForEntry: hash,
    isShapesHashEqual: entry.resolvedShapesHash === hash,
  };
}
