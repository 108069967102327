/* eslint-disable import/no-named-as-default-member */
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';
import { ViewerMode } from '../components/common/viewer-mode';
import { ShapeDrawingResultType } from '../components/orderContractor/shapes/ShapeDrawing';
import {
  ForgeAttributeQueryTypeInput,
  ForgeAttributeType,
} from '../gql/graphql';

export enum EventName {
  ItemAdded = 'Item Added',
  SparkelAttributeAssigned = 'Sparkel Attribute Assigned',
  SparkelAttributeCreated = 'Sparkel Attribute Created',
  ShapeCreated = 'Shape Created',
  CopilotPrompted = 'Copilot Prompted',
  FileUploaded = 'File Uploaded',
  ProjectCreated = 'Project Created',
  BuyModal = 'Buy modal opened',
  TabledCreated = 'Table Created',
  CustomColumnDeleted = 'Custom Column Deleted',
  CustomColumnAdded = 'Custom Column Added',
  ElementLinkedToItem = 'Element Linked to Item',
  ElementUnlinkedFromItem = 'Element Unlinked from Item',
  ItemGrouped = 'Item Grouped',
  ItemFlattened = 'Item Flattened',
  ItemDuplicated = 'Item Duplicated',
  ItemTemplateCreated = 'Item Template Created',
  PropertyAssignedToColumn = 'Property Assigned To Column',
  UserAdded = 'User Added',
  CommentAdded = 'Comment Added',
  TableShared = 'Table Shared',
  TableExported = 'Table Exported',
  TableInfoEdited = 'Table Info Edited',
  ItemsMovedOrCopied = 'Items Moved or Copied',
  IssueCreated = 'Issue Created',
  IssueDeleted = 'Issue Deleted',
  IssueCommentAdded = 'Issue Comment Added',
}

export type EventProperties = {
  Tenant?: string;
  'Project Name'?: string;
  'Project ID'?: string;
  'Project Template'?: string;
  'Table Name'?: string;
  'Buy Modal Name'?: string;
  Labels?: {
    key: string;
    value: string;
  }[];
  'Sparkel Attribute Name'?: string;
  'Sparkel Attribute Value'?: string;
  'Item Name'?: string;
  'Item Template'?: string;
  'From Template'?: boolean;
  'From Suggestion'?: boolean;
  'Column Name'?: string;
  Query?: ForgeAttributeQueryTypeInput;
  Property?: ForgeAttributeType;
  Properties?: ForgeAttributeType[];
  'File Type'?: 'ifc' | 'pdf';
  'File Names'?: string[];
  'Viewer Mode'?: ViewerMode;
  'Shape Type'?: ShapeDrawingResultType;
  Prompt?: string;
  Comment?: string;
  'Expiration Duration'?: string;
  'Table Status'?: string;
  'Issue Name'?: string;
  'Is Reporting Table'?: boolean;
};

// Custom hook for using Mixpanel
export const useMixpanel = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const trackEvent = useCallback(
    (event: EventName, properties?: EventProperties) => {
      const shouldDisableTracking =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';

      if (mixpanel && !shouldDisableTracking) {
        mixpanel.track(event, properties);
      }
    },
    []
  );

  const identify = useCallback(
    (user: { userId: string; email: string }, tenant: string) => {
      mixpanel.identify(user.userId);
      mixpanel.people.set({ Tenant: tenant });
      mixpanel.people.set({ $email: user.email });
      mixpanel.register({ Tenant: tenant });
    },
    []
  );

  return { trackEvent, identify };
};

export default mixpanel;
