import { maxBy, minBy } from 'lodash';
import invariant from 'tiny-invariant';
import {
  Point,
  MultiPolylineResult,
  ResultType,
  Triangle,
} from '../../geometric-types';

// The biggest z-axis value of any point minus the smallest z-axis value of any point
export function calculateVerticalComponent(
  triangles: Triangle[]
): MultiPolylineResult {
  const axis = 2;
  const points: Point[] = triangles
    .flatMap((triangle) => triangle)
    .map((vertex) => vertex);
  const topPoint = maxBy(points, (point) => point[axis]);
  let bottomPoint = minBy(points, (point) => point[axis]);
  invariant(bottomPoint && topPoint, 'Vertical component requires two points');

  // We set the unmeasured axis equal to top point, as we are measuring vertical component
  let shiftedBottomPoint = [...topPoint] as Point;
  shiftedBottomPoint[axis] = bottomPoint[axis];
  const unscaledValue = topPoint[axis] - shiftedBottomPoint[axis];
  return {
    type: ResultType.MULTIPOLYLINE,
    multiPolyline: { lines: [[topPoint, shiftedBottomPoint]] },
    value: unscaledValue,
  };
}
