import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateProjectFromTemplateMutation,
  CreateProjectFromTemplateMutationVariables,
  CreateTableFromTemplateMutation,
  CreateTableFromTemplateMutationVariables,
  CreateTemplateFromProjectMutation,
  CreateTemplateFromProjectMutationVariables,
  DeleteProjectTemplateMutation,
  DeleteProjectTemplateMutationVariables,
  GetProjectPageDocument,
  GetProjectTemplatesPageDocument,
} from '../../gql/graphql';

export const createProjectFromTemplateResolver: UpdateResolver<
  CreateProjectFromTemplateMutation,
  CreateProjectFromTemplateMutationVariables
> = (result, args, cache) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectsConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          // Add the new project to the last page of results
          if (
            result.createProjectFromTemplate?.project &&
            data?.projectsConnection &&
            !data.projectsConnection.pageInfo.hasNextPage
          ) {
            data.projectsConnection.nodes = [
              ...data.projectsConnection.nodes,
              result.createProjectFromTemplate.project,
            ];
          }
          return data;
        }
      );
    });
};

export const createTemplateFromProjectResolver: UpdateResolver = (
  result: CreateTemplateFromProjectMutation,
  args: CreateTemplateFromProjectMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectTemplatesConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectTemplatesPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          // Add the new project to the last page of results
          if (
            result.createTemplateFromProject?.projectTemplate &&
            data?.projectTemplatesConnection &&
            !data.projectTemplatesConnection.pageInfo.hasNextPage
          ) {
            data.projectTemplatesConnection.nodes = [
              ...data.projectTemplatesConnection.nodes,
              result.createTemplateFromProject.projectTemplate,
            ];
          }
          return data;
        }
      );
    });
};

export const deleteProjectTemplateResolver: UpdateResolver = (
  result: DeleteProjectTemplateMutation,
  args: DeleteProjectTemplateMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'projectTemplatesConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetProjectTemplatesPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          if (data?.projectTemplatesConnection) {
            data.projectTemplatesConnection.nodes =
              data.projectTemplatesConnection.nodes.filter(
                (template) => template.id !== args.input.id
              );
          }
          return data;
        }
      );
    });
};

export const createTableFromTemplateResolver: UpdateResolver = (
  result: CreateTableFromTemplateMutation,
  args: CreateTableFromTemplateMutationVariables,
  cache
) => {
  if (result.createTableFromTemplate?.order) {
    const projectId = result.createTableFromTemplate.order.projectId;
    const order = result.createTableFromTemplate.order;

    if (order) {
      cache.invalidate({
        __typename: 'Project',
        id: projectId,
      });
    }
  }
};
