import {
  Button,
  Center,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from '@chakra-ui/react';
import { faClone, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useQuery } from 'urql';

import { useParams } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import { GetProjectSettingsDocument } from '../../../gql/graphql';
import SparkelIcon from '../../common/icon/SparkelIcon';
import CloneProjectTab from '../CloneProjectTab';
import CreateTemplateFromProjectTab from '../CreateTemplateFromProjectTab';
import DeleteProjectTab from '../DeleteProjectTab';
import ElementCategoriesTab from '../ElementCategories';
import ExportProjectTab from '../ExportProjectTab';
import OrderLabelsTab from '../OrderLabelsTab';
import AdvancedSettingsTab from '../AdvancedSettingsTab';
import GeneralSettingsTab from '../GeneralSettingsTab';
import UnitSettingsTab from '../UnitSettingsTab';

type ProjectSettingsModalProps = {
  projectId: string;
  modalIsOpen: boolean;
  closeModal: () => void;
};

type ProjectPathParams = {
  projectId: string;
};

export default function ProjectSettingsModal({
  modalIsOpen,
  closeModal,
  projectId,
}: ProjectSettingsModalProps): React.ReactElement {
  return (
    <Modal size={'4xl'} isOpen={modalIsOpen} onClose={closeModal}>
      <ModalOverlay></ModalOverlay>
      <ModalContent height={'60%'} minHeight={'fit-content'}>
        <ModalHeader paddingBottom={0}>Project settings</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalIsOpen ? (
            <ProjectSettings projectId={projectId} closeModal={closeModal} />
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

function ProjectSettings({
  projectId,
  closeModal,
}: {
  projectId: string;
  closeModal: () => void;
}) {
  const [{ data: getProjectSettings, fetching, error }] = useQuery({
    query: GetProjectSettingsDocument,
    variables: { projectId },
  });

  const enableUnitSettings = useFlag('enable-unit-settings');

  const params = useParams<keyof ProjectPathParams>() as ProjectPathParams;
  const isOnCompanyPage = params.projectId ? false : true;

  const project = getProjectSettings?.project;

  const redIconColor = useColorModeValue('red.500', 'red.200');
  const cyanIconColor = useColorModeValue('cyan.500', 'cyan.300');

  const dividerColor = useColorModeValue('gray.200', 'gray.700');

  if (fetching || !project) {
    return (
      <Center>
        <Spinner color="brand.400" />
      </Center>
    );
  }

  return (
    <>
      <Divider borderColor={dividerColor} marginBottom={2} />
      <Tabs
        orientation="vertical"
        variant="soft-rounded"
        colorScheme="blue"
        height={'100%'}
        size={'sm'}
      >
        <TabList width={300} marginRight={2}>
          <Tab justifyContent="left" borderRadius={'md'}>
            General
          </Tab>
          {enableUnitSettings && (
            <Tab justifyContent="left" borderRadius={'md'}>
              Units
            </Tab>
          )}
          <Tab justifyContent="left" borderRadius={'md'}>
            Table labels
          </Tab>
          <Tab justifyContent="left" borderRadius={'md'}>
            Element attributes
          </Tab>
          <Tab
            justifyContent="left"
            borderRadius={'md'}
            isDisabled={isOnCompanyPage}
          >
            Export project
          </Tab>
          <Tab justifyContent="left" borderRadius={'md'}>
            Advanced
          </Tab>
          <Divider borderColor={dividerColor} marginY={2} />
          <Tab
            justifyContent="left"
            borderRadius={'md'}
            padding={0}
            _selected={{ backgroundColor: 'none' }}
          >
            <Button
              size={'sm'}
              justifyContent={'left'}
              borderRadius={'md'}
              leftIcon={
                <SparkelIcon size="xs" color={cyanIconColor} icon={faClone} />
              }
              colorScheme="cyan"
              variant={'ghost'}
              width={'100%'}
            >
              Clone Project
            </Button>
          </Tab>
          <Tab
            justifyContent="left"
            borderRadius={'md'}
            padding={0}
            _selected={{ backgroundColor: 'none' }}
          >
            <Button
              size={'sm'}
              justifyContent={'left'}
              borderRadius={'md'}
              leftIcon={
                <SparkelIcon size="xs" color={cyanIconColor} icon={faPlus} />
              }
              colorScheme="cyan"
              variant={'ghost'}
              width={'100%'}
            >
              Save as template
            </Button>
          </Tab>
          <Tab
            justifyContent="left"
            borderRadius={'md'}
            padding={0}
            _selected={{ backgroundColor: 'none' }}
          >
            <Button
              size={'sm'}
              justifyContent={'left'}
              borderRadius={'md'}
              leftIcon={
                <SparkelIcon size="xs" color={redIconColor} icon={faTrash} />
              }
              colorScheme="red"
              variant={'ghost'}
              width={'100%'}
            >
              Delete project
            </Button>
          </Tab>
        </TabList>
        <Divider
          borderColor={dividerColor}
          orientation="vertical"
          height={'100%'}
        />
        <TabPanels padding={4}>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              General Settings
            </Heading>
            <GeneralSettingsTab project={project} />
          </TabPanel>
          {enableUnitSettings && (
            <TabPanel paddingTop={0}>
              <Heading marginBottom={3} size={'md'}>
                Project Units
              </Heading>
              <UnitSettingsTab project={project} />
            </TabPanel>
          )}
          <TabPanel paddingTop={0}>
            <OrderLabelsTab project={project}></OrderLabelsTab>
          </TabPanel>
          <TabPanel paddingTop={0}>
            <ElementCategoriesTab project={project}></ElementCategoriesTab>
          </TabPanel>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              Export project
            </Heading>
            <ExportProjectTab />
          </TabPanel>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              Advanced
            </Heading>
            <AdvancedSettingsTab project={project} />
          </TabPanel>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              Clone Project
            </Heading>
            <CloneProjectTab
              project={project}
              closeModal={closeModal}
            ></CloneProjectTab>
          </TabPanel>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              Generate template from project
            </Heading>
            <CreateTemplateFromProjectTab
              project={project}
            ></CreateTemplateFromProjectTab>
          </TabPanel>
          <TabPanel paddingTop={0}>
            <Heading marginBottom={3} size={'md'}>
              Delete project
            </Heading>
            <DeleteProjectTab
              project={project}
              closeModal={closeModal}
            ></DeleteProjectTab>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
