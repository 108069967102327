import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { SheetShapeDeepFragment } from '../../../gql/graphql';
import { toMultiPolygon2 } from '../../../domain/geometry/algorithms/util/type-mapping';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import {
  Viewport,
  getPointInPdfCoordinateSystem,
  getPointInViewerCoordinateSystem,
  useSheetViewer,
} from '../../common/SheetViewer';
import { SheetPolygon } from './SheetPolygon';
import { SheetEdge } from './SheetEdge';
import { SheetVertex } from './SheetVertex';

export const MoveOrCopySheetShapes = ({
  shapes,
  onResult,
}: {
  shapes: SheetShapeDeepFragment[];
  onResult: (translationVector: Point2) => void;
}) => {
  const [startPoint, setStartPoint] = useState<Point2 | null>(null);
  const [intermediatePoint, setIntermediatePoint] = useState<Point2 | null>(
    null
  );

  const {
    viewerRef,
    page,
    addViewportChangeHandler,
    removeViewportChangeHandler,
  } = useSheetViewer();

  const [viewerViewport, setViewerViewport] = useState<Viewport | null>(null);

  // Get the point in the DOM coordinate system
  const getPointInDomCoordinateSystem = useCallback(
    (pointInDom: Point2) => {
      if (!viewerViewport || !page || !viewerRef.current) {
        throw new Error('Viewer not ready');
      }
      return getPointInViewerCoordinateSystem(
        pointInDom,
        viewerViewport.offset,
        viewerViewport.scale,
        page
      );
    },
    [viewerViewport, page, viewerRef]
  );

  useEffect(() => {
    const onViewportChange = (vp: Viewport) => {
      setViewerViewport(vp);
    };
    addViewportChangeHandler(onViewportChange);
    return () => {
      removeViewportChangeHandler(onViewportChange);
    };
  }, [addViewportChangeHandler, removeViewportChangeHandler]);

  useEffect(() => {
    const currentViewer = viewerRef.current;
    if (!currentViewer) {
      return;
    }

    const handlePageClick = (event: MouseEvent) => {
      if (event.button === 0) {
        if (!viewerViewport || !viewerRef.current || !page) {
          return;
        }

        const newPoint = getPointInPdfCoordinateSystem(
          [event.clientX, event.clientY],
          viewerViewport?.offset,
          viewerViewport?.scale,
          page,
          viewerRef.current
        );

        if (!newPoint) {
          event.stopPropagation();
        }

        if (!startPoint) {
          setStartPoint(newPoint);
        } else {
          const translationVector: Point2 = [
            newPoint[0] - startPoint[0],
            newPoint[1] - startPoint[1],
          ];
          onResult(translationVector);
          setStartPoint(null);
        }

        event.stopPropagation();
      }
    };
    currentViewer.addEventListener('click', handlePageClick);
    return () => {
      currentViewer.removeEventListener('click', handlePageClick);
    };
  }, [onResult, page, startPoint, viewerRef, viewerViewport]);

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      if (!viewerViewport || !viewerRef.current || !page) {
        return;
      }

      const newPoint = getPointInPdfCoordinateSystem(
        [event.clientX, event.clientY],
        viewerViewport?.offset,
        viewerViewport?.scale,
        page,
        viewerRef.current
      );

      if (!newPoint) {
        return;
      }
      setIntermediatePoint(newPoint);
    },
    [page, viewerRef, viewerViewport]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const translatedShapes = useMemo(() => {
    if (!startPoint || !intermediatePoint) return [];

    const translation = {
      x: intermediatePoint[0] - startPoint[0],
      y: intermediatePoint[1] - startPoint[1],
    };

    return (
      <Box
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        pointerEvents={'none'}
      >
        {shapes.map((shape) => {
          if ('sheetShapePolygon' in shape && shape.sheetShapePolygon) {
            return IntermediatePolygonShape(
              shape,
              translation,
              getPointInDomCoordinateSystem
            );
          } else if ('sheetShapeLine' in shape && shape.sheetShapeLine) {
            return IntermediateLineShape(
              shape,
              translation,
              getPointInDomCoordinateSystem
            );
          } else if ('sheetShapePoint' in shape && shape.sheetShapePoint) {
            return IntermediatePointShape(
              shape,
              translation,
              getPointInDomCoordinateSystem
            );
          }
        })}
      </Box>
    );
  }, [startPoint, intermediatePoint, shapes, getPointInDomCoordinateSystem]);

  return <>{translatedShapes}</>;
};

const IntermediatePolygonShape = (
  shape: SheetShapeDeepFragment,
  translation: { x: number; y: number },
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2
) => {
  const clonedShape: SheetShapeDeepFragment = JSON.parse(JSON.stringify(shape));
  clonedShape.sheetShapePolygon?.multipolygon.polygons.forEach((polygon) => {
    polygon.exterior.points.forEach((point) => {
      point.x += translation.x;
      point.y += translation.y;
    });
    polygon.interiors.forEach((interior) => {
      interior.points.forEach((point) => {
        point.x += translation.x;
        point.y += translation.y;
      });
    });
  });

  return clonedShape.sheetShapePolygon?.multipolygon ? (
    <SheetPolygon
      key={shape.id}
      multipolygon={toMultiPolygon2(
        clonedShape.sheetShapePolygon?.multipolygon
      )}
      getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      fill={'orange.300'}
    />
  ) : null;
};

const IntermediateLineShape = (
  shape: SheetShapeDeepFragment,
  translation: { x: number; y: number },
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2
) => {
  const clonedShape: SheetShapeDeepFragment = JSON.parse(JSON.stringify(shape));
  clonedShape.sheetShapeLine?.points.forEach((point) => {
    point.x += translation.x;
    point.y += translation.y;
  });

  const edges: LineSegment2[] = [];

  if (clonedShape.sheetShapeLine?.points) {
    for (let i = 0; i < clonedShape.sheetShapeLine.points.length - 1; i++) {
      edges.push([
        [
          clonedShape.sheetShapeLine.points[i].x,
          clonedShape.sheetShapeLine.points[i].y,
        ],
        [
          clonedShape.sheetShapeLine.points[i + 1].x,
          clonedShape.sheetShapeLine.points[i + 1].y,
        ],
      ]);
    }
  }

  return edges.map((edge, index) => (
    <SheetEdge
      key={shape.id + index}
      segment={edge}
      cursor={'pointer'}
      getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      backgroundColor={'orange.300'}
    />
  ));
};

const IntermediatePointShape = (
  shape: SheetShapeDeepFragment,
  translation: { x: number; y: number },
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2
) => {
  const clonedShape: SheetShapeDeepFragment = JSON.parse(JSON.stringify(shape));
  if (clonedShape.sheetShapePoint) {
    clonedShape.sheetShapePoint.point.x += translation.x;
    clonedShape.sheetShapePoint.point.y += translation.y;

    return (
      <SheetVertex
        key={shape.id}
        point={[
          clonedShape.sheetShapePoint.point.x,
          clonedShape.sheetShapePoint.point.y,
        ]}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
        theme={'default'}
        backgroundColor={'orange.300'}
        borderColor={'orange.300'}
      />
    );
  }
};
