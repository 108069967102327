import { maxBy, minBy } from 'lodash';
import invariant from 'tiny-invariant';
import {
  Point,
  MultiPolylineResult,
  ResultType,
  MultiPolygon,
} from '../../geometric-types';

export function calculateShapeVerticalComponent(
  shape: MultiPolygon
): MultiPolylineResult {
  const axis = 2;

  const points = shape.polygons
    .map((polygon) => polygon.exterior)
    .flat() as Point[];

  const topPoint = maxBy(points, (point) => point[axis]);
  let bottomPoint = minBy(points, (point) => point[axis]);
  invariant(bottomPoint && topPoint, 'Vertical component requires two points');

  // We set the unmeasured axis equal to toppoint, as we are measuring vertical component
  const shiftedBottomPoint = [...topPoint] as Point;
  shiftedBottomPoint[axis] = bottomPoint[axis];
  const unscaledValue = topPoint[axis] - shiftedBottomPoint[axis];

  return {
    type: ResultType.MULTIPOLYLINE,
    value: unscaledValue,
    multiPolyline: { lines: [[topPoint, shiftedBottomPoint]] },
  };
}
