import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  BulkDeleteSparkelPropertiesMutation,
  BulkDeleteSparkelPropertiesMutationVariables,
  BulkUpsertSparkelPropertiesMutation,
} from '../../gql/graphql';

export const createAttributeResolver: UpdateResolver = (
  result: BulkUpsertSparkelPropertiesMutation,
  args,
  cache
) => {
  const result2 = result.bulkUpsertSparkelProperties?.sparkelProperties;
  if (result2) {
    // Map to get all projectIds
    const projectIds = result2.map((property) => property.projectId);

    // Create a Set to filter out duplicates, then convert back to array for a list of unique projectIds
    const uniqueProjectIds = Array.from(new Set(projectIds));
    uniqueProjectIds.forEach((projectId) => {
      const propertiesForOrder = cache.resolve(
        {
          __typename: 'Project',
          id: projectId,
        },
        'sparkelProperties'
      ) as string[] | null;

      const propertiesToAddorUpdate = result2
        .filter((prop) => prop.projectId === projectId) // Filter by projectId, ensuring prop is not null implicitly
        .map((prop) =>
          cache.keyOfEntity({
            __typename: 'SparkelProperty',
            id: prop.id,
          })
        ) // Map to a new value, which might be null
        .filter(
          (prop): prop is NonNullable<typeof prop> => prop !== null
        ) as NonNullable<string>[]; // Explicitly filter out null and assert the type

      if (propertiesForOrder && propertiesToAddorUpdate) {
        // Convert propertiesToAddorUpdate to a Set for uniqueness
        const updateSet = new Set(propertiesToAddorUpdate);

        // Filter out any items in propertiesForOrder that are also in propertiesToAddorUpdate
        const filteredPropsForOrder = propertiesForOrder.filter(
          (prop) => !updateSet.has(prop)
        );

        // Combine the filtered propertiesForOrder with propertiesToAddorUpdate, ensuring no duplicates
        const newProps = [...filteredPropsForOrder, ...propertiesToAddorUpdate];
        cache.link(
          {
            __typename: 'Project',
            id: projectId,
          },
          'sparkelProperties',
          newProps
        );
      }
    });
  }
};

export const deleteSparkelPropertiesResolver: UpdateResolver = (
  result: BulkDeleteSparkelPropertiesMutation,
  args: BulkDeleteSparkelPropertiesMutationVariables,
  cache
) => {
  const deletedProperties =
    result.bulkDeleteSparkelProperties?.sparkelProperties;
  if (deletedProperties && deletedProperties.length > 0) {
    // Map to get all projectIds
    const projectIds = deletedProperties.map((property) => property.projectId);

    // Create a Set to filter out duplicates, then convert back to array for a list of unique projectIds
    const uniqueProjectIds = Array.from(new Set(projectIds));

    uniqueProjectIds.forEach((projectId) => {
      const propertiesForProject = cache.resolve(
        {
          __typename: 'Project',
          id: projectId,
        },
        'sparkelProperties'
      ) as string[] | null;

      if (propertiesForProject) {
        const propertiesToRemove = deletedProperties
          .filter((prop) => prop.projectId === projectId)
          .map((prop) =>
            cache.keyOfEntity({
              __typename: 'SparkelProperty',
              id: prop.id,
            })
          )
          .filter((key): key is string => key !== null);

        const updatedProperties = propertiesForProject.filter(
          (key) => !propertiesToRemove.includes(key)
        );

        cache.link(
          {
            __typename: 'Project',
            id: projectId,
          },
          'sparkelProperties',
          updatedProperties
        );
      }
    });
  }
};
