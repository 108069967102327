import { isNil } from 'lodash';

// Finds the next available name given a set of other "taken" names, and a proposed base name
export const findNextAvailableName = (
  baseName: string,
  existingNames: string[]
): string => {
  const pattern = new RegExp(`^${baseName} (\\d+)$`);
  const maxIndex = existingNames.reduce((maxIndex, name) => {
    const match = name.match(pattern);
    // If name matches the pattern, extract the number part and compare with maxIndex
    if (match) {
      const index = parseInt(match[1], 10);
      if (index > maxIndex) {
        maxIndex = index;
      }
    }
    return maxIndex;
  }, 0);

  // Return baseName followed by the next index
  return `${baseName} ${maxIndex + 1}`;
};

const COPY_INDEX_LIMIT = 50;

// Finds the base name up to the last regex match
const findBaseName = (name: string, regex: RegExp) => {
  let match;
  let lastMatchIndex;
  while ((match = regex.exec(name)) !== null) {
    lastMatchIndex = match.index;
  }

  if (isNil(lastMatchIndex)) return name;

  return name.slice(0, lastMatchIndex);
};

export const findNextAvailableNameForCopy = (
  name: string,
  existing: string[]
): string => {
  let base = name;
  let num = 1;

  // Check if the name ends with a number and extract the base name and number
  const match = name.match(/(.*?)(\d+)$/);
  if (match) {
    base = match[1].trim() + ' '; // Add a space between the base name and number
    num = parseInt(match[2]);
  } else if (name.length > 0) {
    base += ' '; // If there's no number, append a space for the new number
  }

  let newName = base + num;

  // Increment the number until a unique name is found
  while (existing.includes(newName)) {
    num++;
    newName = base + num;
  }

  return newName;
};
