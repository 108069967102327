export enum ChakraColor {
  cyan50 = '#EDFDFD',
  cyan100 = '#C4F1F9',
  cyan200 = '#9DECF9',
  cyan300 = '#76E4F7',
  cyan400 = '#0BC5EA',
  cyan500 = '#00B5D8',
  cyan600 = '#00A3C4',
  cyan700 = '#0987A0',
  cyan800 = '#086F83',
  cyan900 = '#065666',
  teal50 = '#E6FFFA',
  teal100 = '#B2F5EA',
  teal200 = '#81E6D9',
  teal300 = '#4FD1C5',
  teal400 = '#38B2AC',
  teal500 = '#319795',
  teal600 = '#2C7A7B',
  teal700 = '#285E61',
  teal800 = '#234E52',
  teal900 = '#1D4044',
  green50 = '#F0FFF4',
  green100 = '#C6F6D5',
  green200 = '#9AE6B4',
  green300 = '#68D391',
  green400 = '#48BB78',
  green500 = '#38A169',
  green600 = '#25855A',
  green700 = '#276749',
  green800 = '#22543D',
  green900 = '#1C4532',
  blue50 = '#EBF8FF',
  blue100 = '#BEE3F8',
  blue200 = '#90CDF4',
  blue300 = '#63B3ED',
  blue400 = '#4299E1',
  blue500 = '#3182CE',
  blue600 = '#2B6CB0',
  blue700 = '#2C5282',
  blue800 = '#2A4365',
  blue900 = '#1A365D',
  purple50 = '#FAF5FF',
  purple100 = '#E9D8FD',
  purple200 = '#D6BCFA',
  purple300 = '#B794F4',
  purple400 = '#9F7AEA',
  purple500 = '#805AD5',
  purple600 = '#6B46C1',
  purple700 = '#553C9A',
  purple800 = '#44337A',
  purple900 = '#322659',
  pink50 = '#FFF5F7',
  pink100 = '#FED7E2',
  pink200 = '#FBB6CE',
  pink300 = '#F687B3',
  pink400 = '#ED64A6',
  pink500 = '#D53F8C',
  pink600 = '#B83280',
  pink700 = '#97266D',
  pink800 = '#702459',
  pink900 = '#521B41',
  red50 = '#FFF5F5',
  red100 = '#FED7D7',
  red200 = '#FEB2B2',
  red300 = '#FC8181',
  red400 = '#F56565',
  red500 = '#E53E3E',
  red600 = '#C53030',
  red700 = '#9B2C2C',
  red800 = '#822727',
  red900 = '#63171B',
  orange50 = '#FFFAF0',
  orange100 = '#FEEBCB',
  orange200 = '#FBD38D',
  orange300 = '#F6AD55',
  orange400 = '#ED8936',
  orange500 = '#DD6B20',
  orange600 = '#C05621',
  orange700 = '#9C4221',
  orange800 = '#7B341E',
  orange900 = '#652B19',
  yellow50 = '#FFFFF0',
  yellow100 = '#FEFCBF',
  yellow200 = '#FAF089',
  yellow300 = '#F6E05E',
  yellow400 = '#ECC94B',
  yellow500 = '#D69E2E',
  yellow600 = '#B7791F',
  yellow700 = '#975A16',
  yellow800 = '#744210',
  yellow900 = '#5F370E',
  gray50 = '#F7FAFC',
  gray100 = '#EDF2F7',
  gray200 = '#E2E8F0',
  gray300 = '#CBD5E0',
  gray400 = '#A0AEC0',
  gray500 = '#718096',
  gray600 = '#4A5568',
  gray700 = '#2D3748',
  gray800 = '#1A202C',
  gray900 = '#171923',
}

export function hexToRgb(hex: string): [number, number, number] {
  let r = 0,
    g = 0,
    b = 0;

  if (hex.length === 4) {
    // 3-digit hex
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    // 6-digit hex
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
  }

  return [r, g, b];
}

export function rgbToHex(rgb: [r: number, g: number, b: number]): string {
  const [r, g, b] = rgb;
  return `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}

export function getColorValueFromGradient(
  gradient: string,
  factor: number
): string {
  // Regular expression to extract hex colors
  const hexColorRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;
  const matches = gradient.match(hexColorRegex);

  if (!matches || matches.length === 0) {
    throw new Error('No valid hex colors found in the gradient string.');
  }

  // Ensure the factor is within bounds
  factor = Math.min(Math.max(factor, 0), 1);

  // Convert hex to RGB
  const colors = matches.map((hex) => hexToRgb(hex));

  // Calculate the interpolated color
  const interpolatedColor = interpolateColors(colors, factor);

  // Convert RGB back to hex
  return rgbToHex(interpolatedColor);
}

export function interpolateColors(
  colors: [r: number, g: number, b: number][],
  factor: number
): [r: number, g: number, b: number] {
  const steps = colors.length - 1;
  const stepFactor = factor * steps;
  const stepIndex = Math.floor(stepFactor);

  if (stepIndex >= steps) {
    return colors[steps];
  }

  const color1 = colors[stepIndex];
  const color2 = colors[stepIndex + 1];
  const interpolateFactor = stepFactor - stepIndex;

  return color1.map((c, i) =>
    Math.round(c + interpolateFactor * (color2[i] - c))
  ) as [r: number, g: number, b: number];
}

export function extractFirstHexColor(colorString: string): string {
  // Regular expression to match hex color codes
  const hexColorRegex = /#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})\b/g;

  if (colorString.startsWith('linear-gradient')) {
    // Find all hex color matches
    const matches = colorString.match(hexColorRegex);
    if (matches && matches.length > 0) {
      // Return the first hex color found
      return matches[0];
    } else {
      // Return a default color or throw an error if no hex color is found
      return '#000000'; // Default color (black)
    }
  } else if (hexColorRegex.test(colorString)) {
    // The string is already a hex color
    return colorString;
  } else {
    // Return a default color or throw an error if the string is not a valid color
    return '#000000'; // Default color (black)
  }
}
