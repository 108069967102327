import { difference } from 'lodash';
import { useCallback, useMemo } from 'react';
import { DbIdsPerModel, useViewer } from '../../components/common/ForgeViewer';
import { useSheetViewer } from '../../components/common/SheetViewer';

export enum ElementType {
  BIM_ELEMENT = 'bim',
  SHAPE = 'shap',
  SHEET_SHAPE = 'sheet',
}

export type SelectionValue = {
  selectedDbIds: DbIdsPerModel;
  selectedBimElements: DbIdsPerModel;
  selectedShapes: DbIdsPerModel;
  selectedSheetShapes: DbIdsPerModel;
  toggleSelectDbId: (dbId: number, modelUrn: string) => void;
  setSelectedDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  selectDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
  deselectDbIds: (dbIdsPerModel: DbIdsPerModel) => void;
};

// Hook for selection of elements, shapes and sheet shapes with a single interface
// Delegating calls to the right viewer
export const useSelection = (): SelectionValue => {
  const {
    loadedModels,
    shapesManager: { loadedShapeModels: loadedShapeModels },
    selectDbIds: selectDbIdsFromViewer,
    selectedDbIds: selectedDbIdsFromViewer,
    setSelectedDbIds: setSelectedDbIdsFromViewer,
    toggleSelectDbId: toggleSelectDbIdFromViewer,
  } = useViewer();
  const {
    shapesManager: { renderedSheetShapes },
    selectDbIds: selectDbIdsFromSheetViewer,
    selectedDbIds: selectedDbIdsFromSheetViewer,
    setSelectedDbIds: setSelectedDbIdsFromSheetViewer,
    toggleSelectDbId: toggleSelectDbIdFromSheetViewer,
  } = useSheetViewer();

  const selectedDbIds: DbIdsPerModel = useMemo(
    () => ({ ...selectedDbIdsFromViewer, ...selectedDbIdsFromSheetViewer }),
    [selectedDbIdsFromViewer, selectedDbIdsFromSheetViewer]
  );

  const selectedBimElements: DbIdsPerModel = useMemo(() => {
    const selectedBimElements: DbIdsPerModel = {};
    for (const modelUrn in selectedDbIdsFromViewer) {
      if (modelUrn in loadedModels) {
        selectedBimElements[modelUrn] = selectedDbIdsFromViewer[modelUrn];
      }
    }
    return selectedBimElements;
  }, [loadedModels, selectedDbIdsFromViewer]);

  const selectedShapes: DbIdsPerModel = useMemo(() => {
    const selectedShapes: DbIdsPerModel = {};
    for (const modelUrn in selectedDbIdsFromViewer) {
      if (modelUrn in loadedShapeModels) {
        selectedShapes[modelUrn] = selectedDbIdsFromViewer[modelUrn];
      }
    }
    return selectedShapes;
  }, [loadedShapeModels, selectedDbIdsFromViewer]);

  const selectedSheetShapes: DbIdsPerModel = useMemo(() => {
    const selectedSheetShapes: DbIdsPerModel = {};
    for (const modelUrn in selectedDbIdsFromSheetViewer) {
      if (modelUrn in renderedSheetShapes) {
        selectedSheetShapes[modelUrn] = selectedDbIdsFromSheetViewer[modelUrn];
      }
    }
    return selectedSheetShapes;
  }, [renderedSheetShapes, selectedDbIdsFromSheetViewer]);

  const getElementType = useCallback(
    (modelUrn: string): ElementType => {
      if (modelUrn in loadedModels) {
        return ElementType.BIM_ELEMENT;
      } else if (modelUrn in loadedShapeModels) {
        return ElementType.SHAPE;
      } else if (modelUrn in renderedSheetShapes) {
        return ElementType.SHEET_SHAPE;
      } else {
        throw new Error('Could not find model');
      }
    },
    [loadedModels, loadedShapeModels, renderedSheetShapes]
  );

  const toggleSelectDbId = useCallback(
    (dbId: number, modelUrn: string) => {
      const elementType = getElementType(modelUrn);
      if (
        elementType === ElementType.BIM_ELEMENT ||
        elementType === ElementType.SHAPE
      ) {
        toggleSelectDbIdFromViewer(dbId, modelUrn);
      } else if (elementType === ElementType.SHEET_SHAPE) {
        toggleSelectDbIdFromSheetViewer(dbId, modelUrn);
      }
    },
    [
      getElementType,
      toggleSelectDbIdFromSheetViewer,
      toggleSelectDbIdFromViewer,
    ]
  );

  const setSelectedDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      const bimDbIdsPerModel: DbIdsPerModel = {};
      const sheetDbIdsPerModel: DbIdsPerModel = {};
      for (const modelUrn in dbIdsPerModel) {
        const elementType = getElementType(modelUrn);
        if (
          elementType === ElementType.BIM_ELEMENT ||
          elementType === ElementType.SHAPE
        ) {
          bimDbIdsPerModel[modelUrn] = dbIdsPerModel[modelUrn];
        } else if (elementType === ElementType.SHEET_SHAPE) {
          sheetDbIdsPerModel[modelUrn] = dbIdsPerModel[modelUrn];
        }
      }
      setSelectedDbIdsFromViewer(bimDbIdsPerModel);
      setSelectedDbIdsFromSheetViewer(sheetDbIdsPerModel);
    },
    [
      getElementType,
      setSelectedDbIdsFromSheetViewer,
      setSelectedDbIdsFromViewer,
    ]
  );

  const selectDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      const bimDbIdsPerModel: DbIdsPerModel = {};
      const sheetDbIdsPerModel: DbIdsPerModel = {};
      for (const modelUrn in dbIdsPerModel) {
        const elementType = getElementType(modelUrn);
        if (
          elementType === ElementType.BIM_ELEMENT ||
          elementType === ElementType.SHAPE
        ) {
          bimDbIdsPerModel[modelUrn] = dbIdsPerModel[modelUrn];
        } else if (elementType === ElementType.SHEET_SHAPE) {
          sheetDbIdsPerModel[modelUrn] = dbIdsPerModel[modelUrn];
        }
      }
      selectDbIdsFromViewer(bimDbIdsPerModel);
      selectDbIdsFromSheetViewer(sheetDbIdsPerModel);
    },
    [getElementType, selectDbIdsFromSheetViewer, selectDbIdsFromViewer]
  );

  const deselectDbIds = useCallback(
    (dbIdsPerModel: DbIdsPerModel) => {
      const updatedDbIds = { ...selectedDbIds };
      for (const modelUrn in dbIdsPerModel) {
        if (modelUrn in updatedDbIds) {
          updatedDbIds[modelUrn] = difference(
            updatedDbIds[modelUrn],
            dbIdsPerModel[modelUrn]
          );
        }
      }
      setSelectedDbIds(updatedDbIds);
    },
    [selectedDbIds, setSelectedDbIds]
  );

  return useMemo(
    () => ({
      selectedDbIds,
      selectedBimElements,
      selectedShapes,
      selectedSheetShapes,
      toggleSelectDbId,
      setSelectedDbIds,
      selectDbIds,
      deselectDbIds,
    }),
    [
      selectedDbIds,
      selectedBimElements,
      selectedShapes,
      selectedSheetShapes,
      toggleSelectDbId,
      setSelectedDbIds,
      selectDbIds,
      deselectDbIds,
    ]
  );
};
