import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function SharedViewPage(): React.ReactElement | null {
  const [fetching, setFetching] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { viewId } = useParams<{ viewId: string }>();

  useEffect(() => {
    const fetchInitialState = async () => {
      try {
        setFetching(true);
        const baseUrl = window.location.origin;
        const response = await fetch(`${baseUrl}/public/view/${viewId}`);

        if (response.redirected) {
          window.location.href = response.url;
        }

        /*
        if (response.status !== 302) {
          const error = await response.json();
          setError(error.message);
          return;
        }
          */
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setFetching(false);
      }
    };

    if (viewId) {
      fetchInitialState();
    }
  }, [viewId]);

  if (fetching) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // This page is redirected to the viewer page with the external_access_token, so no need to render anything
  return null;
}
