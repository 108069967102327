import { useEffect, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { LineSegment2, Point2 } from '../domain/geometry/geometric-types';
import { SheetPageCalibrationFragment } from '../gql/graphql';
import { SheetEdge } from './orderContractor/pdf-shapes/SheetEdge';
import { DraggableVertex } from './orderContractor/pdf-shapes/SheetVertex';

export const SheetMeasurementTool = ({
  getPointInPdfCoordinateSystem,
  getPointInViewerCoordinateSystem,
  viewerRef,
}: {
  getPointInPdfCoordinateSystem: (pointInDom: Point2) => Point2;
  getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
  calibration: SheetPageCalibrationFragment;
  viewerRef: React.RefObject<HTMLDivElement>;
}) => {
  const [lineSegment, setLineSegment] = useState<
    [Point2 | null, Point2 | null]
  >([null, null]);

  const [intermediatePoint, setIntermediatePoint] = useState<Point2 | null>(
    null
  );

  const isShiftPressed = isHotkeyPressed('shift');

  useEffect(() => {
    const currentViewer = viewerRef.current;
    if (!currentViewer) {
      return;
    }
    const handlePageClick = (event: MouseEvent) => {
      if (event.button === 0) {
        const point =
          intermediatePoint ||
          getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
        if (!lineSegment[0]) {
          setLineSegment([point, null]);
        } else if (!lineSegment[1]) {
          setLineSegment([lineSegment[0], point]);
        }
      }
    };
    currentViewer.addEventListener('click', handlePageClick);
    return () => {
      currentViewer.removeEventListener('click', handlePageClick);
    };
  }, [
    getPointInPdfCoordinateSystem,
    intermediatePoint,
    lineSegment,
    viewerRef,
  ]);

  useEffect(() => {
    const handlePointerMove = (event: PointerEvent) => {
      if (!lineSegment[0]) {
        return;
      }
      if (lineSegment[1]) {
        setIntermediatePoint(null);
        return;
      }

      if (isShiftPressed) {
        const previousPointInDom = getPointInViewerCoordinateSystem(
          lineSegment[0]
        );
        previousPointInDom[1] += 68; // Offset for the header
        const deltaX = event.clientX - previousPointInDom[0];
        const deltaY = event.clientY - previousPointInDom[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          setIntermediatePoint(
            getPointInPdfCoordinateSystem([
              event.clientX,
              previousPointInDom[1],
            ])
          );
        } else {
          setIntermediatePoint(
            getPointInPdfCoordinateSystem([
              previousPointInDom[0],
              event.clientY,
            ])
          );
        }
        return;
      }
      setIntermediatePoint(
        getPointInPdfCoordinateSystem([event.clientX, event.clientY])
      );
    };

    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [
    getPointInPdfCoordinateSystem,
    getPointInViewerCoordinateSystem,
    isShiftPressed,
    lineSegment,
    viewerRef,
  ]);

  const p1 = lineSegment[0];
  const p2 = lineSegment[1];

  return (
    <>
      {p1 ? (
        <DraggableVertex
          point={p1}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          onVertexDrag={(event) => {
            const point = [event.clientX, event.clientY] as Point2;
            const pdfPoint = getPointInPdfCoordinateSystem(point);
            const newLineSegment = [pdfPoint, p2] as LineSegment2;
            setLineSegment(newLineSegment);
          }}
        />
      ) : null}
      {p2 ? (
        <DraggableVertex
          point={p2}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
          onVertexDrag={(event) => {
            const point = [event.clientX, event.clientY] as Point2;
            const pdfPoint = getPointInPdfCoordinateSystem(point);
            const newLineSegment = [p1, pdfPoint] as LineSegment2;
            setLineSegment(newLineSegment);
          }}
        />
      ) : null}
      {p1 && p2 ? (
        <SheetEdge
          segment={lineSegment as LineSegment2}
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
        />
      ) : null}
      {p1 && !p2 && intermediatePoint ? (
        <SheetEdge
          segment={[p1, intermediatePoint]}
          backgroundColor="yellow.300"
          getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
        />
      ) : null}
    </>
  );
};
