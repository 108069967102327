import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateOrderMutation,
  CreateOrderMutationVariables,
  DeleteOrderMutation,
  DeleteOrderMutationVariables,
  DuplicateOrderMutation,
  DuplicateOrderMutationVariables,
} from '../../gql/graphql';

export const createOrderResolver: UpdateResolver = (
  result: CreateOrderMutation,
  args: CreateOrderMutationVariables,
  cache
) => {
  if (result.createOrder?.order) {
    const ordersForProject = cache.resolve(
      { __typename: 'Project', id: result.createOrder.order.projectId },
      'orders'
    ) as string[] | null;
    const orderToAdd = cache.keyOfEntity({
      __typename: 'Order',
      id: result.createOrder.order.id,
    });
    if (ordersForProject && orderToAdd) {
      ordersForProject.push(orderToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createOrder.order.projectId,
        },
        'orders',
        ordersForProject
      );
    }
  }
};

export const duplicateOrderResolver: UpdateResolver = (
  result: DuplicateOrderMutation,
  args: DuplicateOrderMutationVariables,
  cache
) => {
  if (result.duplicateOrder?.order) {
    const ordersForProject = cache.resolve(
      { __typename: 'Project', id: result.duplicateOrder.order.projectId },
      'orders'
    ) as string[] | null;
    const orderToAdd = cache.keyOfEntity({
      __typename: 'Order',
      id: result.duplicateOrder.order.id,
    });
    if (ordersForProject && orderToAdd) {
      ordersForProject.push(orderToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.duplicateOrder.order.projectId,
        },
        'orders',
        ordersForProject
      );
    }
  }
};

export const deleteOrderResolver: UpdateResolver = (
  result: DeleteOrderMutation,
  args: DeleteOrderMutationVariables,
  cache
) => {
  if (result.deleteOrder?.order) {
    const ordersForProject = cache.resolve(
      { __typename: 'Project', id: result.deleteOrder.order.projectId },
      'orders'
    ) as string[] | null;
    const orderToRemove = cache.keyOfEntity({
      __typename: 'Order',
      id: result.deleteOrder.order.id,
    });
    if (ordersForProject && orderToRemove) {
      cache.link(
        {
          __typename: 'Project',
          id: result.deleteOrder.order.projectId,
        },
        'orders',
        ordersForProject.filter((key) => key !== orderToRemove)
      );
    }
  }
};
