import { useEffect } from 'react';
import { getPolygonGeometry } from '../../../components/orderContractor/shapes/util';
import { MultiPolygonResult } from '../../../domain/geometry/geometric-types';

export const PolygonResultVisualization = ({
  result,
  viewer,
  overlayName,
}: {
  result: MultiPolygonResult;
  viewer: Autodesk.Viewing.Viewer3D;
  overlayName: string;
}) => {
  useEffect(() => {
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color().setHex(0x38b2ac),
      opacity: 0.7,
      transparent: true,
      depthTest: false,
      side: THREE.DoubleSide,
    });

    const meshes = result.multiPolygon.polygons.map((polygon) => {
      const polygonGeometry = getPolygonGeometry(polygon, result.plane);
      const polygonMesh = new THREE.Mesh(polygonGeometry, material);
      viewer.overlays.addMesh(polygonMesh, overlayName);
      return polygonMesh;
    });

    return () => {
      meshes.forEach((mesh) => {
        viewer.overlays.removeMesh(mesh, overlayName);
        mesh.geometry.dispose();
      });
      material.dispose();
    };
  }, [overlayName, result, viewer]);

  return null;
};
