import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  AskCopilotMutation,
  AskCopilotMutationVariables,
  GetAllCopilotExchangesDocument,
} from '../gql/graphql';

export const askCopilotResolver: UpdateResolver = (
  result: AskCopilotMutation,
  args: AskCopilotMutationVariables,
  cache
) => {
  cache.updateQuery(
    {
      query: GetAllCopilotExchangesDocument,
    },
    (data) => {
      if (result.askCopilot && data?.copilotExchangesConnection) {
        data.copilotExchangesConnection.nodes.push({
          __typename: 'CopilotExchange',
          id: result.askCopilot.exchangeId,
          createdAt: new Date().toISOString(),
        });
      }
      return data;
    }
  );
};
