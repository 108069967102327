import { useCallback, useEffect, useMemo, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { LineSegment, Point } from '../../../domain/geometry/geometric-types';
import { getCanvasMousePosition } from '../../common/ForgeViewer';
import {
  calculatePlaneEquation,
  to3dPoints,
  toPlanarPoint,
} from '../../../domain/geometry/algorithms/util/plane';
import { Edge, getPositionOnEdge } from './Edge';
import {
  InitialShapeDrawingState,
  LineStringShapeDrawingResult,
  ShapeDrawingMode,
} from './ShapeDrawing';
import {
  generateIdsForPoints,
  getArcPoints,
  getSnapPoint,
  isPrimaryMouseButton,
  useSnapper,
  useViewerClickEvent,
} from './util';
import { DraggableVertex } from './Vertex';
import { IntermediateEdges } from './PolygonShapeDrawing';

export const LineStringShapeDrawing = ({
  initialState,
  viewer,
  onResult,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.LineString];
  viewer: Autodesk.Viewing.GuiViewer3D;
  onResult: (result: LineStringShapeDrawingResult) => void;
}) => {
  const [points, setPoints] = useState<Point[]>(initialState?.points ?? []);
  const [intermediatePoint, setIntermediatePoint] = useState<Point | null>(
    null
  );

  const isAltKeyPressed = isHotkeyPressed('alt');

  const snapper = useSnapper(viewer);

  const edges = useMemo(() => {
    const edges: LineSegment[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      edges.push([points[i], points[i + 1]]);
    }

    return edges;
  }, [points]);

  const emitResult = useCallback(
    (points: Point[]) => {
      if (points.length > 1) {
        onResult({ points, valid: true });
      } else {
        onResult({ points, valid: false });
      }
    },
    [onResult]
  );

  const getPoint = useCallback((): Point | null => {
    if (snapper.isSnapped()) {
      snapper.indicator.render();
      return getSnapPoint(snapper, viewer);
    }

    return null;
  }, [snapper, viewer]);

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (!isPrimaryMouseButton(event.button)) {
        return false;
      }

      const newPoint = getPoint();

      if (!newPoint) {
        return true;
      }

      let newPoints: Point[] = [];

      if (isAltKeyPressed) {
        const endPoint = points.pop();
        const startPoint = points.pop();

        if (startPoint && endPoint) {
          const plane = calculatePlaneEquation([
            startPoint,
            endPoint,
            newPoint,
          ]);
          const startPoint2 = toPlanarPoint(startPoint, plane);
          const endPoint2 = toPlanarPoint(endPoint, plane);
          const newPoint2 = toPlanarPoint(newPoint, plane);

          const arcPoints = to3dPoints(
            getArcPoints(startPoint2, endPoint2, newPoint2),
            plane
          );

          newPoints = [...points, ...arcPoints];
        }
      } else {
        newPoints = [...points, newPoint];
      }

      setPoints(newPoints);
      emitResult(newPoints);

      return true; // Stop the event from going to other tools in the stack
    },
    [emitResult, getPoint, isAltKeyPressed, points]
  );

  useViewerClickEvent(viewer, handleViewerClick);

  const handlePointerMove = useCallback(() => {
    snapper.indicator.clearOverlays();

    const newPoint = getPoint();
    if (!newPoint) {
      return;
    }
    setIntermediatePoint(newPoint);
  }, [getPoint, snapper.indicator]);

  useEffect(() => {
    viewer.container.addEventListener('pointermove', handlePointerMove);
    return () => {
      viewer.container.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, snapper.indicator, viewer]);

  const handleVertexDrag = (event: MouseEvent, index: number) => {
    setIntermediatePoint(null);
    const { canvasX, canvasY } = getCanvasMousePosition(
      event.clientX,
      event.clientY
    );
    snapper.onMouseMove({
      x: canvasX,
      y: canvasY,
    });
    snapper.indicator.render();
    const newPoint = getPoint();
    if (!newPoint) {
      return;
    }
    const newPoints = [...points];
    newPoints[index] = newPoint;
    setPoints(newPoints);

    if (event.type === 'pointerup') {
      emitResult(newPoints);
    }
  };

  const handleVertexClick = (event: React.MouseEvent, index: number) => {
    if (index !== points.length - 1) {
      const newPoints = [...points];
      newPoints.push(newPoints[index]);
      setPoints(newPoints);
      emitResult(newPoints);
    }
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, index: number) => {
    const edgeStart = points[index];
    const edgeEnd = points[(index + 1) % points.length];
    const newPoint = getPositionOnEdge(event, [edgeStart, edgeEnd], viewer);
    const newPoints = [...points];
    newPoints.splice(index + 1, 0, newPoint);
    setPoints(newPoints);
    emitResult(newPoints);
  };
  return (
    <>
      {edges.map((edge, index) => {
        // Don't render the last edge if alt is pressed
        if (index === edges.length - 1 && isAltKeyPressed) return;
        return (
          <Edge
            // todo: index is not a good key. use a unique id
            key={index}
            segment={edge}
            viewer={viewer}
            cursor={'pointer'}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, index)}
          />
        );
      })}
      {intermediatePoint && points.length > 0
        ? IntermediateEdges(
            intermediatePoint,
            generateIdsForPoints(points),
            null,
            isAltKeyPressed && points.length > 1,
            viewer,
            'green.500'
          )
        : null}
      {points.map((point, index) => {
        return (
          // todo: index is not a good key. use a unique id
          <DraggableVertex
            key={index}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            viewer={viewer}
            onVertexDrag={(event) => handleVertexDrag(event, index)}
            onClick={(event) => handleVertexClick(event, index)}
          />
        );
      })}
    </>
  );
};
