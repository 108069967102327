import { useCallback, useEffect, useRef, useState } from 'react';

export function useDebouncedLoading(
  timeoutMs = 100
): [boolean, (value: boolean, forId: string) => void] {
  const loadingForId = useRef<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(false);

  // Sets the debounced loading state to true after timeoutMs
  useEffect(() => {
    if (isLoading) {
      setIsLoadingDebounced(false);
      const timer = setTimeout(() => setIsLoadingDebounced(true), timeoutMs);
      return () => clearTimeout(timer);
    } else {
      setIsLoadingDebounced(false);
    }
  }, [isLoading, timeoutMs]);

  // Use with an ID if you need to discard stale results
  const setIsLoadingForId = useCallback((load: boolean, forId?: string) => {
    if (load) {
      loadingForId.current = forId;
      setIsLoading(true);
    } else {
      if (!forId || forId === loadingForId.current) {
        setIsLoading(false);
      }
    }
  }, []);
  return [isLoadingDebounced, setIsLoadingForId];
}
