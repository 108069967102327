import * as math from 'mathjs';

import {
  CalibrationUnit,
  SheetScaleType,
  SheetsDeepFragment,
} from '../gql/graphql';

export function findCurrentCalibration(
  sheets: Pick<SheetsDeepFragment, 'sheetPageCalibrations' | 'id'>[] | null,
  activeSheetId: string | null,
  pageNumber: number
) {
  if (!sheets || !activeSheetId) {
    return null;
  }
  const activeSheet = sheets.find((sheet) => sheet.id === activeSheetId);
  const calibration = activeSheet?.sheetPageCalibrations.find(
    (calibration) => calibration.pageNumber === pageNumber
  );
  return calibration ?? null;
}

export const getUnitScaleFromCalibration = (
  calibration: SheetScaleType
): number => {
  const pA = calibration.pointA;
  const pB = calibration.pointB;
  const pdfWidth = math.distance([pA.x, pA.y], [pB.x, pB.y]) as number;

  const realWidth =
    calibration.destinationUnit === CalibrationUnit.Feet
      ? calibration.destinationWidth * 0.3048
      : calibration.destinationUnit === CalibrationUnit.Mm
      ? calibration.destinationWidth / 1000
      : calibration.destinationWidth;
  return realWidth / pdfWidth;
};
