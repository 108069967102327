import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  List,
} from '@chakra-ui/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import { LabelEnum, Project, UpdateProjectDocument } from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';
import AllowedLabelItem from './AllowLabelItem';

type OrderLabelsTabProps = {
  project: Pick<Project, 'id' | 'settings'>;
};
type LabelInputData = {
  label: string;
};

export default function OrderLabelsTab({
  project,
}: OrderLabelsTabProps): React.ReactElement {
  const [inputActive, setInputActive] = useState(false);
  const { register, handleSubmit } = useForm<LabelInputData>();
  const [, updateProject] = useMutation(UpdateProjectDocument);
  const onSubmit = handleSubmit(async ({ label }) => {
    setInputActive(false);
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            allowedOrderLabels: [
              ...(project.settings?.allowedOrderLabels ?? []),
              { name: label, type: LabelEnum.String },
            ],
          },
        },
      },
    });
  });

  const deleteLabel = async (labelName: string) => {
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            allowedOrderLabels: (
              project.settings?.allowedOrderLabels ?? []
            ).filter((allowedLabel) => allowedLabel?.name !== labelName),
          },
        },
      },
    });
  };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <Heading size={'md'}>Table labels</Heading>
        <IconButton
          aria-label="new project button"
          colorScheme="brand"
          size="xs"
          isRound
          onClick={() => setInputActive(!inputActive)}
          icon={<SparkelIcon icon={faPlus} color={['gray.50', 'gray.700']} />}
        />
      </Flex>
      <List>
        {project.settings?.allowedOrderLabels?.map((allowedLabel) => (
          <AllowedLabelItem
            key={allowedLabel.name}
            allowedLabel={allowedLabel}
            onDeleteLabel={() => deleteLabel(allowedLabel.name)}
          ></AllowedLabelItem>
        ))}
      </List>
      {inputActive ? (
        <Box mt={2}>
          {project.settings?.allowedOrderLabels &&
          project.settings?.allowedOrderLabels.length > 0 ? (
            <Divider />
          ) : null}

          <Box mt={2}>
            <form onSubmit={onSubmit} id="new-label-form">
              <FormControl isRequired>
                <FormLabel>New label</FormLabel>
                <Input
                  autoFocus
                  {...register('label')}
                  placeholder={'New label'}
                />
              </FormControl>
            </form>
            <Button
              mt={2}
              colorScheme="brand"
              type="submit"
              form="new-label-form"
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
}
