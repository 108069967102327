import { Point2 } from '../../../domain/geometry/geometric-types';
import { kilerMap } from '../ProductChoices';
import { Diamond } from './SheetWedgeShapeDrawing';

export function kileSelector(
  diamond: Diamond,
  length: number
): string | undefined {
  const factor = onlyOneSide(diamond) ? 1 : 2;

  return Object.keys(kilerMap)
    .sort((key) => {
      const kileLength = kilerMap[key][0].length;
      return kileLength ? kileLength : 0;
    })
    .find((key) => {
      const kileLength = kilerMap[key][0].length;
      return kileLength ? length < kileLength * factor : false;
    });
}

export function onlyOneSide(diamond: Diamond) {
  if (!diamond.triangle4 && !diamond.triangle2) return true;
  if (!diamond.triangle1 && !diamond.triangle3) return true;
  return false;
}

export function distanceBetweenPoints(
  point1: Point2,
  point2: Point2,
  unitScale: number
): number {
  return Math.sqrt(
    Math.pow((point2[0] - point1[0]) * unitScale, 2) +
      Math.pow((point2[1] - point1[1]) * unitScale, 2)
  );
}
