// Export an object that contains different limits for each tier
// The limits are used to determine the maximum number of users that can be added to a tier
// The limits are also used to determine the maximum number of users that can be added to a tier

export const tierLimits = {
  free: {
    MAX_USERS: 2,
    MAX_PROJECTS: 3,
    MAX_COPILOT_REQUESTS: 3,
    MAX_TABLES: 5,
    MAX_ITEMS_PER_TABLE: 5,
    MAX_MODELS: 1,
  },
};
