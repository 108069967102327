import { devtoolsExchange } from '@urql/devtools';
import { createClient, Exchange, fetchExchange, mapExchange } from 'urql';
import { authExchange } from './auth-exchange';
import { cacheExchange } from './cache-exchange';
import { subscriptionExchange } from './subscription-exchange';

const streamToArrayBuffer = async (stream: ReadableStream) => {
  const reader = stream.getReader();
  const chunks = [];
  let length = 0;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) break;
    chunks.push(value);
    length += value.byteLength;
  }
  const result = new Uint8Array(length);
  let offset = 0;
  for (const chunk of chunks) {
    result.set(chunk, offset);
    offset += chunk.byteLength;
  }
  return result.buffer;
};

export const customFetch: typeof fetch = async (url, options) => {
  if (
    typeof options?.body === 'string' &&
    typeof window.CompressionStream === 'function'
  ) {
    const encodedBody = new TextEncoder().encode(options.body);
    if (encodedBody.byteLength > 1_000_000) {
      console.debug('Compressing request body');
      const compressedBody = new Blob([encodedBody])
        .stream()
        .pipeThrough(new CompressionStream('gzip'));

      const compressedArrayBuffer = await streamToArrayBuffer(compressedBody);

      options.body = compressedArrayBuffer;
      options.headers = {
        ...options.headers,
        'Content-Encoding': 'gzip',
      };
    }
  }

  return fetch(url, options);
};

const queryStringDebugExchange: Exchange = mapExchange({
  onOperation(operation) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const operationName: string = operation.query.definitions[0].name.value;
    return {
      ...operation,
      context: {
        ...operation.context,
        url: `${operation.context.url}?operationName=${operationName}`,
      },
    };
  },
});

const exchanges: Exchange[] = [
  devtoolsExchange,
  cacheExchange,
  authExchange,
  fetchExchange,
  subscriptionExchange,
];

if (process.env.NODE_ENV === 'development') {
  exchanges.unshift(queryStringDebugExchange);
}

export const createUrqlClient = () =>
  createClient({
    url: '/graphql',
    maskTypename: true,
    exchanges,
    fetch: customFetch,
  });
