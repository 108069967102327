import { Point2 } from '../../../domain/geometry/geometric-types';
import { SheetPolygon } from './SheetPolygon';
import { Diamond } from './SheetWedgeShapeDrawing';

type ProtanDiamondProps = {
  trianglePoints: Point2[][];
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
  diamond: Diamond;
};

// eslint-disable-next-line complexity
export default function ProtanDiamond({
  trianglePoints,
  getPointInDomCoordinateSystem,
  diamond,
}: ProtanDiamondProps) {
  const strokeColor = 'green.300';
  const strokeWidth = 2;
  return (
    <>
      <SheetPolygon
        fillOpacity={0}
        strokeWidth={strokeWidth}
        stroke={diamond?.triangle1 ? strokeColor : undefined}
        multipolygon={{
          polygons: [
            {
              exterior: trianglePoints[0],
              interiors: [],
            },
          ],
        }}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      />
      <SheetPolygon
        fillOpacity={0}
        strokeWidth={strokeWidth}
        stroke={diamond?.triangle2 ? strokeColor : undefined}
        multipolygon={{
          polygons: [
            {
              exterior: trianglePoints[1],
              interiors: [],
            },
          ],
        }}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      />
      <SheetPolygon
        fillOpacity={0}
        strokeWidth={strokeWidth}
        stroke={diamond?.triangle3 ? strokeColor : undefined}
        multipolygon={{
          polygons: [
            {
              exterior: trianglePoints[2],
              interiors: [],
            },
          ],
        }}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      />
      <SheetPolygon
        fillOpacity={0}
        strokeWidth={strokeWidth}
        stroke={diamond?.triangle4 ? strokeColor : undefined}
        multipolygon={{
          polygons: [
            {
              exterior: trianglePoints[3],
              interiors: [],
            },
          ],
        }}
        getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
      />
    </>
  );
}
