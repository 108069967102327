import {
  MultiPolygon2Fragment,
  MultiPolygon3Fragment,
  PlaneType,
  Point2Type,
  Point3Type,
} from '../../../../gql/graphql';
import {
  MultiPolygon,
  MultiPolygon2,
  Plane,
  Point,
  Point2,
  Polygon,
  Polygon2,
} from '../../geometric-types';

export const toPlane = (
  plane: Pick<PlaneType, 'a' | 'b' | 'c' | 'd'>
): Plane => {
  return {
    unitNormal: [plane.a, plane.b, plane.c],
    planeCoefficient: plane.d,
  };
};

export const toPoints = (
  points: Pick<Point3Type, 'x' | 'y' | 'z'>[]
): Point[] => {
  return points.map((point) => {
    return [point.x, point.y, point.z] as Point;
  });
};

export const toPoints2 = (points: Pick<Point2Type, 'x' | 'y'>[]): Point2[] => {
  return points.map((point) => {
    return [point.x, point.y] as Point2;
  });
};

export const toPolygon = (
  polygon: MultiPolygon3Fragment['polygons'][0]
): Polygon => {
  return {
    exterior: toPoints(polygon.exterior.points),
    interiors: polygon.interiors.map((interior) => {
      return toPoints(interior.points);
    }),
  };
};

export const toPolygon2 = (
  polygon: MultiPolygon2Fragment['polygons'][0]
): Polygon2 => {
  return {
    exterior: toPoints2(polygon.exterior.points),
    interiors: polygon.interiors.map((interior) => {
      return toPoints2(interior.points);
    }),
  };
};

export const toMultiPolygon = (
  multipolygon: MultiPolygon3Fragment
): MultiPolygon => {
  return {
    polygons: multipolygon.polygons.map((polygon) => toPolygon(polygon)),
  };
};

export const toMultiPolygon2 = (
  multipolygon: MultiPolygon2Fragment
): MultiPolygon2 => {
  return {
    polygons: multipolygon.polygons.map((polygon) => toPolygon2(polygon)),
  };
};
