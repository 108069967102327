import { fromPairs } from 'lodash';
import { ForgeAccessToken, Point3Type } from '../gql/graphql';

function loadManifest(documentId: string): Promise<Autodesk.Viewing.Document> {
  return new Promise((resolve, reject) => {
    Autodesk.Viewing.Document.load(
      documentId,
      (doc) => {
        resolve(doc);
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export const initializeViewing = (
  getToken: () => Promise<ForgeAccessToken | null>
): Promise<void> => {
  const getAccessToken = (
    onTokenReady: (token: string, expiresIn: number) => void
  ) => {
    if (onTokenReady) {
      getToken()
        .then((token) => {
          if (token === null) {
            // A null token should never happen, as renewing the auth of an empty viewer does not make sense.
            throw new Error('No token returned from server');
          } else {
            onTokenReady(token.accessToken, token.expiresIn);
          }
        })
        .catch((error) => console.error('Error fetching access token', error));
    }
  };

  const viewerOptions: Autodesk.Viewing.InitializerOptions = {
    env: 'AutodeskProduction2',
    getAccessToken,
    api: 'streamingV2_EU',
  };

  return new Promise<void>((resolve) => {
    Autodesk.Viewing.Initializer(viewerOptions, () => {
      resolve();
    });
  });
};

export const loadModels = async (
  view: Autodesk.Viewing.AggregatedView,
  urns: string[]
): Promise<Record<string, Autodesk.Viewing.Model>> => {
  const documents = await Promise.all(
    urns.map((forgeUrn) => loadManifest(`urn:${forgeUrn}`))
  );

  const bubbleNodes = await Promise.all(
    documents.map((doc) => {
      const bubbles = doc.getRoot().search({ type: 'geometry', role: '3d' });
      return bubbles[0] ?? null;
    })
  );
  const loadedModels = await view.switchView(bubbleNodes, undefined);

  return fromPairs(loadedModels.map((model) => [model.getData().urn, model]));
};

export async function initializeViewer(
  intoElement: HTMLDivElement,
  getToken: () => Promise<ForgeAccessToken | null>,
  globalOffset: Point3Type
): Promise<Autodesk.Viewing.AggregatedView> {
  console.log('setting up viewer');

  await initializeViewing(getToken);

  const aggregateView = new Autodesk.Viewing.AggregatedView();
  await aggregateView.init(intoElement, {
    getCustomLoadOptions: () => {
      return {
        applyRefPoint: false,
        globalOffset: new THREE.Vector3(
          globalOffset.x,
          globalOffset.y,
          globalOffset.z
        ),
      };
    },
  });

  await aggregateView.viewer.loadExtension('Autodesk.Snapping');
  /* eslint-disable @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  if (window.Cypress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.SPARKEL_3D_VIEWER = aggregateView.viewer;
  }
  return aggregateView;
}
