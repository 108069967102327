import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateOrderEntryMutation,
  CreateOrderEntryMutationVariables,
  FlattenSubrowsMutation,
  FlattenSubrowsMutationVariables,
  MoveOrCopyOrderEntriesMutation,
  MoveOrCopyOrderEntriesMutationVariables,
} from '../../gql/graphql';

export const flattenSubrowsResolver: UpdateResolver<
  FlattenSubrowsMutation,
  FlattenSubrowsMutationVariables
> = (result, args, cache) => {
  // Remove the original subrow and add the flattened subrows
  if (result.flattenSubrows?.orderEntries) {
    const orderId = result.flattenSubrows.orderEntries[0].orderId;
    const orderEntriesForOrder = cache.resolve(
      { __typename: 'Order', id: orderId },
      'orderEntries'
    ) as string[] | null;
    if (orderEntriesForOrder) {
      const orderEntryToRemove = cache.keyOfEntity({
        __typename: 'OrderEntry',
        id: args.input.theOrderEntryId,
      });
      const orderEntriesToAdd = result.flattenSubrows.orderEntries
        .map((oe) =>
          cache.keyOfEntity({
            __typename: 'OrderEntry',
            id: oe.id,
          })
        )
        .filter((key) => key !== null) as string[];

      const updatedOrderEntries = orderEntriesForOrder.filter(
        (oe) => oe !== orderEntryToRemove
      );
      updatedOrderEntries.push(...orderEntriesToAdd);
      cache.link(
        {
          __typename: 'Order',
          id: orderId,
        },
        'orderEntries',
        updatedOrderEntries
      );
    }
  }
};

export const createOrderEntryResolver: UpdateResolver = (
  result: CreateOrderEntryMutation,
  args: CreateOrderEntryMutationVariables,
  cache
) => {
  if (result.createOrderEntry?.orderEntry) {
    const orderEntriesForOrder = cache.resolve(
      { __typename: 'Order', id: result.createOrderEntry.orderEntry.orderId },
      'orderEntries'
    ) as string[] | null;
    const orderEntryToAdd = cache.keyOfEntity({
      __typename: 'OrderEntry',
      id: result.createOrderEntry.orderEntry.id,
    });
    if (orderEntriesForOrder && orderEntryToAdd) {
      orderEntriesForOrder.push(orderEntryToAdd);
      cache.link(
        {
          __typename: 'Order',
          id: result.createOrderEntry.orderEntry.orderId,
        },
        'orderEntries',
        orderEntriesForOrder
      );
    }
  }
};

export const moveOrCopyOrderEntriesResolver: UpdateResolver = (
  result: MoveOrCopyOrderEntriesMutation,
  args: MoveOrCopyOrderEntriesMutationVariables,
  cache
) => {
  if (result.moveOrCopyOrderEntries?.orderEntries) {
    const orderId = args.input.targetOrderId;
    const orderEntriesForOrder = cache.resolve(
      { __typename: 'Order', id: orderId },
      'orderEntries'
    ) as string[] | null;
    if (orderEntriesForOrder) {
      const orderEntriesToAdd = result.moveOrCopyOrderEntries.orderEntries
        .map((oe) =>
          cache.keyOfEntity({
            __typename: 'OrderEntry',
            id: oe.id,
          })
        )
        .filter((key) => key !== null) as string[];

      orderEntriesForOrder.push(...orderEntriesToAdd);
      cache.link(
        {
          __typename: 'Order',
          id: orderId,
        },
        'orderEntries',
        orderEntriesForOrder
      );
    }
  }
};
