import Polygon from 'jsts/org/locationtech/jts/geom/Polygon';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import MultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
import OverlayOp from 'jsts/org/locationtech/jts/operation/overlay/OverlayOp';
import Geometry from 'jsts/org/locationtech/jts/geom/Geometry';
import {
  MultiPolygon2,
  MultiPolygon2Result,
  ResultType,
} from '../../geometric-types';
import { toJstsMultiPolygon, toMultiPolygon2 } from '../util/polygon';
import { SheetShapeDeepFragment } from '../../../../gql/graphql';

//Probably needs clockwise / counterclockwise handlng
function createBufferFromSegment(
  vertex: Coordinate,
  nextVertex: Coordinate,
  buffer: number
) {
  const geomFactory = new GeometryFactory();

  // Calculate direction vector
  const direction = new Coordinate(
    nextVertex.x - vertex.x,
    nextVertex.y - vertex.y
  );
  const lengthNorm = Math.sqrt(
    direction.x * direction.x + direction.y * direction.y
  );
  const unitDirection = new Coordinate(
    direction.x / lengthNorm,
    direction.y / lengthNorm
  );

  // Calculate perpendicular vector for buffer width
  const perpendicular = new Coordinate(-unitDirection.y, unitDirection.x);

  // Extend the endpoints of the segment
  const extensionLength = buffer; // Extend by the buffer length
  const extendedStart = new Coordinate(
    vertex.x + unitDirection.x * extensionLength,
    vertex.y + unitDirection.y * extensionLength
  );
  const extendedEnd = new Coordinate(
    nextVertex.x - unitDirection.x * extensionLength,
    nextVertex.y - unitDirection.y * extensionLength
  );

  // Calculate the buffer polygon points with extension
  const points = [
    new Coordinate(
      extendedStart.x + perpendicular.x * buffer,
      extendedStart.y + perpendicular.y * buffer
    ),
    new Coordinate(
      extendedEnd.x + perpendicular.x * buffer,
      extendedEnd.y + perpendicular.y * buffer
    ),
    new Coordinate(
      extendedEnd.x - perpendicular.x * buffer,
      extendedEnd.y - perpendicular.y * buffer
    ),
    new Coordinate(
      extendedStart.x - perpendicular.x * buffer,
      extendedStart.y - perpendicular.y * buffer
    ),
    new Coordinate(
      extendedStart.x + perpendicular.x * buffer,
      extendedStart.y + perpendicular.y * buffer
    ), // closing the ring
  ];

  return geomFactory.createPolygon(
    geomFactory.createLinearRing(points).getCoordinates()
  );
}

function createBuffersAlongEdges(linearRing: Geometry, buffer: number) {
  const geomFactory = new GeometryFactory();
  const polygons = [];

  const coordinates = linearRing.getCoordinates();

  for (let j = 0; j < coordinates.length - 1; j++) {
    const bufferPolygon = createBufferFromSegment(
      coordinates[j],
      coordinates[j + 1],
      buffer
    );
    polygons.push(bufferPolygon);
  }

  return geomFactory.createMultiPolygon(polygons);
}

// eslint-disable-next-line complexity
export function calculateSheetShapeFireSectioning(
  multiPolygonInput: MultiPolygon2,
  buffer: number,
  externalShapes: SheetShapeDeepFragment[],
  unitScale: number
): MultiPolygon2Result {
  const jstsMultiPolygon = toJstsMultiPolygon(multiPolygonInput);

  if (Math.abs(buffer) < 0.01) {
    const value = jstsMultiPolygon.getArea();
    return {
      type: ResultType.MULTIPOLYGON2,
      value,
      multiPolygon: multiPolygonInput,
    };
  }
  const points = externalShapes
    .filter((shape) => shape.sheetShapePoint && shape.sheetShapePoint.point)
    .map((shape) => shape.sheetShapePoint?.point);
  const polygons = [];

  for (let i = 0; i < jstsMultiPolygon.getNumGeometries(); i++) {
    const polygon = jstsMultiPolygon.getGeometryN(i);

    // Buffer the exterior ring
    const exteriorRing = polygon.getExteriorRing();
    const outerBuffer = createBuffersAlongEdges(exteriorRing, buffer);
    for (let j = 0; j < outerBuffer.getNumGeometries(); j++) {
      polygons.push(outerBuffer.getGeometryN(j));
    }

    // Buffer the interior rings
    for (let k = 0; k < polygon.getNumInteriorRing(); k++) {
      const interiorRing = polygon.getInteriorRingN(k);
      const innerBuffer = createBuffersAlongEdges(interiorRing, buffer);
      for (let j = 0; j < innerBuffer.getNumGeometries(); j++) {
        polygons.push(innerBuffer.getGeometryN(j));
      }
    }
  }

  let segmentsPolygons = new MultiPolygon(polygons);

  let finalMultiPolygon = jstsMultiPolygon;

  for (let i = 0; i < segmentsPolygons.getNumGeometries(); i++) {
    const newPolygons: Polygon[] = [];

    for (let ii = 0; ii < finalMultiPolygon.getNumGeometries(); ii++) {
      const polygon = finalMultiPolygon.getGeometryN(ii) as Polygon;
      const difference = OverlayOp.difference(
        polygon,
        segmentsPolygons.getGeometryN(i)
      );

      if (difference instanceof Polygon) {
        newPolygons.push(difference);
      } else if (difference instanceof MultiPolygon) {
        for (let j = 0; j < difference.getNumGeometries(); j++) {
          newPolygons.push(difference.getGeometryN(j) as Polygon);
        }
      }
    }
    finalMultiPolygon = new MultiPolygon(newPolygons);
  }

  if (points && points.length > 0) {
    for (const point of points) {
      if (!point) continue;
      const square = createSquareAroundPoint(point.x, point.y, unitScale);
      const newPolygons: Polygon[] = [];

      for (let i = 0; i < finalMultiPolygon.getNumGeometries(); i++) {
        const polygon = finalMultiPolygon.getGeometryN(i) as Polygon;
        const difference = OverlayOp.difference(polygon, square);

        if (difference instanceof Polygon) {
          newPolygons.push(difference);
        } else if (difference instanceof MultiPolygon) {
          for (let j = 0; j < difference.getNumGeometries(); j++) {
            newPolygons.push(difference.getGeometryN(j) as Polygon);
          }
        }
      }

      finalMultiPolygon = new MultiPolygon(newPolygons);
    }
  }

  if (finalMultiPolygon) {
    const value = finalMultiPolygon.getArea();
    const multiPolygon = toMultiPolygon2(finalMultiPolygon);

    return {
      type: ResultType.MULTIPOLYGON2,
      value,
      multiPolygon,
    };
  } else {
    throw new Error('finalMultiPolygon is undefined');
  }
}

function createSquareAroundPoint(
  x: number,
  y: number,
  unitScale: number
): Polygon {
  const factory = new GeometryFactory();
  const halfSide = 0.6 / unitScale;

  const coordinates = [
    new Coordinate(x - halfSide, y - halfSide),
    new Coordinate(x + halfSide, y - halfSide),
    new Coordinate(x + halfSide, y + halfSide),
    new Coordinate(x - halfSide, y + halfSide),
    new Coordinate(x - halfSide, y - halfSide),
  ];

  const ring = factory.createLinearRing(coordinates);
  return factory.createPolygon(ring.getCoordinates());
}
