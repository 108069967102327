import { UseMutationExecute } from 'urql';

import {
  BulkUpsertSparkelPropertiesInput,
  BulkUpsertSparkelPropertiesMutation,
  Exact,
  ForgeAttributePredicateType,
  ForgeAttributeQueryTypeInput,
  GetOrderEntryTemplatesPageQuery,
  GetSheetShapesForProjectDeepQuery,
  GetSparkelPropertiesForProjectQuery,
  PredicateType,
} from '../../gql/graphql';
import { ResolvedOrderEntry } from '../../domain/resolve-order-entry';

export enum InnerOuter {
  Inner = 'Yes-inner',
  Outer = 'Yes-outer',
}
type Product = {
  resourceName: string;
  sku: string;
  partOfCombustible?: InnerOuter;
  length?: number;
};

interface ProductMap {
  [key: string]: Product[]; // All values in this dictionary are strings
}

//Wood uses same insulation buildup as steel. Washers are the same length, but fasteners are 20cm longer.
const fasternersInsulationNonCombustibleWood: ProductMap = {
  '0.09': [
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW274399',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276284',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'JT2 6.0x140',
      sku: '39003168',
    },
  ],
  '0.10': [
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 60mm - 40 St/pal',
      sku: 'RW274400',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 310mm - 20 st/pal',
      sku: 'RW276283',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'JT2 6.0x100',
      sku: '39003166',
    },
  ],
  '0.12': [
    {
      resourceName: 'Underlag 70mm',
      sku: 'RW274492',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276280',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003048',
    },
    {
      resourceName: 'JT2 6.0x140',
      sku: '39003168',
    },
  ],
  '0.13': [
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW274399',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 230mm - 25 st/pal',
      sku: 'RW276280',
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'JT2 6.0x120',
      sku: '39003167',
    },
  ],
  '0.18': [
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW128828',
    },
    {
      resourceName: 'Toprock 190mm',
      sku: 'RW347607',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003055',
    },
    {
      resourceName: 'JT2 6.0x140',
      sku: '39003168',
    },
  ],
};

const fasternersInsulationNonCombustibleSteelNord: ProductMap = {
  '0.09': [
    {
      resourceName: 'RW Stålunderlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW87112',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276284',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'JT2 6.0x120',
      sku: '39003167',
    },
  ],
  '0.10': [
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 60mm - 40 St/pal',
      sku: 'RW274400',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 310mm - 20 st/pal',
      sku: 'RW276283',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
    },
  ],
  '0.12': [
    {
      resourceName: 'Underlag 70mm',
      sku: 'RW274492',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276280',
    },
    {
      resourceName: 'Ecotek 50x275',
      sku: '39003048',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
    },
  ],
  '0.13': [
    {
      resourceName: 'RW Stålunderlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW87112',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 230mm - 25 st/pal',
      sku: 'RW276280',
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'JT2 6.0x100',
      sku: '39003166',
    },
  ],
  '0.18': [
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 190mm 14,4m2 pr pall',
      sku: 'RW347607',
    },
    {
      resourceName: 'Ecotek T 50x195',
      sku: '39003055',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
    },
  ],
};

const fasternersInsulationNonCombustibleSteelSor: ProductMap =
  fasternersInsulationNonCombustibleSteelNord;
fasternersInsulationNonCombustibleSteelSor['0.09'] = [
  {
    resourceName: 'RW Stålunderlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
    sku: 'RW87112',
  },
  {
    resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
    sku: 'RW276284',
  },
  {
    resourceName: 'Ecotek 50 x 335',
    sku: '39003049',
  },
  {
    resourceName: 'JT2 6.0x120',
    sku: '39003167',
  },
];

const fasternersInsulationNonCombustibleConcrete: ProductMap = {
  '0.09': [
    {
      resourceName: 'RW Stålunderlag Energy 0,6m x 2,0m x 50mm - 48 St/pal',
      sku: 'RW87112',
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276284',
    },
    {
      resourceName: 'MISSING',
      sku: 'MISSING',
    },
    {
      resourceName: 'MISSING',
      sku: 'MISSING',
    },
  ],
  '0.10': [
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 360mm - 15 st/pal',
      sku: 'RW276284',
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'FBS-R 6.3x70',
      sku: '39003173',
    },
  ],
  '0.12': [
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 310mm - 20 st/pal',
      sku: 'RW276283',
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'FBS-R 6.3x140',
      sku: '39003177',
    },
  ],
  '0.13': [
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 280mm - 20 st/pal',
      sku: 'RW276282',
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'FBS-R 6.3x100',
      sku: '39003175',
    },
  ],
  '0.18': [
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 230mm - 25 st/pal',
      sku: 'RW276280',
    },
    {
      resourceName: 'Ecotek T 50x195',
      sku: '39003055',
    },
    {
      resourceName: 'FBS-R 6.3x100',
      sku: '39003175',
    },
  ],
};

const fasternersInsulationCombustibleNordSteel: ProductMap = {
  '0.10': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 150 x 1200 x 2400 - 3 st/pak',
      sku: 'SL22036',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 150 x 1200 x 2400 - 3 st/pak',
      sku: 'SL22036',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 120mm - 20 St/pal',
      sku: 'RW274405',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 180mm - 14 St/pal',
      sku: 'RW274410',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50 x 335',
      sku: '39003049',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
    },
  ],
  '0.12': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 150 x 1200 x 2400 - 3 st/pak',
      sku: 'SL22036',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 280mm - 20 st/pal',
      sku: 'RW276282',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x275',
      sku: '39003048',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
    },
  ],
  '0.13': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 120 x 1200 x 2400 - 4 st/pak',
      sku: 'SL22014',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 250mm - 25 st/pal',
      sku: 'RW276281',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x225',
      sku: '39003047',
    },
    {
      resourceName: 'JT2 6.0x100',
      sku: '39003166',
    },
  ],
  '0.18': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 60 x 1200 x 2400 - 8 st/pak',
      sku: 'SL22013',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 80 x 1200 x 2400 - 6 st/pak',
      sku: 'SL22034',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 60mm - 40 St/pal',
      sku: 'RW274400',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 80mm - 30 St/pal',
      sku: 'RW274402',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x165',
      sku: '39003045',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003165',
      //Dimensjonerende last?
    },
  ],
};

export const kilerMap: ProductMap = {
  RW80110: [
    {
      resourceName:
        'RW TF-TAKKILER MOD A 0-1200 0,3m x 1,2m x 5/25mm - 16MOD/PK',
      sku: 'RW80110',
      length: 1.2,
    },
  ],
  RW80111: [
    {
      resourceName:
        'RW TF-TAKKILER MOD AB 0-2400 0,6m x 2,4m x 5/45mm - 4MOD/PK',
      sku: 'RW80111',
      length: 2.4,
    },
  ],
  RW80112: [
    {
      resourceName:
        'RW TF-TAKKILER MOD 2ABC 0-3600 0,9m x 3,6m x 5/65mm - 2MOD/PK',
      sku: 'RW80112',
      length: 3.6,
    },
  ],
  RW257379: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-D 0-4800 1,2m x 4,8m x 5/85mm',
      sku: 'RW257379',
      length: 4.8,
    },
  ],
  RW257426: [
    {
      resourceName: 'RW TF-TAKKILER MOD 2A-E 0-6000 1,5 x 6 m 0-6000 - 2MOD/PK',
      sku: 'RW257426',
      length: 6,
    },
  ],
  RW257435: [
    {
      resourceName:
        'RW TF-TAKKILER MOD 2A-F 0-7200 1,8m x 7,2m x 5/125mm - 2MOD/PK',
      sku: 'RW257435',
      length: 7.2,
    },
  ],
  RW257437: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-G 0-8400 2,1m x 8,4m x 5/145mm',
      sku: 'RW257437',
      length: 8.4,
    },
  ],
  RW257439: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-H 0-9600 2,4m x 9,6m x 5/165mm',
      sku: 'RW257439',
      length: 9.6,
    },
  ],
  RW257440: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-I 0-10800 2,7m x 10,8m x 5/185mm',
      sku: 'RW257440',
      length: 10.8,
    },
  ],
  RW257441: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-J 0-12000 3,0m x 12,0m x 5/205mm',
      sku: 'RW257441',
      length: 12,
    },
  ],
  RW257442: [
    {
      resourceName: 'RW TF-TAKKILER MOD A-K 0-13200 3,3m x 13,2m x 5/225mm',
      sku: 'RW257442',
      length: 13.2,
    },
  ],
};

const fasternersInsulationCombustibleSorSteel: ProductMap = {
  '0.10': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 150 x 1200 x 2400 - 3 st/pak',
      sku: 'SL27101',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 280mm - 20 st/pal',
      sku: 'RW276282',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'JT2 6.0x140',
      sku: '39003168',
    },
  ],
  '0.12': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 230mm - 25 st/pal',
      sku: 'RW276280',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'JT2 6.0x80',
      sku: '39003168',
    },
  ],
  '0.13': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 80 x 1200 x 2400 - 6 st/pak',
      sku: 'SL27106',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 180mm - 14 St/pal',
      sku: 'RW274410',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T50x225',
      sku: '39003056',
    },
    {
      resourceName: 'JT2 6.0x60',
      sku: '39003164',
    },
  ],
  '0.18': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 120 x 1200 x 2400 - 4 st/pak',
      sku: 'SL27121',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 120mm - 20 St/pal',
      sku: 'RW274405',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'DUO Energy - 40 St/pal 1,2m x 2,0m x 30mm - 48 St/pal',
      sku: 'RW225372',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x165',
      sku: '39003054',
    },
    {
      resourceName: 'JT2 6.0x60',
      sku: '39003164',
    },
  ],
};

const fasternersInsulationCombustibleNordConcrete: ProductMap = {
  '0.10': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 150 x 1200 x 2400',
      sku: 'SL22036',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 80 x 1200 x 2400',
      sku: 'SL22034',
      partOfCombustible: InnerOuter.Inner,
    },

    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 150mm',
      sku: 'RW274408',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 180mm - 14 St/pal',
      sku: 'RW274410',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x335',
      sku: '39003049',
    },
    {
      resourceName: 'FBS-R 6.3x70',
      sku: '39003173',
    },
  ],
  '0.12': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 80 x 1200 x 2400',
      sku: 'SL22034',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 310mm',
      sku: 'RW276283',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x275',
      sku: '39003048',
    },
    {
      resourceName: 'FBS-R 6.3x80',
      sku: '39003174',
    },
  ],
  '0.13': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL22035',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 150 x 1200 x 2400 - 3 st/pak',
      sku: 'SL22036',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 280mm',
      sku: 'RW276281',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x225',
      sku: '39003047',
    },
    {
      resourceName: 'FBS-R 6.3x100',
      sku: '39003175',
    },
  ],
  '0.18': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 120 x 1200 x 2400',
      sku: 'SL22009',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS S80 50 x 1200 x 2400',
      sku: 'SL22005',
      partOfCombustible: InnerOuter.Inner,
    },

    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 120mm',
      sku: 'RW274405',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 50mm',
      sku: 'RW274399',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek 50x225',
      sku: '39003047',
    },
    {
      resourceName: 'FBS-R 6.3x100',
      sku: '39003175',
    },
  ],
};

const fasternersInsulationCombustibleSorConcrete: ProductMap = {
  '0.10': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 170 x 1200 x 2400',
      sku: 'SL27105',
      partOfCombustible: InnerOuter.Inner,
    },

    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x 250mm',
      sku: 'RW224383',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 50mm',
      sku: 'RW274399',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'FBS-R 6.3x120',
      sku: '39003176',
    },
  ],
  '0.12': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27121',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27121',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'RW Toprock System 0,24m x 2,0m x190mm',
      sku: 'RW276280',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 60mm',
      sku: 'RW274407',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x225',
      sku: '39003056',
    },
    {
      resourceName: 'FBS-R 6.3x70',
      sku: '39003172',
    },
  ],
  '0.13': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 100 x 1200 x 2400 - 5 st/pak',
      sku: 'SL27110',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 100mm',
      sku: 'RW274403',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 100mm',
      sku: 'RW274403',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T50x195',
      sku: '39003046',
    },
    {
      resourceName: 'FBS-R 6.3x80',
      sku: '39003174',
    },
  ],
  '0.18': [
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'SL EPS C80 140 x 1200 x 2400',
      sku: 'SL27164',
      partOfCombustible: InnerOuter.Inner,
    },
    {
      resourceName: 'TF Board 0,6m x 2,0m x 30mm - 80 St/pal',
      sku: 'RW80056',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 80mm - 30 St/pal',
      sku: 'RW274402',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'RW Underlag Energy 0,6m x 2,0m x 60mm - ',
      sku: 'RW274400',
      partOfCombustible: InnerOuter.Outer,
    },
    {
      resourceName: 'Ecotek T 50x135',
      sku: '39003044',
    },
    {
      resourceName: 'FBS-R 6.3x80',
      sku: '39003174',
    },
  ],
};

function iterateDownwardsInMap(
  map: ProductMap,
  energy: string
): Product[] | string {
  const sortedEnergies = Object.keys(map).sort(
    (a, b) => parseFloat(a) - parseFloat(b)
  );
  const index = sortedEnergies.indexOf(energy);

  if (index === -1) {
    return 'No product found';
  }

  for (let i = index; i >= 0; i--) {
    if (map[sortedEnergies[i]].length > 0) {
      return map[sortedEnergies[i]];
    }
  }

  return 'No product found';
}

// eslint-disable-next-line complexity
function getInsulationAndFasteners(
  energy: string,
  fireRequirement: string,
  location: string,
  substrate: string
) {
  if (
    fireRequirement === 'Combustible' &&
    location === 'Nord' &&
    substrate === 'Concrete'
  ) {
    return iterateDownwardsInMap(
      fasternersInsulationCombustibleNordConcrete,
      energy
    );
  }
  if (
    fireRequirement === 'Combustible' &&
    (location === 'Sør' || location === 'Øst' || location === 'Vest') &&
    substrate === 'Concrete'
  ) {
    return iterateDownwardsInMap(
      fasternersInsulationCombustibleSorConcrete,
      energy
    );
  }

  if (fireRequirement === 'Non-Combustible' && substrate === 'Concrete') {
    console.log('Got to non-combustible');
    return iterateDownwardsInMap(
      fasternersInsulationNonCombustibleConcrete,
      energy
    );
  }

  if (fireRequirement === 'Non-Combustible' && substrate === 'OSB/Plywood') {
    console.log('Got to non-combustible');
    return iterateDownwardsInMap(
      fasternersInsulationNonCombustibleWood,
      energy
    );
  }
  if (
    fireRequirement === 'Combustible' &&
    location === 'Nord' &&
    substrate === 'Steel'
  ) {
    return iterateDownwardsInMap(
      fasternersInsulationCombustibleNordSteel,
      energy
    );
  }
  if (
    fireRequirement === 'Combustible' &&
    (location === 'Sør' || location === 'Øst' || location === 'Vest') &&
    substrate === 'Steel'
  ) {
    return iterateDownwardsInMap(
      fasternersInsulationCombustibleSorSteel,
      energy
    );
  }

  if (
    fireRequirement === 'Non-Combustible' &&
    substrate === 'Steel' &&
    location === 'Nord'
  ) {
    console.log('Got to non-combustible');
    return iterateDownwardsInMap(
      fasternersInsulationNonCombustibleSteelNord,
      energy
    );
  }

  if (
    (fireRequirement === 'Non-Combustible' &&
      substrate === 'Steel' &&
      location === 'Sør') ||
    location === 'Øst' ||
    location === 'Vest'
  ) {
    console.log('Got to non-combustible');
    return iterateDownwardsInMap(
      fasternersInsulationNonCombustibleSteelSor,
      energy
    );
  }
  // const key = `${energy}-${location}`;
  return 'No product found';
}

export function getTemplateForSku(
  sku: string,
  data: GetOrderEntryTemplatesPageQuery,
  partOfCombustible?: InnerOuter
) {
  if (partOfCombustible) {
    return data.orderEntryTemplatesConnection?.nodes.find(
      (a) =>
        a.customFields?.some(
          (field) => field.name === 'SKU' && field.value.stringValue === sku
        ) &&
        a.customFields?.some(
          (field) =>
            field.name === 'CombustibleSolution' &&
            field.value.stringValue === partOfCombustible
        )
    );
  }

  return data.orderEntryTemplatesConnection?.nodes.find(
    (a) =>
      a.customFields?.some(
        (field) => field.name === 'SKU' && field.value.stringValue === sku
      ) &&
      //Below is hotfix for not including combustible solutions for non-combustible. Needs to be rewritten.
      !a.customFields?.some(
        (field) =>
          field.name === 'CombustibleSolution' &&
          (field.value.stringValue === InnerOuter.Inner ||
            field.value.stringValue === InnerOuter.Outer)
      )
  );
}

// eslint-disable-next-line complexity
export async function kileChoices(
  kileType: string,
  orderId: string,
  handleCreateFromTemplateClick: (
    templateId: string,
    title: string,
    query: ForgeAttributeQueryTypeInput | undefined,
    orderId: string
  ) => Promise<void>,
  resourceTemplateData: GetOrderEntryTemplatesPageQuery | undefined,
  resolvedOrderEntries: ResolvedOrderEntry[]
) {
  let products: Product[] = [];
  if (
    kileType &&
    !resolvedOrderEntries.some((orderEntry) =>
      orderEntry.customFields.find(
        (field) => field.resolvedValue.value === kileType
      )
    )
  ) {
    const kileProducts = getKile(kileType);
    if (Array.isArray(kileProducts)) {
      products.push(...kileProducts);
    } else {
      console.error('Could not find product match for insulation / fasteners');
    }
  }

  for (const product of products) {
    const template = resourceTemplateData
      ? getTemplateForSku(
          product.sku,
          resourceTemplateData,
          product.partOfCombustible
        )
      : undefined;
    if (template) {
      if (template.elementIdentifiersQuery) {
        const orderIdPredicate: ForgeAttributePredicateType = {
          attribute: {
            __typename: undefined,
            category: 'Sparkel Attributes',
            name: 'OrderId',
          },
          attributeValues: [orderId],
          predicate: PredicateType.AnyOf,
        };
        template.elementIdentifiersQuery.predicates =
          template.elementIdentifiersQuery.predicates.concat(orderIdPredicate);
      }
      await handleCreateFromTemplateClick(
        template.id,
        product.resourceName,
        template.elementIdentifiersQuery || undefined,
        orderId
      );
    } else {
      console.error('Template not found for sku', product.sku);
    }
  }
}

// eslint-disable-next-line complexity
export async function ProductChoices(
  keyValues: { key: string; value: string }[],
  orderId: string,
  handleCreateFromTemplateClick: (
    templateId: string,
    title: string,
    query: ForgeAttributeQueryTypeInput | undefined,
    orderId: string
  ) => Promise<void>,
  resourceTemplateData: GetOrderEntryTemplatesPageQuery | undefined,
  bulkCreateSparkelProps: UseMutationExecute<
    BulkUpsertSparkelPropertiesMutation,
    Exact<{
      input: BulkUpsertSparkelPropertiesInput;
    }>
  >,
  projectId: string,
  shapesData: GetSheetShapesForProjectDeepQuery | undefined,
  sparkelAttributesData: GetSparkelPropertiesForProjectQuery | undefined
) {
  const energyRequirement = keyValues
    .find((label) => label.key === 'Energy requirements')
    ?.value.slice(0, 4);

  const location = keyValues.find((label) => label.key === 'Location')?.value;
  const qkast = keyValues.find((label) => label.key === 'Qₚ (N/m²)')?.value;
  const height = keyValues.find(
    (label) => label.key === 'Building height (m)'
  )?.value;
  const fireRequirement = keyValues.find(
    (label) => label.key === 'Fire requirements'
  )?.value;
  const substrate = keyValues.find((label) => label.key === 'Substrate')?.value;

  let products: Product[] = [];
  if (energyRequirement && location && fireRequirement && substrate) {
    const insulationproducts = getInsulationAndFasteners(
      energyRequirement,
      fireRequirement,
      location,
      substrate
    );
    if (Array.isArray(insulationproducts)) {
      products.push(...insulationproducts);
    } else {
      console.error('Could not find product match for insulation / fasteners');
    }
  }

  if (location) {
    products.push(...getMembrane(location));
  }

  //Sluk
  products.push({
    resourceName: 'Protan sluk 110 mm, lang PVC',
    sku: '39001405',
  });

  //PE Folie
  products.push({
    resourceName: 'PROTAN PE-FOLIE 2MM, 2,7x50m',
    sku: '39001133',
  });

  //Parapetskjørt
  products.push({
    resourceName: 'PARAP.1,6 100 CM, 15/85 FL F94, 1 m x 20 m',
    sku: '34903865',
  });

  //Stålskinne
  products.push({
    resourceName: 'PROTAN GRIP STÅLSKINNE 2M L = 2,00 meter',
    sku: '39000024',
  });

  for (const product of products) {
    const template = resourceTemplateData
      ? getTemplateForSku(
          product.sku,
          resourceTemplateData,
          product.partOfCombustible
        )
      : undefined;
    if (template) {
      if (template.elementIdentifiersQuery) {
        const orderIdPredicate: ForgeAttributePredicateType = {
          attribute: {
            __typename: undefined,
            category: 'Sparkel Attributes',
            name: 'OrderId',
          },
          attributeValues: [orderId],
          predicate: PredicateType.AnyOf,
        };
        let shapeNamePredicate: ForgeAttributePredicateType = {
          attribute: {
            __typename: undefined,
            category: 'Shape',
            name: 'Name',
          },
          attributeValues: ['Zone'],
          predicate: PredicateType.Contains,
        };

        //Special handling drains
        if (product.sku === '39001405') {
          shapeNamePredicate = {
            attribute: {
              __typename: undefined,
              category: 'Shape',
              name: 'Name',
            },
            attributeValues: ['Drain'],
            predicate: PredicateType.Contains,
          };
        }

        template.elementIdentifiersQuery.predicates = [
          shapeNamePredicate,
          orderIdPredicate,
        ];
      }
      await handleCreateFromTemplateClick(
        template.id,
        product.resourceName,
        template.elementIdentifiersQuery || undefined,
        orderId
      );
    } else {
      console.error('Template not found for sku', product.sku);
    }
  }

  //Give me all existing shapes, upsert me
  if (qkast && shapesData && sparkelAttributesData)
    updateExistingShapesWithRelevantSparkelAttributes(
      qkast,
      'QKast',
      bulkCreateSparkelProps,
      projectId,
      orderId,
      shapesData,
      sparkelAttributesData
    );
  if (height && shapesData && sparkelAttributesData)
    updateExistingShapesWithRelevantSparkelAttributes(
      height,
      'Roof height',
      bulkCreateSparkelProps,
      projectId,
      orderId,
      shapesData,
      sparkelAttributesData
    );

  if (height && shapesData && sparkelAttributesData)
    updateExistingShapesWithRelevantSparkelAttributes(
      height,
      'Roof height',
      bulkCreateSparkelProps,
      projectId,
      orderId,
      shapesData,
      sparkelAttributesData
    );

  if (
    fireRequirement === 'Combustible' &&
    shapesData &&
    location &&
    energyRequirement &&
    sparkelAttributesData
  ) {
    let sectioning = getSectioning(location, energyRequirement);
    updateExistingShapesWithRelevantSparkelAttributes(
      sectioning,
      'Fire sectioning',
      bulkCreateSparkelProps,
      projectId,
      orderId,
      shapesData,
      sparkelAttributesData
    );
  }
}
function getMembrane(location: string) {
  switch (location) {
    case 'Nord':
      return [
        {
          resourceName: 'Protan SE 1.6 Flak 3/fl F94 2x15m',
          sku: '33516942',
        },
      ];
    case 'Sør':
      //Løs kanter her ala isolasjon senere
      return [
        {
          resourceName: 'Protan SE 1.6 2x20m',
          sku: '31516948',
        },
      ];
    case 'Øst':
      return [
        {
          resourceName: 'Protan SE 1.6 2x20m',
          sku: '31516948',
        },
      ];
    case 'Vest':
      return [
        {
          resourceName: 'Protan SE 1.6 2x20m',
          sku: '31516948',
        },
      ];
  }
  return [];
}

function getSectioning(location: string, energyRequirement: string) {
  if (Number(energyRequirement) > 0.12) {
    return '-0.72';
  }

  return '-0.6';
}

//Må oppdateres til å håndtere eksisterende ordre
function updateExistingShapesWithRelevantSparkelAttributes(
  value: string,
  category: string,
  bulkCreateSparkelProps: any,
  projectId: string,
  orderId: string,
  shapesData: GetSheetShapesForProjectDeepQuery,
  sparkelAttributesData: GetSparkelPropertiesForProjectQuery
) {
  const filteredSheetShapeList = shapesData.project?.sheetShapes
    .filter((sheetShape) => sheetShape.sheetShapePolygon)
    .filter((sheetShape) =>
      sparkelAttributesData.project?.sparkelProperties.find(
        (attribute) =>
          attribute.dbId === sheetShape.dbId &&
          attribute.modelUrn === sheetShape.urn &&
          attribute.propertyValue === orderId
      )
    )
    .map((sheetshape) => {
      return { modelUrn: sheetshape.urn, dbIds: sheetshape.dbId };
    });

  if (value) {
    filteredSheetShapeList?.forEach((sheetShape) => {
      bulkCreateSparkelProps({
        input: {
          projectId,
          dbIds: [sheetShape],
          thePropertySet: category,
          thePropertyValue: value, //getQkastBasedOnParameters(),
        },
      });
    });
  }
}
function getKile(kileType: string) {
  return kilerMap[kileType];
}
