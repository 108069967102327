import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LineSegment2,
  Point2,
  Polygon2,
} from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import {
  areSegmentsEqual,
  calculateRelativePosition,
  generateIdsForPoints,
  getDistanceToSegment,
  getSegmentIntersection,
  isPoint2,
  projectPointOnSegment,
} from '../shapes/util';
import { getUnitScaleFromCalibration } from '../../../domain/sheet-calibration';
import {
  GetSparkelPropertiesForProjectQuery,
  SheetShapeDeepFragment,
} from '../../../gql/graphql';
import { toPoint2 } from '../../../domain/geometry/algorithms/util/polygon';
import { SheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  ShapeDrawingMode,
  DrainLineDrawingResult,
  SlopedInsulationCrossSection,
} from './SheetShapeDrawing';
import { IntermediateEdges } from './SheetPolygonShapeDrawing';
import { SheetPolygon } from './SheetPolygon';

const minHeight = 20;
const slope = 0.125; // 1 / 40;
const offsetFromRoof = 50;

export const SheetDrainLineDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.DrainLine];
  onResult: (result: DrainLineDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [points, setPoints] = useState<Point2[]>(initialState?.points ?? []);
  const [intermediateEdge, setIntermediateEdge] = useState<LineSegment2 | null>(
    null
  );
  const [crossSection, setCrossSection] = useState<Polygon2[] | null>(null);
  const [interMediateCrossSection, setIntermediateCrossSection] = useState<
    Polygon2[] | null
  >(null);
  const {
    calibration: { calibration },
    shapesManager: { renderedSheetShapes },
  } = useSheetViewer();
  const unitScale = useMemo(
    () =>
      calibration ? getUnitScaleFromCalibration(calibration.calibration) : null,
    [calibration]
  );

  const existingPolygonShapes = useMemo(() => {
    return Object.values(renderedSheetShapes).flatMap((shapesForUrn) =>
      shapesForUrn.filter((shape) => shape.sheetShapePolygon !== null)
    );
  }, [renderedSheetShapes]);

  const edges = useMemo(() => {
    const edges: LineSegment2[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      edges.push([points[i], points[i + 1]]);
    }
    return edges;
  }, [points]);

  const emitResult = useCallback(
    (
      segment: LineSegment2,
      crossSection: SlopedInsulationCrossSection | undefined
    ) => {
      if (crossSection) {
        onResult({
          points: segment,
          valid: true,
          crossSection,
        });
      } else {
        onResult({
          points: segment,
          valid: false,
          crossSection,
        });
      }
    },
    [onResult]
  );

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem]
  );

  const getCrossSectionParameters = useCallback(
    (
      perpendicularSegment: LineSegment2,
      lowPoints: Point2[],
      offsetVector: Point2
    ): SlopedInsulationCrossSection | undefined => {
      const baseLine = perpendicularSegment.map(
        (point) =>
          [point[0] - offsetVector[0], point[1] - offsetVector[1]] as Point2
      ) as LineSegment2;

      // Parametrize the low points
      const lowPointParams = lowPoints.map((lowPoint) => {
        return calculateRelativePosition(lowPoint, perpendicularSegment);
      });

      return {
        baseLine,
        slope,
        lowPoints: lowPointParams,
      };
    },
    []
  );

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0 || !unitScale) {
        return;
      }

      const newPoint = getPoint(event);
      const { segment: splitSegment, shape } = getSplitSegmentFromPoint(
        newPoint,
        existingPolygonShapes
      );

      if (splitSegment && shape) {
        setPoints(splitSegment);

        const offsetDirection = [
          newPoint[0] - splitSegment[0][0],
          newPoint[1] - splitSegment[0][1],
        ];
        const offsetDirectionMagnitude = Math.sqrt(
          offsetDirection[0] ** 2 + offsetDirection[1] ** 2
        );

        const perpendicularSegment =
          getPerpendicularSegmentFromPointAndSegmentInShape(
            newPoint,
            splitSegment,
            shape
          );

        if (perpendicularSegment) {
          const crossSectionPolygon = getCrossSection(
            newPoint,
            perpendicularSegment,
            {
              slope,
              minHeight,
              offset: -(offsetDirectionMagnitude + offsetFromRoof),
            }
          );

          setCrossSection(crossSectionPolygon);

          const crossSectionParameters = getCrossSectionParameters(
            perpendicularSegment,
            [projectPointOnSegment(newPoint, perpendicularSegment)],
            offsetDirection as Point2
          );

          emitResult(splitSegment, crossSectionParameters);
        }
      }
    },
    [
      emitResult,
      existingPolygonShapes,
      getCrossSectionParameters,
      getPoint,
      unitScale,
    ]
  );

  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    // eslint-disable-next-line complexity
    (event: MouseEvent) => {
      if (crossSection) {
        setIntermediateEdge(null);
        setIntermediateCrossSection(null);
        return;
      }
      const newPoint = getPoint(event);
      const { segment: splitSegment, shape } = getSplitSegmentFromPoint(
        newPoint,
        existingPolygonShapes
      );

      if (splitSegment && shape) {
        setIntermediateEdge(splitSegment);

        const offsetDirection = [
          newPoint[0] - splitSegment[0][0],
          newPoint[1] - splitSegment[0][1],
        ];
        const offsetDirectionMagnitude = Math.sqrt(
          offsetDirection[0] ** 2 + offsetDirection[1] ** 2
        );

        const perpendicularSegment =
          getPerpendicularSegmentFromPointAndSegmentInShape(
            newPoint,
            splitSegment,
            shape
          );

        if (perpendicularSegment) {
          const crossSectionPolygon = getCrossSection(
            newPoint,
            perpendicularSegment,
            {
              slope,
              minHeight,
              offset: -(offsetDirectionMagnitude + offsetFromRoof),
            }
          );

          setIntermediateCrossSection(crossSectionPolygon);
        }
      }
    },
    [crossSection, existingPolygonShapes, getPoint]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  return (
    <>
      {edges.map((edge, index) => {
        return (
          <SheetEdge
            // todo: index is not a good key. use a unique id
            key={index}
            segment={edge}
            cursor={'pointer'}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
      {crossSection && unitScale ? (
        <SheetPolygon
          multipolygon={{
            polygons: crossSection,
          }}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'green.200'}
          stroke={'green.400'}
        />
      ) : null}
      {intermediateEdge && unitScale
        ? IntermediateEdges(
            intermediateEdge[0],
            generateIdsForPoints([intermediateEdge[1]]),
            false,
            'orange.300',
            getPointInDomCoordinateSystem
          )
        : null}
      {interMediateCrossSection && unitScale ? (
        <SheetPolygon
          multipolygon={{
            polygons: interMediateCrossSection,
          }}
          getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          fill={'orange.200'}
          stroke={'orange.400'}
        />
      ) : null}
    </>
  );
};

function getClosestEdge(
  point: Point2,
  edges: LineSegment2[]
): LineSegment2 | null {
  let closestEdge: LineSegment2 | null = null;
  let minDistance = Infinity;

  edges.forEach((edge) => {
    const distance = getDistanceToSegment(point, edge);
    if (distance < minDistance) {
      minDistance = distance;
      closestEdge = edge;
    }
  });

  return closestEdge;
}

// eslint-disable-next-line complexity
function getSplitSegmentFromPoint(
  point: Point2,
  shapes: SheetShapeDeepFragment[]
): { segment: LineSegment2 | null; shape: SheetShapeDeepFragment | null } {
  const candidates: [LineSegment2, SheetShapeDeepFragment][] = [];
  for (const shape of shapes) {
    const polygons = shape.sheetShapePolygon?.multipolygon.polygons;
    if (!polygons) {
      continue;
    }
    for (const polygon of polygons) {
      const edges = polygon.exterior.points.map(
        (point, index) =>
          [
            toPoint2(point),
            toPoint2(
              polygon.exterior.points[
                (index + 1) % polygon.exterior.points.length
              ]
            ),
          ] as LineSegment2
      );

      const closestEdgeForThisPolygon = getClosestEdge(point, edges);

      if (closestEdgeForThisPolygon) {
        candidates.push([closestEdgeForThisPolygon, shape]);
        break;
      }
    }
  }

  const [closestEdge, closestShape] = candidates.reduce((prev, curr) =>
    getDistanceToSegment(point, curr[0]) < getDistanceToSegment(point, prev[0])
      ? curr
      : prev
  );

  if (closestEdge && closestShape) {
    const projectedPoint = projectPointOnSegment(point, closestEdge);

    const directionVector: Point2 = [
      point[0] - projectedPoint[0],
      point[1] - projectedPoint[1],
    ];

    const magnitude = Math.sqrt(
      directionVector[0] ** 2 + directionVector[1] ** 2
    );
    const normalizedDirection: Point2 = [
      directionVector[0] / magnitude,
      directionVector[1] / magnitude,
    ];

    const extensionMultiplier = 10000;

    const extendedPoint: Point2 = [
      projectedPoint[0] + normalizedDirection[0] * extensionMultiplier,
      projectedPoint[1] + normalizedDirection[1] * extensionMultiplier,
    ];

    let intersectionPoint: Point2 | null = null;

    const polygonEdges =
      closestShape.sheetShapePolygon?.multipolygon.polygons.flatMap(
        (polygon) => {
          const edges = polygon.exterior.points.map(
            (point, index) =>
              [
                toPoint2(point),
                toPoint2(
                  polygon.exterior.points[
                    (index + 1) % polygon.exterior.points.length
                  ]
                ),
              ] as LineSegment2
          );
          return edges;
        }
      ) || [];

    for (const edge of polygonEdges) {
      if (!areSegmentsEqual(edge, closestEdge, 0.01)) {
        const potentialIntersection = getSegmentIntersection(
          [projectedPoint, extendedPoint],
          edge
        );
        if (isPoint2(potentialIntersection)) {
          intersectionPoint = potentialIntersection as Point2;
          break;
        }
      }
    }

    if (intersectionPoint) {
      const edge = [projectedPoint, intersectionPoint] as LineSegment2;
      return { segment: edge, shape: closestShape };
    }

    return { segment: null, shape: null };
  }

  return { segment: null, shape: null };
}

function getPerpendicularSegmentFromPointAndSegmentInShape(
  point: Point2,
  segment: LineSegment2,
  shape: SheetShapeDeepFragment
): LineSegment2 | null {
  const directionVector: Point2 = [
    segment[1][0] - segment[0][0],
    segment[1][1] - segment[0][1],
  ];

  const normalVector: Point2 = [-directionVector[1], directionVector[0]];
  const magnitude = Math.sqrt(normalVector[0] ** 2 + normalVector[1] ** 2);
  const normalizedNormalVector: Point2 = [
    normalVector[0] / magnitude,
    normalVector[1] / magnitude,
  ];

  const extensionMultiplier = 10000;

  const extendedPoint1: Point2 = [
    point[0] + normalizedNormalVector[0] * extensionMultiplier,
    point[1] + normalizedNormalVector[1] * extensionMultiplier,
  ];

  const extendedPoint2: Point2 = [
    point[0] - normalizedNormalVector[0] * extensionMultiplier,
    point[1] - normalizedNormalVector[1] * extensionMultiplier,
  ];

  let intersectionPoint1: Point2 | null = null;
  let intersectionPoint2: Point2 | null = null;

  const polygonEdges =
    shape.sheetShapePolygon?.multipolygon.polygons.flatMap((polygon) => {
      const edges = polygon.exterior.points.map(
        (point, index) =>
          [
            toPoint2(point),
            toPoint2(
              polygon.exterior.points[
                (index + 1) % polygon.exterior.points.length
              ]
            ),
          ] as LineSegment2
      );
      return edges;
    }) || [];

  for (const edge of polygonEdges) {
    if (!areSegmentsEqual(edge, segment, 0.01)) {
      const potentialIntersection1 = getSegmentIntersection(
        [point, extendedPoint1],
        edge
      );
      if (isPoint2(potentialIntersection1)) {
        intersectionPoint1 = potentialIntersection1 as Point2;
      }

      const potentialIntersection2 = getSegmentIntersection(
        [point, extendedPoint2],
        edge
      );
      if (isPoint2(potentialIntersection2)) {
        intersectionPoint2 = potentialIntersection2 as Point2;
      }
    }
  }

  if (intersectionPoint1 && intersectionPoint2) {
    return [intersectionPoint1, intersectionPoint2];
  }

  return null;
}

type CrossSectionOptions = {
  slope: number;
  minHeight: number;
  offset: number;
};

function getCrossSection(
  point: Point2,
  line: LineSegment2,
  options: CrossSectionOptions
): Polygon2[] {
  const { slope, minHeight } = options;

  const [p1, p2] = line;

  // Calculate the vector along the line
  const directionVector: Point2 = [p2[0] - p1[0], p2[1] - p1[1]];
  const magnitude = Math.sqrt(
    directionVector[0] ** 2 + directionVector[1] ** 2
  );

  // Normalize the line vector
  const normalizedVector: Point2 = [
    directionVector[0] / magnitude,
    directionVector[1] / magnitude,
  ];

  // Perpendicular vector (rotated 90 degrees)
  const normalVector: Point2 = [-normalizedVector[1], normalizedVector[0]];

  // Calculate the height at the given point
  const heightAtPoint = minHeight;

  // Calculate the lengths of the two sides of the trapezoid
  const length1 = Math.sqrt((p1[0] - point[0]) ** 2 + (p1[1] - point[1]) ** 2);
  const length2 = Math.sqrt((p2[0] - point[0]) ** 2 + (p2[1] - point[1]) ** 2);

  // Calculate the height at each endpoint based on the slope
  const heightAtP1 = slope * length1 + heightAtPoint;
  const heightAtP2 = slope * length2 + heightAtPoint;

  // Create the two polygons (trapezoids)
  const polygon1: Polygon2 = {
    exterior: [
      [
        point[0] + normalVector[0] * heightAtPoint,
        point[1] + normalVector[1] * heightAtPoint,
      ],
      [point[0], point[1]],
      [p1[0], p1[1]],
      [
        p1[0] + normalVector[0] * heightAtP1,
        p1[1] + normalVector[1] * heightAtP1,
      ],
      [
        point[0] + normalVector[0] * heightAtPoint,
        point[1] + normalVector[1] * heightAtPoint,
      ],
    ],
    interiors: [],
  };

  const polygon2: Polygon2 = {
    exterior: [
      [
        point[0] + normalVector[0] * heightAtPoint,
        point[1] + normalVector[1] * heightAtPoint,
      ],
      [point[0], point[1]],
      [p2[0], p2[1]],
      [
        p2[0] + normalVector[0] * heightAtP2,
        p2[1] + normalVector[1] * heightAtP2,
      ],
      [
        point[0] + normalVector[0] * heightAtPoint,
        point[1] + normalVector[1] * heightAtPoint,
      ],
    ],
    interiors: [],
  };

  const maxHeight = Math.max(heightAtP1, heightAtP2);

  polygon1.exterior.forEach((point) => {
    point[0] += normalVector[0] * (options.offset - maxHeight);
    point[1] += normalVector[1] * (options.offset - maxHeight);
  });

  polygon2.exterior.forEach((point) => {
    point[0] += normalVector[0] * (options.offset - maxHeight);
    point[1] += normalVector[1] * (options.offset - maxHeight);
  });

  return [polygon1, polygon2];
}

export const getCrossSectionFromProperties = (
  propertyData: GetSparkelPropertiesForProjectQuery | undefined,
  dbId: number,
  urn: string
) => {
  const crossSectionProperty = propertyData?.project?.sparkelProperties.find(
    (prop) =>
      prop.propertySet === 'CrossSection' &&
      prop.dbId === dbId &&
      prop.modelUrn === urn
  );

  if (!crossSectionProperty) {
    return null;
  }

  const crossSectionJsonString = crossSectionProperty.propertyValue;

  if (!crossSectionJsonString) {
    return null;
  }

  return JSON.parse(crossSectionJsonString) as SlopedInsulationCrossSection;
};
