import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalOverlay,
  Spacer,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useFlag } from '@unleash/proxy-client-react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';
import {
  DuplicateProjectDocument,
  GetProjectPageDocument,
  Project,
} from '../../gql/graphql';
import { useUserTenant } from '../../services/auth-info';
import { Buy } from '../project/project-modal/Buy';
import { tierLimits } from '../../utils/tier-utils';

type CloneProjectTabProps = {
  project: Pick<Project, 'id'>;
  closeModal: () => void;
};

type FormValues = {
  projectName: string;
};

export default function CloneProjectTab({
  project,
  closeModal,
}: CloneProjectTabProps): React.ReactElement {
  const toast = useToast();

  const {
    isOpen: buyModalIsOpen,
    onOpen: openBuyModal,
    onClose: closeBuyModal,
  } = useDisclosure();
  const [{ data: listProjectsData, fetching, error }] = useQuery({
    query: GetProjectPageDocument,
    variables: {
      first: 3, // only need to check that there exists 3 projects
    },
  });

  const numberOfActiveProjects =
    listProjectsData?.projectsConnection?.nodes.length;

  const { tenantTier } = useUserTenant();
  const enablePayment = useFlag('enable-payments');

  const maxProjectsReached =
    tenantTier === 'FREE' &&
    enablePayment &&
    numberOfActiveProjects &&
    numberOfActiveProjects >= tierLimits.free.MAX_PROJECTS;

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<FormValues>({ defaultValues: { projectName: '' } });

  const [, cloneProject] = useMutation(DuplicateProjectDocument);
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async ({ projectName }) => {
    if (maxProjectsReached) {
      openBuyModal();
      return;
    }
    const result = await cloneProject({
      input: {
        projectId: project.id,
        newProjectName: projectName,
      },
    });
    if (result.error) {
      toast({
        title: 'An error occurred when cloning project',
        status: 'error',
      });
    } else {
      toast({
        title: 'Project successfully cloned',
        status: 'success',
      });
      navigate(
        `/project/${result.data?.duplicateProject?.project?.id}/takeoff`
      );
      closeModal();
    }
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <FormControl isRequired>
          <FormLabel>Project name</FormLabel>
          <Input
            type="text"
            {...register('projectName')}
            placeholder={'Project name'}
          ></Input>
        </FormControl>
        <HStack>
          <Spacer />
          <Button
            variant={'outline'}
            type="submit"
            alignSelf={'end'}
            isLoading={isSubmitting}
            mt={2}
          >
            Clone project
          </Button>
        </HStack>
      </form>
      <Modal
        isOpen={buyModalIsOpen}
        onClose={closeBuyModal}
        isCentered
        size={'full'}
      >
        <ModalOverlay />

        <Buy
          title="Maximum Project Limit Reached"
          subtitle="Delete existing projects or upgrade your plan to create more projects."
          onClose={closeBuyModal}
        />
      </Modal>
    </>
  );
}
