import { Box, BoxProps } from '@chakra-ui/react';
import { useDrag } from '@use-gesture/react';
import { useMemo } from 'react';
import { Point2 } from '../../../domain/geometry/geometric-types';

type VertexProps = BoxProps & {
  point: Point2;
  theme?: 'default' | 'inverted';
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
};
export const SheetVertex = ({
  point,
  theme = 'default',
  getPointInDomCoordinateSystem,
  ...boxProps
}: VertexProps) => {
  // Convert from PDF coordinate system to DOM coordinate system
  const pointInDom = useMemo(
    () => getPointInDomCoordinateSystem(point),
    [getPointInDomCoordinateSystem, point]
  );

  return (
    <Box
      position="absolute"
      zIndex={2}
      // Placed in style for performance reasons
      style={{
        left: pointInDom[0] - 5,
        top: pointInDom[1] - 5,
      }}
      width="10px"
      height="10px"
      borderRadius={'50%'}
      {...(theme === 'inverted'
        ? { borderColor: 'white', backgroundColor: 'cyan.500' }
        : { borderColor: 'cyan.500', backgroundColor: 'white' })}
      borderWidth={'2px'}
      {...boxProps}
    ></Box>
  );
};

type DraggableVertexProps = VertexProps & {
  onVertexDrag?: (event: MouseEvent) => void;
};

export const DraggableVertex = ({
  onVertexDrag,
  ...rest
}: DraggableVertexProps) => {
  const bindDrag = useDrag<MouseEvent>(
    (state) => {
      if (onVertexDrag) {
        onVertexDrag(state.event);
      }
    },
    {
      filterTaps: true,
      delay: true,
    }
  );
  return (
    <SheetVertex
      cursor={'move'}
      _hover={{
        filter: 'brightness(1.3)',
      }}
      {...bindDrag()}
      {...rest}
    />
  );
};
