import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import {
  CreateIssueCommentMutation,
  CreateIssueCommentMutationVariables,
  CreateIssueMutation,
  CreateIssueMutationVariables,
  DeleteIssueMutation,
} from '../../gql/graphql';

export const createIssueResolver: UpdateResolver = (
  result: CreateIssueMutation,
  args: CreateIssueMutationVariables,
  cache
) => {
  if (result.createIssue?.issue) {
    const issuesForProject = cache.resolve(
      { __typename: 'Project', id: result.createIssue.issue.projectId },
      'issues'
    ) as string[] | null;
    const issueToAdd = cache.keyOfEntity({
      __typename: 'Issue',
      id: result.createIssue.issue.id,
    });
    if (issuesForProject && issueToAdd) {
      issuesForProject.push(issueToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createIssue.issue.projectId,
        },
        'issues',
        issuesForProject
      );
    }
  }
};

export const deleteIssueResolver: UpdateResolver = (
  result: DeleteIssueMutation,
  args: CreateIssueMutationVariables,
  cache
) => {
  if (result.deleteIssue?.issue) {
    const issuesForProject = cache.resolve(
      { __typename: 'Project', id: result.deleteIssue.issue.projectId },
      'issues'
    ) as string[] | null;
    const issueToRemove = cache.keyOfEntity({
      __typename: 'Issue',
      id: result.deleteIssue.issue.id,
    });
    if (issuesForProject && issueToRemove) {
      const updatedIssues = issuesForProject.filter(
        (issue) => issue !== issueToRemove
      );
      cache.link(
        {
          __typename: 'Project',
          id: result.deleteIssue.issue.projectId,
        },
        'issues',
        updatedIssues
      );
    }
  }
};

export const createIssueCommentResolver: UpdateResolver = (
  result: CreateIssueCommentMutation,
  args: CreateIssueCommentMutationVariables,
  cache
) => {
  if (result.createIssueComment?.issueComment) {
    const issueId = result.createIssueComment.issueComment.issueId;
    const issueKey = {
      __typename: 'Issue',
      id: issueId,
    };

    // Update the issueComments list in the cache
    const issueComments = cache.resolve(issueKey, 'issueComments') as
      | string[]
      | null;
    const commentToAdd = cache.keyOfEntity({
      __typename: 'IssueComment',
      id: result.createIssueComment.issueComment.id,
    });
    if (issueComments && commentToAdd) {
      cache.link(issueKey, 'issueComments', [...issueComments, commentToAdd]);
    }

    // Update the commentCount in the cache
    const commentCount = cache.resolve(issueKey, 'commentCount') as
      | number
      | null;
    if (commentCount !== null) {
      const updateCommentCountFragment = gql`
        fragment _ on Issue {
          commentCount
        }
      `;
      cache.writeFragment(updateCommentCountFragment, {
        __typename: 'Issue',
        id: issueId,
        commentCount: commentCount + 1,
      });
    }
  }
};
