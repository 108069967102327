import { useEffect } from 'react';
import { isNil } from 'lodash';

export const useDocumentTitle = (title: string | undefined | null) => {
  useEffect(() => {
    if (!isNil(title)) {
      document.title = `Sparkel | ${title}`;
    }
  }, [title]);
};
