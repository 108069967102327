import { union, without } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DbIdsPerModel } from '../../components/common/ForgeViewer';

export type SheetsVisibilityManager = {
  isolatedElements: DbIdsPerModel;
  setIsolatedElements: (dbIdsPerModel: DbIdsPerModel) => void;
  addToIsolation: (elements: DbIdsPerModel) => void;
  hiddenElements: DbIdsPerModel;
  setHiddenElements: (dbIdsPerModel: DbIdsPerModel) => void;
  // Adds the specified elements to the list of hidden elements
  hide: (elements: DbIdsPerModel) => void;
  // Removes the specified elements from the list of hidden elements
  show: (elements: DbIdsPerModel) => void;
  hiddenSheetShapes: DbIdsPerModel;
  hideSheetShape: (urn: string, dbId: number) => void;
  showSheetShape: (urn: string, dbId: number) => void;
};

/**
 * Hook for managing visibility of elements in the Sheet viewer.
 * If you want visibility for all elements, including sheet elements, consider [visibility.ts](../../services/viewer/visibility.ts)
 */
export const useSheetsVisibility = (): SheetsVisibilityManager => {
  const [isolatedElements, setIsolatedElements] = useState<DbIdsPerModel>({});
  const [hiddenElements, setHiddenElements] = useState<DbIdsPerModel>({});
  const [hiddenSheetShapes, setHiddenSheetShapes] = useState<DbIdsPerModel>({});

  const showSheetShape = useCallback((shapeUrn: string, dbId: number) => {
    setHiddenSheetShapes((hiddenSheetShapes) => {
      if (shapeUrn in hiddenSheetShapes) {
        return {
          ...hiddenSheetShapes,
          [shapeUrn]: without(hiddenSheetShapes[shapeUrn], dbId),
        };
      }
      return hiddenSheetShapes;
    });
  }, []);

  const hideSheetShape = useCallback((shapeUrn: string, dbId: number) => {
    setHiddenSheetShapes((hiddenSheetShapes) => {
      if (shapeUrn in hiddenSheetShapes) {
        return {
          ...hiddenSheetShapes,
          [shapeUrn]: union(hiddenSheetShapes[shapeUrn], [dbId]),
        };
      } else {
        return { ...hiddenSheetShapes, [shapeUrn]: [dbId] };
      }
    });
  }, []);

  const hide = useCallback((elements: DbIdsPerModel) => {
    setHiddenElements((hiddenElements) => {
      const result = { ...hiddenElements };
      for (const [urn, dbIds] of Object.entries(elements)) {
        if (urn in hiddenElements) {
          result[urn] = union(hiddenElements[urn], dbIds);
        } else {
          result[urn] = dbIds;
        }
      }
      return result;
    });
  }, []);

  // Removes the specified elements from the list of hidden elements
  const show = useCallback((elements: DbIdsPerModel) => {
    setHiddenElements((hiddenElements) => {
      const result = { ...hiddenElements };
      for (const [urn, dbIds] of Object.entries(elements)) {
        if (urn in hiddenElements) {
          result[urn] = without(hiddenElements[urn], ...dbIds);
        }
      }
      return result;
    });
  }, []);

  const addToIsolation = useCallback((elements: DbIdsPerModel) => {
    setIsolatedElements((isolatedElements) => {
      const result = { ...isolatedElements };
      for (const [urn, dbIds] of Object.entries(elements)) {
        if (urn in isolatedElements) {
          result[urn] = union(isolatedElements[urn], dbIds);
        } else {
          result[urn] = dbIds;
        }
      }
      return result;
    });
  }, []);

  return useMemo(
    () => ({
      isolatedElements,
      setIsolatedElements,
      hiddenElements,
      setHiddenElements,
      hiddenSheetShapes,
      hideSheetShape,
      showSheetShape,
      addToIsolation,
      hide,
      show,
    }),
    [
      addToIsolation,
      hiddenElements,
      hiddenSheetShapes,
      hideSheetShape,
      isolatedElements,
      showSheetShape,
      hide,
      show,
    ]
  );
};
