import earcut from 'earcut';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import { Point, Triangle } from '../../geometric-types';
import {
  getPointInPlane,
  bestFitPlane,
  rotationMatrixPlaneToXY,
} from './plane';

type HalfEdgeGraph = {
  halfEdges: HalfEdge[];
  vertices: Vertex[];
  faces: Face[];
};

type HalfEdge = {
  vertex: Vertex; // vertex at the start of the half-edge
  next: HalfEdge; // next half-edge around the face
  prev: HalfEdge; // previous half-edge around the face
  pair: HalfEdge; // opposite half-edge
  face: Face | null; // null if boundary edge
};

type Vertex = {
  point: Point; // coordinates of the vertex
  edge: HalfEdge; // one of the half-edges that starts at this vertex
};

type Face = {
  halfEdge: HalfEdge; // one of the half-edges that bounds this face
};

function equals(p1: Point, p2: Point): boolean {
  return p1[0] === p2[0] && p1[1] === p2[1] && p1[2] === p2[2];
}

type IntermediateVertex = {
  point: Point;
  edge: IntermediateHalfEdge | null;
};

type IntermediateHalfEdge = {
  vertex: IntermediateVertex;
  next: IntermediateHalfEdge | null;
  prev: IntermediateHalfEdge | null;
  pair: IntermediateHalfEdge | null;
  face: IntermediateFace | null;
};

type IntermediateFace = {
  halfEdge: IntermediateHalfEdge | null;
};

type IntermediateHalfEdgeGraph = {
  halfEdges: IntermediateHalfEdge[];
  vertices: IntermediateVertex[];
  faces: IntermediateFace[];
};

function isHalfEdgeGraph(
  intermediateHalfEdgeGraph: IntermediateHalfEdgeGraph
): intermediateHalfEdgeGraph is HalfEdgeGraph {
  if (intermediateHalfEdgeGraph.faces.find((f) => !f.halfEdge)) {
    return false;
  }
  if (
    intermediateHalfEdgeGraph.halfEdges.find(
      (he) => !he.vertex || !he.next || !he.prev || !he.pair
    )
  ) {
    return false;
  }
  if (
    intermediateHalfEdgeGraph.vertices.find(
      (v) => !v.point || v.edge === undefined
    )
  ) {
    return false;
  }
  return true;
}

export function buildHalfEdgeGraph(triangles: Triangle[]): HalfEdgeGraph {
  const halfEdges: IntermediateHalfEdge[] = [];
  const vertices: IntermediateVertex[] = [];
  const faces: IntermediateFace[] = [];

  for (const triangle of triangles) {
    const face: IntermediateFace = { halfEdge: null };
    faces.push(face);

    let halfEdgePrev: IntermediateHalfEdge | null = null;
    let halfEdgeFirst: IntermediateHalfEdge | null = null;

    for (const point of triangle) {
      const vertex: IntermediateVertex = vertices.find((v) =>
        equals(v.point, point)
      ) || {
        point: point,
        edge: null,
      };
      if (!vertices.includes(vertex)) vertices.push(vertex);

      const halfEdge: IntermediateHalfEdge = {
        vertex: vertex,
        next: null,
        prev: halfEdgePrev,
        pair: null,
        face: face,
      };
      halfEdges.push(halfEdge);

      if (halfEdgePrev) halfEdgePrev.next = halfEdge;
      else halfEdgeFirst = halfEdge;

      halfEdgePrev = halfEdge;
      vertex.edge = halfEdge;
    }

    if (halfEdgePrev) halfEdgePrev.next = halfEdgeFirst;
    if (halfEdgeFirst) halfEdgeFirst.prev = halfEdgePrev;
    face.halfEdge = halfEdgeFirst;
  }

  for (const halfEdge of halfEdges) {
    const vertexPair = halfEdge.prev?.vertex;
    const halfEdgePair = halfEdges.find(
      (he) => he.vertex === vertexPair && he.prev?.vertex === halfEdge.vertex
    );
    if (halfEdgePair) {
      halfEdge.pair = halfEdgePair;
    } else {
      // construct a boundary edge
      const boundaryHalfEdge: IntermediateHalfEdge = {
        vertex: halfEdge.vertex,
        next: halfEdge.prev,
        prev: halfEdge.next,
        pair: halfEdge,
        face: null,
      };
      halfEdge.pair = boundaryHalfEdge;
      halfEdges.push(boundaryHalfEdge);
    }
  }

  const graph: IntermediateHalfEdgeGraph = {
    halfEdges: halfEdges,
    vertices: vertices,
    faces: faces,
  };
  invariant(isHalfEdgeGraph(graph), 'Could not create a valid half-edge graph');
  return graph;
}

type Segment = {
  faceIndices: number[];
};

function calculateAngle(unitNormal1: Point, unitNormal2: Point): number {
  // take the real part of the angle to avoid complex numbers due to rounding errors
  return math.re(
    math.acos(
      unitNormal1[0] * unitNormal2[0] +
        unitNormal1[1] * unitNormal2[1] +
        unitNormal1[2] * unitNormal2[2]
    )
  ) as number;
}

// Assumes that the graph is a triangle mesh
export function getTriangleForFace(face: Face): Triangle {
  return [
    face.halfEdge.vertex.point,
    face.halfEdge.next.vertex.point,
    face.halfEdge.next.next.vertex.point,
  ];
}

function calculateFaceUnitNormals(halfEdgeGraph: HalfEdgeGraph): Point[] {
  const faceNormals: Point[] = [];

  halfEdgeGraph.faces.forEach((face) => {
    const halfEdge = face.halfEdge;
    const v1 = math.subtract(halfEdge.next.vertex.point, halfEdge.vertex.point);
    const v2 = math.subtract(
      halfEdge.next.next.vertex.point,
      halfEdge.next.vertex.point
    );
    const normal = math.cross(v1, v2) as Point;
    const unitNormal = math.divide(normal, math.norm(normal)) as Point;
    faceNormals.push(unitNormal);
  });

  return faceNormals;
}

// Segments a half edge graph into connected components based on the angle between adjacent faces
export function segmentByAngle(
  halfEdgeGraph: HalfEdgeGraph,
  angleLimit: number
): Segment[] {
  const faceNormals: Point[] = calculateFaceUnitNormals(halfEdgeGraph);
  const visitedFaces: boolean[] = new Array(halfEdgeGraph.faces.length).fill(
    false
  );
  const segments: Segment[] = [];

  for (let faceIdx = 0; faceIdx < halfEdgeGraph.faces.length; faceIdx++) {
    if (visitedFaces[faceIdx]) continue;

    visitedFaces[faceIdx] = true;
    const segmentFaceIndices: number[] = [faceIdx];
    const queue: number[] = [faceIdx];

    while (queue.length > 0) {
      const currentFaceIdx = queue.pop() as number;
      const currentFace = halfEdgeGraph.faces[currentFaceIdx];

      // Iterate over the neighbors of the current face
      let halfEdge = currentFace.halfEdge;
      do {
        if (!halfEdge.pair.face) {
          halfEdge = halfEdge.next;
          continue;
        }
        const pairFace = halfEdge.pair.face;
        halfEdge = halfEdge.next;
        const adjacentFaceIdx = halfEdgeGraph.faces.indexOf(pairFace);

        if (visitedFaces[adjacentFaceIdx]) continue;

        const angle = calculateAngle(
          faceNormals[currentFaceIdx],
          faceNormals[adjacentFaceIdx]
        );
        if (math.abs(angle) < angleLimit) {
          queue.push(adjacentFaceIdx);
          segmentFaceIndices.push(adjacentFaceIdx);
          visitedFaces[adjacentFaceIdx] = true;
        }
      } while (halfEdge !== currentFace.halfEdge);

      visitedFaces[currentFaceIdx] = true;
    }

    segments.push({
      faceIndices: segmentFaceIndices,
    });
  }

  return segments;
}

type Border = {
  halfEdgeIndices: number[];
};

export function findSegmentBorders(
  halfEdgeGraph: HalfEdgeGraph,
  segmentFaceIndices: number[] // the indices of faces to find borders of
): Border[] {
  const visitedHalfEdges: boolean[] = new Array(
    halfEdgeGraph.halfEdges.length
  ).fill(false);
  const borders: Border[] = [];

  for (const faceIdx of segmentFaceIndices) {
    const face = halfEdgeGraph.faces[faceIdx];
    let halfEdge = face.halfEdge;
    do {
      if (visitedHalfEdges[halfEdgeGraph.halfEdges.indexOf(halfEdge)]) {
        halfEdge = halfEdge.next;
        continue;
      }

      if (isHalfEdgeBorder(halfEdgeGraph, segmentFaceIndices, halfEdge)) {
        let currentHalfEdge = halfEdge;
        let borderHalfEdges: HalfEdge[] = [];

        // From the discovered border we iterate out to neighbouring halfedges that are borders
        // When we reencounter a vertex we've found a border we find its points and mark it as visited
        do {
          borderHalfEdges.push(currentHalfEdge);

          // Next part of the border is one of the other halfedges to the same vertex
          const nextHalfEdge = nextBorderHalfedge(
            halfEdgeGraph,
            segmentFaceIndices,
            currentHalfEdge
          );
          currentHalfEdge = nextHalfEdge;

          // Until we reencounter a vertex within borderHalfEdges
        } while (!borderHalfEdges.includes(currentHalfEdge));

        // Mark the borderHalfEdges as visited and return points
        let borderIndices: number[] = [];
        for (const halfEdge of borderHalfEdges) {
          borderIndices.push(halfEdgeGraph.halfEdges.indexOf(halfEdge));
          visitedHalfEdges[halfEdgeGraph.halfEdges.indexOf(halfEdge)] = true;
          if (halfEdge.pair) {
            visitedHalfEdges[halfEdgeGraph.halfEdges.indexOf(halfEdge.pair)] =
              true;
          }
        }
        borders.push({ halfEdgeIndices: borderIndices });
      }
      visitedHalfEdges[halfEdgeGraph.halfEdges.indexOf(halfEdge)] = true;
      visitedHalfEdges[halfEdgeGraph.halfEdges.indexOf(halfEdge.pair)] = true;
      halfEdge = halfEdge.next;
    } while (halfEdge !== face.halfEdge);
  }

  return borders;
}

function isHalfEdgeBorder(
  halfEdgeGraph: HalfEdgeGraph,
  faceIndices: number[],
  halfEdge: HalfEdge
): boolean {
  const faceIdx = halfEdge.face
    ? halfEdgeGraph.faces.indexOf(halfEdge.face)
    : null;
  const oppositeFaceIdx = halfEdge.pair.face
    ? halfEdgeGraph.faces.indexOf(halfEdge.pair.face)
    : null;
  const faceInSegment = faceIdx ? faceIndices.includes(faceIdx) : false;
  const oppositeFaceInSegment = oppositeFaceIdx
    ? faceIndices.includes(oppositeFaceIdx)
    : false;

  return faceInSegment !== oppositeFaceInSegment;
}

function nextBorderHalfedge(
  halfEdgeGraph: HalfEdgeGraph,
  faceIndices: number[],
  halfEdge: HalfEdge
): HalfEdge {
  const startHalfEdge = halfEdge.next;
  let searchHalfEdge = startHalfEdge;
  let iterCount = 0;

  do {
    iterCount++;
    if (iterCount > halfEdgeGraph.halfEdges.length) {
      throw new Error(
        'Infinite loop in nextBorderHalfedge, bad halfedge graph'
      );
    }
    if (
      searchHalfEdge.next.vertex !== halfEdge.vertex &&
      isHalfEdgeBorder(halfEdgeGraph, faceIndices, searchHalfEdge)
    ) {
      return searchHalfEdge;
    }
    searchHalfEdge = searchHalfEdge.pair.next;
  } while (searchHalfEdge !== startHalfEdge);
  throw new Error('No border halfedge found');
}

// Create a triangulation of the surface contained by the border of a segment
// Use 2d triangulation to find a surface because 3d triangulation is harder to implement
// This results in a flat hole which is fine for now because most borders are flat
export const triangulateBorder = (graph: HalfEdgeGraph, border: Border) => {
  const borderPoints = border.halfEdgeIndices.map(
    (i) => graph.halfEdges[i].vertex.point
  );

  const plane = bestFitPlane(borderPoints);
  if (!plane) {
    console.warn('Could not find plane for triangulation');
    return [];
  }

  const rotationMatrix = rotationMatrixPlaneToXY(plane?.unitNormal);
  const rotationArm = getPointInPlane(plane);

  const borderPointsXY = borderPoints
    .map((point) => math.subtract(point, rotationArm))
    .map((point) => math.multiply(rotationMatrix, point))
    .map((point) => math.add(point, rotationArm)) as Point[];

  const triangulatedBorderIndices = earcut(borderPointsXY.flat(), undefined, 3);
  const triangulatedBorderXY: Triangle[] = [];
  for (let i = 0; i < triangulatedBorderIndices.length; i += 3) {
    const a = borderPointsXY[triangulatedBorderIndices[i]];
    const b = borderPointsXY[triangulatedBorderIndices[i + 1]];
    const c = borderPointsXY[triangulatedBorderIndices[i + 2]];
    const triangle: Triangle = [a, b, c];
    triangulatedBorderXY.push(triangle);
  }

  const invRotation = math.transpose(rotationMatrix);

  const triangulatedBorder: Triangle[] = triangulatedBorderXY.map(
    (triangle) =>
      triangle
        .map((point) => math.subtract(point, rotationArm))
        .map((point) => math.multiply(invRotation, point))
        .map((point) => math.add(point, rotationArm)) as Triangle
  );

  return triangulatedBorder;
};
