import { IconButton, Tooltip } from '@chakra-ui/react';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import SparkelIcon from '../common/icon/SparkelIcon';

export const ToggleShapeVisibilityButton = ({
  isHidden,
  hideShape,
  showShape,
}: {
  isHidden: boolean;
  hideShape: () => void;
  showShape: () => void;
}) => {
  const { t } = useTranslation('project');
  if (isHidden) {
    return (
      <Tooltip label={t('shapes.show-shape')}>
        <IconButton
          colorScheme="gray"
          variant="ghost"
          textColor="gray.400"
          size="xs"
          aria-label="show shape"
          icon={<SparkelIcon color="gray.400" icon={faEyeSlash} />}
          onClick={showShape}
        />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip label={t('shapes.hide-shape')}>
        <IconButton
          colorScheme="gray"
          variant="ghost"
          aria-label="hide shape"
          size="xs"
          icon={<SparkelIcon icon={faEye} />}
          onClick={hideShape}
        />
      </Tooltip>
    );
  }
};
