import {
  FormLabel,
  IconButton,
  Input,
  useToast,
  HStack,
  VStack,
} from '@chakra-ui/react';

import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'urql';
import { faCheck, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ChakraStylesConfig, Select } from 'chakra-react-select';
import {
  GetProjectTagsDocument,
  Project,
  ProjectTagDeepFragment,
  UpdateProjectDocument,
} from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';

type GeneralSettingsTabProps = {
  project: Pick<Project, 'id' | 'name' | 'settings' | 'tags'>;
};

export default function GeneralSettingsTab({
  project,
}: GeneralSettingsTabProps): React.ReactElement {
  const [{ data: projectTagsData }] = useQuery({
    query: GetProjectTagsDocument,
  });

  const [isEditingProjectName, setIsEditingProjectName] = useState(false);

  const { register, handleSubmit, reset } = useForm<{ name: string }>({
    defaultValues: { name: project?.name },
  });

  const allTags = useMemo(
    () => projectTagsData?.projectTagsConnection?.nodes || [],
    [projectTagsData?.projectTagsConnection?.nodes]
  );

  const projectTags = useMemo(() => {
    if (!project.tags || !allTags) {
      return [];
    }

    const projectTags: ProjectTagDeepFragment[] = [];

    project.tags.forEach((tagId) => {
      const tag = allTags.find((tag) => tag.id === tagId);
      if (tag) {
        projectTags.push(tag);
      }
    });

    return projectTags;
  }, [allTags, project.tags]);

  const toast = useToast();

  const [, updateProject] = useMutation(UpdateProjectDocument);

  const onRenameProjectSubmit = handleSubmit(async ({ name }) => {
    if (project && name !== project.name) {
      const result = await updateProject({
        input: { id: project.id, patch: { name } },
      });

      if (result.error) {
        toast({
          title: 'An error occurred when renaming project',
          status: 'error',
        });
        throw Error();
      } else {
        toast({
          title: 'Project successfully renamed',
          status: 'success',
        });
        setIsEditingProjectName(false);
      }
    } else {
      setIsEditingProjectName(false);
    }
  });

  const handleTagChange = useCallback(
    async (tagIds: string[]) => {
      const result = await updateProject({
        input: {
          id: project.id,
          patch: {
            tags: tagIds,
          },
        },
      });

      if (result.error) {
        toast({
          title: 'An error occurred when updating project tags',
          status: 'error',
        });
        throw Error();
      }
    },
    [project.id, toast, updateProject]
  );

  const chakraStyles: ChakraStylesConfig = {
    control: (provided) => {
      return {
        ...provided,
        borderRadius: 'base',
        minHeight: 6,
        overflowY: 'auto',
        width: 400,
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        paddingLeft: 2,
        fontSize: 'md',
        marginTop: -0.5,
        paddingRight: 0,
        paddingY: 1,
      };
    },
    multiValue: (provided) => {
      return {
        ...provided,
        fontSize: 'md',
        paddingTop: 0.5,
        paddingBottom: 0,
        minHeight: 0,
        minWidth: 'fit-content',
      };
    },
    multiValueRemove: (provided) => {
      return {
        ...provided,
        paddingY: 0,
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        width: 4,
        height: 4,
        fontSize: '0.5rem',
        colorScheme: 'gray',
      };
    },
    dropdownIndicator: (provided) => {
      return {
        ...provided,
        marginX: 0,
        padding: 0,
        paddingBottom: 1,
        marginTop: '2px',
      };
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
        fontSize: 'xs',
      };
    },
    menuList: (provided) => {
      return {
        ...provided,
        borderRadius: 'md',
        padding: 1,
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        minWidth: 200,
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        fontSize: 'xs',
        borderRadius: 'lg',
        paddingY: 0,
        paddingLeft: 2,
        minHeight: 7,
        fontWeight: state.isSelected ? 'bold' : 'normal',
      };
    },
  };

  return (
    <VStack alignItems={'start'} gap={4}>
      <VStack alignItems={'start'} gap={1}>
        <FormLabel>Project Name</FormLabel>
        <HStack>
          <form onSubmit={onRenameProjectSubmit}>
            <Input
              {...register('name', {
                required: true,
              })}
              autoFocus
              variant={'filled'}
              borderRadius={'md'}
              isDisabled={!isEditingProjectName}
              width={400}
            />
          </form>
          {isEditingProjectName ? (
            <>
              <IconButton
                aria-label="Cancel renaming project"
                size="xs"
                colorScheme="gray"
                onClick={() => {
                  reset();
                  setIsEditingProjectName(false);
                }}
                isRound
                icon={<SparkelIcon icon={faTimes} color={'inherit'} />}
              />
              <IconButton
                aria-label="Save project name"
                size="xs"
                onClick={() => {
                  onRenameProjectSubmit();
                  setIsEditingProjectName(false);
                }}
                isRound
                icon={<SparkelIcon icon={faCheck} color={'inherit'} />}
              />
            </>
          ) : (
            <IconButton
              aria-label="Rename project"
              colorScheme="gray"
              size="xs"
              onClick={() => setIsEditingProjectName(true)}
              isRound
              icon={<SparkelIcon icon={faPen} />}
            />
          )}
        </HStack>
      </VStack>
      <VStack alignItems={'start'} gap={1}>
        <FormLabel>Project Tags</FormLabel>
        <Select
          isMulti
          chakraStyles={chakraStyles}
          variant="filled"
          value={projectTags.map((tag) => ({
            value: tag.id,
            label: tag.name,
            colorScheme: tag.color.toLowerCase(),
          }))}
          options={allTags.map((tag) => ({
            value: tag.id,
            label: tag.name,
            colorScheme: tag.color.toLowerCase(),
          }))}
          onChange={(tags) =>
            handleTagChange(tags.map((tag) => (tag as { value: string }).value))
          }
        />
      </VStack>
    </VStack>
  );
}
