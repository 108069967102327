import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { useQuery } from 'urql';
import {
  GetOrderNameDocument,
  GetProjectNameDocument,
} from '../../gql/graphql';

type BreadcrumbContext = {
  projectId?: string;
  projectName?: string;
  orderId?: string;
  orderName?: string;
};

const BreadcrumbContext = createContext<BreadcrumbContext | null>(null);

interface BreadcrumbProviderProps {
  children: React.ReactNode;
}

// Step 2: Create the provider component
export const BreadcrumbProvider: React.FC<BreadcrumbProviderProps> = ({
  children,
}) => {
  const params = useParams();

  const [{ data: projectBreadcrumbData }] = useQuery({
    query: GetProjectNameDocument,
    variables: {
      // This is ok since we pause when this is missing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      projectId: params.projectId!,
    },
    pause: !params.projectId,
  });
  const [{ data: orderBreadcrumbData }] = useQuery({
    query: GetOrderNameDocument,
    variables: {
      // This is ok since we pause when this is missing
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      orderId: params.orderId!,
    },
    pause: !params.orderId,
  });

  const breadcrumbContext: BreadcrumbContext = {
    projectId: params.projectId,
    projectName: projectBreadcrumbData?.project?.name,
    orderId: params.orderId,
    orderName: orderBreadcrumbData?.order?.name,
  };

  return (
    <BreadcrumbContext.Provider value={breadcrumbContext}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);

  invariant(context, 'useBreadcrumb must be used within an BreadcrumbContext');

  return context;
};
