import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import invariant from 'tiny-invariant';
import { OrderDeepFragment } from '../../gql/graphql';

interface ExportStateManagerContextType {
  isExporting: boolean;
  setIsExporting: Dispatch<SetStateAction<boolean>>;
  isSuccessful: boolean;
  setIsSuccessful: Dispatch<SetStateAction<boolean>>;
  triggerExport: (
    ordersToExport?: OrderDeepFragment[],
    columnsToExport?: string[]
  ) => void;
  ordersToExport: OrderDeepFragment[];
  columnsToExport: string[];
}

interface ExportStateManagerProps {
  children: React.ReactNode;
}

const ExportStateManagerContext = createContext<
  ExportStateManagerContextType | undefined
>(undefined);

export const ExportStateManager: React.FC<ExportStateManagerProps> = ({
  children,
}) => {
  const [isExporting, setIsExporting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [ordersToExport, setOrdersToExport] = useState<OrderDeepFragment[]>([]);
  const [columnsToExport, setColumnsToExport] = useState<string[]>([]);

  const triggerExport = useCallback(
    (orders?: OrderDeepFragment[], columns?: string[]) => {
      setIsSuccessful(false);
      setIsExporting(true);
      setOrdersToExport(orders || []);
      setColumnsToExport(columns || []);
    },
    []
  );

  return (
    <ExportStateManagerContext.Provider
      value={{
        isExporting,
        setIsExporting,
        triggerExport,
        isSuccessful,
        setIsSuccessful,
        ordersToExport,
        columnsToExport,
      }}
    >
      {children}
    </ExportStateManagerContext.Provider>
  );
};

export const useExportState = () => {
  const context = useContext(ExportStateManagerContext);

  invariant(
    context,
    'useExportState must be used within an ExportStateManagerContext'
  );

  return context;
};
