import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'urql';
import { useExportState } from '../components/common/ExportStateManagerContext';
import { ResolvedOrderEntry } from '../domain/resolve-order-entry';
import { GetProjectDeepDocument } from '../gql/graphql';
import { ResolvedOrder, useResolvedOrders } from '../hooks/order';
import { exportTableToExcel } from '../utils/excel-utils';

interface ExportProjectDataParams {
  projectId: string;
}

export const ExportProjectData = () => {
  const { projectId } = useParams<
    keyof ExportProjectDataParams
  >() as ExportProjectDataParams;

  const { setIsSuccessful, setIsExporting, ordersToExport, columnsToExport } =
    useExportState();

  const [{ data: projectData, error: projectDataError }] = useQuery({
    query: GetProjectDeepDocument,
    variables: {
      id: projectId,
    },
  });

  const project = projectData?.project;
  const projectOrders =
    project?.orders.filter((order) => !order.isAggregatedOrder) || [];

  const { resolvedOrders, error: errorResolvingOrders } = useResolvedOrders(
    projectId,
    ordersToExport.length > 0 ? ordersToExport : projectOrders
  );

  useEffect(() => {
    if (resolvedOrders && projectData?.project?.name) {
      const tableToExport = prepareExport(resolvedOrders, columnsToExport);

      exportTableToExcel({
        ...tableToExport,
        filename: `Sparkel Export - ${
          projectData?.project?.name
        } - ${new Date().toUTCString()}.xlsx`,
      });

      setIsSuccessful(true);
      setIsExporting(false);
    }
  }, [
    projectData?.project?.name,
    resolvedOrders,
    setIsSuccessful,
    setIsExporting,
    columnsToExport,
  ]);

  useEffect(() => {
    if (errorResolvingOrders) {
      console.error(errorResolvingOrders);
    }
    if (projectDataError) {
      console.error(projectDataError);
    }
  }, [errorResolvingOrders, projectDataError]);

  return <></>;
};

export // Recursive function to handle both entries and sub-rows
const prepareExport = (orders: ResolvedOrder[], columns?: string[]) => {
  function processItem(
    entry: ResolvedOrderEntry,
    order: ResolvedOrder,
    depth: number,
    parentNames: string[] = []
  ): void {
    if (entry.subRows && entry.subRows.length > 0) {
      entry.subRows.forEach((subRow) =>
        processItem(subRow, order, depth + 1, [...parentNames, entry.name])
      );
    } else {
      // Initialize row with empty values for each column
      const row = new Array(headers.length).fill('');

      // Set 'Item Name' and 'Table' values
      row[0] = entry.name;
      row[1] = order.name;

      // Set the label values
      Array.from(labelKeys).forEach((key, labelIndex) => {
        const label = order.labels.find((label) => label.key === key);
        row[2 + labelIndex] = label ? label.value : ''; // +2 for the first two standard columns
      });

      // Set the itemGroup values
      parentNames.forEach((name, index) => {
        row[2 + labelKeys.size + index] = name; // Adjust for label columns
      });

      // Set the Quantity value
      const quantityIndex = 2 + labelKeys.size + maxDepth;
      const quantityValue = entry.resolvedQuantity
        ? Number(entry.resolvedQuantity.value)
        : 0;
      row[quantityIndex] = quantityValue;

      // Set the Unit
      const unitIndex = 3 + labelKeys.size + maxDepth;
      const unitValue = entry.unit;
      row[unitIndex] = unitValue;

      // Set the custom field values
      entry.customFields?.forEach((customField) => {
        const columnName = order.orderColumns.find(
          (column) => column.id === customField.orderColumnId
        )?.name;
        if (columnName) {
          const index = headers.indexOf(columnName);
          if (index !== -1) {
            let cellValue = customField.resolvedValue?.value;

            if (typeof cellValue === 'number' && !isNaN(cellValue)) {
              if (columnName === 'SKU') {
                // Format SKU as a string
                cellValue = cellValue.toString();
              } else {
                cellValue = inferAndFormatValue(cellValue.toString());
              }
            } else if (Array.isArray(cellValue)) {
              cellValue = uniqueRoundedArray(cellValue);
            }

            row[index] = cellValue;
          }
        }
      });

      // Set 'Created At' and 'Updated At' values
      row[headers.indexOf('Created At')] = new Date(entry.createdAt) ?? '';
      row[headers.indexOf('Updated At')] = new Date(entry.updatedAt) ?? '';

      rows.push(row);
    }
  }

  function uniqueRoundedArray(
    values: (string | number | (string | number)[])[]
  ): (string | number)[] {
    function roundToNearestFiveDecimals(num: number): number {
      return Math.round(num * 100000) / 100000;
    }
    const uniqueValues = new Set<string | number>();

    values.forEach((value) => {
      if (Array.isArray(value)) {
        value.forEach((innerValue) => {
          if (typeof innerValue === 'number') {
            uniqueValues.add(roundToNearestFiveDecimals(innerValue));
          } else {
            uniqueValues.add(innerValue);
          }
        });
      } else {
        if (typeof value === 'number') {
          uniqueValues.add(roundToNearestFiveDecimals(value));
        } else {
          uniqueValues.add(value);
        }
      }
    });

    return Array.from(uniqueValues);
  }

  // Function to get the depth of subRows
  function getDepth(entry: ResolvedOrderEntry, currentDepth: number): number {
    if (!entry.subRows || entry.subRows.length === 0) {
      return currentDepth;
    }
    return Math.max(
      ...entry.subRows.map((subRow) => getDepth(subRow, currentDepth + 1))
    );
  }

  // Find all unique custom field names
  const customFieldNames = new Set<string>();

  orders.forEach((order) => {
    order.orderEntries.forEach((entry) => {
      entry.customFields?.forEach((customField) => {
        const columnName = order.orderColumns.find(
          (column) => column.id === customField.orderColumnId
        )?.name;
        if (columnName) {
          customFieldNames.add(columnName);
        }
      });
    });
  });

  // Find unique label keys and max depth
  const labelKeys = new Set<string>();
  let maxDepth = 0;
  orders.forEach((order) => {
    order.labels.forEach((label) => labelKeys.add(label.key));
    order.orderEntries.forEach((entry) => {
      const depth = getDepth(entry, 0);
      maxDepth = Math.max(maxDepth, depth);
    });
  });

  // Prepare headers
  const headers: string[] = ['Item Name', 'Table'];
  headers.push(...Array.from(labelKeys)); // Add label headers
  for (let i = 1; i <= maxDepth; i++) {
    headers.push(`itemGroup${i}`); // Add itemGroup headers
  }
  headers.push('Quantity'); // Add 'Quantity' header
  headers.push('Unit'); // Add 'Unit' header
  headers.push(...Array.from(customFieldNames)); // Add unique custom field names as headers
  headers.push('Created At', 'Updated At'); // Add 'Created At' and 'Updated At' headers

  // Prepare rows
  const rows: string[][] = [];
  orders.forEach((order) => {
    order.orderEntries.forEach((entry) => {
      processItem(entry, order, 1);
    });
  });

  // If columns are provided, filter the headers and rows to only include the provided columns.
  // The headers should also be sorted in the same order as the provided columns.
  if (columns && columns.length > 0) {
    const columnSet = new Set(columns);
    const filteredHeaders = columns.filter((column) => columnSet.has(column));
    const headerIndexMap = new Map(
      headers.map((header, index) => [header, index])
    );
    const filteredRows = rows.map((row) =>
      filteredHeaders.map((header) => {
        const index = headerIndexMap.get(header);
        return typeof index === 'number' ? row[index] : undefined;
      })
    ) as string[][];
    return { headers: filteredHeaders, rows: filteredRows };
  }

  return { headers, rows };
};

function inferAndFormatValue(value: string) {
  // Check if the value is numeric and convert it to a number if so
  const numberValue = Number(value);
  if (!isNaN(numberValue) && numberValue.toString() === value.toString()) {
    return numberValue;
  }
  // Return the original value if it's not numeric
  return value;
}
