import { add, divide, floor, max, min, multiply, norm, subtract } from 'mathjs';
import { memo, useMemo } from 'react';
import { calculateRelativePosition } from '../../shapes/util';
import {
  LinearRing2,
  LineSegment2,
  Point2,
  Polygon2,
  Polyline2,
} from '../../../../domain/geometry/geometric-types';
import { SlopedInsulationCrossSection } from '../SheetShapeDrawing';
import { SheetPolygon } from '../SheetPolygon';

export type Trapezoid2 = [Point2, Point2, Point2, Point2];

type RectangularProduct = {
  name: string;
  sku: string;
  dimensions: {
    height: number;
    length?: number;
  };
};

type SlopedProduct = {
  name: string;
  sku: string;
  dimensions: {
    minHeight: number;
    maxHeight: number;
    length: number;
  };
};

type SlopedInsulationBuildUp = {
  baseLayer: RectangularProduct;
  insulationLayer: RectangularProduct[];
  slopedInsulationLayer: SlopedProduct[];
  topLayer: RectangularProduct;
};

type SlopedInsulationProps = {
  slope: number;
  baseLine: Polyline2;
  minHeight: number;
  lowPoints: number[];
  buildUp: SlopedInsulationBuildUp;
};

const OFFSET_FROM_ROOF = 50;

const BUILDUP = {
  baseLayer: {
    name: 'Base Layer',
    sku: '0000',
    dimensions: {
      height: 3,
    },
  },
  insulationLayer: [
    {
      name: 'Insulation',
      sku: '0001',
      dimensions: {
        height: 40,
      },
    },
    {
      name: 'Insulation',
      sku: '0000',
      dimensions: {
        height: 20,
      },
    },
  ],
  slopedInsulationLayer: [
    {
      name: 'Sloped Insulation',
      sku: '5678',
      dimensions: {
        minHeight: 5,
        maxHeight: 10,
        length: 40,
      },
    },
    {
      name: 'Sloped Insulation',
      sku: '1234',
      dimensions: {
        minHeight: 10,
        maxHeight: 15,
        length: 40,
      },
    },
    {
      name: 'Sloped Insulation',
      sku: '0000',
      dimensions: {
        minHeight: 15,
        maxHeight: 20,
        length: 40,
      },
    },
    {
      name: 'Sloped Insulation',
      sku: '0000',
      dimensions: {
        minHeight: 20,
        maxHeight: 25,
        length: 40,
      },
    },
  ],
  topLayer: {
    name: 'Top Layer',
    sku: '1234',
    dimensions: {
      height: 3,
    },
  },
};

export const SlopedInsulationCrossSectionComponent = memo(
  ({
    crossSection,
    getPointInViewerCoordinateSystem,
    isSelected,
    onClick,
  }: {
    crossSection: SlopedInsulationCrossSection;
    getPointInViewerCoordinateSystem: (pointInPdf: Point2) => Point2;
    isSelected?: boolean;
    onClick?: React.MouseEventHandler;
  }) => {
    const offsetDirection = useMemo(
      () => [
        // OffsetDirection is perpendicular to the base line
        crossSection.baseLine[1][1] - crossSection.baseLine[0][1],
        crossSection.baseLine[0][0] - crossSection.baseLine[1][0],
      ],
      [crossSection]
    );

    const offsetDirectionNormalized = useMemo(
      () => [
        offsetDirection[0] / Math.hypot(offsetDirection[0], offsetDirection[1]),
        offsetDirection[1] / Math.hypot(offsetDirection[0], offsetDirection[1]),
      ],
      [offsetDirection]
    );

    const { trapezoids: crossSectionTrapezoids, maxHeight } = useMemo(
      () =>
        computeTrapezoids(
          crossSection.baseLine,
          crossSection.lowPoints[0],
          30,
          crossSection.slope
        ),
      [crossSection]
    );

    const offsetVector = useMemo(
      () => [
        offsetDirectionNormalized[0] * (OFFSET_FROM_ROOF + maxHeight),
        offsetDirectionNormalized[1] * (OFFSET_FROM_ROOF + maxHeight),
      ],
      [offsetDirectionNormalized, maxHeight]
    );

    const crossSectionTrapezoidsOffsetted: Trapezoid2[] = useMemo(
      () =>
        crossSectionTrapezoids.map(
          (trapezoid) =>
            trapezoid.map((point) => add(point, offsetVector)) as Trapezoid2
        ),
      [crossSectionTrapezoids, offsetVector]
    );

    const baseLayerProductSegments = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeBaseLayerFromTrapezoid(trapezoid, BUILDUP.baseLayer)
        ),
      [crossSectionTrapezoidsOffsetted]
    );

    const topLayerProductSegments = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeTopLayerFromTrapezoid(trapezoid, BUILDUP.topLayer)
        ),
      [crossSectionTrapezoidsOffsetted]
    );

    const slopedInsulationSegmentsPerTrapezoid = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.map((trapezoid) =>
          computeSlopedInsulationFromTrapezoid(
            trapezoid,
            BUILDUP.slopedInsulationLayer,
            BUILDUP.topLayer
          )
        ),
      [crossSectionTrapezoidsOffsetted]
    );

    const rectangularInsulationSegments = useMemo(
      () =>
        crossSectionTrapezoidsOffsetted.flatMap((trapezoid, index) =>
          computeRectangularInsulationFromSlopedSegments(
            slopedInsulationSegmentsPerTrapezoid[index],
            trapezoid,
            BUILDUP.insulationLayer,
            BUILDUP.baseLayer
          )
        ),
      [crossSectionTrapezoidsOffsetted, slopedInsulationSegmentsPerTrapezoid]
    );

    const productList = useMemo(() => {
      const baseLayerProductList = baseLayerProductSegments.flatMap((segment) =>
        segment.products.map((product) => product)
      );

      const topLayerProductList = topLayerProductSegments.flatMap((segment) =>
        segment.products.map((product) => product)
      );

      const slopedInsulationProductList =
        slopedInsulationSegmentsPerTrapezoid.flatMap((mainTrapezoid) =>
          mainTrapezoid.flatMap((segment) =>
            segment.products.map((product) => product)
          )
        );

      const rectangularInsulationProductList =
        rectangularInsulationSegments.flatMap((segment) =>
          segment.products.map((product) => product)
        );

      return [
        ...slopedInsulationProductList,
        ...rectangularInsulationProductList,
        ...topLayerProductList,
        ...baseLayerProductList,
      ];
    }, [
      baseLayerProductSegments,
      rectangularInsulationSegments,
      slopedInsulationSegmentsPerTrapezoid,
      topLayerProductSegments,
    ]);

    const productPolygonsPerSegment = useMemo(() => {
      const baseLayerProductPolygons = baseLayerProductSegments.map((segment) =>
        segment.trapezoids.map((trapezoid) => trapezoidToPolygon2(trapezoid))
      );

      const topLayerProductPolygons = topLayerProductSegments.flatMap(
        (segment) => [
          segment.trapezoids.map((trapezoid) => trapezoidToPolygon2(trapezoid)),
        ]
      );

      const slopedInsulationTrapezoids =
        slopedInsulationSegmentsPerTrapezoid.flatMap((mainTrapezoid) =>
          mainTrapezoid.map((segment) =>
            segment.trapezoids.map((trapezoid) =>
              trapezoidToPolygon2(trapezoid)
            )
          )
        );

      const rectangularInsulationPolygons = rectangularInsulationSegments.map(
        (segment) =>
          segment.trapezoids.map((trapezoid) => trapezoidToPolygon2(trapezoid))
      );

      return [
        ...slopedInsulationTrapezoids,
        ...rectangularInsulationPolygons,
        ...topLayerProductPolygons,
        ...baseLayerProductPolygons,
      ];
    }, [
      baseLayerProductSegments,
      rectangularInsulationSegments,
      slopedInsulationSegmentsPerTrapezoid,
      topLayerProductSegments,
    ]);

    return (
      <SheetPolygon
        multipolygon={{
          polygons: [
            ...productPolygonsPerSegment.flatMap((polygons) => polygons),
          ],
        }}
        getPointInDomCoordinateSystem={getPointInViewerCoordinateSystem}
        fill={isSelected ? 'blue.200' : 'orange.100'}
        stroke={isSelected ? 'blue.500' : 'orange.300'}
        onClick={onClick}
        selectable
      />
    );
  },
  (prev, next) => {
    return (
      prev.crossSection === next.crossSection &&
      prev.isSelected === next.isSelected &&
      prev.getPointInViewerCoordinateSystem ===
        next.getPointInViewerCoordinateSystem
    );
  }
);

export function computeTrapezoids(
  baseline: LineSegment2,
  lowPoint: number,
  minHeight: number,
  slope: number
): { trapezoids: Trapezoid2[]; maxHeight: number } {
  const [p0, p1] = baseline; // Base points
  const [x0, y0] = p0;
  const [x1, y1] = p1;

  // Direction vector components
  const dx = x1 - x0;
  const dy = y1 - y0;

  // Length of the baseline
  const L = Math.hypot(dx, dy);

  // Normal vector components (perpendicular to baseline)
  const n: Point2 = [-dy / L, dx / L];

  // Points along the baseline at s = 0, t, 1
  const pt: Point2 = [x0 + lowPoint * dx, y0 + lowPoint * dy];

  // Heights at s = 0, t, 1
  const H0 = minHeight + slope * L * Math.abs(0 - lowPoint);
  const Ht = minHeight; // At the low point
  const H1 = minHeight + slope * L * Math.abs(1 - lowPoint);

  // Top points by adding the normal vector scaled by height
  const top0: Point2 = [x0 + H0 * n[0], y0 + H0 * n[1]];
  const topT: Point2 = [pt[0] + Ht * n[0], pt[1] + Ht * n[1]];
  const top1: Point2 = [x1 + H1 * n[0], y1 + H1 * n[1]];

  // Create trapezoids
  const trapezoid1: Trapezoid2 = [
    p0, // Base start (s=0)
    pt, // Base at low point (s=lowPoint)
    topT, // Top at low point
    top0, // Top at s=0
  ];

  const trapezoid2: Trapezoid2 = [
    pt, // Base at low point (s=lowPoint)
    p1, // Base end (s=1)
    top1, // Top at s=1
    topT, // Top at low point
  ];

  const trapezoids = [trapezoid1, trapezoid2];

  const maxHeight = Math.max(H0, H1);

  return { trapezoids, maxHeight };
}

SlopedInsulationCrossSectionComponent.displayName =
  'SlopedInsulationCrossSectionComponent';

export function trapezoidToPolygon2(trapezoid: Trapezoid2): Polygon2 {
  const [p0, pt, topT, top0] = trapezoid;

  return {
    exterior: [p0, pt, topT, top0, p0] as LinearRing2,
    interiors: [],
  };
}

export function computeBaseLayerFromTrapezoid(
  trapezoid: Trapezoid2,
  baseLayer: RectangularProduct
): RectangularProductSegment {
  const { baseLine, normal } = getBaselineProperties(trapezoid);

  const baseLayerTrapezoid: Trapezoid2 = [
    baseLine[0], // Base start point
    baseLine[1], // Base end point
    add(baseLine[1], multiply(baseLayer.dimensions.height, normal)) as Point2, // Top end point
    add(baseLine[0], multiply(baseLayer.dimensions.height, normal)) as Point2, // Top start point
  ];

  return {
    products: [baseLayer],
    trapezoids: [baseLayerTrapezoid],
  };
}

export function computeTopLayerFromTrapezoid(
  trapezoid: Trapezoid2,
  topLayer: RectangularProduct
): RectangularProductSegment {
  // Extract points from the main trapezoid
  const [p0, pt, topT, top0] = trapezoid;

  // Height (thickness) of the top layer product
  const { height } = topLayer.dimensions;

  // Left side vector from top0 to p0
  const v_left: Point2 = [p0[0] - top0[0], p0[1] - top0[1]];
  const len_left = Math.hypot(v_left[0], v_left[1]);
  const u_left: Point2 = [v_left[0] / len_left, v_left[1] / len_left];

  // Right side vector from topT to pt
  const v_right: Point2 = [pt[0] - topT[0], pt[1] - topT[1]];
  const len_right = Math.hypot(v_right[0], v_right[1]);
  const u_right: Point2 = [v_right[0] / len_right, v_right[1] / len_right];

  // Bottom points of the top layer trapezoid
  const bottom0: Point2 = [
    top0[0] + u_left[0] * height,
    top0[1] + u_left[1] * height,
  ];
  const bottomT: Point2 = [
    topT[0] + u_right[0] * height,
    topT[1] + u_right[1] * height,
  ];

  // Top layer trapezoid points
  const topLayerTrapezoid: Trapezoid2 = [
    top0, // Top start point
    topT, // Top end point
    bottomT, // Bottom end point
    bottom0, // Bottom start point
  ];

  return {
    products: [topLayer],
    trapezoids: [topLayerTrapezoid],
  };
}

type SlopedProductSegment = {
  products: SlopedProduct[];
  trapezoids: Trapezoid2[];
};

type RectangularProductSegment = {
  products: RectangularProduct[];
  trapezoids: Trapezoid2[];
};

export function computeSlopedInsulationFromTrapezoid(
  trapezoid: Trapezoid2,
  products: SlopedProduct[],
  topLayer?: RectangularProduct
): SlopedProductSegment[] {
  // 1. Get the baseline properties
  const properties = getBaselineProperties(trapezoid);

  // 2. Find out how many segments are needed
  const fullSegmentLength = products.reduce(
    (acc, layer) => acc + layer.dimensions.length,
    0
  );
  const numFullSegments = floor(properties.baseLineLength / fullSegmentLength);

  // 3. Get the segments
  return getSlopedProductSegments(
    properties,
    numFullSegments,
    products,
    topLayer
  );
}

type TrapezoidProperties = {
  baseLine: LineSegment2;
  baseLineLength: number;
  tangent: Point2;
  normal: Point2;
  maxHeight: number;
  minHeight: number;
};

function getBaselineProperties(trapezoid: Trapezoid2): TrapezoidProperties {
  const [p0, pt, topT, top0] = trapezoid;

  const h1 = norm(subtract(top0, p0)) as number;
  const h2 = norm(subtract(topT, pt)) as number;

  const slopeSign = Math.sign(h2 - h1);

  const baseLine =
    slopeSign > 0 ? ([p0, pt] as LineSegment2) : ([pt, p0] as LineSegment2);

  const baseLineDirection = subtract(baseLine[1], baseLine[0]) as Point2;
  const baseLineLength = norm(baseLineDirection) as number;
  const baseLineDirectionNormalized = divide(
    baseLineDirection,
    baseLineLength
  ) as Point2;
  const normalVector =
    slopeSign > 0
      ? ([
          -baseLineDirectionNormalized[1],
          baseLineDirectionNormalized[0],
        ] as Point2)
      : ([
          baseLineDirectionNormalized[1],
          -baseLineDirectionNormalized[0],
        ] as Point2);

  const maxHeight = Math.max(h1, h2);
  const minHeight = Math.min(h1, h2);

  return {
    baseLine,
    baseLineLength,
    tangent: baseLineDirectionNormalized,
    normal: normalVector,
    maxHeight,
    minHeight,
  };
}

const MIN_SEGMENT_LENGTH = 10;

function getSlopedProductSegments(
  baseLineProperties: TrapezoidProperties,
  numFullSegments: number,
  products: SlopedProduct[],
  topLayer?: RectangularProduct
): SlopedProductSegment[] {
  let topLayerOffset = 0;

  if (topLayer) {
    const topLayerHeight = topLayer.dimensions.height;
    const slope =
      (baseLineProperties.maxHeight - baseLineProperties.minHeight) /
      baseLineProperties.baseLineLength;
    topLayerOffset = Math.cos(Math.atan(slope)) * topLayerHeight;
  }

  // Define global offset as the min height of the trapezoid minus the minimum height of the last product along the baseline normal
  const globalOffset = multiply(
    baseLineProperties.minHeight -
      min(products.map((p) => p.dimensions.minHeight)) -
      topLayerOffset,
    baseLineProperties.normal
  );

  const offsetPerSegmentMagnitude =
    max(products.map((p) => p.dimensions.maxHeight)) -
    min(products.map((p) => p.dimensions.minHeight));
  const offsetPerSegment = multiply(
    offsetPerSegmentMagnitude,
    baseLineProperties.normal
  ) as Point2;

  const segments: SlopedProductSegment[] = [];

  const fullSegmentLength = products.reduce(
    (acc, layer) => acc + layer.dimensions.length,
    0
  );

  for (let i = 0; i < numFullSegments; i++) {
    const productOffset = add(
      add(multiply(offsetPerSegment, i), globalOffset), // Global offset
      multiply(baseLineProperties.tangent, fullSegmentLength * i) // Offset for the full segments
    ) as Point2;

    const productsInSegment = getSlopedProductsInSegment(
      baseLineProperties,
      products,
      productOffset
    );

    if (productsInSegment) {
      segments.push(productsInSegment);
    }
  }

  const remainingLength =
    baseLineProperties.baseLineLength - fullSegmentLength * numFullSegments;
  if (remainingLength > 0) {
    // Remove the last element from the products array until the sum of all product lengths is less than the remaining length

    let remainingProducts = products.slice(); // Copy the array
    let lastPoppedProduct: SlopedProduct | undefined;

    while (
      remainingProducts.reduce(
        (acc, layer) => acc + layer.dimensions.length,
        0
      ) > remainingLength
    ) {
      lastPoppedProduct = remainingProducts.pop();
    }

    const productOffset = add(
      add(
        multiply(
          offsetPerSegmentMagnitude * numFullSegments,
          baseLineProperties.normal
        ) as Point2,
        globalOffset
      ),
      multiply(baseLineProperties.tangent, fullSegmentLength * numFullSegments)
    ) as Point2;

    const productsInSegment = getSlopedProductsInSegment(
      baseLineProperties,
      remainingProducts,
      productOffset
    );

    if (productsInSegment) {
      segments.push(productsInSegment);
    }

    const lastSegmentLength = remainingProducts.reduce(
      (acc, layer) => acc + layer.dimensions.length,
      0
    );

    const newRemainingLength =
      baseLineProperties.baseLineLength -
      fullSegmentLength * numFullSegments -
      lastSegmentLength;

    if (lastPoppedProduct && newRemainingLength > MIN_SEGMENT_LENGTH) {
      const productOffset = add(
        add(
          multiply(
            offsetPerSegmentMagnitude * numFullSegments,
            baseLineProperties.normal
          ) as Point2,
          globalOffset
        ),
        multiply(
          baseLineProperties.tangent,
          fullSegmentLength * numFullSegments + lastSegmentLength
        )
      ) as Point2;

      const lastModifiedSegment = getLastModifiedSegment(
        baseLineProperties,
        lastPoppedProduct,
        newRemainingLength,
        productOffset
      );

      if (lastModifiedSegment) {
        segments.push(lastModifiedSegment);
      }
    }
  }

  return segments;
}

const getSlopedProductsInSegment = (
  baseLineProperties: TrapezoidProperties,
  products: SlopedProduct[],
  offset: Point2
): SlopedProductSegment | null => {
  if (products.length === 0) {
    return null;
  }

  const productsInSegment: SlopedProduct[] = [];
  const trapzoidsInSegment: Trapezoid2[] = [];

  let traversedLength = 0;

  for (const product of products) {
    const productStartPoint = add(
      add(
        baseLineProperties.baseLine[0],
        multiply(traversedLength, baseLineProperties.tangent)
      ),
      offset
    ) as Point2;

    const productEndPoint = add(
      add(
        baseLineProperties.baseLine[0],
        multiply(
          traversedLength + product.dimensions.length,
          baseLineProperties.tangent
        )
      ),
      offset
    ) as Point2;

    const productTrapezoid: Trapezoid2 = [
      productStartPoint,
      productEndPoint,
      add(
        productEndPoint,
        multiply(product.dimensions.maxHeight, baseLineProperties.normal)
      ) as Point2,
      add(
        productStartPoint,
        multiply(product.dimensions.minHeight, baseLineProperties.normal)
      ) as Point2,
    ];

    productsInSegment.push(product);
    trapzoidsInSegment.push(productTrapezoid);

    traversedLength += product.dimensions.length;
  }

  return { products: productsInSegment, trapezoids: trapzoidsInSegment };
};

const getLastModifiedSegment = (
  baseLineProperties: TrapezoidProperties,
  product: SlopedProduct,
  remainingLength: number,
  offset: Point2
): SlopedProductSegment | null => {
  const modifiedProduct = {
    ...product,
    dimensions: {
      ...product.dimensions,
      length: remainingLength,
      // Adjust the maxHeight so the slope remains the same
      maxHeight:
        product.dimensions.minHeight +
        ((product.dimensions.maxHeight - product.dimensions.minHeight) *
          remainingLength) /
          product.dimensions.length,
    },
  };

  return getSlopedProductsInSegment(
    baseLineProperties,
    [modifiedProduct],
    offset
  );
};

export function computeRectangularInsulationFromSlopedSegments(
  slopedProductSegments: SlopedProductSegment[],
  mainTrapezoid: Trapezoid2,
  products: RectangularProduct[],
  baseLayer?: RectangularProduct
): RectangularProductSegment[] {
  const rectangularInsulation: RectangularProductSegment[] = [];

  const filteredSlopedProductSegments = slopedProductSegments.filter(
    (s) => s.trapezoids.length > 0
  );

  // 1. For each segment, create a rectangular product segment
  for (const slopedProductSegment of filteredSlopedProductSegments) {
    const rectangularSegment = getRectangularProductSegment(
      slopedProductSegment,
      mainTrapezoid,
      products,
      baseLayer
    );

    if (rectangularSegment) {
      rectangularInsulation.push(rectangularSegment);
    }
  }

  return rectangularInsulation;
}

function getRectangularProductSegment(
  slopedSegment: SlopedProductSegment,
  mainTrapezoid: Trapezoid2,
  productCandidates: RectangularProduct[],
  baseLayer?: RectangularProduct
): RectangularProductSegment | null {
  const [p0] = slopedSegment.trapezoids[0]; // Bottom left point of the first trapezoid
  const { baseLine, normal, tangent } = getBaselineProperties(mainTrapezoid);

  const t = calculateRelativePosition(p0, baseLine);

  const projectedPointOnBaseline = [
    baseLine[0][0] + t * (baseLine[1][0] - baseLine[0][0]),
    baseLine[0][1] + t * (baseLine[1][1] - baseLine[0][1]),
  ] as Point2;

  const segmentHeight =
    (norm(subtract(p0, projectedPointOnBaseline)) as number) -
    (baseLayer?.dimensions.height || 0);

  const segmentWidth = slopedSegment.products.reduce(
    (acc, p) => acc + p.dimensions.length,
    0
  );

  if (segmentWidth <= 0 || segmentHeight <= 0) {
    return null;
  }

  const products = fillGapWithProducts(segmentHeight, productCandidates);
  const trapezoids: Trapezoid2[] = [];

  for (let i = 0; i < products.length; i++) {
    const product = products[i];
    const offset = products
      .slice(0, i)
      .reduce(
        (acc, p) => acc + p.dimensions.height,
        baseLayer?.dimensions.height || 0
      );

    const trapezoid: Trapezoid2 = [
      projectedPointOnBaseline, // Base start point
      add(projectedPointOnBaseline, multiply(segmentWidth, tangent)) as Point2, // Base end point
      add(
        add(
          projectedPointOnBaseline,
          multiply(product.dimensions.height, normal)
        ),
        multiply(segmentWidth, tangent)
      ) as Point2, // Top end point
      add(
        projectedPointOnBaseline,
        multiply(product.dimensions.height, normal)
      ) as Point2, // Top start point
    ];

    // Move the trapezoid to the correct position
    trapezoid[0] = add(trapezoid[0], multiply(offset, normal)) as Point2;
    trapezoid[1] = add(trapezoid[1], multiply(offset, normal)) as Point2;
    trapezoid[2] = add(trapezoid[2], multiply(offset, normal)) as Point2;
    trapezoid[3] = add(trapezoid[3], multiply(offset, normal)) as Point2;

    trapezoids.push(trapezoid);
  }

  return { products, trapezoids };
}

function fillGapWithProducts(
  gap: number,
  productCandidates: RectangularProduct[],
  tolerance = 1
) {
  // 1. Start by filling with the largest product
  // 2. If the gap is still not filled, continue with the next largest product
  // 3. The same product can be used multiple times

  let remainingGap = gap;

  const products: RectangularProduct[] = [];

  while (remainingGap > 0) {
    // Find the largest product that fits the remaining gap
    const product = productCandidates.find(
      (p) => p.dimensions.height <= remainingGap + tolerance
    );

    if (!product) {
      break;
    }

    products.push(product);
    remainingGap -= product.dimensions.height;
  }

  return products;
}
