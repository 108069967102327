import {
  difference as _difference,
  intersection as _intersection,
} from 'lodash';
import { ElementIdentifierDbidType } from '../gql/graphql';
import { DbIdsPerModel } from '../components/common/ForgeViewer';

// Because we use two different types of dbIds different places :(
export const convertToElementIdentifierDbidType = (
  modelDbIds: DbIdsPerModel
): ElementIdentifierDbidType[] => {
  return Object.entries(modelDbIds).map(([modelUrn, dbIds]) => ({
    modelUrn,
    dbIds,
  }));
};

export const convertToDbIdsPerModel = (
  dbIds: ElementIdentifierDbidType[]
): DbIdsPerModel => {
  return Object.fromEntries(dbIds.map((dbId) => [dbId.modelUrn, dbId.dbIds]));
};

export const isEmpty = (modelDbIds: ElementIdentifierDbidType[]) => {
  return modelDbIds.every((modelDbId) => modelDbId.dbIds.length === 0);
};

// Combines the dbIds into a single list with dbIds per model
export const merge = (
  modelDbIds1: ElementIdentifierDbidType[],
  modelDbIds2: ElementIdentifierDbidType[]
): ElementIdentifierDbidType[] => {
  const resultDbIds = new Map<string, Set<number>>(
    modelDbIds1.map((dbId) => [dbId.modelUrn, new Set(dbId.dbIds)])
  );
  for (const dbId of modelDbIds2) {
    const existingDbIds = resultDbIds.get(dbId.modelUrn);
    if (existingDbIds) {
      dbId.dbIds.forEach((dbId) => existingDbIds.add(dbId));
    } else {
      resultDbIds.set(dbId.modelUrn, new Set(dbId.dbIds));
    }
  }
  return Array.from(resultDbIds).map(([modelUrn, dbIds]) => ({
    modelUrn,
    dbIds: Array.from(dbIds),
  }));
};

// Checks if there exists a common dbId in both dbIds1 and dbIds2
export const intersects = (
  modelDbIds1: ElementIdentifierDbidType[],
  modelDbIds2: ElementIdentifierDbidType[]
): boolean => {
  const dbIdMap1 = new Map<string, Set<number>>(
    modelDbIds1.map((dbId) => [dbId.modelUrn, new Set(dbId.dbIds)])
  );
  for (const dbId of modelDbIds2) {
    const existingDbIds = dbIdMap1.get(dbId.modelUrn);
    if (existingDbIds) {
      for (const dbIdValue of dbId.dbIds) {
        if (existingDbIds.has(dbIdValue)) {
          return true;
        }
      }
    }
  }
  return false;
};

// Return all dbIds which are both in dbIds1 and dbIds2
export const intersection = (
  modelDbIds1: ElementIdentifierDbidType[],
  modelDbIds2: ElementIdentifierDbidType[]
): ElementIdentifierDbidType[] => {
  const dbIdMap2 = new Map<string, number[]>(
    modelDbIds2.map((dbId) => [dbId.modelUrn, dbId.dbIds])
  );
  const result: ElementIdentifierDbidType[] = [];
  for (const dbId of modelDbIds1) {
    const dbIds2 = dbIdMap2.get(dbId.modelUrn);
    if (dbIds2) {
      const dbIds = _intersection(dbId.dbIds, dbIds2);
      if (dbIds.length > 0) {
        result.push({
          modelUrn: dbId.modelUrn,
          dbIds,
        });
      }
    }
  }
  return result;
};

// Return all dbIds in dbIds1 which are not in dbIds2
export const difference = (
  modelDbIds1: ElementIdentifierDbidType[],
  modelDbIds2: ElementIdentifierDbidType[]
): ElementIdentifierDbidType[] => {
  const dbIdMap2 = new Map<string, number[]>(
    modelDbIds2.map((dbId) => [dbId.modelUrn, dbId.dbIds])
  );
  const result: ElementIdentifierDbidType[] = [];
  for (const dbId of modelDbIds1) {
    const dbIds2 = dbIdMap2.get(dbId.modelUrn);
    if (dbIds2) {
      const dbIds = _difference(dbId.dbIds, dbIds2);
      if (dbIds.length > 0) {
        result.push({
          modelUrn: dbId.modelUrn,
          dbIds,
        });
      }
    } else {
      result.push(dbId);
    }
  }
  return result;
};
