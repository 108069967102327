import { Box, Text, useColorMode } from '@chakra-ui/react';
import { Point2 } from '../../../domain/geometry/geometric-types';

type SheetShapeLabelProps = {
  label: string;
  position: Point2;
  color: string;
  subLabels?: string[];
};

const SheetShapeLabel = ({
  label,
  position,
  color,
  subLabels,
}: SheetShapeLabelProps) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      position={'absolute'}
      top={position[1]}
      left={position[0]}
      transform="translate(-50%, -50%)"
      pointerEvents={'none'}
    >
      <Text
        color={color}
        filter={`brightness(${colorMode === 'light' ? 40 : 160}%)`}
        fontWeight={'semibold'}
        fontSize={'sm'}
      >
        {label}
      </Text>
      {subLabels &&
        subLabels.map((subLabel, index) => (
          <Text
            key={index}
            color={color}
            filter={`brightness(${colorMode === 'light' ? 40 : 160}%)`}
            fontSize={'xs'}
          >
            {subLabel}
          </Text>
        ))}
    </Box>
  );
};

export default SheetShapeLabel;
