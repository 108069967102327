import { Point, Triangle } from './geometric-types';

export function getTrianglesForFragId(
  model: Autodesk.Viewing.Model,
  fragId: number
) {
  const triangles: Triangle[] = [];

  let localToWorldMatrix = new THREE.Matrix4();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  model.getFragmentList().getWorldMatrix(fragId, localToWorldMatrix);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let geometry = model.getFragmentList().getGeometry(fragId);

  let attributes = geometry.attributes;
  let positions = geometry.vb ? geometry.vb : attributes.position.array;
  let stride = geometry.vb ? geometry.vbstride : 3;

  if (attributes.index !== undefined) {
    let indices = attributes.index.array || geometry.ib;
    let offsets = geometry.offsets;

    if (!offsets || offsets.length === 0) {
      offsets = [{ start: 0, count: indices.length, index: 0 }];
    }

    for (let oi = 0, ol = offsets.length; oi < ol; ++oi) {
      let start = offsets[oi].start;
      let count = offsets[oi].count;
      let index = offsets[oi].index;

      for (let i = start, il = start + count; i < il; i += 3) {
        let a = index + indices[i];
        let b = index + indices[i + 1];
        let c = index + indices[i + 2];

        triangles.push(
          toTriangle(positions, stride, a, b, c, localToWorldMatrix)
        );
      }
    }
  } else {
    for (let i = 0, j = 0, il = positions.length; i < il; i += 3, j += 9) {
      let a = i;
      let b = i + 1;
      let c = i + 2;

      triangles.push(
        toTriangle(positions, stride, a, b, c, localToWorldMatrix)
      );
    }
  }
  return triangles;
}

export function toVertex(vector3: THREE.Vector3): Point {
  return [
    vector3.getComponent(0),
    vector3.getComponent(1),
    vector3.getComponent(2),
  ];
}

function toTriangle(
  positions: number[],
  stride: number,
  a: number,
  b: number,
  c: number,
  matrix: THREE.Matrix4
): Triangle {
  let vA = new THREE.Vector3();
  let vB = new THREE.Vector3();
  let vC = new THREE.Vector3();

  vA.fromArray(positions, a * stride);
  vB.fromArray(positions, b * stride);
  vC.fromArray(positions, c * stride);

  vA.applyMatrix4(matrix);
  vB.applyMatrix4(matrix);
  vC.applyMatrix4(matrix);

  return [toVertex(vA), toVertex(vB), toVertex(vC)];
}
