import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateOrderEntryFromTemplateMutation,
  CreateOrderEntryFromTemplateMutationVariables,
  CreateTemplateFromOrderEntryMutation,
  CreateTemplateFromOrderEntryMutationVariables,
  DeleteOrderEntryTemplateMutation,
  DeleteOrderEntryTemplateMutationVariables,
  GetOrderEntryTemplatesPageDocument,
} from '../../gql/graphql';

export const createTemplateFromOrderEntryResolver: UpdateResolver<
  CreateTemplateFromOrderEntryMutation,
  CreateTemplateFromOrderEntryMutationVariables
> = (result, args, cache) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'orderEntryTemplatesConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetOrderEntryTemplatesPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          // Add the new template to the last page of results
          if (
            result.createTemplateFromOrderEntry?.orderEntryTemplate &&
            data?.orderEntryTemplatesConnection &&
            !data.orderEntryTemplatesConnection.pageInfo.hasNextPage
          ) {
            data.orderEntryTemplatesConnection.nodes = [
              ...data.orderEntryTemplatesConnection.nodes,
              result.createTemplateFromOrderEntry.orderEntryTemplate,
            ];
          }
          return data;
        }
      );
    });
};

export const createOrderEntryFromTemplateResolver: UpdateResolver = (
  result: CreateOrderEntryFromTemplateMutation,
  args: CreateOrderEntryFromTemplateMutationVariables,
  cache
) => {
  if (result.createOrderEntryFromTemplate?.orderEntry) {
    const orderId = result.createOrderEntryFromTemplate.orderEntry.orderId;
    const order = result.createOrderEntryFromTemplate.orderEntry.order;
    const orderEntriesForOrder = cache.resolve(
      {
        __typename: 'Order',
        id: orderId,
      },
      'orderEntries'
    ) as string[] | null;
    const orderEntryToAdd = cache.keyOfEntity({
      __typename: 'OrderEntry',
      id: result.createOrderEntryFromTemplate.orderEntry.id,
    });
    if (orderEntriesForOrder && orderEntryToAdd) {
      orderEntriesForOrder.push(orderEntryToAdd);
      cache.link(
        {
          __typename: 'Order',
          id: orderId,
        },
        'orderEntries',
        orderEntriesForOrder
      );
    }

    const orderColumnsForOrder = new Set(
      cache.resolve(
        { __typename: 'Order', id: orderId },
        'orderColumns'
      ) as string[]
    ) as Set<string>;
    if (order && orderColumnsForOrder) {
      order.orderColumns
        .map((oe) =>
          cache.keyOfEntity({
            __typename: 'OrderColumn',
            id: oe.id,
          })
        )
        .filter((key) => key !== null)
        .forEach((col) => col != null && orderColumnsForOrder.add(col));

      cache.link(
        {
          __typename: 'Order',
          id: orderId,
        },
        'orderColumns',
        Array.from(orderColumnsForOrder.values())
      );
    }
  }
};

export const deleteOrderEntryTemplateResolver: UpdateResolver = (
  result: DeleteOrderEntryTemplateMutation,
  args: DeleteOrderEntryTemplateMutationVariables,
  cache
) => {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'orderEntryTemplatesConnection')
    .forEach((field) => {
      cache.updateQuery(
        {
          query: GetOrderEntryTemplatesPageDocument,
          variables: {
            ...field.arguments,
          },
        },
        (data) => {
          if (data?.orderEntryTemplatesConnection) {
            data.orderEntryTemplatesConnection.nodes =
              data.orderEntryTemplatesConnection.nodes.filter(
                (template) => template.id !== args.input.id
              );
          }
          return data;
        }
      );
    });
};
