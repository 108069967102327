import {
  MultiPolyline2Result,
  Polyline2,
  ResultType,
} from '../../geometric-types';
import { calculatePolylineLength } from '../util/line-segment';

export function calculateLine2Length(points: Polyline2): MultiPolyline2Result {
  const value = calculatePolylineLength(points);

  return {
    type: ResultType.MULTIPOLYLINE2,
    value,
    multiPolyline: { lines: [points] },
  };
}
