import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Link,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { CSSProperties, useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { CheckoutUrlDocument } from '../../../gql/graphql';
import { EventName, useMixpanel } from '../../../services/mixpanel';
import { ChakraColor } from '../../../utils/color-util';

type PlanItemProps = {
  name: string;
  price: string;
  subtitle?: string;
  features: string[];
  containerStyle?: CSSProperties;
  buttonColorScheme?: string;
  buttonStyle?: CSSProperties;
  buttonTitle: string;
  buttonLink?: string;
  buttonOnClick?: () => void;
  openInNewTab?: boolean;
};

const PlanItem = (props: PlanItemProps) => {
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <VStack
      style={{ ...props.containerStyle }}
      width={280}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      borderWidth="1px"
      borderRadius={'3xl'}
      padding={6}
      alignItems={'start'}
      boxShadow={'xl'}
      gap={3}
    >
      <Box>
        <Heading size={'md'} color={'gray.500'}>
          {props.name}
        </Heading>
        <Heading marginBottom={0}>{props.price}</Heading>
        {props.subtitle && (
          <Heading size={'sm'} fontWeight={'normal'} color={'gray.500'}>
            {props.subtitle}
          </Heading>
        )}
      </Box>
      {props.features.map((feature, idx) => (
        <HStack key={idx}>
          <Text color={'gray.400'}>✓</Text>
          <Text>{feature}</Text>
        </HStack>
      ))}
      <Button
        style={{ ...props.buttonStyle }}
        colorScheme={props.buttonColorScheme || 'gray'}
        width={'full'}
        as={Link}
        href={props.buttonLink}
        size={'lg'}
        borderRadius={'full'}
        marginTop={5}
        target={props.openInNewTab ? '_blank' : undefined}
        onClick={props.buttonOnClick}
      >
        {props.buttonTitle}
      </Button>
    </VStack>
  );
};

export function Buy({
  title,
  subtitle,
  onClose,
}: {
  title: string;
  subtitle: string;
  onClose: () => void;
}) {
  const [checkoutUrl, setCheckoutUrl] = useState<string>('');

  const [, createCheckoutUrl] = useMutation(CheckoutUrlDocument);

  const { trackEvent } = useMixpanel();

  async function getCheckoutUrl() {
    const checkoutUrl1 = await createCheckoutUrl({});
    if (checkoutUrl1.data && checkoutUrl1.data.createCheckoutUrl)
      setCheckoutUrl(checkoutUrl1.data?.createCheckoutUrl);
  }

  //Getting checkout URL after the component is loaded seems weird / might cause weird behaviour.
  useEffect(() => {
    getCheckoutUrl();
    trackEvent(EventName.BuyModal, {
      'Buy Modal Name': title,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backgroundColor = useColorModeValue('whiteAlpha.700', 'blackAlpha.700');
  const subtitleColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <ModalContent backgroundColor={backgroundColor}>
      <ModalCloseButton />
      <ModalBody padding={20}>
        <Center flexDirection={'column'} marginBottom={10} gap={4}>
          <Heading
            letterSpacing={'wide'}
            size={'2xl'}
            width={'md'}
            textAlign={'center'}
          >
            {title}
          </Heading>
          <Heading
            letterSpacing={'wide'}
            size={'sm'}
            width={'md'}
            textAlign={'center'}
            color={subtitleColor}
          >
            {subtitle}
          </Heading>
        </Center>
        <HStack alignItems={'end'} gap={3} width={'full'}>
          <Box flex={1} />
          <PlanItem
            name="Free"
            price={'0 EUR'}
            features={[
              'Sparkel AI Copilot',
              '2D and 3D Takeoffs',
              '3 Active Projects',
              '2 Active Users',
            ]}
            buttonTitle="Continue with Free Plan"
            buttonOnClick={onClose}
          />
          <PlanItem
            name="Pro"
            subtitle="Per user per month"
            price={'199 EUR'}
            features={[
              'Everything in Free',
              'Unlimited Projects',
              'Unlimited Tables and Items',
              'Federate BIM Models',
              'Advanced Export',
            ]}
            buttonTitle="Upgrade to Pro"
            buttonLink={checkoutUrl}
            openInNewTab
            containerStyle={{
              width: 300,
              boxShadow: `0px 0px 80px ${ChakraColor.cyan500}30`,
            }}
            buttonColorScheme="brand"
            buttonStyle={{
              boxShadow: `0px 0px 20px ${ChakraColor.cyan500}80`,
            }}
          />
          <PlanItem
            name="Enterprise"
            subtitle="Billed annually"
            price={'Custom'}
            features={[
              'Everything in Pro',
              'For 5+ Users',
              'Invoicing and purchase orders',
              'Prioritized Onboarding and Support',
              'Custom Integrations',
            ]}
            buttonTitle="Get in Touch"
            buttonLink="https://www.sparkel.ai/book-meeting"
            openInNewTab
          />
          <Box flex={1} />
        </HStack>
      </ModalBody>
    </ModalContent>
  );
}
