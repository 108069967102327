import {
  MultiPolygon2,
  MultiPolyline2Result,
  Polyline2,
  ResultType,
} from '../../geometric-types';
import { calculateMultiPolylineLength } from '../util/line-segment';

export function calculateSheetShapePerimeter(
  shape: MultiPolygon2
): MultiPolyline2Result {
  const lines: Polyline2[] = [];

  for (const polygon of shape.polygons) {
    lines.push(polygon.exterior);
    for (const interior of polygon.interiors) {
      lines.push(interior);
    }
  }

  const multiPolyline = { lines };

  const totalLength = calculateMultiPolylineLength(multiPolyline);

  return {
    type: ResultType.MULTIPOLYLINE2,
    value: totalLength,
    multiPolyline,
  };
}
