import Polygon from 'jsts/org/locationtech/jts/geom/Polygon';
import GeometryFactory from 'jsts/org/locationtech/jts/geom/GeometryFactory';
import MultiPolygon from 'jsts/org/locationtech/jts/geom/MultiPolygon';
import Coordinate from 'jsts/org/locationtech/jts/geom/Coordinate';
import OverlayOp from 'jsts/org/locationtech/jts/operation/overlay/OverlayOp';
import Geometry from 'jsts/org/locationtech/jts/geom/Geometry';
import {
  MultiPolygon2,
  MultiPolygon2Result,
  ResultType,
} from '../../geometric-types';
import { toJstsMultiPolygon, toMultiPolygon2 } from '../util/polygon';
import { calculateSheetShapeRandfelt } from '../sheet-shape-randfelt';

function generatePoints(
  extendedStart: Coordinate,
  extendedEnd: Coordinate,
  perpendicular: Coordinate,
  widthBuffer: number
) {
  return [
    new Coordinate(
      extendedStart.x + perpendicular.x * widthBuffer,
      extendedStart.y + perpendicular.y * widthBuffer
    ),
    new Coordinate(
      extendedEnd.x + perpendicular.x * widthBuffer,
      extendedEnd.y + perpendicular.y * widthBuffer
    ),
    new Coordinate(
      extendedEnd.x - perpendicular.x * widthBuffer,
      extendedEnd.y - perpendicular.y * widthBuffer
    ),
    new Coordinate(
      extendedStart.x - perpendicular.x * widthBuffer,
      extendedStart.y - perpendicular.y * widthBuffer
    ),
    new Coordinate(
      extendedStart.x + perpendicular.x * widthBuffer,
      extendedStart.y + perpendicular.y * widthBuffer
    ), // closing the ring
  ];
}

function createBufferFromSegment(
  vertex: Coordinate,
  nextVertex: Coordinate,
  widthBuffer: number,
  lengthBuffer: number
): Polygon[] {
  const geomFactory = new GeometryFactory();

  const direction = new Coordinate(
    nextVertex.x - vertex.x,
    nextVertex.y - vertex.y
  );
  const lengthNorm = Math.sqrt(
    direction.x * direction.x + direction.y * direction.y
  );
  const unitDirection = new Coordinate(
    direction.x / lengthNorm,
    direction.y / lengthNorm
  );

  const perpendicular = new Coordinate(-unitDirection.y, unitDirection.x);

  const extendedStart = new Coordinate(
    vertex.x + unitDirection.x * lengthBuffer,
    vertex.y + unitDirection.y * lengthBuffer
  );

  const extendedEnd = new Coordinate(
    vertex.x - unitDirection.x * widthBuffer,
    vertex.y - unitDirection.y * widthBuffer
  );

  const points1 = generatePoints(
    extendedStart,
    extendedEnd,
    perpendicular,
    widthBuffer
  );

  let arrayBuffers = [points1];

  return arrayBuffers.map((point) =>
    geomFactory.createPolygon(
      geomFactory.createLinearRing(point).getCoordinates()
    )
  );
}

function createBuffersAlongEdges(
  linearRing: Geometry,
  widthBuffer: number,
  lengthBuffer: number
): MultiPolygon {
  const geomFactory = new GeometryFactory();
  const polygons: Polygon[] = [];

  const coordinates = linearRing.getCoordinates();

  for (let j = 0; j < coordinates.length - 1; j++) {
    const bufferPolygon = createBufferFromSegment(
      coordinates[j],
      coordinates[j + 1],
      widthBuffer,
      lengthBuffer
    );
    polygons.push(...bufferPolygon);
  }

  for (let j = 1; j < coordinates.length; j++) {
    const bufferPolygon = createBufferFromSegment(
      coordinates[j],
      coordinates[j - 1],
      widthBuffer,
      lengthBuffer
    );
    polygons.push(...bufferPolygon);
  }

  return geomFactory.createMultiPolygon(polygons);
}

export function calculateBoundingBox(polygon: MultiPolygon, unitScale: number) {
  const coordinates = polygon.getCoordinates();
  let minX = coordinates[0].x;
  let maxX = coordinates[0].x;
  let minY = coordinates[0].y;
  let maxY = coordinates[0].y;

  for (let i = 1; i < coordinates.length; i++) {
    if (coordinates[i].x < minX) {
      minX = coordinates[i].x;
    }
    if (coordinates[i].x > maxX) {
      maxX = coordinates[i].x;
    }
    if (coordinates[i].y < minY) {
      minY = coordinates[i].y;
    }
    if (coordinates[i].y > maxY) {
      maxY = coordinates[i].y;
    }
  }

  const width = maxX - minX;
  const height = maxY - minY;

  return Math.max(width * unitScale, height * unitScale);
}

// eslint-disable-next-line complexity
export function calculateSheetShapeCornerAreas(
  multiPolygonInput: MultiPolygon2,
  roofHeight: number | undefined,
  unitScale: number
): MultiPolygon2Result {
  // Calculate bounding box for each polygon
  const jstsMultiPolygon = toJstsMultiPolygon(multiPolygonInput);
  let e = calculateBoundingBox(jstsMultiPolygon, unitScale);

  //TPF Number 5. e is minimum of b and 2H
  if (roofHeight) e = Math.min(e, 2 * roofHeight);

  const geomFactory = new GeometryFactory();

  const widthBufferScaled = e / 10;
  const lengthBufferScaled = e / 4;

  const polygons: Polygon[] = [];

  for (let i = 0; i < jstsMultiPolygon.getNumGeometries(); i++) {
    const polygon = jstsMultiPolygon.getGeometryN(i);

    const exteriorRing = polygon.getExteriorRing();
    const outerBuffer = createBuffersAlongEdges(
      exteriorRing,
      widthBufferScaled / unitScale,
      lengthBufferScaled / unitScale
    );
    for (let j = 0; j < outerBuffer.getNumGeometries(); j++) {
      polygons.push(outerBuffer.getGeometryN(j));
    }
  }

  let segmentsPolygons = geomFactory.createMultiPolygon(polygons);

  let naiveCornerPolygon: MultiPolygon = jstsMultiPolygon;

  for (let i = 0; i < segmentsPolygons.getNumGeometries(); i++) {
    const newPolygons: Polygon[] = [];

    for (let ii = 0; ii < naiveCornerPolygon.getNumGeometries(); ii++) {
      const polygon = naiveCornerPolygon.getGeometryN(ii) as Polygon;
      const difference = OverlayOp.difference(
        polygon,
        segmentsPolygons.getGeometryN(i)
      );

      if (difference instanceof Polygon) {
        newPolygons.push(difference);
      } else if (difference instanceof MultiPolygon) {
        for (let j = 0; j < difference.getNumGeometries(); j++) {
          newPolygons.push(difference.getGeometryN(j) as Polygon);
        }
      }
    }
    naiveCornerPolygon = geomFactory.createMultiPolygon(newPolygons);
  }

  if (naiveCornerPolygon) {
    const cornerPolygonWithinOriginalPolygon = OverlayOp.difference(
      jstsMultiPolygon,
      naiveCornerPolygon
    );

    const randfelt = calculateSheetShapeRandfelt(
      multiPolygonInput,
      roofHeight,
      unitScale
    );
    const diff = OverlayOp.difference(
      cornerPolygonWithinOriginalPolygon,
      toJstsMultiPolygon(randfelt.multiPolygon)
    );

    const finalPolygon = OverlayOp.difference(
      cornerPolygonWithinOriginalPolygon,
      diff
    );

    const value = finalPolygon.getArea();
    const multiPolygon = toMultiPolygon2(finalPolygon);

    return {
      type: ResultType.MULTIPOLYGON2,
      value,
      multiPolygon,
    };
  } else {
    throw new Error('finalMultiPolygon is undefined');
  }
}
