import { Alert, AlertIcon, Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { useExportState } from '../common/ExportStateManagerContext';

export default function ExportProjectTab(): React.ReactElement {
  const { triggerExport, isSuccessful, isExporting } = useExportState();

  const handleExportProject = () => {
    triggerExport();
  };

  return (
    <VStack spacing={2} alignItems={'start'}>
      <Alert status="info" borderRadius={'lg'}>
        <AlertIcon />
        {
          'This will export the entire project to a xlsx-file, including all grouped items. This might take a few minutes depending on the complexity of your project.'
        }
      </Alert>
      {isSuccessful ? (
        <Alert status="success" borderRadius={'lg'}>
          <AlertIcon />
          Project successfully exported
        </Alert>
      ) : undefined}
      <Button
        onClick={handleExportProject}
        alignSelf={'end'}
        isLoading={isExporting}
      >
        Start Export
      </Button>
    </VStack>
  );
}
