import { round, uniq } from 'lodash';
import { QuantityWithUnits } from '../domain/property-types';
import {
  convertSIQuantity,
  formatUnit,
  roundingToPrecision,
  unitToUnitType,
} from './unit-utils';
import { UnitSettingsType } from 'src/gql/graphql';

// eslint-disable-next-line complexity
export const formatQuantity = (
  quantity: QuantityWithUnits,
  unitSettings?: UnitSettingsType,
  showUnitSymbol = true
): string => {
  const formatNumericValue = (value: number, precision: number): string =>
    round(value, precision).toString();

  const formatValue = (value: number | string, precision = 2): string =>
    typeof value === 'number' ? formatNumericValue(value, precision) : value;

  if (typeof quantity.value === 'string') {
    return quantity.value;
  }
  if (typeof quantity.value === 'number') {
    if (quantity.units && unitSettings) {
      const convertedQuantity = convertSIQuantity(quantity, unitSettings);

      const unitType = unitToUnitType(quantity.units);
      const rounding = unitType ? unitSettings[unitType]?.rounding : undefined;
      const precision = rounding ? roundingToPrecision(rounding) : 2;

      let resultString = formatValue(
        convertedQuantity.value as number,
        precision
      );

      if (showUnitSymbol) {
        resultString += ` ${formatUnit(convertedQuantity.units)}`;
      }

      return resultString;
    } else {
      return formatValue(quantity.value);
    }
  }
  if (Array.isArray(quantity.value)) {
    return uniq(
      quantity.value
        .filter((v) => typeof v !== 'number' || !isNaN(v))
        .map((v) => {
          if (quantity.units && unitSettings) {
            const convertedQuantity = convertSIQuantity(
              { value: v, units: quantity.units },
              unitSettings
            );

            const unitType = unitToUnitType(quantity.units);
            const rounding = unitType
              ? unitSettings[unitType]?.rounding
              : undefined;
            const precision = rounding ? roundingToPrecision(rounding) : 2;

            let resultString = formatValue(
              convertedQuantity.value as number,
              precision
            );

            if (showUnitSymbol) {
              resultString += ` ${formatUnit(convertedQuantity.units)}`;
            }

            return resultString;
          }

          return formatValue(v);
        })
    ).join(', ');
  }

  return '';
};
