import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateShapeMutation,
  CreateShapeMutationVariables,
  DeleteShapeMutation,
  DeleteShapeMutationVariables,
} from '../../gql/graphql';

export const createShapeResolver: UpdateResolver = (
  result: CreateShapeMutation,
  args: CreateShapeMutationVariables,
  cache
) => {
  if (result.createShape?.shape) {
    const shapesForProject = cache.resolve(
      { __typename: 'Project', id: result.createShape.shape.projectId },
      'shapes'
    ) as string[] | null;
    const shapeToAdd = cache.keyOfEntity({
      __typename: 'Shape',
      id: result.createShape.shape.id,
    });
    if (shapesForProject && shapeToAdd) {
      shapesForProject.push(shapeToAdd);
      cache.link(
        {
          __typename: 'Project',
          id: result.createShape.shape.projectId,
        },
        'shapes',
        shapesForProject
      );
    }
  }
};

export const deleteShapeResolver: UpdateResolver = (
  result: DeleteShapeMutation,
  args: DeleteShapeMutationVariables,
  cache
) => {
  if (result.deleteShape?.shape) {
    const shapesForProject = cache.resolve(
      { __typename: 'Project', id: result.deleteShape.shape.projectId },
      'shapes'
    ) as string[] | null;
    const shapeToRemove = cache.keyOfEntity({
      __typename: 'Shape',
      id: result.deleteShape.shape.id,
    });
    if (shapesForProject && shapeToRemove) {
      cache.link(
        {
          __typename: 'Project',
          id: result.deleteShape.shape.projectId,
        },
        'shapes',
        shapesForProject.filter((key) => key !== shapeToRemove)
      );
    }
  }
};
