import {
  MultiPolygon,
  MultiPolylineResult,
  Polyline,
  ResultType,
} from '../../geometric-types';
import { calculateMultiPolylineLength } from '../util/line-segment';

export function calculateShapePerimeter(
  shape: MultiPolygon
): MultiPolylineResult {
  const lines: Polyline[] = [];

  for (const polygon of shape.polygons) {
    lines.push(polygon.exterior);
    for (const interior of polygon.interiors) {
      lines.push(interior);
    }
  }

  const multiPolyline = { lines };

  const totalLength = calculateMultiPolylineLength(multiPolyline);

  return {
    type: ResultType.MULTIPOLYLINE,
    value: totalLength,
    multiPolyline,
  };
}
