import { UpdateResolver } from '@urql/exchange-graphcache';
import {
  CreateOrderColumnMutation,
  CreateOrderColumnMutationVariables,
  DeleteOrderColumnMutation,
  DeleteOrderColumnMutationVariables,
} from '../../gql/graphql';

export const createOrderColumnResolver: UpdateResolver = (
  result: CreateOrderColumnMutation,
  args: CreateOrderColumnMutationVariables,
  cache
) => {
  if (result.createOrderColumn?.orderColumn) {
    const createdColumn = result.createOrderColumn.orderColumn;
    const orderColumnsForOrder = cache.resolve(
      { __typename: 'Order', id: createdColumn.orderId },
      'orderColumns'
    ) as string[] | null;
    const orderColumnToAdd = cache.keyOfEntity({
      __typename: 'OrderColumn',
      id: createdColumn.id,
    });
    if (orderColumnsForOrder && orderColumnToAdd) {
      orderColumnsForOrder.push(orderColumnToAdd);
      cache.link(
        {
          __typename: 'Order',
          id: createdColumn.orderId,
        },
        'orderColumns',
        orderColumnsForOrder
      );
    }
  }
};

export const deleteOrderColumnResolver: UpdateResolver = (
  result: DeleteOrderColumnMutation,
  args: DeleteOrderColumnMutationVariables,
  cache
) => {
  if (result.deleteOrderColumn?.orderColumn) {
    const deletedColumnId = result.deleteOrderColumn.orderColumn.id;
    const deletedColumnOrderId = result.deleteOrderColumn.orderColumn.orderId;
    const orderEntriesForOrder = cache.resolve(
      { __typename: 'Order', id: deletedColumnOrderId },
      'orderEntries'
    ) as string[] | null;
    orderEntriesForOrder?.forEach((orderEntry) => {
      const customFields = cache.resolve(orderEntry, 'customFields') as
        | string[]
        | null;
      const updatedCustomFields =
        customFields?.filter(
          (key) => cache.resolve(key, 'orderColumnId') !== deletedColumnId
        ) ?? [];
      cache.link(orderEntry, 'customFields', updatedCustomFields);
    });

    const orderColumnsForOrder = cache.resolve(
      { __typename: 'Order', id: deletedColumnOrderId },
      'orderColumns'
    ) as string[] | null;
    const orderColumnToRemove = cache.keyOfEntity({
      __typename: 'OrderColumn',
      id: deletedColumnId,
    });
    if (orderColumnsForOrder && orderColumnToRemove) {
      cache.link(
        {
          __typename: 'Order',
          id: deletedColumnOrderId,
        },
        'orderColumns',
        orderColumnsForOrder.filter((key) => key != orderColumnToRemove)
      );
    }
  }
};
