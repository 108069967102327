import { produce } from 'immer';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import {
  bestFitPlane,
  calculatePlaneEquation,
  projectPointOnPlane,
  to3dPoints,
  toPlanarPoints,
} from '../../../domain/geometry/algorithms/util/plane';
import {
  LineSegment2,
  LinearRing,
  LinearRing2,
  Plane,
  Point,
} from '../../../domain/geometry/geometric-types';
import {
  EdgeIntersections,
  EdgeWithId,
  addEdge,
  copy,
  intersects,
  removeEdge,
  updateEdge,
} from '../../../services/viewer/shape-drawing/intersections';
import { mergeShapes } from '../../../services/viewer/shape-drawing/one-click-shapes/polygon-union-shape';
import {
  LinearRing2WithId,
  LinearRing3WithId,
  Point2WithId,
  Point3WithId,
  generateIdForEdge,
  generateIdForPoint,
  generateIdForRing,
  generateIdsForEdges,
  generateIdsForPoints,
  getArcPoints,
  getPositions,
  toPoint2WithId,
  toPoints2WithId,
} from './util';

export type PolygonDrawingState = {
  completedRings: LinearRing3WithId[];
  completedRings2: LinearRing2WithId[];
  incompleteRing: Point3WithId[];
  incompleteRing2: Point2WithId[];
  mousePointerPoint: Point | null;
  intersectionsForIncompleteRing: EdgeIntersections;
  intersectionsForCompletedRings: EdgeIntersections;
  plane: Plane | null;
  thickness?: number;
};

export enum PolygonDrawingActionType {
  ADD_INCOMPLETE_RING_POINT,
  ADD_ONE_CLICK_SHAPE,
  REMOVE_INCOMPLETE_RING_POINT,
  REMOVE_COMPLETE_RING_POINT,
  REMOVE_COMPLETE_RING,
  MOVE_INCOMPLETE_RING_POINT,
  MOVE_INCOMPLETE_RING_EDGE,
  SPLIT_INCOMPLETE_RING_EDGE,
  MOVE_COMPLETE_RING_POINT,
  MOVE_COMPLETE_RING_EDGE,
  SPLIT_COMPLETE_RING_EDGE,
  SET_MOUSE_POINTER_POINT,
  CLOSE_RING,
  FLIP_PLANE_NORMAL,
}

export type PolygonDrawingAction =
  | {
      type: PolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT;
      position: Point;
      isAltKeyPressed?: boolean;
    }
  | {
      type: PolygonDrawingActionType.ADD_ONE_CLICK_SHAPE;
      rings: LinearRing[];
      plane: Plane;
    }
  | {
      type: PolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT;
      pointId: string;
    }
  | {
      type: PolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT;
      pointId: string;
      ringId: string;
    }
  | {
      type: PolygonDrawingActionType.REMOVE_COMPLETE_RING;
      ringId: string;
    }
  | {
      type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT;
      position: Point;
      pointId: string;
    }
  | {
      type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE;
      startPositionTranslation: Point;
      endPositionTranslation: Point;
      startPointId: string;
    }
  | {
      type: PolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE;
      splitPosition: Point;
      startPointId: string;
    }
  | {
      type: PolygonDrawingActionType.MOVE_COMPLETE_RING_POINT;
      position: Point;
      pointId: string;
      ringId: string;
    }
  | {
      type: PolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE;
      startPositionTranslation: Point;
      endPositionTranslation: Point;
      startPointId: string;
      ringId: string;
    }
  | {
      type: PolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE;
      splitPosition: Point;
      startPointId: string;
      ringId: string;
    }
  | {
      type: PolygonDrawingActionType.SET_MOUSE_POINTER_POINT;
      point: Point | null;
    }
  | { type: PolygonDrawingActionType.CLOSE_RING }
  | { type: PolygonDrawingActionType.FLIP_PLANE_NORMAL };

// eslint-disable-next-line complexity
export const polygonDrawingReducer = (
  state: PolygonDrawingState,
  action: PolygonDrawingAction
): PolygonDrawingState => {
  switch (action.type) {
    case PolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT:
      return handleAddPoint(state, action);
    case PolygonDrawingActionType.ADD_ONE_CLICK_SHAPE:
      return handleAddOneClickShape(state, action);
    case PolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT:
      return handleRemoveIncompleteRingPoint(state, action);
    case PolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT:
      return handleRemoveCompleteRingPoint(state, action);
    case PolygonDrawingActionType.REMOVE_COMPLETE_RING:
      return handleRemoveCompleteRing(state, action);
    case PolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT:
      return handleMoveIncompleteRingPoint(state, action);
    case PolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE:
      return handleMoveIncompleteRingEdge(state, action);
    case PolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE:
      return handleSplitIncompleteRingEdge(state, action);
    case PolygonDrawingActionType.MOVE_COMPLETE_RING_POINT:
      return handleMoveCompleteRingPoint(state, action);
    case PolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE:
      return handleMoveCompleteRingEdge(state, action);
    case PolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE:
      return handleSplitCompleteRingEdge(state, action);
    case PolygonDrawingActionType.SET_MOUSE_POINTER_POINT:
      return handleSetMousePointerPoint(state, action);
    case PolygonDrawingActionType.CLOSE_RING:
      return handleCloseRing(state, action);
    case PolygonDrawingActionType.FLIP_PLANE_NORMAL:
      return handleFlipPlaneNormal(state, action);
  }
};

// eslint-disable-next-line complexity
const handleAddPoint = produce(function (
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.ADD_INCOMPLETE_RING_POINT;
    isAltKeyPressed?: boolean;
  }
) {
  const newPointWithId = generateIdForPoint(action.position);

  if (state.plane) {
    const newPoint2WithId = toPoint2WithId(newPointWithId, state.plane);

    if (action.isAltKeyPressed) {
      const endPoint = state.incompleteRing.pop();
      const endPoint2 = state.incompleteRing2.pop();
      const startPoint = state.incompleteRing.pop();
      const startPoint2 = state.incompleteRing2.pop();
      if (startPoint && endPoint && startPoint2 && endPoint2) {
        const arcPoints = to3dPoints(
          getArcPoints(
            startPoint2.point,
            endPoint2.point,
            newPoint2WithId.point
          ),
          state.plane
        );

        const arcPointsWithIds = arcPoints.map((point) =>
          generateIdForPoint(point)
        );
        const arcPoints2WithIds = toPoints2WithId(
          arcPointsWithIds,
          state.plane
        );

        state.incompleteRing.push(...arcPointsWithIds);
        state.incompleteRing2.push(...arcPoints2WithIds);
      }
      return;
    }

    if (state.incompleteRing2.length >= 1) {
      const oldEdges = generateIdsForEdges(state.incompleteRing2);
      const newEdge: EdgeWithId = generateIdForEdge(
        state.incompleteRing2[state.incompleteRing2.length - 1],
        newPoint2WithId
      );
      addEdge(state.intersectionsForIncompleteRing, oldEdges, newEdge);
    }
    state.incompleteRing.push(newPointWithId);
    state.incompleteRing2.push(newPoint2WithId);
  } else {
    if (action.isAltKeyPressed && state.incompleteRing.length === 2) {
      const endPoint = state.incompleteRing.pop();
      const startPoint = state.incompleteRing.pop();

      if (startPoint && endPoint) {
        const intermediatePlane = calculatePlaneEquation([
          endPoint.point,
          startPoint.point,
          action.position,
        ]);

        const startPoint2 = toPoint2WithId(startPoint, intermediatePlane);
        const endPoint2 = toPoint2WithId(endPoint, intermediatePlane);

        const newPoint2WithId = toPoint2WithId(
          newPointWithId,
          intermediatePlane
        );
        const arcPoints = to3dPoints(
          getArcPoints(
            startPoint2.point,
            endPoint2.point,
            newPoint2WithId.point
          ),
          intermediatePlane
        );

        const arcPointsWithIds = arcPoints.map((point) =>
          generateIdForPoint(point)
        );
        const arcPoints2WithIds = toPoints2WithId(
          arcPointsWithIds,
          intermediatePlane
        );

        state.incompleteRing.push(...arcPointsWithIds);
        state.incompleteRing2.push(...arcPoints2WithIds);

        return;
      }
    } else {
      state.incompleteRing.push(newPointWithId);
    }

    state.plane = bestFitPlane(getPositions(state.incompleteRing));

    // If plane is first set, transform existing points to 2d
    if (state.plane) {
      state.incompleteRing2 = toPoints2WithId(
        state.incompleteRing,
        state.plane
      );
      for (let i = 0; i < state.incompleteRing2.length - 1; i++) {
        const edge = generateIdForEdge(
          state.incompleteRing2[i],
          state.incompleteRing2[i + 1]
        );
        addEdge(
          state.intersectionsForIncompleteRing,
          generateIdsForEdges(state.incompleteRing2.slice(0, i)),
          edge
        );
      }
    }
  }
});

function ringsIntersect(
  ring1: LinearRing2,
  ring2: LinearRing2,
  tolerance = 0
): boolean {
  for (let i = 0; i < ring1.length - 1; i++) {
    const edge1: LineSegment2 = [ring1[i], ring1[i + 1]];
    for (let j = 0; j < ring2.length - 1; j++) {
      const edge2: LineSegment2 = [ring2[j], ring2[j + 1]];
      if (intersects(edge1, edge2, tolerance)) {
        return true;
      }
    }
  }
  return false;
}

const bufferAmount = 0.02;

const handleAddOneClickShape = produce(function (
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.ADD_ONE_CLICK_SHAPE;
  }
) {
  if (state.plane) {
    const plane = state.plane;
    invariant(plane);
    // Project ring on plane because the new one click shape might be in a different plane
    const projectedRings: LinearRing[] = action.rings.map((ring) =>
      ring.map((point) => projectPointOnPlane(point, plane))
    );

    for (const newRing of projectedRings) {
      const newRing2 = toPlanarPoints(newRing, plane);
      const intersectingExistingRings: LinearRing2WithId[] = [];
      for (const existingRing of state.completedRings2) {
        if (
          ringsIntersect(
            newRing2,
            getPositions(existingRing.linearRing),
            bufferAmount
          )
        ) {
          intersectingExistingRings.push(existingRing);
        }
      }
      // If new ring doesn't intersect with existing rings, add the new ring
      // otherwise, merge intersecting rings
      if (intersectingExistingRings.length === 0) {
        const newRingWithId = generateIdForRing(generateIdsForPoints(newRing));
        const newRing2WithId = {
          id: newRingWithId.id,
          linearRing: toPoints2WithId(newRingWithId.linearRing, plane),
        };
        state.completedRings.push(newRingWithId);
        state.completedRings2.push(newRing2WithId);
      } else {
        const intersectingRingIds = intersectingExistingRings.map(
          (ring) => ring.id
        );
        const ringsToMerge: LinearRing2[] = [
          ...intersectingExistingRings.map((ring) =>
            getPositions(ring.linearRing)
          ),
          newRing2,
        ];

        const merged = mergeShapes(ringsToMerge, bufferAmount);

        const newRings2WithId: LinearRing2WithId[] = merged.map((ring) =>
          generateIdForRing(generateIdsForPoints(ring))
        );
        const newRings3WithId: LinearRing3WithId[] = newRings2WithId.map(
          (ring, ringIndex) => {
            const ringPositions = getPositions(ring.linearRing);
            const ring3 = to3dPoints(ringPositions, plane);

            return {
              id: newRings2WithId[ringIndex].id,
              linearRing: ring.linearRing.map((point, pointIndex) => ({
                id: point.id,
                point: ring3[pointIndex],
              })),
            };
          }
        );

        state.completedRings = [
          ...state.completedRings.filter(
            (ring) => !intersectingRingIds.includes(ring.id)
          ),
          ...newRings3WithId,
        ];

        state.completedRings2 = [
          ...state.completedRings2.filter(
            (ring) => !intersectingRingIds.includes(ring.id)
          ),
          ...newRings2WithId,
        ];
        intersectingExistingRings.forEach((ring) => {
          const edges = generateIdsForEdges(ring.linearRing);
          edges.forEach((edge) =>
            removeEdge(state.intersectionsForCompletedRings, edge.id)
          );
        });
        const newEdges = newRings2WithId
          .map((ring) => generateIdsForEdges(ring.linearRing))
          .flat();
        const allEdges2 = state.completedRings2
          .map((ring) => generateIdsForEdges(ring.linearRing))
          .flat();
        newEdges.forEach((edge) =>
          addEdge(state.intersectionsForCompletedRings, allEdges2, edge)
        );
      }
    }
  } else {
    state.plane = action.plane;
    state.completedRings = action.rings.map((ring) => {
      const pointsWithids = generateIdsForPoints(ring);
      const ringWithId = generateIdForRing(pointsWithids);
      return ringWithId;
    });
    state.completedRings2 = state.completedRings.map((ring) => {
      const points2 = toPoints2WithId(ring.linearRing, action.plane);
      return {
        id: ring.id,
        linearRing: points2,
      };
    });
  }
  state.incompleteRing = [];
  state.incompleteRing2 = [];
  state.intersectionsForIncompleteRing = new Map();
  state.mousePointerPoint = null;
});

const handleRemoveIncompleteRingPoint = produce(
  (
    state: PolygonDrawingState,
    action: PolygonDrawingAction & {
      type: PolygonDrawingActionType.REMOVE_INCOMPLETE_RING_POINT;
    }
  ) => {
    const pointIndex = state.incompleteRing.findIndex(
      (point) => point.id === action.pointId
    );
    // Remove edge from previous point to current point (if any)
    if (pointIndex !== 0) {
      const edge = generateIdForEdge(
        state.incompleteRing[pointIndex - 1],
        state.incompleteRing[pointIndex]
      );
      removeEdge(state.intersectionsForIncompleteRing, edge.id);
    }
    // Remove edge from current point to next point (if any)
    if (pointIndex !== state.incompleteRing.length - 1) {
      const edge = generateIdForEdge(
        state.incompleteRing[pointIndex],
        state.incompleteRing[pointIndex + 1]
      );
      removeEdge(state.intersectionsForIncompleteRing, edge.id);
    }
    // Add edge from previous point to next point (if any)
    if (pointIndex !== 0 && pointIndex !== state.incompleteRing.length - 1) {
      const edge = generateIdForEdge(
        state.incompleteRing2[pointIndex - 1],
        state.incompleteRing2[pointIndex + 1]
      );
      addEdge(
        state.intersectionsForIncompleteRing,
        generateIdsForEdges(state.incompleteRing2),
        edge
      );
    }
    state.incompleteRing.splice(pointIndex, 1);
    state.incompleteRing2.splice(pointIndex, 1);
  }
);

const handleRemoveCompleteRingPoint = produce(
  (
    state: PolygonDrawingState,
    action: PolygonDrawingAction & {
      type: PolygonDrawingActionType.REMOVE_COMPLETE_RING_POINT;
    }
  ) => {
    const ringIndex = state.completedRings.findIndex(
      (ring) => ring.id === action.ringId
    );
    const ring = state.completedRings[ringIndex].linearRing;
    if (ring.length === 4) {
      // If ring has only 3 different points, remove the ring
      state.completedRings.splice(ringIndex, 1);
      state.completedRings2.splice(ringIndex, 1);
      for (const edge of generateIdsForEdges(ring)) {
        removeEdge(state.intersectionsForCompletedRings, edge.id);
      }
      return;
    }

    const pointIndex = ring.findIndex((point) => point.id === action.pointId);
    const ring2 = state.completedRings2[ringIndex].linearRing;

    const point = ring2[pointIndex];
    const pointBefore =
      ring2[pointIndex === 0 ? ring2.length - 2 : pointIndex - 1];
    const pointAfter =
      pointIndex === ring2.length - 1 ? ring2[1] : ring2[pointIndex + 1];
    // Remove edge from previous point
    removeEdge(
      state.intersectionsForCompletedRings,
      generateIdForEdge(pointBefore, point).id
    );
    // Remove edge from current point to next point
    removeEdge(
      state.intersectionsForCompletedRings,
      generateIdForEdge(point, pointAfter).id
    );

    // Add edge from previous point to next point
    const newEdge = generateIdForEdge(pointBefore, pointAfter);
    addEdge(
      state.intersectionsForCompletedRings,
      generateIdsForEdges(ring2),
      newEdge
    );

    // Update points. If first / last point is removed, set new last/first point to satisfy first point === last point
    ring.splice(pointIndex, 1);
    ring2.splice(pointIndex, 1);
    if (pointIndex === 0) {
      ring[ring.length - 1] = ring[0];
      ring2[ring2.length - 1] = ring2[0];
    } else if (pointIndex === ring.length) {
      ring[0] = ring[ring.length - 1];
      ring2[0] = ring2[ring2.length - 1];
    }
  }
);

const handleRemoveCompleteRing = produce(
  (
    state: PolygonDrawingState,
    action: PolygonDrawingAction & {
      type: PolygonDrawingActionType.REMOVE_COMPLETE_RING;
    }
  ) => {
    const ringIndex = state.completedRings.findIndex(
      (ring) => ring.id === action.ringId
    );
    const ring = state.completedRings[ringIndex].linearRing;
    state.completedRings.splice(ringIndex, 1);
    state.completedRings2.splice(ringIndex, 1);
    for (const edge of generateIdsForEdges(ring)) {
      removeEdge(state.intersectionsForCompletedRings, edge.id);
    }
  }
);

function handleMoveIncompleteRingPoint(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_POINT;
  }
): PolygonDrawingState {
  const index = state.incompleteRing.findIndex(
    (point) => point.id === action.pointId
  );
  invariant(index >= 0, 'point not found');
  const newPoints = [...state.incompleteRing];
  const newPoint = {
    id: action.pointId,
    point: action.position,
  };
  newPoints[index] = newPoint;
  let newPoints2 = state.incompleteRing2;
  if (state.plane) {
    newPoints2 = [...newPoints2];
    newPoints2[index] = toPoint2WithId(newPoint, state.plane);
  }

  if (!state.plane || newPoints.length < 2) {
    return {
      ...state,
      mousePointerPoint: null,
      incompleteRing: newPoints,
      incompleteRing2: newPoints2,
    };
  }

  const newEdges2 = generateIdsForEdges(newPoints2);
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  if (index !== 0) {
    const updatedIncomingEdge = generateIdForEdge(
      newPoints2[index - 1],
      newPoints2[index]
    );
    updateEdge(newIntersections, newEdges2, updatedIncomingEdge);
  }
  if (index !== newPoints.length - 1) {
    const updatedOutgoingEdge = generateIdForEdge(
      newPoints2[index],
      newPoints2[index + 1]
    );
    updateEdge(newIntersections, newEdges2, updatedOutgoingEdge);
  }
  return {
    ...state,
    incompleteRing: newPoints,
    incompleteRing2: newPoints2,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleMoveIncompleteRingEdge(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.MOVE_INCOMPLETE_RING_EDGE;
  }
): PolygonDrawingState {
  const newPoints = [...state.incompleteRing];
  const index = newPoints.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(index >= 0, 'point not found');
  const newStartPoint = {
    id: action.startPointId,
    point: math.add(newPoints[index].point, action.startPositionTranslation),
  };
  const newEndPoint = {
    id: newPoints[index + 1].id,
    point: math.add(newPoints[index + 1].point, action.endPositionTranslation),
  };
  newPoints[index] = newStartPoint;
  newPoints[index + 1] = newEndPoint;
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  let newPoints2 = state.incompleteRing2;
  if (state.plane) {
    newPoints2 = [...newPoints2];
    newPoints2[index] = toPoint2WithId(newStartPoint, state.plane);
    newPoints2[index + 1] = toPoint2WithId(newEndPoint, state.plane);
    const newEdges2 = generateIdsForEdges(newPoints2);
    // Update previous edge
    if (index !== 0) {
      updateEdge(
        newIntersections,
        newEdges2,
        generateIdForEdge(newPoints2[index - 1], newPoints2[index])
      );
    }
    // Update current edge
    updateEdge(
      newIntersections,
      newEdges2,
      generateIdForEdge(newPoints2[index], newPoints2[index + 1])
    );

    // Update next edge
    if (index !== newPoints2.length - 2) {
      updateEdge(
        newIntersections,
        newEdges2,
        generateIdForEdge(newPoints2[index + 1], newPoints2[index + 2])
      );
    }
  }

  return {
    ...state,
    incompleteRing: newPoints,
    incompleteRing2: newPoints2,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSplitIncompleteRingEdge(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.SPLIT_INCOMPLETE_RING_EDGE;
  }
): PolygonDrawingState {
  const newPoints = [...state.incompleteRing];
  const index = newPoints.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(index >= 0, 'point not found');
  const newPointWithId = generateIdForPoint(action.splitPosition);
  newPoints.splice(index + 1, 0, newPointWithId);
  let newPoints2 = state.incompleteRing2;
  const newIntersections = copy(state.intersectionsForIncompleteRing);
  if (state.plane) {
    newPoints2 = [...newPoints2];
    const newPoint2 = toPoint2WithId(newPointWithId, state.plane);
    newPoints2.splice(index + 1, 0, newPoint2);

    const newEdges = generateIdsForEdges(newPoints2);
    const edgeToNewPoint = generateIdForEdge(
      newPoints2[index],
      newPoints2[index + 1]
    );
    const edgeFromNewPoint = generateIdForEdge(
      newPoints2[index + 1],
      newPoints2[index + 2]
    );
    const oldEdge = generateIdForEdge(
      state.incompleteRing[index],
      state.incompleteRing[index + 1]
    );
    removeEdge(newIntersections, oldEdge.id);
    addEdge(newIntersections, newEdges, edgeToNewPoint);
    addEdge(newIntersections, newEdges, edgeFromNewPoint);
  }
  return {
    ...state,
    incompleteRing: newPoints,
    incompleteRing2: newPoints2,
    intersectionsForIncompleteRing: newIntersections,
    mousePointerPoint: null,
  };
}

function handleMoveCompleteRingPoint(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.MOVE_COMPLETE_RING_POINT;
  }
): PolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const pointIndex = ring.findIndex((point) => point.id === action.pointId);
  invariant(pointIndex >= 0, 'point not found');
  const newRing = [...ring];
  const newPoint = {
    id: action.pointId,
    point: action.position,
  };
  newRing[pointIndex] = newPoint;
  if (pointIndex === 0) {
    // Update last point to be the same as the first point
    newRing[newRing.length - 1] = newPoint;
  } else if (pointIndex === newRing.length - 1) {
    // Update first point to be the same as the last point
    newRing[0] = newPoint;
  }
  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };

  const newIntersections = copy(state.intersectionsForCompletedRings);
  let newRings2 = state.completedRings2;
  if (state.plane) {
    newRings2 = [...newRings2];
    const newRing2 = [...newRings2[ringIndex].linearRing];
    const newPoint2 = toPoint2WithId(newPoint, state.plane);

    newRing2[pointIndex] = newPoint2;
    if (pointIndex === 0) {
      // Update last point to be the same as the first point
      newRing2[newRing2.length - 1] = newPoint2;
    } else if (pointIndex === newRing2.length - 1) {
      // Update first point to be the same as the last point
      newRing2[0] = newPoint2;
    }
    newRings2[ringIndex] = {
      id: action.ringId,
      linearRing: newRing2,
    };
    const newEdges = newRings2
      .map((ring) => generateIdsForEdges(ring.linearRing))
      .flat();
    // -2 because the last point is a duplicate of the first point,
    const pointBefore =
      newRing2[pointIndex === 0 ? newRing.length - 2 : pointIndex - 1];
    const pointAfter =
      pointIndex === newRing.length - 1
        ? newRing2[1]
        : newRing2[pointIndex + 1];
    const updatedIncomingEdge = generateIdForEdge(pointBefore, newPoint2);
    updateEdge(newIntersections, newEdges, updatedIncomingEdge);
    const updatedOutgoingEdge = generateIdForEdge(newPoint2, pointAfter);
    updateEdge(newIntersections, newEdges, updatedOutgoingEdge);
  }

  return {
    ...state,
    intersectionsForCompletedRings: newIntersections,
    completedRings: newRings,
    completedRings2: newRings2,
    mousePointerPoint: null,
  };
}

function handleMoveCompleteRingEdge(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.MOVE_COMPLETE_RING_EDGE;
  }
): PolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const newRing = [...ring];
  const startPointIndex = newRing.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(startPointIndex >= 0, 'point not found');
  const newStartPoint = {
    id: action.startPointId,
    point: math.add(
      newRing[startPointIndex].point,
      action.startPositionTranslation
    ),
  };
  const newEndPoint = {
    id: newRing[startPointIndex + 1].id,
    point: math.add(
      newRing[startPointIndex + 1].point,
      action.endPositionTranslation
    ),
  };
  newRing[startPointIndex] = newStartPoint;
  newRing[startPointIndex + 1] = newEndPoint;
  if (startPointIndex === 0) {
    // Update last point to be the same as the first point
    newRing[newRing.length - 1] = newStartPoint;
  } else if (startPointIndex + 1 === newRing.length - 1) {
    // Update first point to be the same as the last point
    newRing[0] = newEndPoint;
  }

  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };

  const newIntersections = copy(state.intersectionsForCompletedRings);
  let newRings2 = state.completedRings2;
  if (state.plane) {
    newRings2 = [...newRings2];
    const newStartPoint2 = toPoint2WithId(newStartPoint, state.plane);
    const newEndPoint2 = toPoint2WithId(newEndPoint, state.plane);
    const newRing2 = [...newRings2[ringIndex].linearRing];

    newRing2[startPointIndex] = newStartPoint2;
    newRing2[startPointIndex + 1] = newEndPoint2;
    if (startPointIndex === 0) {
      // Update last point to be the same as the first point
      newRing2[newRing2.length - 1] = newStartPoint2;
    } else if (startPointIndex + 1 === newRing2.length - 1) {
      // Update first point to be the same as the last point
      newRing2[0] = newEndPoint2;
    }

    newRings2[ringIndex] = {
      id: action.ringId,
      linearRing: newRing2,
    };
    const newEdges = newRings2
      .map((ring) => generateIdsForEdges(ring.linearRing))
      .flat();

    const endPointOfPreviousEdge =
      newRing2[
        startPointIndex === 0 ? newRing2.length - 2 : startPointIndex - 1
      ];

    const startPointOfNextEdge =
      newRing2[
        startPointIndex + 2 === newRing2.length ? 1 : startPointIndex + 2
      ];
    // Update previous edge
    updateEdge(
      newIntersections,
      newEdges,
      generateIdForEdge(endPointOfPreviousEdge, newStartPoint2)
    );
    // Update current edge
    updateEdge(
      newIntersections,
      newEdges,
      generateIdForEdge(newStartPoint2, newEndPoint2)
    );

    // Update next edge
    updateEdge(
      newIntersections,
      newEdges,
      generateIdForEdge(newEndPoint2, startPointOfNextEdge)
    );
  }

  return {
    ...state,
    completedRings: newRings,
    completedRings2: newRings2,
    intersectionsForCompletedRings: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSplitCompleteRingEdge(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.SPLIT_COMPLETE_RING_EDGE;
  }
): PolygonDrawingState {
  const ring = state.completedRings.find(
    (ring) => ring.id === action.ringId
  )?.linearRing;
  invariant(ring, 'ring not found');
  const newRing = [...ring];
  const startPointIndex = newRing.findIndex(
    (point) => point.id === action.startPointId
  );
  invariant(startPointIndex >= 0, 'point not found');
  const newPointWithId = generateIdForPoint(action.splitPosition);
  newRing.splice(startPointIndex + 1, 0, newPointWithId);

  const newRings = [...state.completedRings];
  const ringIndex = newRings.findIndex((ring) => ring.id === action.ringId);
  invariant(ringIndex >= 0, 'ring not found');
  newRings[ringIndex] = {
    id: action.ringId,
    linearRing: newRing,
  };

  const newIntersections = copy(state.intersectionsForCompletedRings);
  let newRings2 = state.completedRings2;
  if (state.plane) {
    newRings2 = [...newRings2];
    const newRing2 = [...newRings2[ringIndex].linearRing];
    const newPoint2 = toPoint2WithId(newPointWithId, state.plane);

    newRing2.splice(startPointIndex + 1, 0, newPoint2);
    newRings2[ringIndex] = {
      id: action.ringId,
      linearRing: newRing2,
    };

    const newEdges = newRings2
      .map((ring) => generateIdsForEdges(ring.linearRing))
      .flat();

    const edgeToNewPoint = generateIdForEdge(
      newRing2[startPointIndex],
      newRing2[startPointIndex + 1]
    );
    const edgeFromNewPoint = generateIdForEdge(
      newRing2[startPointIndex + 1],
      newRing2[startPointIndex + 2]
    );
    const oldEdge = generateIdForEdge(
      state.completedRings2[ringIndex].linearRing[startPointIndex],
      state.completedRings2[ringIndex].linearRing[startPointIndex + 1]
    );
    removeEdge(newIntersections, oldEdge.id);
    addEdge(newIntersections, newEdges, edgeToNewPoint);
    addEdge(newIntersections, newEdges, edgeFromNewPoint);
  }

  return {
    ...state,
    completedRings: newRings,
    completedRings2: newRings2,
    intersectionsForCompletedRings: newIntersections,
    mousePointerPoint: null,
  };
}

function handleSetMousePointerPoint(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.SET_MOUSE_POINTER_POINT;
  }
): PolygonDrawingState {
  if (state.incompleteRing.length === 0) {
    return state;
  }
  return {
    ...state,
    mousePointerPoint: action.point,
  };
}

function handleCloseRing(
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.CLOSE_RING;
  }
): PolygonDrawingState {
  if (state.incompleteRing.length < 3 || !state.plane) {
    return state;
  }
  const intersectionsForCompletedRings = copy(
    state.intersectionsForCompletedRings
  );
  const completedRing = generateIdForRing([
    ...state.incompleteRing,
    state.incompleteRing[0],
  ]);

  const completedRing2: LinearRing2WithId = {
    id: completedRing.id,
    linearRing: [...state.incompleteRing2, state.incompleteRing2[0]],
  };
  const newEdges = generateIdsForEdges(completedRing2.linearRing);

  // Update intersections for completed rings
  const completedEdges = [
    ...state.completedRings2
      .map((ring) => generateIdsForEdges(ring.linearRing))
      .flat(),
    ...newEdges,
  ];

  for (const newEdge of newEdges) {
    addEdge(intersectionsForCompletedRings, completedEdges, newEdge);
  }

  return {
    ...state,
    completedRings: [...state.completedRings, completedRing],
    completedRings2: [...state.completedRings2, completedRing2],
    incompleteRing: [],
    incompleteRing2: [],
    intersectionsForIncompleteRing: new Map(),
    intersectionsForCompletedRings,
    mousePointerPoint: null,
  };
}

const handleFlipPlaneNormal = produce(function (
  state: PolygonDrawingState,
  action: PolygonDrawingAction & {
    type: PolygonDrawingActionType.FLIP_PLANE_NORMAL;
  }
) {
  if (state.plane) {
    // 1. Flip the normal of the plane and adjust the plane coefficient
    state.plane = {
      unitNormal: math.multiply(state.plane.unitNormal, -1),
      planeCoefficient: -state.plane.planeCoefficient,
    };

    // 2. Recalculate 2D projections of all points with the new plane
    // Update incompleteRing2
    state.incompleteRing2 = toPoints2WithId(state.incompleteRing, state.plane);

    // Update completedRings2
    state.completedRings2 = state.completedRings.map((ring3D) => {
      const ring2DWithId = toPoints2WithId(
        ring3D.linearRing,
        state.plane as Plane
      );
      return {
        linearRing: ring2DWithId,
        id: ring3D.id,
      };
    });

    // 3. Recalculate intersections for incomplete ring
    state.intersectionsForIncompleteRing = new Map();
    if (state.incompleteRing2.length > 1) {
      for (let i = 0; i < state.incompleteRing2.length - 1; i++) {
        const edge = generateIdForEdge(
          state.incompleteRing2[i],
          state.incompleteRing2[i + 1]
        );
        addEdge(
          state.intersectionsForIncompleteRing,
          generateIdsForEdges(state.incompleteRing2.slice(0, i)),
          edge
        );
      }
    }

    // Recalculate intersections for completed rings
    state.intersectionsForCompletedRings = new Map();
    state.completedRings2.forEach((ring2D) => {
      const edges = generateIdsForEdges(ring2D.linearRing);
      for (let i = 0; i < edges.length; i++) {
        addEdge(
          state.intersectionsForCompletedRings,
          edges.slice(0, i),
          edges[i]
        );
      }
    });
  }
});
