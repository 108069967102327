import { isEmpty, omit, pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useViewer } from '../components/common/ForgeViewer';
import { useSheetViewer } from '../components/common/SheetViewer';
import { useSelection } from '../services/viewer/selection';
import { useSheetShapes } from '../hooks/sheet-shapes';

// Hacky little helper for clearing the selection in the sheet viewer when the user clicks on the forge viewer and vice versa
export const ClearOtherViewerSelection = () => {
  const {
    viewer,
    loadedModels,
    shapesManager: { loadedShapeModels },
  } = useViewer();
  const { viewerRef: sheetViewerRef } = useSheetViewer();
  const { selectedDbIds, setSelectedDbIds } = useSelection();
  const {
    drawing: {
      isMovingShapes: isMovingShapes2D,
      isCopyingShapes: isCopyingShapes2D,
    },
  } = useSheetShapes();
  const [isPointerDown, setIsPointerDown] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);

  useEffect(() => {
    const sheetViewer = sheetViewerRef.current;
    if (sheetViewer && viewer) {
      const clearBimElementsHandler = (e: MouseEvent) => {
        if (
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.button !== 0 ||
          isEmpty(Object.keys(selectedDbIds)) ||
          isMovingShapes2D ||
          isCopyingShapes2D
        ) {
          return;
        }
        setSelectedDbIds(
          omit(
            selectedDbIds,
            Object.keys(loadedModels),
            Object.keys(loadedShapeModels)
          )
        );
      };

      const clearSheetElementsHandler = (e: MouseEvent) => {
        setIsPointerDown(false);

        if (
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.button !== 0 ||
          hasDragged ||
          isEmpty(Object.keys(selectedDbIds))
        ) {
          return;
        }
        setSelectedDbIds(
          pick(
            selectedDbIds,
            Object.keys(loadedModels),
            Object.keys(loadedShapeModels)
          )
        );
      };

      const pointerDownHandler = () => {
        setIsPointerDown(true);
        setHasDragged(false);
      };
      const pointerMoveHandler = () => {
        if (isPointerDown) {
          setHasDragged(true);
        }
      };

      sheetViewer.addEventListener('click', clearBimElementsHandler);

      viewer.container.addEventListener('pointerdown', pointerDownHandler);
      viewer.container.addEventListener('pointermove', pointerMoveHandler);
      viewer.container.addEventListener('pointerup', clearSheetElementsHandler);

      return () => {
        sheetViewer.removeEventListener('click', clearBimElementsHandler);

        viewer.container.removeEventListener('pointerdown', pointerDownHandler);
        viewer.container.removeEventListener('pointermove', pointerMoveHandler);
        viewer.container.removeEventListener(
          'pointerup',
          clearSheetElementsHandler
        );
      };
    }
  }, [
    hasDragged,
    isCopyingShapes2D,
    isMovingShapes2D,
    isPointerDown,
    loadedModels,
    loadedShapeModels,
    selectedDbIds,
    setSelectedDbIds,
    sheetViewerRef,
    viewer,
  ]);

  return null;
};
