import { maxBy, sum } from 'lodash';
import invariant from 'tiny-invariant';

import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
import {
  MultiPolygon,
  MultiPolyline,
  MultiPolylineResult,
  ResultType,
  Triangle,
} from '../../geometric-types';
import { calculateMultiPolylineLength } from '../util/line-segment';
import {
  findPlanes,
  to3dMultiPolygon,
  toPlanarMultiPolygon,
} from '../util/plane';
import {
  calculateArea,
  toJstsMultiPolygon,
  toMultiPolygon2,
} from '../util/polygon';

export function calculatePerimeter(triangles: Triangle[]): MultiPolylineResult {
  const planes = findPlanes(triangles);
  const largestPlane = maxBy(planes, (group) =>
    sum(group.triangles.map(calculateArea))
  );
  invariant(largestPlane, 'Should have found at least one plane');
  // Because the triangles found might overlap, we union them together
  const trianglesAsMultiPolygon: MultiPolygon = {
    polygons: largestPlane.triangles.map((triangle) => ({
      exterior: [...triangle, triangle[0]],
      interiors: [],
    })),
  };
  const multipolygon2 = toPlanarMultiPolygon(
    trianglesAsMultiPolygon,
    largestPlane.plane
  );

  const jstsMultiPolygon = toJstsMultiPolygon(multipolygon2);
  // Use a small buffer amount to close slightly disjoint elements
  const union = BufferOp.bufferOp(jstsMultiPolygon, 1e-5, 0);
  const union2 = toMultiPolygon2(union);
  const multipolygon = to3dMultiPolygon(union2, largestPlane.plane);
  const perimeters: MultiPolyline = {
    lines: [...multipolygon.polygons.map((p) => p.exterior)],
  };

  return {
    type: ResultType.MULTIPOLYLINE,
    value: calculateMultiPolylineLength(perimeters),
    multiPolyline: perimeters,
  };
}
