import { uniqueId } from 'lodash';
import { useEffect } from 'react';
import { MultiPolygon, Plane } from '../../../domain/geometry/geometric-types';
import { getPolygonGeometry } from './util';
import { ChakraColor } from 'src/utils/color-util';

export const Polygon = ({
  multipolygon,
  plane,
  isClosed,
  viewer,
}: {
  multipolygon: MultiPolygon;
  plane: Plane;
  isClosed: boolean;
  viewer: Autodesk.Viewing.Viewer3D;
}) => {
  useEffect(() => {
    const overlayName = uniqueId('polygon-overlay-');
    const material = isClosed
      ? new THREE.MeshBasicMaterial({
          color: ChakraColor.teal300,
          opacity: 0.8,
          transparent: true,
          depthTest: false,
        })
      : new THREE.MeshBasicMaterial({
          color: ChakraColor.orange300,
          opacity: 0.7,
          transparent: true,
          depthTest: false,
        });
    viewer.overlays.addScene(overlayName);
    const meshes = multipolygon.polygons.map((polygon) => {
      const polygonGeometry = getPolygonGeometry(polygon, plane);
      const polygonMesh = new THREE.Mesh(polygonGeometry, material);
      viewer.overlays.addMesh(polygonMesh, overlayName);
      return polygonMesh;
    });

    return () => {
      meshes.forEach((mesh) => {
        viewer.overlays.removeMesh(mesh, overlayName);
        mesh.geometry.dispose();
      });
      material.dispose();
    };
  }, [isClosed, multipolygon.polygons, plane, viewer.overlays]);

  return null;
};
