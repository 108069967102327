import { sortBy } from 'lodash';
import * as math from 'mathjs';
import invariant from 'tiny-invariant';
import { makeHull } from '../../convex-hull';
import {
  BoundingBox2DReturn,
  LineSegment2,
  MinimalBoundingBoxReturn,
  Point,
  Point2,
  Triangle,
} from '../../geometric-types';
import { findPlanes, to3dPoints, toPlanarPoints } from './plane';

// Finds the bounding boxes surrounding each plane
export function find2DBoundingBoxes(
  triangles: Triangle[]
): BoundingBox2DReturn {
  const planes = findPlanes(triangles);
  const result: BoundingBox2DReturn = [];
  for (const plane of planes) {
    const points = plane.triangles.flatMap((triangle) => triangle);

    // Silly javascript code for reducing duplicate points
    let uniquePoints = Array.from(
      new Set(points.map((p) => JSON.stringify(p)))
    ).map((point) => JSON.parse(point));

    const xyPoints = toPlanarPoints(uniquePoints, plane.plane);

    const pointsAsObjects = xyPoints.map((point) => ({
      x: point[0],
      y: point[1],
    }));

    const hullPoints = makeHull(pointsAsObjects);
    const boundingBox = minimalBoundingBox(hullPoints.map((p) => [p.x, p.y]));
    result.push({
      boundingBox: boundingBox.boundingBox,
      dimensions: boundingBox.dimensions,
      plane: plane,
    });
  }
  return result;
}

export function findLargest2DBoundingBox(
  boundingBoxes: BoundingBox2DReturn,
  maxLineZValue: number
) {
  const boundingBoxesByArea = sortBy(
    boundingBoxes,
    (bbox) => bbox.dimensions.width * bbox.dimensions.height
  ).reverse();

  for (const boundingBox of boundingBoxesByArea) {
    const plane = boundingBox.plane;
    const boundingBox3d = to3dPoints(boundingBox.boundingBox, plane.plane) as [
      Point,
      Point,
      Point,
      Point
    ];

    if (
      Math.abs(boundingBox3d[0][2] - boundingBox3d[1][2]) < maxLineZValue ||
      Math.abs(boundingBox3d[0][2] - boundingBox3d[3][2]) < maxLineZValue
    ) {
      return boundingBox3d;
    }
  }
}

export function minimalBoundingBox(
  polygon: Point2[]
): MinimalBoundingBoxReturn {
  let minAngle = 0;
  let minBoundingBox: [Point2, Point2] | undefined = undefined;
  let minArea = Infinity;
  for (let i = 0; i < polygon.length; i++) {
    // Loosely based on
    // https://github.com/Glissando/Rotating-Calipers#code-snippet
    const segment: LineSegment2 = [
      polygon[i],
      polygon[(i + 1) % polygon.length],
    ];
    const theta = -Math.atan2(
      segment[1][1] - segment[0][1],
      segment[1][0] - segment[0][0]
    );
    const rotatedPoints = polygon.map((point) => math.rotate(point, theta));
    const boundingBox = axisAlignedBoundingBox(rotatedPoints);
    const area = math.abs(
      (boundingBox[1][1] - boundingBox[0][1]) *
        (boundingBox[1][0] - boundingBox[0][0])
    );

    if (area < minArea) {
      minArea = area;
      minAngle = theta;
      minBoundingBox = boundingBox;
    }
  }
  invariant(minBoundingBox, 'Bounding box must be set');

  return {
    boundingBox: [
      math.rotate(minBoundingBox[0], -minAngle),
      math.rotate([minBoundingBox[1][0], minBoundingBox[0][1]], -minAngle),
      math.rotate(minBoundingBox[1], -minAngle),
      math.rotate([minBoundingBox[0][0], minBoundingBox[1][1]], -minAngle),
    ],
    dimensions: {
      width: minBoundingBox[1][0] - minBoundingBox[0][0],
      height: minBoundingBox[1][1] - minBoundingBox[0][1],
    },
  };
}

export function axisAlignedBoundingBox(points: Point2[]): [Point2, Point2] {
  let minX = points[0][0];
  let maxX = points[0][0];
  let minY = points[0][1];
  let maxY = points[0][1];
  for (const point of points) {
    if (minX > point[0]) {
      minX = point[0];
    } else if (maxX < point[0]) {
      maxX = point[0];
    }
    if (minY > point[1]) {
      minY = point[1];
    } else if (maxY < point[1]) {
      maxY = point[1];
    }
  }
  return [
    [minX, minY],
    [maxX, maxY],
  ];
}
