import { useCallback, useEffect, useMemo, useState } from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { LineSegment2, Point2 } from '../../../domain/geometry/geometric-types';
import { useSheetViewer } from '../../common/SheetViewer';
import { generateIdsForPoints, getArcPoints } from '../shapes/util';
import { SheetEdge } from './SheetEdge';
import {
  InitialShapeDrawingState,
  LineStringShapeDrawingResult,
  ShapeDrawingMode,
} from './SheetShapeDrawing';
import { DraggableVertex } from './SheetVertex';
import { IntermediateEdges } from './SheetPolygonShapeDrawing';

export const SheetLineShapeDrawing = ({
  initialState,
  onResult,
  getPointInPdfCoordinateSystem,
  getPointInDomCoordinateSystem,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.LineString];
  onResult: (result: LineStringShapeDrawingResult) => void;
  getPointInPdfCoordinateSystem: (pointInPdf: Point2) => Point2;
  getPointInDomCoordinateSystem: (pointInPdf: Point2) => Point2;
}) => {
  const [points, setPoints] = useState<Point2[]>(initialState?.points ?? []);
  const [intermediatePoint, setIntermediatePoint] = useState<Point2 | null>(
    null
  );

  const isShiftPressed = isHotkeyPressed('shift');
  const isAltKeyPressed = isHotkeyPressed('alt');

  const edges = useMemo(() => {
    const edges: LineSegment2[] = [];
    for (let i = 0; i < points.length - 1; i++) {
      edges.push([points[i], points[i + 1]]);
    }
    return edges;
  }, [points]);

  const emitResult = useCallback(
    (points: Point2[]) => {
      if (points.length > 1) {
        onResult({ points, valid: true });
      } else {
        onResult({ points, valid: false });
      }
    },
    [onResult]
  );

  const previousPointInDom = useMemo(() => {
    if (points.length === 0) {
      return null;
    }
    const pointInPageCoordinates = getPointInDomCoordinateSystem(
      points[points.length - 1]
    );
    return [pointInPageCoordinates[0], pointInPageCoordinates[1]];
  }, [getPointInDomCoordinateSystem, points]);

  const getPoint = useCallback(
    (event: MouseEvent): Point2 => {
      if (isShiftPressed && previousPointInDom) {
        const deltaX = event.clientX - previousPointInDom[0];
        const deltaY = event.clientY - previousPointInDom[1];
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          return getPointInPdfCoordinateSystem([
            event.clientX,
            previousPointInDom[1],
          ]);
        } else {
          return getPointInPdfCoordinateSystem([
            previousPointInDom[0],
            event.clientY,
          ]);
        }
      }
      return getPointInPdfCoordinateSystem([event.clientX, event.clientY]);
    },
    [getPointInPdfCoordinateSystem, isShiftPressed, previousPointInDom]
  );

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (event.button !== 0) {
        return;
      }

      const newPoint = getPoint(event);

      let newPoints: Point2[] = [];

      if (isAltKeyPressed) {
        const endPoint = points.pop();
        const startPoint = points.pop();

        if (startPoint && endPoint) {
          const arcPoints = getArcPoints(startPoint, endPoint, newPoint);

          newPoints = [...points, ...arcPoints];
        }
      } else {
        newPoints = [...points, newPoint];
      }

      setPoints(newPoints);
      emitResult(newPoints);
    },
    [emitResult, getPoint, isAltKeyPressed, points]
  );

  const { viewerRef } = useSheetViewer();
  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('click', handleViewerClick);
    return () => {
      current.removeEventListener('click', handleViewerClick);
    };
  }, [handleViewerClick, viewerRef]);

  const handlePointerMove = useCallback(
    (event: MouseEvent) => {
      const newPoint = getPoint(event);

      setIntermediatePoint(newPoint);
    },
    [getPoint]
  );

  useEffect(() => {
    const current = viewerRef.current;
    if (!current) {
      return;
    }
    current.addEventListener('pointermove', handlePointerMove);
    return () => {
      current.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, viewerRef]);

  const handleVertexDrag = (event: MouseEvent, index: number) => {
    setIntermediatePoint(null);
    const newPoint = getPoint(event);
    const newPoints = [...points];
    newPoints[index] = newPoint;
    setPoints(newPoints);

    if (event.type === 'pointerup') {
      emitResult(newPoints);
    }
  };

  const handleVertexClick = (event: React.MouseEvent, index: number) => {
    if (index !== points.length - 1) {
      const newPoints = [...points];
      newPoints.push(newPoints[index]);
      setPoints(newPoints);
      emitResult(newPoints);
    }
  };

  const handleEdgeDoubleClick = (event: React.MouseEvent, index: number) => {
    const newPoint = getPointInPdfCoordinateSystem([
      event.clientX,
      event.clientY,
    ]);
    const newPoints = [...points];
    newPoints.splice(index + 1, 0, newPoint);
    setPoints(newPoints);
    emitResult(newPoints);
  };

  return (
    <>
      {edges.map((edge, index) => {
        // Don't render the last edge if alt is pressed
        if (index === edges.length - 1 && isAltKeyPressed) return;
        return (
          <SheetEdge
            // todo: index is not a good key. use a unique id
            key={index}
            segment={edge}
            cursor={'pointer'}
            onDoubleClick={(event) => handleEdgeDoubleClick(event, index)}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
      {intermediatePoint && points.length > 0
        ? IntermediateEdges(
            intermediatePoint,
            generateIdsForPoints(points),
            isAltKeyPressed && points.length > 1,
            'green.500',
            getPointInDomCoordinateSystem
          )
        : null}
      {points.map((point, index) => {
        return (
          // todo: index is not a good key. use a unique id
          <DraggableVertex
            key={index}
            theme={index === 0 ? 'inverted' : 'default'}
            point={point}
            onVertexDrag={(event) => handleVertexDrag(event, index)}
            onClick={(event) => handleVertexClick(event, index)}
            getPointInDomCoordinateSystem={getPointInDomCoordinateSystem}
          />
        );
      })}
    </>
  );
};
