import { sortBy } from 'lodash';
import { MultiPolygonAlgorithm } from '../../algorithm-types';
import { MultiPolygonResult, ResultType } from '../../geometric-types';
import { toPlanarPoints } from '../util/plane';
import { isPointWithinRing, ringArea } from '../util/polygon';

export const calculateShapeGrossSideArea: MultiPolygonAlgorithm<
  MultiPolygonResult
> = (multipolygon, plane) => {
  let value = 0;
  let exteriorsWithArea = multipolygon.polygons
    .map((polygon) => toPlanarPoints(polygon.exterior, plane))
    .map((exterior) => ({
      exterior,
      area: Math.abs(ringArea(exterior)),
    }));
  // Exclude interior polygons from being included in the area
  exteriorsWithArea = sortBy(
    exteriorsWithArea,
    (polygon) => polygon.area
  ).reverse();

  const outerExteriors: typeof exteriorsWithArea = [];
  for (const exterior of exteriorsWithArea) {
    const isPolygonContainedInAnotherPolygon = outerExteriors.some(
      (relevantExterior) =>
        // Because a multipolygon doesn't contain intersections, it is sufficient to test a single point
        isPointWithinRing(relevantExterior.exterior, exterior.exterior[0])
    );
    if (!isPolygonContainedInAnotherPolygon) {
      outerExteriors.push(exterior);
      value += exterior.area;
    }
  }

  return {
    type: ResultType.MULTIPOLYGON,
    value,
    plane,
    multiPolygon: {
      polygons: multipolygon.polygons.map((polygon) => ({
        exterior: polygon.exterior,
        interiors: [],
      })),
    },
  };
};
