import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import { enableMapSet } from 'immer';
/* eslint-disable import/no-named-as-default-member */
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { theme } from './theme';

import { PlausibleContextProvider, createPlausibleClient } from './analytics';
import awsExports from './aws-exports';

const plausibleClient = createPlausibleClient();

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

enableMapSet();

const toAbsoluteUrl = (url: string) => {
  if (url.startsWith('http')) {
    return url;
  } else {
    const { origin } = window.location;
    return `${origin}${url}`;
  }
};

// Enable OPFS caching for the APS viewer
window.USE_OPFS = true;

const unleashConfig: IConfig = {
  disableMetrics:
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1',
  // Use value from backend config and fall back to VITE-injected env variables
  url: awsExports.unleash_url
    ? toAbsoluteUrl(awsExports.unleash_url)
    : toAbsoluteUrl(import.meta.env.VITE_UNLEASH_PROXY_URL),
  clientKey:
    awsExports.unleash_client_key ??
    import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'sparkel',
};
// Warn if no unleash config can be resolved
if (!unleashConfig.url || !unleashConfig.clientKey) {
  console.warn(
    'Unleash could not be configured. All feature toggles will be disabled.'
  );
}

if (
  awsExports.sentry_dsn &&
  awsExports.sentry_environment &&
  import.meta.env.VITE_SENTRY_RELEASE
) {
  console.group('Sentry config:');
  console.debug('Sentry environment:', awsExports.sentry_environment);
  console.debug('Sentry release:', import.meta.env.VITE_SENTRY_RELEASE);
  console.groupEnd();

  const graphqlEndpoint = awsExports.graphql_endpoint;

  if (!graphqlEndpoint) {
    throw new Error('No graphql endpoint found');
  }

  let tracingOrigins: string[] = [];

  if (graphqlEndpoint.startsWith('https://')) {
    tracingOrigins = [new URL(graphqlEndpoint).hostname];
  }
  Sentry.init({
    dsn: awsExports.sentry_dsn,
    environment: awsExports.sentry_environment,
    integrations: [
      new BrowserTracing({
        tracingOrigins,
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay({ maskAllText: false }),
    ],
    // NOTE: For now sending all errors is fine, but we may want to adjust this
    // to a lower value in the future.
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    release: import.meta.env.VITE_SENTRY_RELEASE,
  });
}

if (import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN) {
  // Initialize Mixpanel
  mixpanel.init(import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN, {
    debug: window.location.hostname !== 'app.sparkel.io',
    persistence: 'localStorage',
    track_pageview: true,
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <PlausibleContextProvider value={plausibleClient}>
      <FlagProvider config={unleashConfig}>
        <ChakraProvider
          theme={theme}
          toastOptions={{
            defaultOptions: {
              position: 'top',
              variant: 'subtle',
            },
          }}
        >
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </FlagProvider>
    </PlausibleContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
