import {
  ClientOptions,
  subscriptionExchange as subscriptionExchangeFactory,
} from 'urql';
import { createClient } from 'graphql-ws';
import { Auth } from '@aws-amplify/auth';

const scheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
const host = window.location.host;
const url = `${scheme}://${host}/graphql`;

export const createWSClient = (options?: ClientOptions) => {
  return createClient({
    url,
    shouldRetry: () => true,
    connectionParams: async () => {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getIdToken().getJwtToken();

        return { authorization: accessToken };
      } catch (e) {
        const localToken = localStorage.getItem('external_access_token');
        if (localToken) {
          return { authorization: localToken };
        }
        throw e;
      }
    },
    ...options,
  });
};

const wsClient = createWSClient();
export const subscriptionExchange = subscriptionExchangeFactory({
  forwardSubscription(request) {
    const input = { ...request, query: request.query || '' };
    return {
      subscribe(sink) {
        const unsubscribe = wsClient.subscribe(input, sink);
        return { unsubscribe };
      },
    };
  },
});
