import { Map as IMap, Set as ISet } from 'immutable';
import { useCallback, useMemo, useRef, useSyncExternalStore } from 'react';

export const useViewerSelection = (
  viewer: Autodesk.Viewing.Viewer3D | null
): IMap<string, ISet<number>> => {
  const viewerSelectionRef = useRef<IMap<string, ISet<number>>>(IMap());

  const subscribeToAggregateSelection = useMemo(() => {
    if (!viewer) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return (callback: () => void) => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        return () => {};
      };
    } else {
      return (callback: () => void) => {
        viewer.addEventListener(
          Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
          callback
        );

        return () => {
          viewer.removeEventListener(
            Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
            callback
          );
        };
      };
    }
  }, [viewer]);

  const getSelectedElements = useCallback(() => {
    let selections: readonly {
      model: Autodesk.Viewing.Model;
      selection: Array<number>;
    }[] = [];

    if (viewer) {
      selections = viewer.getAggregateSelection();
    }

    const selectionsMap = IMap(
      selections.map(({ model, selection }) => [
        model.getData().urn,
        ISet(selection),
      ])
    );

    if (selectionsMap.equals(viewerSelectionRef.current)) {
      return viewerSelectionRef.current;
    } else {
      viewerSelectionRef.current = selectionsMap;
      return selectionsMap;
    }
  }, [viewer]);

  const aggregateSelection = useSyncExternalStore(
    subscribeToAggregateSelection,
    getSelectedElements
  );

  return aggregateSelection;
};
