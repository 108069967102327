import { Flex } from '@chakra-ui/react';
import { memo, useCallback, useRef } from 'react';
import { ShapeDeepFragment, SheetShapeDeepFragment } from '../../gql/graphql';
import { useSelection } from '../../services/viewer/selection';
import { BimShapeItem } from './BimShapeItem';
import { SheetShapeItem } from './SheetShapeItem';

export type ShapeItemProps = {
  shape: ShapeDeepFragment | SheetShapeDeepFragment;
  existingShapes: (ShapeDeepFragment | SheetShapeDeepFragment)[];
  readOnly: boolean;
  isSelected?: boolean;
  isPrevSelected?: boolean;
  isNextSelected?: boolean;
  onChange: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
  index: number;
};

export const isShapeSelected = (
  shape: ShapeDeepFragment | SheetShapeDeepFragment,
  selectedDbIds: Record<string, number[]>
) => {
  const shapeUrn = shape.urn;
  return (
    shapeUrn in selectedDbIds && selectedDbIds[shapeUrn].includes(shape.dbId)
  );
};

function ShapeItem({
  shape,
  existingShapes,
  readOnly,
  isSelected,
  isPrevSelected,
  isNextSelected,
  onChange,
  index,
}: ShapeItemProps) {
  const {
    selectedShapes: selectedShapeDbIds,
    selectedSheetShapes: selectedSheetShapeDbIds,
  } = useSelection();
  const dragStart = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      let shapesToDrag: (ShapeDeepFragment | SheetShapeDeepFragment)[];
      // Drag selected shapes in current folder if the dragged shape is selected
      // Drag only the dragged shape if the dragged shape is not selected
      if (isSelected) {
        shapesToDrag = existingShapes
          .filter((existingShape) => {
            const shapeUrn = existingShape.urn;
            if (shapeUrn in selectedShapeDbIds) {
              return selectedShapeDbIds[shapeUrn].includes(existingShape.dbId);
            } else if (shapeUrn in selectedSheetShapeDbIds) {
              return selectedSheetShapeDbIds[shapeUrn].includes(
                existingShape.dbId
              );
            }
            return false;
          })
          .filter((existingShape) => existingShape.folder === shape.folder);
      } else {
        shapesToDrag = [shape];
      }

      e.dataTransfer.setData(
        'shape_ids',
        shapesToDrag.map((shape) => shape.id).join(',')
      );

      // Create a temporary "box" for the drag image
      const dragBox = document.createElement('div');
      const selectedShapeNames = shapesToDrag.map((shape) => shape.name);
      dragBox.innerText = selectedShapeNames.join(', ');
      dragBox.className = 'sparkel-property-drag-image';

      document.body.appendChild(dragBox);

      e.dataTransfer.effectAllowed = 'move';
      // Set the temporary box as the drag image
      e.dataTransfer.setDragImage(dragBox, 0, 0);

      // Clean up after the setDragImage call
      setTimeout(function () {
        document.body.removeChild(dragBox);
      }, 0);
    },
    [
      existingShapes,
      isSelected,
      selectedShapeDbIds,
      selectedSheetShapeDbIds,
      shape,
    ]
  );

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const shapeItemRef = useRef<HTMLDivElement>(null);

  return (
    <Flex
      ref={shapeItemRef}
      id={shape.id}
      draggable={!readOnly}
      onDragStart={dragStart}
      onDragOver={dragOver}
      width="100%"
      py={1}
      alignItems="center"
      gap={1}
      role="group"
      aria-checked={isSelected}
      justifyContent="flex-start"
      _hover={{
        bgColor: 'gray.50',
        borderRadius: 'lg',
        _dark: {
          bgColor: 'gray.700',
        },
      }}
      _checked={{
        bgColor: 'blue.50',
        textColor: 'blue.700',
        fontWeight: 'bold',
        borderTopRadius: !isPrevSelected ? 'xl' : undefined,
        borderBottomRadius: !isNextSelected ? 'xl ' : undefined,

        _dark: {
          bgColor: 'blue.800',
          textColor: 'blue.200',
        },
      }}
    >
      {shape.__typename === 'Shape' ? (
        <BimShapeItem
          onChange={onChange}
          index={index}
          shape={shape}
          existingShapes={existingShapes}
          readOnly={readOnly}
        />
      ) : null}
      {shape.__typename === 'SheetShape' ? (
        <SheetShapeItem
          onChange={onChange}
          index={index}
          shape={shape}
          existingShapes={existingShapes}
          readOnly={readOnly}
        />
      ) : null}
    </Flex>
  );
}

function propsAreEqual(prevProps: ShapeItemProps, nextProps: ShapeItemProps) {
  if (prevProps.isSelected !== nextProps.isSelected) {
    return false;
  }

  if (prevProps.isPrevSelected !== nextProps.isPrevSelected) {
    return false;
  }
  if (prevProps.isNextSelected !== nextProps.isNextSelected) {
    return false;
  }
  if (prevProps.onChange !== nextProps.onChange) {
    return false;
  }

  return true;
}

export default memo(ShapeItem, propsAreEqual);
