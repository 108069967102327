import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  List,
} from '@chakra-ui/react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'urql';
import {
  ElementCategoryEnum,
  Project,
  UpdateProjectDocument,
} from '../../gql/graphql';
import SparkelIcon from '../common/icon/SparkelIcon';
import { useBreadcrumb } from '../common/BreadcrumbProvider';
import { EventName, useMixpanel } from '../../services/mixpanel';
import AllowedElementCategoryItem from './AllowedElementCategoryItem';

type OrderLabelsTabProps = {
  project: Pick<Project, 'id' | 'settings'>;
};
type ElementCategoryInputData = {
  element_categories: string;
};

export default function ElementCategoriesTab({
  project,
}: OrderLabelsTabProps): React.ReactElement {
  const [inputActive, setInputActive] = useState(false);
  const { register, handleSubmit } = useForm<ElementCategoryInputData>();
  const [, updateProject] = useMutation(UpdateProjectDocument);

  const { trackEvent } = useMixpanel();

  const { projectName } = useBreadcrumb();
  const onSubmit = handleSubmit(async ({ element_categories }) => {
    trackEvent(EventName.SparkelAttributeCreated, {
      'Sparkel Attribute Name': element_categories,
      'Project Name': projectName,
    });
    setInputActive(false);
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            allowedElementsCategories: [
              ...(project.settings?.allowedElementsCategories ?? []),
              { name: element_categories, type: ElementCategoryEnum.String },
            ],
          },
        },
      },
    });
  });

  const deleteElementCategory = async (elementCategory: string) => {
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            allowedElementsCategories: (
              project.settings?.allowedElementsCategories ?? []
            ).filter(
              (allowedElementCategory) =>
                allowedElementCategory?.name !== elementCategory
            ),
          },
        },
      },
    });
  };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <Heading size={'md'}>Element attributes</Heading>
        <IconButton
          aria-label="new element attribute button"
          colorScheme="brand"
          size="xs"
          isRound
          onClick={() => setInputActive(!inputActive)}
          icon={<SparkelIcon icon={faPlus} color={['gray.50', 'gray.700']} />}
        />
      </Flex>
      <List>
        {project.settings?.allowedElementsCategories?.map(
          (allowedElementsCategories) => (
            <AllowedElementCategoryItem
              key={allowedElementsCategories.name}
              allowedElementsCategory={allowedElementsCategories}
              //Fix backend?

              onDeleteElementCategory={() =>
                deleteElementCategory(allowedElementsCategories.name)
              }
            ></AllowedElementCategoryItem>
          )
        )}
      </List>
      {inputActive ? (
        <Box mt={2}>
          {project.settings?.allowedElementsCategories &&
          project.settings?.allowedElementsCategories.length > 0 ? (
            <Divider />
          ) : null}

          <Box mt={2}>
            <form onSubmit={onSubmit} id="new-element-category-form">
              <FormControl isRequired>
                <FormLabel>New element classification category</FormLabel>
                <Input
                  autoFocus
                  {...register('element_categories')}
                  placeholder={'New element attribute category'}
                />
              </FormControl>
            </form>
            <Button
              mt={2}
              colorScheme="brand"
              type="submit"
              form="new-element-category-form"
            >
              Save
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
}
