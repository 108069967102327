import * as math from 'mathjs';
import OverlayOp from 'jsts/org/locationtech/jts/operation/overlay/OverlayOp';
import BufferOp from 'jsts/org/locationtech/jts/operation/buffer/BufferOp';
import TopologyPreservingSimplifier from 'jsts/org/locationtech/jts/simplify/TopologyPreservingSimplifier';
import {
  to3dPoints,
  toPlanarPoint,
  toPlanarPoints,
} from '../../../domain/geometry/algorithms/util/plane';
import {
  isPointWithinRing,
  toJstsMultiPolygon,
  toMultiPolygon2,
} from '../../../domain/geometry/algorithms/util/polygon';
import { getTriangles } from '../../../domain/geometry/extract-triangles';
import {
  LineSegment,
  LineSegment2,
  LinearRing,
  LinearRing2,
  Plane,
  Point,
  Point2,
  Polygon2,
  Triangle,
} from '../../../domain/geometry/geometric-types';
import { MagicShapeResult } from '../../../services/viewer/shape-drawing/one-click-shapes/magic-shape-types';
import { mergeShapes } from '../../../services/viewer/shape-drawing/one-click-shapes/polygon-union-shape';
import {
  getAllRelatedDbIds,
  getPropertyByDbId,
} from '../../../domain/property-operations';
import {
  areRingsEquivalent,
  areSegmentsEqual,
  createMultipolygon,
  getSegmentPlaneIntersection,
  isNearlyParallelAndCloseToPlane,
} from './util';

const ALLOWED_CATEGORIES = [
  'IfcWall',
  'IfcWallStandardCase',
  'IfcCovering',
  'IfcSlab',
  'IfcDoor',
  'IfcRoof',
  'IfcWindow',
  'Revit Walls',
];

/*
This function is used to generate rings based on the intersection of the shape with the model.

It works as follows:
  1. It first gets the bounding box of the one-click-shape.
  2. It then finds all the potentially intersecting elements in the model based on the bounding box of the initial one-click-shape.
  3. It then calculates the intersection segments between the shape and the potentially intersecting meshes.
     These segments are then turned into rings and merged into a polygon.
  4. It then calculates the difference between the initial shape and the intersection polygon.
  5. Finally, it returns the difference as a set of rings that contains the hit point of the initial shape.
*/

const bufferAmount = 1e-2;

export async function getRingsFromIntersections(
  shape: NonNullable<MagicShapeResult>,
  viewer: Autodesk.Viewing.Viewer3D
): Promise<LinearRing[] | undefined> {
  const relatedDbIds = await getAllRelatedDbIds(
    shape.hitTest.model,
    shape.hitTest.dbId
  );
  const potentiallyIntersectingMeshes =
    await findIntersectingBoxesWithAllowedCategories(
      viewer,
      getBoundingBox(shape.rings.flatMap((ring) => ring)),
      relatedDbIds
    );

  if (potentiallyIntersectingMeshes.length === 0) {
    return shape.rings;
  }

  const intersectionContours = getIntersectionContours(
    shape,
    potentiallyIntersectingMeshes
  );
  const mergedIntersectionContours = mergeShapes(intersectionContours);

  const planarShape = shape.rings.map((ring) =>
    toPlanarPoints(ring, shape.plane)
  ) as LinearRing2[];

  const shapeAsPolygon = createMultipolygon(planarShape);
  let shapeAsJSTSPolygon = toJstsMultiPolygon(shapeAsPolygon);

  const intersectionPolygon = toJstsMultiPolygon(
    createMultipolygon(mergedIntersectionContours)
  );
  // Use a small buffer amount to prevent thin polygons from forming at the edges after difference.
  const bufferedIntersectionPolygon = BufferOp.bufferOp(
    intersectionPolygon,
    bufferAmount,
    0
  );
  shapeAsJSTSPolygon = OverlayOp.difference(
    shapeAsJSTSPolygon,
    bufferedIntersectionPolygon
  );

  // Simplify to remove duplicate points, points along the same line, etc.
  const simplifier = new TopologyPreservingSimplifier(shapeAsJSTSPolygon);
  simplifier.setDistanceTolerance(bufferAmount * 10);
  const simplified = simplifier.getResultGeometry();

  const differenceAsMultiPolygon2 = toMultiPolygon2(simplified);

  const polygonContainingPoint = findPolygonContainingPoint(
    shape,
    differenceAsMultiPolygon2.polygons
  );

  const ringsFromIntersections = polygonContainingPoint
    ? [polygonContainingPoint.exterior, ...polygonContainingPoint.interiors]
    : undefined;

  if (ringsFromIntersections && ringsFromIntersections.length > 0) {
    return ringsFromIntersections.map(
      (ring) => to3dPoints(ring, shape.plane) as LinearRing
    );
  }

  return undefined;
}

const getBoundingBox = (points: Point[], tolerance = 0.1): THREE.Box3 => {
  const minX = Math.min(...points.map((point) => point[0]));
  const maxX = Math.max(...points.map((point) => point[0]));
  const minY = Math.min(...points.map((point) => point[1]));
  const maxY = Math.max(...points.map((point) => point[1]));
  const minZ = Math.min(...points.map((point) => point[2]));
  const maxZ = Math.max(...points.map((point) => point[2]));

  return new THREE.Box3(
    new THREE.Vector3(minX - tolerance, minY - tolerance, minZ - tolerance),
    new THREE.Vector3(maxX + tolerance, maxY + tolerance, maxZ + tolerance)
  );
};

function getIntersectionContours(
  shape: NonNullable<MagicShapeResult>,
  potentiallyIntersectingMeshes: { dbId: number; triangles: Triangle[] }[]
): LinearRing2[] {
  const intersectionContours: LinearRing2[] = [];

  for (const mesh of potentiallyIntersectingMeshes) {
    const intersectionSegments = getIntersectionSegments(
      mesh.triangles,
      shape.plane
    );

    if (intersectionSegments.length < 3) continue;

    const deduplicatedSegments = removeDuplicateSegments(intersectionSegments);
    const rings = createRingsFromSegments(deduplicatedSegments);
    const mergedRings = mergeShapes(rings);

    intersectionContours.push(...mergedRings);
  }

  return intersectionContours;
}

function removeDuplicateSegments(
  segments: LineSegment2[],
  tolerance = 0.01
): LineSegment2[] {
  let uniqueSegments: LineSegment2[] = []; // Will store one copy of each unique segment

  for (const segment of segments) {
    // Flag to check if current segment is a duplicate
    let isDuplicate = false;

    for (let i = 0; i < uniqueSegments.length; i++) {
      if (areSegmentsEqual(segment, uniqueSegments[i], tolerance)) {
        isDuplicate = true;
        break;
      }
    }

    // If not a duplicate, add it to the list of unique segments
    if (!isDuplicate) {
      uniqueSegments.push(segment);
    }
  }

  return uniqueSegments;
}

function createRingsFromSegments(
  contours: LineSegment2[],
  precision = 2
): LinearRing2[] {
  const graph = buildGraph(contours, precision);
  let allRings = findAllRings(graph);

  return mergeShapes(allRings);
}

function buildGraph(
  lineSegments: LineSegment2[],
  precision = 5
): Map<string, Point2[]> {
  const graph = new Map<string, Set<string>>();

  for (const [start, end] of lineSegments) {
    const startKey = math.round(start, precision).join(',');
    const endKey = math.round(end, precision).join(',');

    if (!graph.has(startKey)) {
      graph.set(startKey, new Set<string>());
    }
    graph.get(startKey)?.add(endKey);

    if (!graph.has(endKey)) {
      graph.set(endKey, new Set<string>());
    }
    graph.get(endKey)?.add(startKey);
  }

  // Convert neighbor sets back to arrays and ensure neighbors are unique
  const finalGraph = new Map<string, Point2[]>();
  for (const [vertexKey, neighbors] of graph) {
    const pointArray: Point2[] = Array.from(neighbors).map(
      (neighborKey): Point2 => {
        return neighborKey.split(',').map(Number) as Point2;
      }
    );
    finalGraph.set(vertexKey, pointArray);
  }

  return finalGraph;
}

function findAllRings(graph: Map<string, Point2[]>): LinearRing2[] {
  let allPotentialRings: LinearRing2[] = [];
  let iterations = 0;
  const maxIterations = 10000;

  const dfs = (
    current: string,
    start: string | null,
    graph: Map<string, Point2[]>,
    path: Point2[]
  ): void => {
    if (++iterations > maxIterations) {
      return;
    }

    if (start !== null && current === start && path.length >= 3) {
      const ring = [...path, path[0]];
      allPotentialRings.push(ring);
      return;
    }

    const neighbors = graph.get(current) || [];
    for (const neighbor of neighbors) {
      const neighborKey = neighbor.join(',');
      if (
        start === null ||
        !path.some(
          (point) => point[0] === neighbor[0] && point[1] === neighbor[1]
        )
      ) {
        dfs(neighborKey, start === null ? current : start, graph, [
          ...path,
          neighbor,
        ]);
      }
    }
  };

  for (const [vertex] of graph) {
    dfs(vertex, vertex, graph, []);
  }

  // Mark duplicates
  const duplicateMarks = markDuplicates(allPotentialRings);

  // Filter keeping only valid rings and at least one instance of each duplicate
  const validRings = allPotentialRings.filter(
    (_, index) => !duplicateMarks[index]
  );

  return validRings;
}

function markDuplicates(rings: LinearRing2[]): boolean[] {
  const marked: boolean[] = new Array(rings.length).fill(false);

  for (let i = 0; i < rings.length; i++) {
    if (marked[i]) continue;
    for (let j = i + 1; j < rings.length; j++) {
      if (areRingsEquivalent(rings[i], rings[j])) {
        marked[j] = true;
      }
    }
  }

  return marked;
}

// eslint-disable-next-line complexity
async function findIntersectingBoxesWithAllowedCategories(
  viewer: Autodesk.Viewing.Viewer3D,
  boundingBox: THREE.Box3,
  dbIdsToIgnore: number[]
): Promise<{ dbId: number; triangles: Triangle[] }[]> {
  const allowedCategoriesSet = new Set(ALLOWED_CATEGORIES);
  const potentiallyIntersectingMeshes: {
    dbId: number;
    triangles: Triangle[];
  }[] = [];

  for (const model of viewer.getAllModels()) {
    const boundingBoxesCoordinates = model.getData().fragments
      .boxes as number[];
    const fragId2dbId = model.getData().fragments.fragId2dbId as number[];

    for (let i = 0; i < boundingBoxesCoordinates.length; i += 6) {
      const boxMin = new THREE.Vector3(
        boundingBoxesCoordinates[i],
        boundingBoxesCoordinates[i + 1],
        boundingBoxesCoordinates[i + 2]
      );
      const boxMax = new THREE.Vector3(
        boundingBoxesCoordinates[i + 3],
        boundingBoxesCoordinates[i + 4],
        boundingBoxesCoordinates[i + 5]
      );
      const fragBox = new THREE.Box3(boxMin, boxMax);

      if (fragBox.intersectsBox(boundingBox)) {
        const fragId = i / 6;
        const dbId = fragId2dbId[fragId];
        try {
          const ifcClass = await getPropertyByDbId(model, dbId, 'IfcClass');
          const revitCategory = await getPropertyByDbId(
            model,
            dbId,
            'Category'
          );

          if (
            viewer.isNodeVisible(dbId, model) &&
            !dbIdsToIgnore.includes(dbId) &&
            ((ifcClass && allowedCategoriesSet.has(ifcClass)) ||
              (revitCategory && allowedCategoriesSet.has(revitCategory)))
          ) {
            const triangles = getTriangles(model, dbId);
            potentiallyIntersectingMeshes.push({ dbId, triangles });
          }
        } catch (e) {
          console.error(`Error getting property for dbId ${dbId}:`, e);
        }
      }
    }
  }

  return potentiallyIntersectingMeshes;
}

function getIntersectionSegments(
  elementTriangles: Triangle[],
  plane: Plane
): LineSegment2[] {
  const intersectionLines: LineSegment2[] = [];

  for (const triangle of elementTriangles) {
    const points = [triangle[0], triangle[1], triangle[2]];
    const intersectionSegment: Point2[] = [];

    // Check each edge of the triangle for intersection
    for (let i = 0; i < 3; i++) {
      const triangleEdge = [points[i], points[(i + 1) % 3]] as LineSegment;

      // Check if the edge is nearly parallel and close to the plane
      const isNearlyParallelAndClose = isNearlyParallelAndCloseToPlane(
        triangleEdge,
        plane
      );
      if (isNearlyParallelAndClose) {
        const projectedSegment = toPlanarPoints(
          triangleEdge,
          plane
        ) as LineSegment2;
        //const clippedSegments = clipSegmentAndFindBoundaryIntersections(projectedSegment, polygon);
        intersectionLines.push(projectedSegment);

        continue;
      }

      const intersection = getSegmentPlaneIntersection(triangleEdge, plane);
      if (intersection) {
        intersectionSegment.push(intersection);
      }
    }

    // If there are two intersection points, add the line segment to the list
    if (intersectionSegment.length === 2) {
      //const clippedSegments = clipSegmentAndFindBoundaryIntersections(intersectionSegment as LineSegment2, polygon);
      intersectionLines.push(intersectionSegment as LineSegment2);
    }
  }

  return intersectionLines;
}

function findRingContainingPoint(
  magicShapeResult: NonNullable<MagicShapeResult>,
  rings: LinearRing2[]
): LinearRing2 | undefined {
  return rings.find((ring) => {
    const planarHitPoint = toPlanarPoint(
      [
        magicShapeResult.hitTest.point.x,
        magicShapeResult.hitTest.point.y,
        magicShapeResult.hitTest.point.z,
      ],
      magicShapeResult.plane
    );

    return isPointWithinRing(ring, planarHitPoint);
  });
}

function findPolygonContainingPoint(
  magicShapeResult: NonNullable<MagicShapeResult>,
  polygons: Polygon2[]
): Polygon2 | undefined {
  return polygons.find((polygon) => {
    const planarHitPoint = toPlanarPoint(
      [
        magicShapeResult.hitTest.point.x,
        magicShapeResult.hitTest.point.y,
        magicShapeResult.hitTest.point.z,
      ],
      magicShapeResult.plane
    );

    return isPointWithinRing(polygon.exterior, planarHitPoint);
  });
}
