import BiMap from 'ts-bidirectional-map';
import {
  GeometricAlgorithmType,
  MeshAlgorithmType,
  PolylineAlgorithmType,
  MultiPolygonAlgorithmType,
  MultiPolygon2AlgorithmType,
  Polyline2AlgorithmType,
  ExtrudedPolygonAlgorithmType,
} from './algorithm-types';
import { GeometricProperty } from './geometric-types';

export enum IfcClass {
  CurtainWall = 'IfcCurtainWall',
  Door = 'IfcDoor',
  Covering = 'IfcCovering',
  Roof = 'IfcRoof',
  Space = 'IfcSpace',
  Slab = 'IfcSlab',
  Proxy = 'IfcBuildingElementProxy',
  Part = 'IfcBuildingElementPart',
  Wall = 'IfcWall',
  WallStandardCase = 'IfcWallStandardCase',
  Window = 'IfcWindow',
  Member = 'IfcMember',
  Column = 'IfcColumn',
  Beam = 'IfcBeam',
  ElementAssembly = 'IfcElementAssembly',
  Footing = 'IfcFooting',
  Pile = 'IfcPile',
}

export enum RevitCategory {
  Wall = 'Revit Walls',
  Window = 'Revit Windows',
  Door = 'Revit Doors',
  Slab = 'Revit Floors',
  Column = 'Revit Columns',
  Beam = 'Revit Beams',
  Space = 'Revit Spaces',
  Roof = 'Revit Roofs',
  CurtainWall = 'Revit Curtain Walls',
  Covering = 'Revit Coverings',
  Member = 'Revit Members',
  Furniture = 'Revit Furniture',
  Plants = 'Revit Planting',
  Stairs = 'Revit Stairs',
  Railing = 'Revit Railings',
}

export enum CustomElementClass {
  PolygonShape = 'PolygonShape',
  ExtrudedPolygonShape = 'ExtrudedPolygonShape',
  LineShape = 'LineShape',
  Polygon2Shape = 'Polygon2Shape',
  Line2Shape = 'Line2Shape',
}

export type AlgorithmTargetType = IfcClass | RevitCategory | CustomElementClass;

const mapToBiMap = (pairs: [GeometricProperty, GeometricAlgorithmType][]) => {
  let biMap = new BiMap<GeometricProperty, GeometricAlgorithmType>();
  for (const [key, value] of pairs) {
    biMap.set(key, value);
  }
  return biMap;
};

// For each each algorithm target type (ifcclass or shapes) there is a 1-1 relationship between geometric property types and geometric algorithm types
export const GEOMETRIC_PROPERTY_TO_ALGORITHM_MAP = new Map<
  AlgorithmTargetType,
  BiMap<GeometricProperty, GeometricAlgorithmType>
>([
  [
    IfcClass.Wall,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.CurtainWall,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Door,
    mapToBiMap([
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Covering,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Roof,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
      [GeometricProperty.TOP_SURFACE_AREA, MeshAlgorithmType.TOP_SURFACE_AREA],
      [
        GeometricProperty.BOTTOM_SURFACE_AREA,
        MeshAlgorithmType.BOTTOM_SURFACE_AREA,
      ],
    ]),
  ],
  [
    IfcClass.Space,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [GeometricProperty.TOP_SURFACE_AREA, MeshAlgorithmType.TOP_SURFACE_AREA],
      [
        GeometricProperty.BOTTOM_SURFACE_AREA,
        MeshAlgorithmType.BOTTOM_SURFACE_AREA,
      ],
      [GeometricProperty.TOTAL_SURFACE_AREA, MeshAlgorithmType.TOTAL_MESH_AREA],
    ]),
  ],
  [
    IfcClass.Slab,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.LARGEST_SURFACE_AREA_WITHOUT_OPENINGS,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Proxy,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.WallStandardCase,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Window,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Member,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
    ]),
  ],
  [
    IfcClass.Column,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
    ]),
  ],
  [
    IfcClass.Beam,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.Footing,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
    ]),
  ],
  [
    IfcClass.Pile,
    mapToBiMap([
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    IfcClass.ElementAssembly,
    mapToBiMap([
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    CustomElementClass.PolygonShape,
    mapToBiMap([
      [
        GeometricProperty.HEIGHT,
        MultiPolygonAlgorithmType.POLYGON_VERTICAL_COMPONENT,
      ],
      [
        GeometricProperty.CIRCUMFERENCE,
        MultiPolygonAlgorithmType.POLYGON_PERIMETER,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MultiPolygonAlgorithmType.POLYGON_AREA,
      ],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MultiPolygonAlgorithmType.POLYGON_GROSS_SIDE_AREA,
      ],
    ]),
  ],
  [
    CustomElementClass.ExtrudedPolygonShape,
    mapToBiMap([
      [
        GeometricProperty.CIRCUMFERENCE,
        ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_PERIMETER,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_AREA,
      ],
      [
        GeometricProperty.EXTRUDED_SIDE_AREA,
        ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_SIDE_AREA,
      ],
      [
        GeometricProperty.VOLUME,
        ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_VOLUME,
      ],
      [
        GeometricProperty.THICKNESS,
        ExtrudedPolygonAlgorithmType.EXTRUDED_POLYGON_THICKNESS,
      ],
    ]),
  ],
  [
    CustomElementClass.LineShape,
    mapToBiMap([[GeometricProperty.LENGTH, PolylineAlgorithmType.LINE_LENGTH]]),
  ],
  [
    CustomElementClass.Polygon2Shape,
    mapToBiMap([
      [
        GeometricProperty.CIRCUMFERENCE,
        MultiPolygon2AlgorithmType.POLYGON2_PERIMETER,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MultiPolygon2AlgorithmType.POLYGON2_AREA,
      ],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MultiPolygon2AlgorithmType.POLYGON2_GROSS_SIDE_AREA,
      ],
    ]),
  ],
  [
    CustomElementClass.Line2Shape,
    mapToBiMap([
      [GeometricProperty.LENGTH, Polyline2AlgorithmType.LINE2_LENGTH],
    ]),
  ],
  [
    RevitCategory.Wall,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    RevitCategory.Window,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    RevitCategory.Slab,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.LARGEST_SURFACE_AREA_WITHOUT_OPENINGS,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    RevitCategory.CurtainWall,
    mapToBiMap([
      [
        GeometricProperty.LENGTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_WIDTH,
      ],
      [GeometricProperty.HEIGHT, MeshAlgorithmType.VERTICAL_COMPONENT],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
      [
        GeometricProperty.LARGEST_SIDE_AREA,
        MeshAlgorithmType.LARGEST_FLAT_SIDE_AREA,
      ],
      [GeometricProperty.CIRCUMFERENCE, MeshAlgorithmType.MESH_PERIMETER],
    ]),
  ],
  [
    RevitCategory.Beam,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
      [
        GeometricProperty.GROSS_SIDE_AREA,
        MeshAlgorithmType.CONVEX_LARGEST_FLAT_SIDE_AREA_INFINITY,
      ],
    ]),
  ],
  [
    RevitCategory.Column,
    mapToBiMap([
      [GeometricProperty.LENGTH, MeshAlgorithmType.BOUNDING_BOX_LENGTH],
      [
        GeometricProperty.WIDTH,
        MeshAlgorithmType.BOUNDING_BOX_HORIZONTAL_LENGTH,
      ],
      [GeometricProperty.VOLUME, MeshAlgorithmType.TETRAHEDRON_VOLUME],
    ]),
  ],
]);
