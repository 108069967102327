import { isEmpty, isNil } from 'lodash';
import { InferType, array, object, string } from 'yup';

const labelsSchema = array()
  .of(object({ key: string().required(), value: string().optional() }))
  .required();

export type Labels = InferType<typeof labelsSchema>;

export const parseLabels = (labelsString?: string | null | undefined): Labels =>
  isNil(labelsString) ||
  isEmpty(labelsString) ||
  labelsString.includes('[null]')
    ? []
    : labelsSchema.validateSync(labelsString);
