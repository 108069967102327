import {
  Box,
  Button,
  Divider,
  FormLabel,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Switch,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { useMutation } from 'urql';
import { useFlag } from '@unleash/proxy-client-react';
import {
  Project,
  TranslationType,
  UpdateProjectDocument,
} from '../../gql/graphql';

type SettingsProps = {
  project: Pick<Project, 'id' | 'settings'>;
};

export default function AdvancedSettingsTab({
  project,
}: SettingsProps): React.ReactElement {
  const [, updateProject] = useMutation(UpdateProjectDocument);

  const enablePolyCountLimitSetting = useFlag(
    'enable-poly-count-limit-setting'
  );

  const { t } = useTranslation('project');

  const enableManuallyApplyFilters = useFlag('enable-manually-apply-filters');

  const toast = useToast();

  const handleTranslationTypeSubmit = async (
    translationType: TranslationType
  ) => {
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            translationType: translationType,
          },
        },
      },
    });
    toast({
      title: 'Translation type updated',
      status: 'success',
    });
  };

  const [polygonLimitValue, setPolygonLimitValue] = useState(
    project.settings?.polygonCalculationLimit || 5000
  );

  const handlePolygonLimitSubmit = useCallback(async () => {
    await updateProject({
      input: {
        id: project.id,
        patch: {
          settings: {
            ...project.settings,
            polygonCalculationLimit: polygonLimitValue,
          },
        },
      },
    });
    toast({
      title: 'Polygon calculation limit updated',
      status: 'success',
    });
  }, [polygonLimitValue, project.id, project.settings, toast, updateProject]);

  const onManuallyApplyFiltersChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const result = await updateProject({
        input: {
          id: project.id,
          patch: {
            settings: {
              ...project.settings,
              manuallyApplyFilters: e.target.checked,
            },
          },
        },
      });

      if (result.error) {
        toast({
          title: 'Failed to update manually apply filters setting',
          status: 'error',
        });
      }
    },
    [project.id, project.settings, toast, updateProject]
  );

  return (
    <VStack alignItems={'start'}>
      {enableManuallyApplyFilters && (
        <Box marginTop={4}>
          <Switch
            defaultChecked={!!project.settings?.manuallyApplyFilters}
            onChange={onManuallyApplyFiltersChange}
            colorScheme="cyan"
          >
            Manually apply filters for dynamic linking
          </Switch>
          <Divider marginY={4} />
        </Box>
      )}
      <Box>
        <FormLabel>IFC translation type</FormLabel>
        <Select
          value={
            project.settings?.translationType
              ? project.settings?.translationType
              : 'modern'
          }
          // Patch
          onChange={(e) =>
            handleTranslationTypeSubmit(e.target.value as TranslationType)
          }
          variant={'filled'}
          borderRadius={'md'}
        >
          {Object.values(TranslationType).map((value) => (
            <option key={value}>{value}</option>
          ))}
        </Select>
      </Box>
      {enablePolyCountLimitSetting && (
        <Box>
          <FormLabel>Polygon calculation limit</FormLabel>
          <HStack>
            <NumberInput
              defaultValue={project.settings?.polygonCalculationLimit || 5000}
              min={500}
              max={50000}
              step={100}
              variant={'filled'}
              inputMode="numeric"
              precision={0}
              onChange={(_, numericValue) => setPolygonLimitValue(numericValue)}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {polygonLimitValue !==
              project.settings?.polygonCalculationLimit && (
              <Button variant={'outline'} onClick={handlePolygonLimitSubmit}>
                {t('settings.apply')}
              </Button>
            )}
          </HStack>
        </Box>
      )}
    </VStack>
  );
}
