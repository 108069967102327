import { Box, BoxProps } from '@chakra-ui/react';
import { useDrag } from '@use-gesture/react';
import { useState, useCallback, useMemo } from 'react';
import { Point, Point2 } from '../../../domain/geometry/geometric-types';
import { projectInDOM, useCameraChangedEvent } from './util';

type VertexProps = BoxProps & {
  point: Point;
  viewer: Autodesk.Viewing.Viewer3D;
  theme: 'default' | 'inverted';
};
export const Vertex = ({
  point,
  viewer,
  theme = 'default',
  ...boxProps
}: VertexProps) => {
  // Just use an empty object so we can notify the component when the camera changes

  const [cameraPosition, setCameraPosition] = useState({});
  const position2D = useMemo<Point2>(
    () => projectInDOM(point, viewer),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [point, viewer, cameraPosition]
  );

  useCameraChangedEvent(
    viewer,
    useCallback(() => {
      setCameraPosition({});
    }, [])
  );

  return (
    <Box
      position="absolute"
      zIndex={1}
      // Placed in style for performance reasons
      style={{
        left: position2D[0] - 5,
        top: position2D[1] - 5,
      }}
      width="10px"
      height="10px"
      borderRadius={'50%'}
      {...(theme === 'inverted'
        ? { borderColor: 'white', backgroundColor: 'cyan.500' }
        : { borderColor: 'cyan.500', backgroundColor: 'white' })}
      borderWidth={'2px'}
      {...boxProps}
    ></Box>
  );
};

type DraggableVertexProps = VertexProps & {
  onVertexDrag?: (event: MouseEvent) => void;
};

export const DraggableVertex = ({
  onVertexDrag,
  ...rest
}: DraggableVertexProps) => {
  const bindDrag = useDrag<MouseEvent>(
    (state) => {
      if (onVertexDrag) {
        onVertexDrag(state.event);
      }
    },
    {
      filterTaps: true,
      delay: true,
    }
  );
  return (
    <Vertex
      cursor={'move'}
      _hover={{
        filter: 'brightness(1.3)',
      }}
      {...bindDrag()}
      {...rest}
    />
  );
};
