import { useCallback, useEffect, useState } from 'react';
import { Point } from '../../../domain/geometry/geometric-types';
import { getCanvasMousePosition } from '../../common/ForgeViewer';
import {
  InitialShapeDrawingState,
  PointDrawingResult,
  ShapeDrawingMode,
} from './ShapeDrawing';
import {
  getSnapPoint,
  isPrimaryMouseButton,
  useSnapper,
  useViewerClickEvent,
} from './util';
import { DraggableVertex } from './Vertex';

export const PointShapeDrawing = ({
  initialState,
  viewer,
  onResult,
}: {
  initialState?: InitialShapeDrawingState[ShapeDrawingMode.Point];
  viewer: Autodesk.Viewing.GuiViewer3D;
  onResult: (result: PointDrawingResult) => void;
}) => {
  const [point, setPoint] = useState<Point | null>(initialState?.point ?? null);

  const snapper = useSnapper(viewer);

  const emitResult = useCallback(
    (point: Point | null) => {
      if (point) {
        onResult({ point, valid: true });
      } else {
        onResult({ point: null, valid: false });
      }
    },
    [onResult]
  );

  const getPoint = useCallback((): Point | null => {
    if (snapper.isSnapped()) {
      snapper.indicator.render();
      return getSnapPoint(snapper, viewer);
    }

    return null;
  }, [snapper, viewer]);

  const handleViewerClick = useCallback(
    (event: MouseEvent) => {
      if (!isPrimaryMouseButton(event.button)) {
        return false;
      }

      const newPoint = getPoint();

      if (!newPoint) {
        return true;
      }

      setPoint(newPoint);
      emitResult(newPoint);

      return true; // Stop the event from going to other tools in the stack
    },
    [emitResult, getPoint]
  );

  useViewerClickEvent(viewer, handleViewerClick);

  const handlePointerMove = useCallback(() => {
    snapper.indicator.clearOverlays();

    const newPoint = getPoint();
    if (!newPoint) {
      return;
    }
  }, [getPoint, snapper.indicator]);

  useEffect(() => {
    viewer.container.addEventListener('pointermove', handlePointerMove);
    return () => {
      viewer.container.removeEventListener('pointermove', handlePointerMove);
    };
  }, [handlePointerMove, snapper.indicator, viewer]);

  const handleVertexDrag = (event: MouseEvent) => {
    const { canvasX, canvasY } = getCanvasMousePosition(
      event.clientX,
      event.clientY
    );
    snapper.onMouseMove({
      x: canvasX,
      y: canvasY,
    });
    snapper.indicator.render();
    const newPoint = getPoint();
    if (!newPoint) {
      return;
    }
    setPoint(newPoint);

    if (event.type === 'pointerup') {
      emitResult(newPoint);
    }
  };

  return (
    point && (
      <DraggableVertex
        theme={'default'}
        point={point}
        viewer={viewer}
        onVertexDrag={(event) => handleVertexDrag(event)}
      />
    )
  );
};
